import React, { useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import { Stack, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import SectionMessage from '@atlaskit/section-message';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { messages } from '../common/messages.tsx';
import { FieldTitleWithIcon } from '../common/ui/field-title-with-icon/index.tsx';
import { fieldTypeNameForPolarisFieldType } from '../common/utils/field-type-name/index.tsx';
import { useCallbacks } from '../controllers/selectors/callback-hooks.tsx';
import { useComponents } from '../controllers/selectors/components-hooks.tsx';
import {
	useCanEditField,
	useIsCollectionView,
	useIsPreview,
} from '../controllers/selectors/config-hooks.tsx';
import {
	useFieldDescription,
	useFieldLabel,
	useFieldTypeIcon,
	useFieldKey,
	useFieldType,
	useIsGlobalCustomField,
	useFieldValueDecorations,
	useIsGlobalNonSystemField,
	useIsGlobalSystemField,
	useIsGlobalSystemFieldWithProjectLevelConfiguration,
} from '../controllers/selectors/field-hooks.tsx';
import { IntervalOverrideConfiguration } from './configuration/interval-override/index.tsx';
import { PeopleConfiguration } from './configuration/people/index.tsx';
import { DecorationConfigContent } from './decoration/decoration-config-content/index.tsx';
import { DecorationConfigHeader } from './decoration/decoration-config-header/index.tsx';
import { FieldMainPropertiesEditor } from './field-main-properties-editor/index.tsx';

// field types that support read only config sections to be shown
const SUPPORTED_READONLY_CONFIG_SECTION_TYPES: FieldType[] = [
	FIELD_TYPES.SINGLE_SELECT,
	FIELD_TYPES.MULTI_SELECT,
	FIELD_TYPES.JSW_MULTI_SELECT,
	FIELD_TYPES.RATING,
	FIELD_TYPES.PROJECT,
];

export const FieldConfigurationInternal = () => {
	const fieldDescription = useFieldDescription();
	const label = useFieldLabel();
	const fieldType = useFieldType();
	const { onUpdateDescription, onUpdateFieldName, onUpdateEmoji } = useCallbacks();
	const { renderConfigSection, formulaContent } = useComponents();
	// TODO: uncomment this line once we have the formula content
	// const { updateFieldFormula, updateFieldConfiguration, updateLabel, updateDescription } =
	//     useFieldActions();
	// const updateDynamicField = useUpdateDynamicField();
	const icon = useFieldTypeIcon();
	const fieldKey = useFieldKey();
	const isFieldWithOptions =
		fieldType === FIELD_TYPES.SINGLE_SELECT ||
		fieldType === FIELD_TYPES.MULTI_SELECT ||
		fieldType === FIELD_TYPES.JSW_MULTI_SELECT ||
		fieldType === FIELD_TYPES.CUSTOM_LABELS ||
		fieldType === FIELD_TYPES.LABELS;
	const fieldDecorations = useFieldValueDecorations();
	const isFieldWithDecorations = fieldDecorations.length > 0;
	const { formatMessage } = useIntl();
	const canEditField = useCanEditField();
	const isPreview = useIsPreview();
	const isGlobalCustomField = useIsGlobalCustomField();
	const isGlobalNonSystemField = useIsGlobalNonSystemField();
	const isGlobalSystemField = useIsGlobalSystemField();
	const isGlobalSystemFieldWithProjectLevelConfiguration =
		useIsGlobalSystemFieldWithProjectLevelConfiguration();
	const isCollectionView = useIsCollectionView();

	const showSystemFieldProjectLevelConfigInfo = fg('polaris_display-gsf-as-a-regular-project-field')
		? isCollectionView && isGlobalSystemFieldWithProjectLevelConfiguration
		: false;

	const handleFieldNameUpdate = useCallback(
		async (updatedLabel?: string) => {
			if (updatedLabel === undefined || !onUpdateFieldName) {
				return false;
			}

			return onUpdateFieldName(updatedLabel);
		},
		[onUpdateFieldName],
	);

	const handleUpdateDescription = useCallback(
		async (value: string) => {
			if (value !== fieldDescription.value) {
				onUpdateDescription?.(value);
			}
		},
		[fieldDescription.value, onUpdateDescription],
	);

	const fieldCanHaveOptions = [
		FIELD_TYPES.SINGLE_SELECT,
		FIELD_TYPES.MULTI_SELECT,
		FIELD_TYPES.JSW_MULTI_SELECT,
	].some((t) => t === fieldType);
	const fieldCanHaveDecorations = [
		FIELD_TYPES.NUMBER,
		FIELD_TYPES.FORMULA,
		FIELD_TYPES.LINKED_ISSUES,
		FIELD_TYPES.CHECKBOX,
		FIELD_TYPES.SLIDER,
		FIELD_TYPES.RATING,
		FIELD_TYPES.INTERVAL,
	].some((t) => t === fieldType);

	const fieldsWithNoDecorations = [
		FIELD_TYPES.CREATED,
		FIELD_TYPES.UPDATED,
		FIELD_TYPES.INSIGHTS,
	].some((t) => t === fieldType);

	const displayFormattingSection =
		!fieldsWithNoDecorations &&
		(!isPreview ||
			(fieldCanHaveOptions && isFieldWithOptions) ||
			(fieldCanHaveDecorations && isFieldWithDecorations));

	const formattingSection = useMemo(() => {
		if (!displayFormattingSection) {
			return null;
		}

		return (
			<FormattingSection isFieldWithOptions={isFieldWithOptions} isPreview={isPreview}>
				<SectionContent data-component-selector="field-item-section-content-kU39">
					<DecorationConfigContent decorationConfigHeader={<DecorationConfigHeader />} />
				</SectionContent>
			</FormattingSection>
		);
	}, [displayFormattingSection, isPreview, isFieldWithOptions]);

	const displayConfigSection = useMemo(() => {
		if (fg('polaris_display-gsf-as-a-regular-project-field')) {
			return (
				(!isCollectionView || !isGlobalSystemField) &&
				(canEditField || (fieldType && SUPPORTED_READONLY_CONFIG_SECTION_TYPES.includes(fieldType)))
			);
		}

		return (
			canEditField || (fieldType && SUPPORTED_READONLY_CONFIG_SECTION_TYPES.includes(fieldType))
		);
	}, [canEditField, fieldType, isCollectionView, isGlobalSystemField]);

	if (fieldKey === undefined) {
		return null;
	}

	const fieldTypeName = fieldTypeNameForPolarisFieldType(fieldType);
	const fieldTypeNameWithSuffix = formatMessage(messages.fieldTypeLabel, {
		fieldTypeName: fieldTypeName ? `${formatMessage(fieldTypeName)} ` : '',
	});
	const title = isPreview ? label : fieldTypeNameWithSuffix;

	const configSection = renderConfigSection
		? renderConfigSection(formattingSection)
		: formattingSection;

	const isTitleOnlyPreview = isPreview && !displayFormattingSection && !fieldDescription.value;

	return (
		<Stack xcss={containerStyles}>
			<FieldConfigurationSection isPreview={isPreview}>
				<SectionHeader isPreview={isPreview} isTitleOnlyPreview={isTitleOnlyPreview}>
					<FieldTitleWithIcon
						icon={icon}
						isGlobalNonSystemField={
							fg('polaris_display-gsf-as-a-regular-project-field')
								? isGlobalNonSystemField
								: isGlobalCustomField
						}
						isPreview={isPreview}
						title={title}
						fieldType={fieldType}
					/>
				</SectionHeader>
				<SectionContent data-component-selector="field-item-section-content-kU39">
					<FieldMainPropertiesEditor
						onUpdateFieldName={handleFieldNameUpdate}
						onUpdateDescription={handleUpdateDescription}
						onUpdateEmoji={onUpdateEmoji}
					/>
				</SectionContent>
			</FieldConfigurationSection>
			{canEditField && !isPreview && fieldType === FIELD_TYPES.INTERVAL && (
				<FieldConfigurationSection isPreview={isPreview}>
					<IntervalOverrideConfiguration />
				</FieldConfigurationSection>
			)}
			{canEditField &&
				!isPreview &&
				(fieldType === FIELD_TYPES.PEOPLE || fieldType === FIELD_TYPES.JSW_PEOPLE) && (
					<FieldConfigurationSection isPreview={isPreview}>
						<PeopleConfiguration />
					</FieldConfigurationSection>
				)}
			{formulaContent && <FieldConfigurationSection>{formulaContent}</FieldConfigurationSection>}
			{showSystemFieldProjectLevelConfigInfo && (
				<SectionMessage>
					{formatMessage(messages.systemFieldProjectLevelConfigText, { fieldLabel: label })}
				</SectionMessage>
			)}
			{displayConfigSection ? configSection : null}
		</Stack>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FieldConfigurationSection = styled.div<{
	isPreview?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBlock: ({ isPreview }) =>
		isPreview ? '0' : `${token('space.0', '0')} ${token('space.200')}`,
	marginInline: '0',
	'&:empty': {
		display: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-of-type': {
		height: '100%',
	},
});

const containerStyles = xcss({
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionHeader = styled.div<{
	isFieldWithOptions?: boolean;
	isPreview?: boolean;
	isTitleOnlyPreview?: boolean;
}>({
	display: 'flex',
	alignItems: 'center',
	justifyItems: 'center',
	justifyContent: 'space-between',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	font: ({ isPreview }) => (isPreview ? token('font.body') : token('font.body.small')),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 'inherit',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingBottom: ({ isTitleOnlyPreview }) => (isTitleOnlyPreview ? '0' : token('space.100')),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingInline: ({ isFieldWithOptions }) => (isFieldWithOptions ? token('space.200') : '0'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ isFieldWithOptions }) => (isFieldWithOptions ? '24px' : undefined),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionContent = styled.div<{ isFieldWithOptions?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N100),

	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FormattingSection = styled(FieldConfigurationSection)<{
	isFieldWithOptions: boolean;
	isPreview?: boolean;
}>({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'div:has(~ [data-component-selector="field-item-section-content-kU39"]:empty)': {
		display: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minHeight: ({ isFieldWithOptions, isPreview }) =>
		isFieldWithOptions && !isPreview ? '400px' : undefined,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBlock: ({ isFieldWithOptions, isPreview }) =>
		isFieldWithOptions || isPreview ? 0 : undefined,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginInline: ({ isFieldWithOptions, isPreview }) =>
		isFieldWithOptions || isPreview ? 0 : undefined,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingBlock: ({ isFieldWithOptions, isPreview }) =>
		isFieldWithOptions && !isPreview ? token('space.100') : undefined,
});
