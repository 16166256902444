import differenceBy from 'lodash/differenceBy';
import intersectionBy from 'lodash/intersectionBy';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { ConnectionsBulkRequestInput } from '@atlassian/jira-polaris-remote-issue/src/services/jira/connection/types.tsx';
import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import { createGetConnectionFieldIssueIds } from '../../selectors/connection.tsx';
import { getLocalIssueIdToJiraId } from '../../selectors/issue-ids.tsx';
import type { Props, State } from '../../types.tsx';
import { isConnectionFieldValue } from '../../utils/field-mapping/connection/index.tsx';
import { updateConnectionsProperties } from '../common/connection/index.tsx';
import {
	createGetUpdateIssueFieldsBulkProgress,
	getFailedConnections,
	getFilteredIssuesForConnections,
	logUpdateIssueConnectionsError,
} from './utils.tsx';

type UpdateIssueConnectionsRequest = {
	localIssueId: LocalIssueId;
	issuesToConnect?: ConnectionFieldValue[];
	issuesToDisconnect?: ConnectionFieldValue[];
	onError?: (error: Error) => void;
};

class UpdateIssueConnectionsError extends Error {}

export const updateIssueConnections =
	({
		localIssueId,
		issuesToConnect = [],
		issuesToDisconnect = [],
		onError,
	}: UpdateIssueConnectionsRequest): Action<State, Props> =>
	async ({ setState, getState }, props) => {
		const state = getState();
		const previousProperties = { ...getState().properties };
		const localIssueIdToJiraId = getLocalIssueIdToJiraId(state, props);
		const sourceIssueId = localIssueIdToJiraId[localIssueId];
		const issuesToConnectFiltered = getFilteredIssuesForConnections(
			localIssueId,
			issuesToConnect,
			state,
			props,
		);

		if (!issuesToConnectFiltered.length && !issuesToDisconnect.length) {
			return;
		}

		const { issuesRemote, onIssueUpdateFailed } = props;

		const optimisticProperties = updateConnectionsProperties({
			issuesToDisconnect,
			issuesToConnect: issuesToConnectFiltered,
			localIssueId,
			state,
			props,
		});

		setState({
			properties: optimisticProperties,
		});

		let failedAddConnections: ConnectionFieldValue[] | undefined;
		let failedDeleteConnections: ConnectionFieldValue[] | undefined;

		try {
			const addConnectionsPromise =
				issuesToConnectFiltered.length > 0
					? issuesRemote
							.createConnections({
								issueFrom: sourceIssueId,
								issueTo: issuesToConnectFiltered.map(({ id }) => id),
							})
							.then(({ failures }) =>
								getFailedConnections(failures, issuesToConnectFiltered, 'create'),
							)
					: Promise.resolve([]);

			const deleteConnectionsPromise =
				issuesToDisconnect.length > 0
					? issuesRemote
							.deleteConnections({
								issueFrom: sourceIssueId,
								issueTo: issuesToDisconnect.map(({ id }) => id),
							})
							.then(({ failures }) => getFailedConnections(failures, issuesToDisconnect, 'delete'))
					: Promise.resolve([]);

			const [failedAddConnectionsResult, failedDeleteConnectionsResult] = await Promise.allSettled([
				addConnectionsPromise,
				deleteConnectionsPromise,
			]);

			if (failedAddConnectionsResult.status === 'rejected') {
				logUpdateIssueConnectionsError(failedAddConnectionsResult.reason, 'create');
				failedAddConnections = issuesToConnectFiltered;
			} else {
				failedAddConnections = failedAddConnectionsResult.value;
			}

			if (failedDeleteConnectionsResult.status === 'rejected') {
				logUpdateIssueConnectionsError(failedDeleteConnectionsResult.reason, 'delete');
				failedDeleteConnections = issuesToDisconnect;
			} else {
				failedDeleteConnections = failedDeleteConnectionsResult.value;
			}

			if (failedAddConnections.length || failedDeleteConnections.length) {
				throw new UpdateIssueConnectionsError();
			}
		} catch (error) {
			if (error instanceof Error) {
				if (error instanceof UpdateIssueConnectionsError) {
					// revert optimistic update for failed connections
					const revertedProperties = updateConnectionsProperties({
						issuesToDisconnect: failedAddConnections ?? issuesToConnectFiltered,
						issuesToConnect: failedDeleteConnections ?? issuesToDisconnect,
						localIssueId,
						state: {
							...state,
							properties: optimisticProperties,
						},
						props,
					});

					setState({ properties: revertedProperties });
				} else {
					// Log error in case of runtime error caused by code in this action or it's dependencies
					logUpdateIssueConnectionsError(error);
					// and revert all changed properties in case of runtime error
					setState({ properties: previousProperties });
				}

				onIssueUpdateFailed(error);
				onError?.(error);
			}
		}
	};

type UpdateIssueConnectionsBulkMap = Map<
	LocalIssueId,
	{
		issuesToConnect: ConnectionFieldValue[];
		issuesToDisconnect: ConnectionFieldValue[];
	}
>;

export const updateIssueConnectionsBulk =
	(updatesMap: UpdateIssueConnectionsBulkMap): Action<State, Props> =>
	async ({ setState, getState }, props) => {
		if (updatesMap.size === 0) {
			return;
		}

		const { issuesRemote, onIssueUpdateFailed, onIssueBulkUpdate } = props;

		const previousProperties = { ...getState().properties };
		const optimisticState = { ...getState() };
		const localIssueIdToJiraId = getLocalIssueIdToJiraId(optimisticState, props);

		const addConnections: ConnectionsBulkRequestInput = [];
		const deleteConnections: ConnectionsBulkRequestInput = [];

		updatesMap.forEach(({ issuesToConnect, issuesToDisconnect }, localIssueId) => {
			const issuesToConnectFiltered = getFilteredIssuesForConnections(
				localIssueId,
				issuesToConnect,
				optimisticState,
				props,
			);

			if (!issuesToConnectFiltered.length && !issuesToDisconnect.length) {
				return;
			}

			optimisticState.properties = updateConnectionsProperties({
				issuesToDisconnect,
				issuesToConnect: issuesToConnectFiltered,
				localIssueId,
				state: optimisticState,
				props,
			});

			if (issuesToConnectFiltered.length > 0) {
				addConnections.push({
					issueFrom: localIssueIdToJiraId[localIssueId],
					issueTo: issuesToConnectFiltered.map(({ id }) => id),
				});
			}

			if (issuesToDisconnect.length > 0) {
				deleteConnections.push({
					issueFrom: localIssueIdToJiraId[localIssueId],
					issueTo: issuesToDisconnect.map(({ id }) => id),
				});
			}
		});

		if (addConnections.length === 0 && deleteConnections.length === 0) {
			return;
		}

		setState(optimisticState);

		try {
			const [createConnectionsResponse, deleteConnectionsResponse] = await Promise.all([
				addConnections.length > 0 ? issuesRemote.createConnectionsBulk(addConnections) : undefined,
				deleteConnections.length > 0
					? issuesRemote.deleteConnectionsBulk(deleteConnections)
					: undefined,
			]);

			onIssueBulkUpdate({
				getUpdateIssueFieldsBulkProgress: createGetUpdateIssueFieldsBulkProgress(
					createConnectionsResponse,
					deleteConnectionsResponse,
					props,
				),
				taskId: `${createConnectionsResponse?.taskId ?? ''}-${deleteConnectionsResponse?.taskId ?? ''}`,
			});
		} catch (error) {
			setState({ properties: previousProperties });

			if (error instanceof Error) {
				logUpdateIssueConnectionsError(error, undefined, true);

				onIssueUpdateFailed(error);
			}
		}
	};

type UpdateConnectionFieldValueRequest = {
	fieldKey: FieldKey;
	localIssueIds: LocalIssueId[];
	newValue: unknown | undefined;
	removeValue?: unknown | undefined;
	appendMultiValues: boolean;
	onError?: (error: Error) => void;
};

export const updateConnectionFieldValue =
	({
		fieldKey,
		localIssueIds,
		newValue: newValueUnknown,
		removeValue: removeValueUnknown,
		appendMultiValues,
		onError,
	}: UpdateConnectionFieldValueRequest): Action<State, Props> =>
	({ getState, dispatch }, props) => {
		if (localIssueIds.length === 0) {
			return;
		}

		const state = getState();
		const newValue = Array.isArray(newValueUnknown)
			? newValueUnknown.filter(isConnectionFieldValue)
			: [];
		const removeValue = Array.isArray(removeValueUnknown)
			? removeValueUnknown.filter(isConnectionFieldValue)
			: [];

		const updateIssueConnectionsBulkRequest = localIssueIds.reduce<UpdateIssueConnectionsBulkMap>(
			(acc, localIssueId) => {
				const currentConnections = createGetConnectionFieldIssueIds(fieldKey, localIssueId)(
					state,
					props,
				);

				const issuesToConnect = differenceBy(newValue, currentConnections, 'id');
				const issuesToDisconnect = appendMultiValues
					? intersectionBy(removeValue, currentConnections, 'id')
					: differenceBy(currentConnections, newValue, 'id');

				acc.set(localIssueId, { issuesToConnect, issuesToDisconnect });

				return acc;
			},
			new Map(),
		);

		if (localIssueIds.length === 1) {
			const localIssueId = localIssueIds[0];
			dispatch(
				updateIssueConnections({
					localIssueId,
					issuesToConnect: updateIssueConnectionsBulkRequest.get(localIssueId)?.issuesToConnect,
					issuesToDisconnect:
						updateIssueConnectionsBulkRequest.get(localIssueId)?.issuesToDisconnect,
					onError,
				}),
			);
		} else {
			dispatch(updateIssueConnectionsBulk(updateIssueConnectionsBulkRequest));
		}
	};
