import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	caption: {
		id: 'polaris-ideas.view-header.import-button.caption',
		defaultMessage: 'Import',
		description: 'Caption of the button to import ideas from a file.',
	},
	captionMenuItem: {
		id: 'polaris-ideas.view-header.import-button.caption-menu-item',
		defaultMessage: 'Import issues',
		description: 'Caption of the menu element to import ideas from a file.',
	},
});
