import React, { type ComponentType } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { FilterComponentProps } from './types.tsx';
import { IssueTypeFilter } from './filters/issue-type-filter.tsx';
import { ConnectionFilter } from './filters/connection-filter/connection-filter.tsx';
import { PolarisIdeaIssueIdFilter } from './filters/issue-id-filter.tsx';
import { InsightsFilter } from './filters/insights-filter.tsx';
import { CheckboxesFilter } from './filters/checkboxes-filter.tsx';
import { NumericFilter } from './filters/numeric-filter.tsx';
import { MultiOptionFilter } from './filters/multi-option-filter.tsx';
import { IntervalFilter } from './filters/interval-filter.tsx';
import { DeliveryFilter } from './filters/delivery-filter.tsx';
import { FilterComponent as FilterComponentOld } from './index-old.tsx';

type FieldTypes = typeof FIELD_TYPES;

// We use Partial because not all FieldTypes have a corresponding renderer;
// Maybe it should be revised.
const filterComponentMapping: Partial<
	Record<
		FieldTypes[keyof FieldTypes],
		// Values in this record are functions to make it easier to evaluate feature gates
		() => ComponentType<FilterComponentProps> | null
	>
> = {
	[FIELD_TYPES.INSIGHTS]: () => InsightsFilter,

	[FIELD_TYPES.CHECKBOX]: () => CheckboxesFilter,

	[FIELD_TYPES.NUMBER]: () => NumericFilter,
	[FIELD_TYPES.RATING]: () => NumericFilter,
	[FIELD_TYPES.SLIDER]: () => NumericFilter,
	[FIELD_TYPES.FORMULA]: () => NumericFilter,
	[FIELD_TYPES.LINKED_ISSUES]: () => NumericFilter,

	[FIELD_TYPES.ISSUE_TYPE]: () => (fg('jpd_issues_relationships') ? IssueTypeFilter : null),

	[FIELD_TYPES.CONNECTION]: () => (fg('jpd_issues_relationships') ? ConnectionFilter : null),

	[FIELD_TYPES.ASSIGNEE]: () => MultiOptionFilter,
	[FIELD_TYPES.CREATOR]: () => MultiOptionFilter,
	[FIELD_TYPES.REPORTER]: () => MultiOptionFilter,
	[FIELD_TYPES.PEOPLE]: () => MultiOptionFilter,
	[FIELD_TYPES.JSW_PEOPLE]: () => MultiOptionFilter,
	[FIELD_TYPES.SINGLE_SELECT]: () => MultiOptionFilter,
	[FIELD_TYPES.MULTI_SELECT]: () => MultiOptionFilter,
	[FIELD_TYPES.JSW_MULTI_SELECT]: () => MultiOptionFilter,
	[FIELD_TYPES.LABELS]: () => MultiOptionFilter,
	[FIELD_TYPES.CUSTOM_LABELS]: () => MultiOptionFilter,
	[FIELD_TYPES.STATUS]: () => MultiOptionFilter,
	[FIELD_TYPES.ATLAS_GOAL]: () => MultiOptionFilter,
	[FIELD_TYPES.ATLAS_PROJECT]: () => MultiOptionFilter,
	[FIELD_TYPES.ATLAS_PROJECT_STATUS]: () => MultiOptionFilter,
	[FIELD_TYPES.REACTIONS]: () => MultiOptionFilter,
	[FIELD_TYPES.PROJECT]: () => MultiOptionFilter,
	[FIELD_TYPES.PLATFORM_GOALS]: () => MultiOptionFilter,
	[FIELD_TYPES.TEAM]: () => MultiOptionFilter,

	[FIELD_TYPES.ISSUE_ID]: () => PolarisIdeaIssueIdFilter,

	[FIELD_TYPES.DATE]: () => IntervalFilter,
	[FIELD_TYPES.CREATED]: () => IntervalFilter,
	[FIELD_TYPES.UPDATED]: () => IntervalFilter,
	[FIELD_TYPES.INTERVAL]: () => IntervalFilter,

	[FIELD_TYPES.DELIVERY_PROGRESS]: () => DeliveryFilter,
	[FIELD_TYPES.DELIVERY_STATUS]: () => DeliveryFilter,
};

const FilterComponentNew = (props: FilterComponentProps) => {
	const Component = (filterComponentMapping[props.field.type] ?? (() => null))();

	if (Component === null) {
		return null;
	}

	return <Component {...props} />;
};

export const FilterComponent = functionWithCondition(
	() => fg('jpd_decompose_sidebar_filtering'),
	FilterComponentNew,
	FilterComponentOld,
);
