import React from 'react';
import { Stack, Text, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useFieldActions } from '../../../../../controllers/field/main.tsx';
import {
	useConnectionFieldIssueTypeIds,
	useIssueTypeField,
} from '../../../../../controllers/field/selectors/field-hooks.tsx';
import { useIssueActions } from '../../../../../controllers/issue/index.tsx';
import { FilterComponent } from './filter-component/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	fieldKey: FieldKey;
	readonly: boolean;
};

export const ConnectionFieldFilters = ({ fieldKey, readonly }: Props) => {
	const { formatMessage } = useIntl();
	const { updateConnectionFieldFilter } = useFieldActions();

	const connectionFieldIssueTypeIds = useConnectionFieldIssueTypeIds(fieldKey);
	const [issueTypeField] = useIssueTypeField();

	const { refreshConnectionFieldsValues, resetConnectionFieldsValues } = useIssueActions();

	const handleUpdateFilters = async (values: string[]) => {
		resetConnectionFieldsValues([fieldKey]);
		await updateConnectionFieldFilter(fieldKey, {
			type: 'id',
			ids: values,
		});
		await refreshConnectionFieldsValues([fieldKey]);
	};

	if (!issueTypeField) {
		return null;
	}

	return (
		<Stack xcss={containerStyles} space="space.100">
			<Text weight="semibold">{formatMessage(messages.headerNonFinal)}</Text>
			<FilterComponent
				field={issueTypeField}
				values={connectionFieldIssueTypeIds}
				isDisabled={readonly}
				onChange={handleUpdateFilters}
			/>
		</Stack>
	);
};

const containerStyles = xcss({
	paddingBlock: 'space.200',
});
