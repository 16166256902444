import React, { type ReactNode, type MouseEvent, type ComponentType } from 'react';
import noop from 'lodash/noop';
import { IconButton } from '@atlaskit/button/new';
import DeleteIcon from '@atlaskit/icon/core/delete';
import EditIcon from '@atlaskit/icon/core/edit';
import { Flex, Inline, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import type {
	ConfigureSource,
	DeleteActionSubjectId,
	EditActionSubjectId,
} from '@atlassian/jira-issue-status-and-types-common/src/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

export type InlineConfigButtonsProps<QueryParams> = {
	children: ReactNode;
	source?: ConfigureSource;
	editActionSubjectId?: EditActionSubjectId;
	deleteActionSubjectId?: DeleteActionSubjectId;
	showEditButton?: boolean;
	showDeleteButton?: boolean;
	isFocused: boolean;
	editLabelText?: string;
	deleteLabelText?: string;
	queryParams: QueryParams;
	onEditButtonClick?: (ev: MouseEvent<HTMLElement>) => void;
	EditButtonWrapper?: ComponentType<{
		children: ReactNode;
		queryParams: QueryParams;
		onClick?: (ev: MouseEvent<HTMLElement>) => void;
	}>;
	DeleteButtonWrapper?: ComponentType<{
		children: ReactNode;
		queryParams: QueryParams;
	}>;
};

export const InlineConfigButtons = <QueryParams extends {}>({
	children,
	source,
	editActionSubjectId,
	deleteActionSubjectId,
	showEditButton,
	showDeleteButton,
	isFocused,
	EditButtonWrapper,
	DeleteButtonWrapper,
	queryParams,
	editLabelText,
	deleteLabelText,
	onEditButtonClick,
}: InlineConfigButtonsProps<QueryParams>) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onFireRequiredAnalyticsForEditButtonClick = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			editActionSubjectId ?? '',
			{
				source,
			},
		);
	};

	const onFireRequiredAnalyticsForDeleteButtonClick = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			deleteActionSubjectId ?? '',
			{
				source,
			},
		);
	};

	const handleOnEditButtonMouseDown = (e: MouseEvent | React.MouseEvent) => {
		// fire event on left click only
		if (e.button === 0) {
			onFireRequiredAnalyticsForEditButtonClick();
		}
	};

	const handleOnDeleteButtonMouseDown = (e: MouseEvent | React.MouseEvent) => {
		// fire event on left click only
		if (e.button === 0) {
			onFireRequiredAnalyticsForDeleteButtonClick();
		}
	};

	const handleOnEditButtonKeyDown = (e: KeyboardEvent | React.KeyboardEvent) => {
		if (e.code === 'Enter' || e.code === 'Space') {
			onFireRequiredAnalyticsForEditButtonClick();
		}
	};

	const handleOnDeleteButtonKeyDown = (e: KeyboardEvent | React.KeyboardEvent) => {
		if (e.code === 'Enter' || e.code === 'Space') {
			onFireRequiredAnalyticsForDeleteButtonClick();
		}
	};

	const handleClick = (e: MouseEvent) => {
		e.stopPropagation();
	};

	const renderButtons = isFocused || fg('jira_inline_config_buttons_styling_update');

	return (
		<Flex direction="row" columnGap="space.050">
			<Inline
				xcss={
					fg('jira_inline_config_buttons_styling_update') ? leftColumnStyleNew : leftColumnStyleOld
				}
			>
				{children}
			</Inline>
			{renderButtons && (
				<Inline
					xcss={
						// eslint-disable-next-line no-nested-ternary
						fg('jira_inline_config_buttons_styling_update')
							? isFocused
								? rightColumnStyleVisible
								: rightColumnStyleHidden
							: rightColumnStyleOld
					}
				>
					<Flex direction="row" columnGap="space.050">
						{showEditButton && EditButtonWrapper && (
							<EditButtonWrapper queryParams={queryParams} onClick={onEditButtonClick}>
								<Tooltip content={editLabelText ?? formatMessage(messages.editButtonText)}>
									<IconButton
										tabIndex={isFocused ? 0 : -1}
										appearance="subtle"
										onClick={
											fg('jira-list-view-stop-propagation-on-edit-icon-click') ||
											fg('jira_create_issue_type_in_gic_keyboard_navigation')
												? handleClick
												: noop
										}
										// onClick is not working well with Select component and ModalEntryPointPressableTrigger
										// Therefore, we need to handle event analytics using mouseDown, touchEnd and keyDown
										onMouseDown={handleOnEditButtonMouseDown}
										onTouchEnd={onFireRequiredAnalyticsForEditButtonClick}
										onKeyDown={handleOnEditButtonKeyDown}
										icon={EditIcon}
										label={editLabelText ?? formatMessage(messages.editButtonText)}
									/>
								</Tooltip>
							</EditButtonWrapper>
						)}
						{showDeleteButton && DeleteButtonWrapper && (
							<DeleteButtonWrapper queryParams={queryParams}>
								<Tooltip content={deleteLabelText ?? formatMessage(messages.deleteButtonText)}>
									<IconButton
										tabIndex={isFocused ? 0 : -1}
										appearance="subtle"
										onClick={
											fg('jira-list-view-stop-propagation-on-edit-icon-click') ? handleClick : noop
										}
										// onClick is not working well with Select component and ModalEntryPointPressableTrigger
										// Therefore, we need to handle event analytics using mouseDown, touchEnd and keyDown
										onMouseDown={handleOnDeleteButtonMouseDown}
										onTouchEnd={onFireRequiredAnalyticsForDeleteButtonClick}
										onKeyDown={handleOnDeleteButtonKeyDown}
										icon={DeleteIcon}
										label={deleteLabelText ?? formatMessage(messages.deleteButtonText)}
									/>
								</Tooltip>
							</DeleteButtonWrapper>
						)}
					</Flex>
				</Inline>
			)}
		</Flex>
	);
};

const leftColumnStyleOld = xcss({
	wordBreak: 'break-word',
	flex: 1,
});

const leftColumnStyleNew = xcss({
	wordBreak: 'break-word',
	flex: 1,
	alignItems: 'center',
});

const rightColumnStyleOld = xcss({
	width: 'auto',
	height: 'auto',
	marginTop: 'space.negative.075',
	marginBottom: 'space.negative.075',
	marginRight: 'space.negative.075',
});

const rightColumnStyleVisible = xcss({
	width: 'auto',
	height: 'auto',
});

const rightColumnStyleHidden = xcss({
	width: 'auto',
	height: 'auto',
	opacity: 0,
});
