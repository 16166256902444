import React, { useCallback, useState } from 'react';
import Button from '@atlaskit/button';
import EditorAddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldValuesUpdateRequest } from '@atlassian/jira-polaris-common/src/controllers/issue/actions/update-field-value/types.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useFieldLabel } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import {
	useCurrentViewGroupBy,
	useCurrentViewVerticalGroupBy,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import type { BoardProps } from '@atlassian/jira-polaris-lib-board/src/types/ui.tsx';
import { useOnCreatedOrMovedIssueFiltered } from '../../common/on-created-filtered-issue/index.tsx';
import { assertNonNullable } from '../utils/types.tsx';

import messages from './messages.tsx';
import { AddCard } from './search-and-create/index.tsx';

export const ColumnFooter: BoardProps['components']['ColumnFooter'] = ({
	fieldValue,
	groupIdentity,
	verticalFieldValue,
	verticalGroupIdentity,
	setSeparatorVisibility,
}) => {
	const groupByField = useCurrentViewGroupBy();
	const verticalGroupByField = useCurrentViewVerticalGroupBy();
	assertNonNullable(groupByField);

	const fieldKey = groupByField.key;
	const verticalFieldKey = verticalGroupByField?.key;

	const { formatMessage } = useIntl();
	const { updateFieldValues } = useIssueActions();
	const [isInAddingCardState, _setIsInAddingCardState] = useState<boolean>(false);
	const label = useFieldLabel(fieldKey, groupIdentity, fieldValue);
	const onMovedIssueFiltered = useOnCreatedOrMovedIssueFiltered();

	const setIsInAddingCardState = useCallback(
		(value: boolean) => {
			_setIsInAddingCardState(value);
			setSeparatorVisibility(value);
		},
		[setSeparatorVisibility],
	);

	const onMoveIssueToColumn = useCallback(
		(issueId: string) => {
			const fieldsToUpdate = {
				[fieldKey]: fieldValue,
			};

			if (verticalFieldKey !== undefined) {
				fieldsToUpdate[verticalFieldKey] = verticalFieldValue;
			}

			const { filtered } = updateFieldValues({
				localIssueIds: [issueId],
				fields: Object.entries(fieldsToUpdate).reduce<FieldValuesUpdateRequest['fields']>(
					(acc, [keyToUpdate, valueToUpdate]) => {
						acc[keyToUpdate] = {
							newValue: valueToUpdate,
							appendMultiValues: true,
						};

						return acc;
					},
					{},
				),
			});

			if (filtered === true) {
				onMovedIssueFiltered(issueId, true, label);
			}
			setIsInAddingCardState(false);
		},
		[
			fieldKey,
			fieldValue,
			verticalFieldKey,
			updateFieldValues,
			setIsInAddingCardState,
			verticalFieldValue,
			onMovedIssueFiltered,
			label,
		],
	);

	if (isInAddingCardState) {
		return (
			<AddCard
				fieldKey={fieldKey}
				fieldValue={fieldValue}
				groupIdentity={groupIdentity}
				onBlur={() => setIsInAddingCardState(false)}
				onMoveIssueToColumn={onMoveIssueToColumn}
				verticalFieldKey={verticalFieldKey}
				verticalFieldValue={verticalFieldValue}
				verticalGroupIdentity={verticalGroupIdentity}
			/>
		);
	}

	return (
		<Button
			testId="polaris-ideas.ui.view-content.idea-board-next.column-footer.button"
			iconBefore={<EditorAddIcon label="" LEGACY_size="small" />}
			appearance="subtle-link"
			onClick={() => setIsInAddingCardState(true)}
		>
			{formatMessage(messages.addCard)}
		</Button>
	);
};
