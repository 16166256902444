import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types.tsx';
import { execWithIssueAnalyticsData } from './analytics/index.tsx';
import { archiveIssues, unarchiveIssues } from './archive-issues/index.tsx';
import { clonePolarisIssueLegacy, clonePolarisIssue } from './clone-issue/index.tsx';
import {
	setCommentsForSelectedIssue,
	setTimestampIssueCommentsSeen,
	setTimestampCommentsSeen,
	signalCommentsUpdates,
} from './comments/index.tsx';
import { rankIdeasWithCurrentSorting } from './commit-current-sorting/index.tsx';
import { updateIssueConnections, updateConnectionFieldValue } from './connection/index.tsx';
import {
	updateDynamicFieldSelector,
	createDynamicFieldSelectors,
	scheduleDynamicFieldCalculation,
} from './create-dynamic-fields/index.tsx';
import {
	createIssue,
	saveIssue,
	cancelCreateIssue,
	cancelCreations,
	clearCreatedProperty,
	createAndUpdateLegacy,
	createAndUpdate,
	createAndConnect,
	createIssueAfter,
	createIssueBefore,
	submitIdea,
	safelySwapRowInCreationWithCreated,
} from './create-issue/index.tsx';
import { deleteIssues, deleteExternalIssues } from './delete-issues/index.tsx';
import { exportStringValues } from './export-csv/index.tsx';
import { adaptExternalIssueRank } from './external-issue-rank/index.tsx';
import { updateExternalReferenceEntities } from './external-reference/index.tsx';
import {
	initialize,
	initializeArchived,
	addPropertiesForLoadedIssue,
} from './initialize/index.tsx';
import { createInsight } from './insights/create/index.tsx';
import { deleteInsight, deleteInsightFromRemote } from './insights/delete/index.tsx';
import { refreshInsights, refreshSnippet } from './insights/refresh/index.tsx';
import { setTimestampIssueInsightsSeen } from './insights/seen/index.tsx';
import {
	updateInsightFromRemote,
	updateInsights,
	updateInsightsFromProps,
} from './insights/update/index.tsx';
import { invalidateFieldValue } from './invalidate-field-value/index.tsx';
import { linkIssue, linkDeliveryIssue } from './link-issue/index.tsx';
import {
	loadDeliveryProgress,
	refreshDeliveryProgressForSelectedIssue,
	refreshDeliveryProgressForSpecificIssue,
} from './load-delivery-data/index.tsx';
import { loadIssues, loadArchivedIssues } from './load-issues/index.tsx';
import { moveIssue, rankAfter, rankBefore } from './move-issue/index.tsx';
import { moveIssuesToTop } from './move-issues-to-top/index.tsx';
import {
	createPlayContribution,
	deletePlayContribution,
	deletePlayContributionFromRemote,
	updatePlayContributionsFromProps,
	updatePlayContribution,
	upsertPlayContributionFromRemote,
} from './plays/index.tsx';
import { toggleReaction } from './reactions/index.tsx';
import { decrementOpenUpdateCounter, incrementOpenUpdateCounter } from './real-time/index.tsx';
import {
	refreshConnectionFieldsValues,
	resetConnectionFieldsValues,
} from './refresh-connection-field-values/index.tsx';
import { refreshIssues } from './refresh-issues/index.tsx';
import { removeIssueFromState } from './remove-issue/index.tsx';
import { setError } from './set-error/index.tsx';
import { setIsSingleIssueLoaded } from './set-is-single-issue-loaded/index.tsx';
import { setIssueLinksForSelectedIssue } from './set-issue-links/index.tsx';
import { setLoading } from './set-loading/index.tsx';
import { syncFieldValues } from './sync-field-values/index.tsx';
import { unlinkDeliveryIssue, unlinkIssueFromStore } from './unlink-issue/index.tsx';
import { updateCheckboxOrRatingNumberFieldMapping } from './update-checkbox-or-rating-number-field-mapping/index.tsx';
import {
	updateFieldValue,
	updateFieldValues,
	updateFieldValueWithBulk,
	updateFieldValueForSelectedIssue,
	resetLastUpdatedIssueIds,
} from './update-field-value/index.tsx';
import { updateField } from './update-field/index.tsx';
import {
	updateStatus,
	updateStatusInStateOnly,
	updateStatusInStateOnlyWithBulk,
} from './update-status/index.tsx';

// Do not add to this list, this is a major runtime barelling point
// Instead of
//    dispatch(actions.foo(arg))
// Use
//    dispatch(actions.dispatch(foo(arg)))
// Where you've imported `foo()` directly from it's definition
const actions = {
	submitIdea,
	createAndUpdateLegacy,
	createAndUpdate,
	createAndConnect,
	setLoading,
	setError,
	setIssueLinksForSelectedIssue,
	moveIssue,
	rankBefore,
	archiveIssues,
	unarchiveIssues,
	rankAfter,
	loadIssues,
	loadArchivedIssues,
	initialize,
	initializeArchived,
	addPropertiesForLoadedIssue,
	loadDeliveryProgress,
	refreshDeliveryProgressForSelectedIssue,
	refreshDeliveryProgressForSpecificIssue,
	createIssue,
	createIssueAfter,
	createIssueBefore,
	safelySwapRowInCreationWithCreated,
	saveIssue,
	cancelCreateIssue,
	cancelCreations,
	updateCheckboxOrRatingNumberFieldMapping,
	updateFieldValue,
	updateFieldValues,
	updateFieldValueWithBulk,
	updateFieldValueForSelectedIssue,
	refreshConnectionFieldsValues,
	resetConnectionFieldsValues,
	updateIssueConnections,
	updateConnectionFieldValue,
	invalidateFieldValue,
	resetLastUpdatedIssueIds,
	updateStatus,
	updateField,
	refreshIssues,
	clearCreatedProperty,
	updateStatusInStateOnly,
	updateStatusInStateOnlyWithBulk,
	removeIssueFromState,
	deleteIssues,
	clonePolarisIssueLegacy,
	clonePolarisIssue,
	deleteExternalIssues,
	createDynamicFieldSelectors,
	updateDynamicFieldSelector,
	linkIssue,
	linkDeliveryIssue,
	unlinkIssueFromStore,
	unlinkDeliveryIssue,
	moveIssuesToTop,
	adaptExternalIssueRank,
	rankIdeasWithCurrentSorting,
	incrementOpenUpdateCounter,
	decrementOpenUpdateCounter,
	updateExternalReferenceEntities,
	toggleReaction,
	execWithIssueAnalyticsData,
	exportStringValues,
	scheduleDynamicFieldCalculation,
	setIsSingleIssueLoaded,
	syncFieldValues,

	// Comments Actions
	setCommentsForSelectedIssue,
	setTimestampIssueCommentsSeen,
	setTimestampCommentsSeen,
	signalCommentsUpdates,

	// Insights Actions
	createInsight,
	updateInsightFromRemote,
	updateInsights,
	updateInsightsFromProps,
	deleteInsight,
	deleteInsightFromRemote,
	refreshInsights,
	refreshSnippet,
	setTimestampIssueInsightsSeen,
	// Play actions
	createPlayContribution,
	deletePlayContribution,
	deletePlayContributionFromRemote,
	updatePlayContributionsFromProps,
	updatePlayContribution,
	upsertPlayContributionFromRemote,
	/**
	 * this action has no side effects. It is a helper accessor to get the container
	 * props in through the hook API and forwarded to the reselect selectors
	 */
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function universalDispatch<T extends Action<State, any, any>>(thunk: T) {
	return ({ dispatch }: StoreActionApi<State>): ReturnType<T> => dispatch(thunk);
}

// We patch the actions to add a universal dispatcher
// Eventually the actions list should be entirely eliminated
const ActionsWithDispatch = {
	...actions,
	dispatch: universalDispatch,
};
export default ActionsWithDispatch;
