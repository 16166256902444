/** @jsx jsx */
import React, { useMemo, useState } from 'react';
import { css, jsx } from '@compiled/react';
import Button, { IconButton, LinkIconButton } from '@atlaskit/button/new';
import EditorCloseIcon from '@atlaskit/icon/core/migration/close--editor-close';
import ShortcutIcon from '@atlaskit/icon/core/migration/link-external--shortcut';
import { useCloseOnEscapePress } from '@atlaskit/layering';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useCollectionsActions } from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import {
	useActions as useProjectMetadataActions,
	useProjectsMetadataForContainer,
} from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import type { ProjectMetadata } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import { ignoreEscapePress } from '@atlassian/jira-polaris-lib-escape-keypress-utils/src/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { ProjectTag } from '@atlassian/jira-polaris-lib-project-tag/src/ui/index.tsx';
import { RightSidebarBody } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/body/index.tsx';
import { RightSidebarFooter } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/footer/index.tsx';
import { RightSidebarHeader } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/header/index.tsx';
import { SearchInput } from '@atlassian/jira-polaris-lib-search-input/src/ui/index.tsx';
import { SIDEBAR_ADD_PROJECT_CONTENT_TYPE } from '../../common/constants.tsx';
import type { SidebarContentType } from '../../common/types.tsx';
import { useActions, useCollectionUUID } from '../../controllers/index.tsx';
import messages from './messages.tsx';

type Props = {
	onContentChange: (content: SidebarContentType | undefined) => void;
};

export const ManageProjectsSidebarContent = ({ onContentChange }: Props) => {
	const { formatMessage } = useIntl();
	const [searchQuery, setSearchQuery] = useState<string>('');
	const collectionUUID = useCollectionUUID();
	const { success, errorWithRefresh } = useNotifications();
	const projects = useProjectsMetadataForContainer({ containerId: collectionUUID });
	const { onClose } = useActions();
	const { updateCollection } = useCollectionsActions();
	const { removeProjectMetadata } = useProjectMetadataActions();

	const handleRemoveProject = async ({ id, key, name }: ProjectMetadata) => {
		if (!collectionUUID) {
			return;
		}

		try {
			const projectKeys = projects
				.map((project) => project.key)
				.filter((projectKey) => key !== projectKey);

			await updateCollection(collectionUUID, { projectKeys });
			removeProjectMetadata(collectionUUID, id);

			success({
				title: formatMessage(messages.removeSuccessFlagMessage, { projectName: name }),
			});
		} catch (error) {
			if (error instanceof Error) {
				errorWithRefresh({
					title: formatMessage(messages.errorTitle),
					description: formatMessage(messages.errorDescription),
				});
			}
		}
	};

	useCloseOnEscapePress({
		onClose: (event) => {
			if (ignoreEscapePress(event)) {
				return;
			}
			onContentChange(undefined);
		},
	});

	const filteredProjects = useMemo(
		() =>
			projects.filter((project) =>
				[project.name.toLocaleLowerCase(), project.key.toLocaleLowerCase()].some((value) =>
					value.includes(searchQuery.toLocaleLowerCase()),
				),
			),
		[projects, searchQuery],
	);

	return (
		<>
			<RightSidebarHeader
				title={formatMessage(messages.title)}
				onClose={onClose}
				onBack={() => onContentChange(undefined)}
				backLabel={formatMessage(messages.backButtonLabel)}
			/>
			<RightSidebarBody>
				<Stack xcss={sidebarBodyStyles}>
					<Text>
						{formatMessage(messages.projectsCountText, { projectsCount: projects.length })}
					</Text>
					<Box paddingBlock="space.150">
						<SearchInput
							testId="polaris-component-collection-configure-sidebar.ui.manage-projects-content.search-input"
							value={searchQuery}
							placeholder={formatMessage(messages.searchPlaceholder)}
							onChange={setSearchQuery}
						/>
					</Box>
					{filteredProjects.length > 0 ? (
						<Box xcss={projectsListStyles}>
							{filteredProjects.map((project) => (
								<Inline
									key={project.key}
									space="space.050"
									spread="space-between"
									alignBlock="center"
									xcss={projectItemStyles}
								>
									<ProjectTag
										projectName={project.name}
										projectKey={project.key}
										projectAvatarUrl={project.avatarUrls['16x16']}
										maxWidth={240}
									/>
									<Inline>
										<IconButton
											testId="polaris-component-collection-configure-sidebar.ui.manage-projects-content.remove-button"
											appearance="subtle"
											icon={(props) => (
												<EditorCloseIcon
													{...props}
													color={iconColor}
													LEGACY_primaryColor={iconColor}
												/>
											)}
											label={formatMessage(messages.removeButtonTooltip)}
											isTooltipDisabled={false}
											onClick={() => handleRemoveProject(project)}
										/>
										<span css={projectLinkWrapperStyles}>
											<LinkIconButton
												testId="polaris-component-collection-configure-sidebar.ui.manage-projects-content.link-button"
												appearance="subtle"
												icon={(props) => <ShortcutIcon {...props} color={iconColor} />}
												label={formatMessage(messages.linkToProjectTooltip)}
												isTooltipDisabled={false}
												href={`/browse/${project.key}`}
												target="_blank"
											/>
										</span>
									</Inline>
								</Inline>
							))}
						</Box>
					) : (
						<Stack space="space.150">
							<Text size="small" color="color.text.subtlest">
								{formatMessage(messages.noSearchMatchMessage)}
							</Text>
							<Text weight="semibold" color="color.text.subtlest">
								{formatMessage(messages.noProjectsFoundMessage)}
							</Text>
						</Stack>
					)}
				</Stack>
			</RightSidebarBody>
			<RightSidebarFooter>
				<Button
					appearance="primary"
					shouldFitContainer
					onClick={() => onContentChange(SIDEBAR_ADD_PROJECT_CONTENT_TYPE)}
					testId="polaris-component-collection-configure-sidebar.ui.manage-projects-content.add-button"
				>
					{formatMessage(messages.addProjectButtonLabel)}
				</Button>
			</RightSidebarFooter>
		</>
	);
};

const iconColor = token('color.icon');

const sidebarBodyStyles = xcss({ height: '100%' });

const projectsListStyles = xcss({ overflowY: 'auto' });

const projectItemStyles = xcss({
	paddingInline: 'space.050',
	height: '40px',
	borderRadius: 'border.radius.100',
	':hover': {
		backgroundColor: 'color.background.input.hovered',
	},
});

const projectLinkWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& svg': {
		width: '16px',
		height: '16px',
	},
});
