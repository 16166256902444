import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { N20, B75 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { useIsEmbedded } from '../../../controllers/environment/index.tsx';
import {
	useIssueCreatedProperty,
	useLinkedDeliveryIssuesExist,
	useSafeIssueKey,
} from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { IssueCreateStatusInTransition } from '../../../controllers/issue/types.tsx';
import { useRightSidebarActions } from '../../../controllers/right-sidebar/actions/hooks.tsx';
import { usePolarisRouter } from '../../../controllers/route/index.tsx';
import { useCurrentViewVisibleIssueActionFields } from '../../../controllers/views/selectors/view-hooks.tsx';
import { ActionFields as ActionFieldsUnstyled } from '../actions-fields/index.tsx';
import { LinkedIssuesProgressField } from '../body/linked-issues-progress/index.tsx';
import { LinkedIssuesStatus } from '../body/linked-issues-status/index.tsx';
import { Body } from '../body/main.tsx';
import { DISPLAYING_FIELDS_MIN_CARD_WIDTH, FIELD_HEIGHT, ROW_GAP } from '../constants.tsx';
import { Header } from '../header/index.tsx';
import { Highlighter } from '../highlighter/index.tsx';
import { Menu } from '../menu/index.tsx';
import { messages } from '../messages.tsx';
import type { IdeaCardProps } from '../types.tsx';
import { useIdeaCardContext, FOOTER_BORDER_TOP } from '../utils.tsx';

export const DetailedCard = ({
	id,
	isDisabled: isDisabledDefault = false,
	isFocused = false,
	isHovered = false,
	isResizing = false,
	isSummary = false,
	hoverBackgroundColor,
	hideEmptyFields = false,
	hasMultilineStrings = false,
	disableLazyRendering = false,
	viewLayoutType,
	cappedFieldsDisplay,
	hasFullscreenButton = true,
}: IdeaCardProps) => {
	const { formatMessage } = useIntl();
	const embedded = useIsEmbedded();
	const { openIssueView } = usePolarisRouter();
	const issueKey = useSafeIssueKey(id);
	const [, { close }] = useRightSidebarActions();
	const actionFields = useCurrentViewVisibleIssueActionFields();
	const issueCreatedProperty = useIssueCreatedProperty(id);
	const { visibleFields, deliveryProgressField, deliveryStatusField } = useIdeaCardContext();
	const deliveryIssuesExist = useLinkedDeliveryIssuesExist(id);

	const isCompact = viewLayoutType === ViewLayoutType.COMPACT;
	const isDisabled = embedded || isDisabledDefault;
	const shouldShowDeliveryProgress = deliveryProgressField && deliveryIssuesExist;
	const shouldShowDeliveryStatus = deliveryStatusField && deliveryIssuesExist;
	const hasActionFields = actionFields.length > 0;
	const showFooter =
		hasActionFields || ((shouldShowDeliveryProgress || shouldShowDeliveryStatus) && isCompact);

	const handleShowAllFields = useCallback(() => {
		if (issueKey && !isDisabled) {
			close();
			openIssueView(issueKey, { layout: 'sidebar', section: 'overview' });
		}
	}, [close, isDisabled, issueKey, openIssueView]);

	return (
		<>
			{!isDisabled && hasFullscreenButton && <Menu localIssueId={id} />}
			<Header
				id={id}
				hasMultilineSummary={hasMultilineStrings}
				hideEmptyFields={hideEmptyFields}
				isCompact={isCompact}
				isResizing={isResizing}
				isFullWidth={isDisabled || !hasFullscreenButton}
			/>
			{!isSummary || (isSummary && visibleFields.length > 1) ? (
				<>
					<Body
						issueId={id}
						fields={visibleFields}
						isCompact={isCompact}
						isSummary={isSummary}
						isHovered={isHovered}
						isFocused={isFocused}
						isResizing={isResizing}
						hoverBackgroundColor={hoverBackgroundColor}
						hideEmptyFields={hideEmptyFields}
						hasMultilineStrings={hasMultilineStrings}
						isDisabled={isDisabled}
						onAllFieldsClick={handleShowAllFields}
						disableLazyRendering={disableLazyRendering}
						cappedFieldsDisplay={cappedFieldsDisplay}
					/>
					{issueCreatedProperty &&
						issueCreatedProperty.status === IssueCreateStatusInTransition && (
							<SpinnerWrapper>
								<Spinner />
							</SpinnerWrapper>
						)}
					{showFooter && (
						<Footer isFocused={isFocused}>
							{hasActionFields && <ActionFields localIssueId={id} isDisabled={isDisabled} />}
							{(shouldShowDeliveryProgress || shouldShowDeliveryStatus) && isCompact && (
								<DeliveryFields>
									{shouldShowDeliveryProgress && (
										<LinkedIssuesProgressField
											issueId={id}
											fieldKey={deliveryProgressField.key}
											appearance="compact"
											hideEmpty={hideEmptyFields}
										/>
									)}
									{shouldShowDeliveryStatus && (
										<LinkedIssuesStatusWrapper>
											<LinkedIssuesStatus
												issueId={id}
												fieldKey={deliveryStatusField.key}
												isCompact
												hideEmpty={hideEmptyFields}
											/>
										</LinkedIssuesStatusWrapper>
									)}
								</DeliveryFields>
							)}
						</Footer>
					)}
				</>
			) : (
				<Box
					testId="polaris-common.ui.idea-card-v2.detailed-card.empty"
					paddingBlockStart="space.100"
					xcss={emptyBodyStyles}
				>
					{formatMessage(messages.emptyFieldsTooltipState)}
				</Box>
			)}
			{!isSummary && <Highlighter id={id} isResizing={isResizing} />}
		</>
	);
};

const emptyBodyStyles = xcss({
	color: 'color.text.subtle',
	fontWeight: token('font.weight.regular'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Footer = styled.div<{
	isFocused?: boolean;
}>({
	display: 'grid',
	boxSizing: 'border-box',
	marginTop: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderTop: ({ isFocused }) =>
		isFocused
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`${FOOTER_BORDER_TOP}px solid ${token('color.background.accent.blue.subtler', B75)}`
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`${FOOTER_BORDER_TOP}px solid ${token('color.border', N20)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-container-queries, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`@container cardContainer (max-width: ${DISPLAYING_FIELDS_MIN_CARD_WIDTH}px)`]: {
		width: 0,
		overflow: 'hidden',
		visibility: 'hidden',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionFields = styled(ActionFieldsUnstyled)<{ isDisabled?: boolean }>({
	padding: 0,
	display: 'grid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gap: `${ROW_GAP}px`,
	gridTemplateColumns: 'minmax(0, auto) auto',
	gridAutoColumns: 'min-content',
	gridAutoFlow: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gridAutoRows: `${FIELD_HEIGHT - 1}px`,
	alignItems: 'flex-end',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${FIELD_HEIGHT}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	pointerEvents: ({ isDisabled }) => (isDisabled ? 'none' : 'pointer'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryFields = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginTop: `${ROW_GAP}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gap: `${ROW_GAP}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkedIssuesStatusWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	marginLeft: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	position: 'absolute',
	top: token('space.050'),
	right: token('space.050'),
});
