import React, { useCallback, useEffect } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import AkSpinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import {
	TASK_FAIL,
	TASK_SUCCESS,
} from '@atlassian/jira-experience-tracker/src/common/constants.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useApplication,
	useEdition,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { FeatureFlagValue } from '@atlassian/jira-feature-flagging-using-meta';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import messages from './messages.tsx';
import NoWatchers from './no-watchers/index.tsx';
import UsersList from './users-list/index.tsx';

type Props = {
	isLoading: boolean;
	canManageWatchers: boolean;
	users: User[];
	onDeleteUser: (
		arg1: User,
		arg2: (accountId: AccountId | null) => void,
		arg3: () => void,
		arg4: UIAnalyticsEvent,
	) => void;
	onFetchUsers?: () => void;
};

export const WatchersList = (props: Props) => {
	const { isLoading, canManageWatchers, users, onDeleteUser, onFetchUsers } = props;
	const { formatMessage } = useIntl();

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onSuccess = useCallback(
		(accountId: AccountId | null) => {
			sendExperienceAnalytics({
				wasExperienceSuccesful: true,
				action: TASK_SUCCESS,
				analyticsSource: 'button',
				experience: 'removeUserAsWatcher',
				application: application ?? null,
				edition: edition ?? null,
			});

			if (fg('one-event-rule-all-watcher-analytics')) {
				getUpdateAnalyticsFlowHelper().fireAnalyticsEnd('watchers', {
					analytics: createAnalyticsEvent({}),
					attributes: {
						fieldType: 'watchers',
						actionTaken: 'remove',
						oldValId: accountId,
						newValId: '',
					},
				});
			}
		},
		[application, createAnalyticsEvent, edition],
	);

	const onError = useCallback(
		(error?: Error | FetchError) => {
			const additionalAttributes: Record<
				string,
				string | boolean | number | Record<string, FeatureFlagValue>
			> = {};

			if (error?.message && fg('thor_add_missing_attributes_across_issue_view_2')) {
				additionalAttributes.errorMessage = error.message;
			}

			if (error instanceof FetchError && fg('thor_add_missing_attributes_across_issue_view_2')) {
				if (error?.traceId) additionalAttributes.traceId = error.traceId;
				if (error?.statusCode) additionalAttributes.statusCode = error.statusCode;
			}
			sendExperienceAnalytics({
				wasExperienceSuccesful: false,
				action: TASK_FAIL,
				analyticsSource: 'button',
				experience: 'removeUserAsWatcher',
				application: application ?? null,
				edition: edition ?? null,
				...(fg('thor_add_missing_attributes_across_issue_view_2') && {
					additionalAttributes,
				}),
			});
		},
		[application, edition],
	);

	if (!fg('relay-migration-issue-header-and-parent')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			onFetchUsers?.();
		}, [onFetchUsers]);
	}

	if (users.length > 0) {
		return (
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			<Container data-testid="issue.watchers.dropdown.watchers-list.populated">
				<UsersList
					users={users}
					headerText={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.watchingThisIssueIssueTermRefresh
							: messages.watchingThisIssue,
					)}
					onDeleteUser={onDeleteUser}
					canManageWatchers={canManageWatchers}
					onDeleteSuccess={onSuccess}
					onDeleteError={onError}
				/>
			</Container>
		);
	}

	if (isLoading) {
		return (
			<Container data-testid="issue-view-watchers.watchers.dropdown.watchers-list.loading">
				<SpinnerContainer>
					<AkSpinner size="medium" />
				</SpinnerContainer>
			</Container>
		);
	}

	return (
		// eslint-disable-next-line jira/integration/test-id-by-folder-structure
		<Container data-testid="issue.watchers.dropdown.watchers-list.empty">
			<NoWatchers text={formatMessage(messages.noWatchersYet)} />
		</Container>
	);
};

export default WatchersList;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ContainerOld = styled.div({
	paddingTop: token('space.100'),
	maxHeight: '190px',
	overflowY: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${token('color.border', colors.N30)};`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ContainerVisualRefresh = styled.div({
	maxHeight: '190px',
	overflowY: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${token('color.border', colors.N30)};`,
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = componentWithCondition(
	() => isVisualRefreshEnabled(),
	ContainerVisualRefresh,
	ContainerOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SpinnerContainer = styled.div({
	height: '190px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});
