import React, { type SyntheticEvent, useState, useCallback, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import find from 'lodash/find';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { LoadingButton } from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { Grid, Box, xcss } from '@atlaskit/primitives';
import TextField from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import PickerWithAvatar from '@atlassian/jira-common-components-picker/src/with-avatar/index.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
// eslint-disable-next-line jira/import/no-restricted-import
import DisabledButtonWithTooltip from '@atlassian/jira-disabled-button-with-tooltip/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { EPIC_ISSUE_TYPE_NAME } from '@atlassian/jira-polaris-common/src/common/types/issue-types/index.tsx';
import {
	ProjectPicker,
	PROJECT_TYPES_WITHOUT_JPD,
} from '@atlassian/jira-polaris-common/src/common/ui/project-picker/index.tsx';
import type {
	SelectedProject,
	SelectedProjectIssueType,
} from '@atlassian/jira-polaris-common/src/common/ui/project-picker/option/index.tsx';
import { getDirectIssueLink } from '@atlassian/jira-polaris-common/src/common/utils/issue-link/index.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useLocalIssueIdsByJiraIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import {
	useSelectedIssue,
	useSelectedIssueKey,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useProjectActions } from '@atlassian/jira-polaris-common/src/controllers/project/main.tsx';
import { useSelectedDeliveryProject } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks.tsx';
import {
	useDeliveryIssueLinkType,
	useEpicNameFieldKey,
} from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireAnalyticsEventForIssueUpdate } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { OnRequiredFields } from '@atlassian/jira-polaris-remote-issue/src/common/types.tsx';
import { useIssueRemote } from '@atlassian/jira-polaris-remote-issue/src/main.tsx';
import {
	fireUIAnalytics,
	fireTrackAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useRequiredFieldsDeliveryTicketCreation } from '../../utils/on-required-fields-delivery-tickets-creation.tsx';
import messages from './messages.tsx';

type Props = {
	isCompact?: boolean;
	defaultSummary: string | undefined;
	onCancel: () => void;
	onCreated: () => void;
};

export const SendToBacklog = (props: Props) => {
	const { defaultSummary, onCancel, onCreated, isCompact } = props;
	const [selectedProject, setSelectedProject] = useState<SelectedProject | undefined>();

	const [selectedIssueType, setSelectedIssueType] = useState<SelectedProjectIssueType>();
	const [isEmbedIdeaChecked, setIsEmbedIdeaChecked] = useState(false);
	const [summary, setSummary] = useState(defaultSummary);
	const [progress, setProgress] = useState<'running' | 'created' | 'linked' | 'error' | 'done'>();
	const isCreateButtonDisabled =
		selectedProject?.item === undefined ||
		selectedIssueType === undefined ||
		summary === undefined ||
		summary.trim().length === 0 ||
		(progress !== undefined && progress !== 'done' && progress !== 'error');

	const { formatMessage } = useIntl();
	const ideaId = useSelectedIssue();
	const jiraKey = useSelectedIssueKey();
	const polarisDeliveryIssueLinkType = useDeliveryIssueLinkType();
	const epicNameFieldKey = useEpicNameFieldKey();
	const { linkDeliveryIssue } = useIssueActions();
	const { setSelectedDeliveryProject } = useProjectActions();
	const cloudId = useCloudId();
	const idMap = useLocalIssueIdsByJiraIssueId();
	const onRequiredFields: OnRequiredFields = useRequiredFieldsDeliveryTicketCreation();
	const [selectedDeliveryProject] = useSelectedDeliveryProject();

	const { createAndLinkIssue } = useIssueRemote();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issueLinkUrl = getDirectIssueLink(jiraKey, true);

	const onProjectChanged = useCallback((payload?: SelectedProject) => {
		if (payload !== undefined) {
			setSelectedProject(payload);
			const epicIssueType = find(
				payload.item.issueTypes,
				(issueType) => issueType.name === EPIC_ISSUE_TYPE_NAME,
			);
			setSelectedIssueType(epicIssueType ?? payload.item.issueTypes[0]);
		} else {
			setSelectedProject(undefined);
			setSelectedIssueType(undefined);
		}
	}, []);

	const onCancelClicked = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'cancelCreateNewIssue');
			onCancel();
		},
		[onCancel],
	);

	const onCreateClicked = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			if (
				selectedProject !== undefined &&
				selectedIssueType !== undefined &&
				summary !== undefined
			) {
				fireUIAnalytics(analyticsEvent, 'createNewIssue');
				experience.ideaView.deliveryTicketCreate.start();
				setProgress('running');
				const onIssueCreated = (
					_id: string,
					_createdJiraIssueKey: string,
					createdJiraIssueId: string,
				) => {
					experience.ideaView.deliveryTicketCreate.success();
					setProgress('created');
					fireTrackAnalytics(createAnalyticsEvent({}), 'issue created', {
						createdIssueId: createdJiraIssueId,
						createdIssueProjectId: selectedProject?.item.id,
						projectType: SOFTWARE_PROJECT,
					});
					fireTrackAnalytics(createAnalyticsEvent({}), 'deliveryTicket created', {
						createdIssueId: createdJiraIssueId,
						createdIssueProjectId: selectedProject?.item.id,
						embedIdeaInDescription: isEmbedIdeaChecked,
					});
				};
				const onIssueLinked = (id: string, linkedJiraIssueKey: string) => {
					setProgress('linked');
					linkDeliveryIssue(id, linkedJiraIssueKey).then(() => {
						setProgress('done');
						onCreated();
						// store selected delivery project in polaris backend
						const deliveryProjectAri = createAri({
							resourceOwner: 'jira',
							cloudId,
							resourceType: 'project',
							resourceId: selectedProject?.item.id,
						});
						setSelectedDeliveryProject(deliveryProjectAri);

						fireAnalyticsEventForIssueUpdate(createAnalyticsEvent({}), idMap[id], {
							addedDeliveryTicketId: linkedJiraIssueKey,
						});
					});
				};

				const onOperationFailed = (_: string, error: Error) => {
					if (!isClientFetchError(error)) {
						experience.ideaView.deliveryTicketCreate.failure(error);
					}
					setProgress('error');
				};

				if (ideaId !== undefined && jiraKey !== undefined) {
					createAndLinkIssue({
						ideaIssue: {
							localIssueId: ideaId,
							key: jiraKey,
							summary,
							description:
								isEmbedIdeaChecked && issueLinkUrl
									? getEmbedIssueDescription(issueLinkUrl)
									: undefined,
						},
						epicNameFieldKey,
						issueLinkTypeId: polarisDeliveryIssueLinkType,
						direction: 'outward',
						issueTypeId: selectedIssueType.id,
						projectId: selectedProject?.item.id,
						onIssueCreated,
						onIssueLinked,
						onOperationFailed,
						onRequiredFields: (fields) => onRequiredFields(fields, onCancel),
					});
				}
			}
		},
		[
			selectedProject,
			selectedIssueType,
			summary,
			ideaId,
			jiraKey,
			createAnalyticsEvent,
			linkDeliveryIssue,
			onCreated,
			cloudId,
			setSelectedDeliveryProject,
			idMap,
			createAndLinkIssue,
			issueLinkUrl,
			isEmbedIdeaChecked,
			epicNameFieldKey,
			polarisDeliveryIssueLinkType,
			onRequiredFields,
			onCancel,
		],
	);

	const onSummaryChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setSummary(event.target.value);
	}, []);

	return (
		<Box xcss={wrapperStyles}>
			<Caption>
				{formatMessage(
					fg('polaris-issue-terminology-refresh')
						? messages.descriptionIssueTermRefresh
						: messages.description,
				)}
			</Caption>
			<Container>
				<PickerComponents isCompact={isCompact}>
					<ProjectPickerWrapper isCompact={isCompact}>
						<ProjectPicker
							autoFocus={selectedDeliveryProject === undefined}
							includeProjectTypes={PROJECT_TYPES_WITHOUT_JPD}
							width="100%"
							onProjectSelected={onProjectChanged}
							selectedProject={selectedProject}
						/>
					</ProjectPickerWrapper>
					<IssueTypePickerWrapper isCompact={isCompact}>
						<PickerWithAvatar
							isCompact
							value={selectedIssueType || null}
							width="100%"
							isDisabled={!selectedProject}
							isRequired
							defaultOptions={[]}
							options={
								selectedProject?.item
									? selectedProject?.item.issueTypes
											.filter((type) => !type.subtask)
											.map((type) => ({
												...type,
												value: type.id,
												avatar: type.iconUrl,
											}))
									: []
							}
							onSelected={(selected) => {
								setSelectedIssueType(selected);
								fireUIAnalytics(
									createAnalyticsEvent({
										action: 'clicked',
										actionSubject: 'dropdownItem',
									}),
									'issueType',
									{
										selectedIssueTypeId: selected?.id,
									},
								);
							}}
							isClearable={false}
							formatOptionDataTransformer={(issueType) => ({
								avatar: issueType.iconUrl,
								label: issueType.name,
							})}
							placeholder={formatMessage(
								fg('polaris-issue-terminology-refresh')
									? messages.issueTypeLabelIssueTermRefresh
									: messages.issueTypeLabel,
							)}
						/>
					</IssueTypePickerWrapper>
					<IdeaSummaryInputWrapper isCompact={isCompact}>
						<TextField width="100%" isCompact onChange={onSummaryChanged} value={summary} />
					</IdeaSummaryInputWrapper>
				</PickerComponents>
			</Container>
			<Grid
				templateColumns={isCompact ? '1fr' : '2fr 1fr'}
				gap="space.200"
				alignItems="center"
				xcss={isCompact ? controlsCompactStyles : controlsStyles}
			>
				<Box>
					<Checkbox
						isChecked={isEmbedIdeaChecked}
						onChange={() => setIsEmbedIdeaChecked(!isEmbedIdeaChecked)}
						label={formatMessage(
							fg('polaris-issue-terminology-refresh')
								? messages.ideaEmbedCheckboxLabelIssueTermRefresh
								: messages.ideaEmbedCheckboxLabel,
						)}
					/>
				</Box>
				<ButtonGroup isCompact={isCompact}>
					<Button appearance="subtle-link" onClick={onCancelClicked}>
						{formatMessage(messages.cancel)}
					</Button>
					<Tooltip
						content={
							progress === 'error'
								? formatMessage(
										fg('polaris-issue-terminology-refresh')
											? messages.issueCreationErrorIssueTermRefresh
											: messages.issueCreationError,
									)
								: null
						}
					>
						<DisabledButtonWithTooltip isDisabled={isCreateButtonDisabled}>
							<LoadingButton
								appearance="primary"
								isDisabled={isCreateButtonDisabled}
								testId="polaris-ideas.common.ui.send-to-backlog.create-button"
								onClick={onCreateClicked}
								isLoading={progress !== undefined && progress !== 'done' && progress !== 'error'}
								// @ts-expect-error - TS2322 - Type 'Element | null' is not assignable to type 'ReactChild | undefined'.
								iconAfter={
									progress === 'error' ? (
										<ErrorIcon
											LEGACY_size="small"
											label="Error"
											color={token('color.text.danger', colors.R400)}
										/>
									) : null
								}
							>
								{formatMessage(messages.create)}
							</LoadingButton>
						</DisabledButtonWithTooltip>
					</Tooltip>
				</ButtonGroup>
			</Grid>
		</Box>
	);
};

const getEmbedIssueDescription = (issueLinkUrl: string): ADF => ({
	version: 1,
	type: 'doc',
	content: [
		{
			type: 'embedCard',
			attrs: {
				url: issueLinkUrl,
				layout: 'center',
				width: 100,
			},
		},
	],
});

const wrapperStyles = xcss({
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Caption = styled.div({
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N500),
	marginBottom: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
	flexGrow: 1,
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PickerComponents = styled.div<{ isCompact?: boolean }>({
	display: 'flex',
	flexGrow: 1,
	flexWrap: 'wrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ isCompact }) =>
		`${isCompact ? token('space.negative.050') : token('space.negative.075')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonGroup = styled.div<{ isCompact?: boolean }>({
	display: 'flex',
	justifyContent: 'flex-end',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flexDirection: ({ isCompact }) => (isCompact ? 'row-reverse' : 'row'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueTypePickerWrapper = styled.div<{ isCompact?: boolean }>({
	display: 'inline-table',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flex: ({ isCompact }) => (isCompact ? '1 1 100%' : '1 0 140px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ isCompact }) => `${isCompact ? token('space.050') : token('space.075')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdeaSummaryInputWrapper = styled.div<{ isCompact?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flex: ({ isCompact }) => (isCompact ? '1 1 100%' : '1 0 250px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ isCompact }) => `${isCompact ? token('space.050') : token('space.075')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectPickerWrapper = styled.div<{ isCompact?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flex: ({ isCompact }) => (isCompact ? '1 1 100%' : '1 1 360px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ isCompact }) => `${isCompact ? token('space.050') : token('space.075')}`,
});

const controlsStyles = xcss({
	paddingTop: 'space.200',
});

const controlsCompactStyles = xcss({
	paddingTop: 'space.100',
});
