import React, { type ReactNode, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type {
	ProfileClient,
	ProfilecardTriggerPosition,
	TriggerType,
	PrepopulatedData,
} from '@atlaskit/profilecard';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntlV2 } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import {
	fireTrackAnalytics,
	ContextualAnalyticsData,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import type { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { RouterActions } from '@atlassian/react-resource-router';
import { useIntl } from '@atlassian/jira-intl';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { profileCardNextSourceName, profileCardNextLegacySourceName } from './common/constants.tsx';
import type { Actions, AnalyticsData } from './types.tsx';
import {
	ProfileCardLegacyOld as ProfileCardLegacyViewOld,
	ProfileCardLegacyNew as ProfileCardLegacyViewNew,
} from './ui/profilecard-legacy/index.tsx';
import { ProfileCardWrapperOld, ProfileCardWrapper } from './ui/profilecard-wrapper/main.tsx';
import { ProfileCardViewOld, ProfileCardViewNew } from './ui/profilecard/index.tsx';
import { getSpaActions } from './utils/index.tsx';

type ProfileCardBaseProps = {
	/**
	 * @deprecated will be removed by empanada_nin_concurrent_mode_fixes FG
	 */
	TenantContextSubscriber?: typeof TenantContextSubscriber;
	externalProfileClient?: ProfileClient;
	actions?: Actions[];
	prepopulatedData?: PrepopulatedData;
};

export type ProfileCardProps = ProfileCardBaseProps & {
	accountId: string;
	analyticsData?: AnalyticsData;
	children: ReactNode;
	position?: ProfilecardTriggerPosition;
	testId?: string;
	trigger?: TriggerType;
	ariaHideProfileTrigger?: boolean;
};

/**
 * @deprecated Cleanup with empanada_nin_concurrent_mode_fixes
 */
const ProfileCardOld: ComponentType<ProfileCardProps> = (props: ProfileCardProps) => {
	const {
		TenantContextSubscriber: TenantContextSubscriberComponent,
		externalProfileClient,
		accountId,
		actions,
		analyticsData,
		children,
		position,
		testId,
		trigger,
		prepopulatedData,
		ariaHideProfileTrigger,
	} = props;
	const intl = useIntlV2();
	const { showFlag, dismissFlag } = useFlagsService();

	return (
		<ContextualAnalyticsData
			sourceType={SCREEN}
			sourceName={profileCardNextSourceName}
			attributes={analyticsData}
		>
			<ProfileCardWrapperOld
				TenantContextSubscriber={TenantContextSubscriberComponent}
				externalProfileClient={externalProfileClient}
				analyticsData={analyticsData}
			>
				{/* @ts-expect-error - TS2769 - No overload matches this call. */}
				{(atlassianAccountId, profileClient, cloudId, baseUrl) => {
					if (atlassianAccountId !== '') {
						return (
							<RouterActions>
								{({ push }) => (
									<ProfileCardViewOld
										profileClient={profileClient}
										cloudId={cloudId}
										accountId={accountId}
										actions={getSpaActions(accountId, baseUrl, intl, push, actions)}
										position={position}
										onProfileClick={(profileAccountId) =>
											push(`${baseUrl}/jira/people/${profileAccountId}`)
										}
										addFlag={showFlag}
										dismissFlag={dismissFlag}
										testId={testId}
										trigger={trigger}
										prepopulatedData={prepopulatedData}
										viewingUserId={atlassianAccountId || undefined}
										ariaHideProfileTrigger={ariaHideProfileTrigger}
									>
										{children}
									</ProfileCardViewOld>
								)}
							</RouterActions>
						);
					}
					return children;
				}}
			</ProfileCardWrapperOld>
		</ContextualAnalyticsData>
	);
};

const ProfileCardNew = ({
	externalProfileClient,
	accountId,
	actions,
	analyticsData,
	children,
	position,
	testId,
	trigger,
	prepopulatedData,
	ariaHideProfileTrigger,
}: ProfileCardProps) => {
	const intl = useIntl();
	const { showFlag, dismissFlag } = useFlagsService();
	const currentUserAccountId = useAccountId();

	return (
		<ContextualAnalyticsData
			sourceType={SCREEN}
			sourceName={profileCardNextSourceName}
			attributes={analyticsData}
		>
			<ProfileCardWrapper externalProfileClient={externalProfileClient}>
				{currentUserAccountId ? (
					<RouterActions>
						{({ push }) => (
							<ProfileCardViewNew
								accountId={accountId}
								actions={getSpaActions(accountId, '', intl, push, actions)}
								position={position}
								onProfileClick={(profileAccountId) => push(`/jira/people/${profileAccountId}`)}
								addFlag={showFlag}
								dismissFlag={dismissFlag}
								testId={testId}
								trigger={trigger}
								prepopulatedData={prepopulatedData}
								ariaHideProfileTrigger={ariaHideProfileTrigger}
							>
								{children}
							</ProfileCardViewNew>
						)}
					</RouterActions>
				) : (
					children
				)}
			</ProfileCardWrapper>
		</ContextualAnalyticsData>
	);
};

export const ProfileCard = componentWithFG(
	'empanada_nin_concurrent_mode_fixes',
	ProfileCardNew,
	ProfileCardOld,
);

/**
 * @deprecated Cleanup with empanada_nin_concurrent_mode_fixes
 */
export const ProfileCardLegacyOld: ComponentType<ProfileCardBaseProps> = (
	props: ProfileCardBaseProps,
) => {
	const {
		TenantContextSubscriber: TenantContextSubscriberComponent,
		externalProfileClient,
		actions,
	} = props;

	const intl = useIntlV2();

	const onLoadError = (error: Error, analyticsEvent?: UIAnalyticsEvent) => {
		if (analyticsEvent) {
			fireTrackAnalytics(
				analyticsEvent,
				`${analyticsEvent.payload.actionSubject} ${analyticsEvent.payload.action}`,
				`${analyticsEvent.payload.actionSubject}${analyticsEvent.payload.action}`,
				{
					error,
				},
			);
		}
	};

	return (
		<ContextualAnalyticsData sourceType={SCREEN} sourceName={profileCardNextLegacySourceName}>
			<ProfileCardWrapperOld
				TenantContextSubscriber={TenantContextSubscriberComponent}
				externalProfileClient={externalProfileClient}
				onLoadError={onLoadError}
			>
				{(atlassianAccountId, profileClient, cloudId, baseUrl) =>
					// @ts-expect-error - TS2769 - No overload matches this call.
					atlassianAccountId !== null ? (
						<LegacyProfileCardStyled>
							<RouterActions>
								{({ push }) => (
									<ProfileCardLegacyViewOld
										profileClient={profileClient}
										cloudId={cloudId}
										baseUrl={baseUrl}
										onGetActions={(accountId: string, onActionCleanup?: () => void) =>
											getSpaActions(accountId, baseUrl, intl, push, actions, onActionCleanup)
										}
										onProfileClick={(profileAccountId) =>
											push(`${baseUrl}/jira/people/${profileAccountId}`)
										}
									/>
								)}
							</RouterActions>
						</LegacyProfileCardStyled>
					) : null
				}
			</ProfileCardWrapperOld>
		</ContextualAnalyticsData>
	);
};

export const ProfileCardLegacyNew = ({ externalProfileClient, actions }: ProfileCardBaseProps) => {
	const intl = useIntl();
	const currentUserAccountId = useAccountId();

	const onLoadError = (error: Error, analyticsEvent?: UIAnalyticsEvent) => {
		if (analyticsEvent) {
			fireTrackAnalytics(
				analyticsEvent,
				`${analyticsEvent.payload.actionSubject} ${analyticsEvent.payload.action}`,
				`${analyticsEvent.payload.actionSubject}${analyticsEvent.payload.action}`,
				{
					error,
				},
			);
		}
	};

	return (
		<ContextualAnalyticsData sourceType={SCREEN} sourceName={profileCardNextLegacySourceName}>
			<ProfileCardWrapper externalProfileClient={externalProfileClient} onLoadError={onLoadError}>
				{currentUserAccountId ? (
					<LegacyProfileCardStyled>
						<RouterActions>
							{({ push }) => (
								<ProfileCardLegacyViewNew
									onGetActions={(accountId: string, onActionCleanup?: () => void) =>
										getSpaActions(accountId, '', intl, push, actions, onActionCleanup)
									}
									onProfileClick={(profileAccountId) => push(`/jira/people/${profileAccountId}`)}
								/>
							)}
						</RouterActions>
					</LegacyProfileCardStyled>
				) : null}
			</ProfileCardWrapper>
		</ContextualAnalyticsData>
	);
};

export const ProfileCardLegacy = componentWithFG(
	'empanada_nin_concurrent_mode_fixes',
	ProfileCardLegacyNew,
	ProfileCardLegacyOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LegacyProfileCardStyled = styled.div({
	position: 'relative',
	zIndex: 100,
});
