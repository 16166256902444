/** @jsx jsx */
import React, { type RefCallback } from 'react';
import { jsx, css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { xcss, Anchor } from '@atlaskit/primitives';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { useIssueLink } from '../../../common/utils/issue-link/index.tsx';
import type { IssueViewSection } from '../../../controllers/issue/types.tsx';
import {
	CONNECTION_ITEM_ELEM_AFTER_STYLES,
	CONNECTION_ITEM_RATING_FIELD_WIDTH,
	CONNECTION_ITEM_STYLES,
} from './constants.tsx';

export type ConnectionItemProps = {
	issueKey: string;
	isLastChild?: boolean;
	issueViewSection?: IssueViewSection;
	summary: string;
	summaryRef?: RefCallback<HTMLDivElement>;
	elementAfter?: React.ReactNode;
	fieldType?: FieldType;
};

export const ConnectionItem = ({
	isLastChild,
	issueKey,
	issueViewSection,
	summary,
	summaryRef,
	elementAfter,
	fieldType,
}: ConnectionItemProps) => {
	const isRating = fieldType === FIELD_TYPES.RATING;
	const { link, target, onClick } = useIssueLink({
		issueKey,
		shouldOpenInSidebar: true,
		issueViewSection,
	});

	return (
		<Anchor onClick={onClick} xcss={linkStyles} href={link} target={target}>
			<div css={[contentContainerStyles, isRating && ratingFieldStyles]}>
				<div ref={summaryRef} css={[summaryContainerStyles, !isLastChild && beforeElementStyles]}>
					<div css={[summaryStyles]}>{summary}</div>
				</div>

				{elementAfter}
			</div>
		</Anchor>
	);
};

/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values */
const contentContainerStyles = css({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	gridTemplateColumns: 'auto 1fr auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
	'& > :nth-child(2)': {
		display: 'flex',
		justifyContent: 'end',
		width: `${CONNECTION_ITEM_ELEM_AFTER_STYLES.width}px`,
		height: CONNECTION_ITEM_STYLES.lineHeight,
		marginLeft: `${CONNECTION_ITEM_ELEM_AFTER_STYLES.marginLeft}px`,
		'&:empty': {
			display: 'none',
		},
	},
});

const ratingFieldStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
	'& > :nth-child(2)': {
		width: `${CONNECTION_ITEM_RATING_FIELD_WIDTH}px`,
	},
});

const summaryContainerStyles = css({
	position: 'relative',
	flex: 1,
});

// childrenStyles should be in sync with CONNECTION_ITEM_STYLES for card height calculations
const summaryStyles = css({
	flex: 1,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: CONNECTION_ITEM_STYLES.lineHeight,
	fontSize: CONNECTION_ITEM_STYLES.fontsize,
	display: CONNECTION_ITEM_STYLES.display,
	overflow: CONNECTION_ITEM_STYLES.overflow,
	paddingLeft: CONNECTION_ITEM_STYLES.paddingLeft,
	WebkitLineClamp: CONNECTION_ITEM_STYLES.webkitLineClamp,
	WebkitBoxOrient: CONNECTION_ITEM_STYLES.webkitBoxOrient,
});
/* eslint-enable @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values */

const beforeElementStyles = css({
	'&::before': {
		content: '""',
		position: 'absolute',
		display: 'block',
		borderTop: `2px solid ${token('color.border')}`,
		transform: 'translateY(9px) translateX(3px)',
		width: '8px',
		flexShrink: 0,
	},
});

const linkStyles = xcss({
	display: 'flex',
	color: 'color.text',
	textDecoration: 'none',
	width: '100%',
	':hover': {
		color: 'color.text',
	},
	':focus': {
		color: 'color.text',
		textDecoration: 'none',
	},
});
