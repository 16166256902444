import React, { useCallback, useRef, useState } from 'react';
import Button from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/core/migration/add';
import { useIntl } from '@atlassian/jira-intl';
import { FieldSearchableDropdown } from '@atlassian/jira-polaris-component-field-searchable-dropdown/src/ui/field-searchable-dropdown/index.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isGlobalNonSystemField } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import messages from './messages.tsx';

type Props = {
	label?: string;
	options: Field[];
	onAddFilter: (value: FieldKey | undefined) => void;
};

export const AddFilterComponent = ({ options, onAddFilter, label: buttonLabel }: Props) => {
	const { formatMessage } = useIntl();
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const [dialogOpen, setDialogOpen] = useState(false);

	const toggleDialog = useCallback(() => setDialogOpen((prev) => !prev), []);

	return (
		<PolarisInlineDialog
			noPadding
			onClose={(target) => {
				if (buttonRef.current && !buttonRef.current.contains(target)) {
					setDialogOpen(false);
				}
			}}
			isOpen={dialogOpen}
			placement="bottom-start"
			content={
				<FieldSearchableDropdown
					options={options.map((field) => ({
						key: field.key,
						label: field.label,
						type: field.type,
						emoji: field.emoji,
						hasWeightType: field.configuration?.optionWeightType !== undefined,
						...(fg('jpd_icon_for_polaris_field_type_new') && {
							configuration: field.configuration,
						}),
						isGlobalNonSystemField: fg('polaris_display-gsf-as-a-regular-project-field')
							? isGlobalNonSystemField(field)
							: field.global && field.custom,
					}))}
					onSelect={(fieldKey) => {
						onAddFilter(fieldKey);
						setDialogOpen(false);
					}}
					onClose={() => setDialogOpen(false)}
				/>
			}
		>
			<Button
				interactionName="jpd.view-controls.add-filter"
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				testId="polaris-ideas.ui.view-controls.config-filters.add.button"
				ref={buttonRef}
				isSelected={dialogOpen}
				onClick={toggleDialog}
				iconBefore={(iconProps) => <AddIcon LEGACY_size="small" {...iconProps} />}
			>
				{buttonLabel ?? formatMessage(messages.addFilter)}
			</Button>
		</PolarisInlineDialog>
	);
};
