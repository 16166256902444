import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	getPermalinkId,
	COMMENTS,
} from '@atlassian/jira-issue-fetch-services-common/src/common/utils/permalinks.tsx';
import type { ViewIssueData as IssueServiceViewIssueData } from '@atlassian/jira-issue-fetch-services/src/types.tsx';
import type {
	CommentsTransformResult,
	NormalizedLoadedCommentsV2,
	NormalizedUser,
	NormalizedComment,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import type { ViewIssueData } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/server-data.tsx';
import type { TransformedGiraData } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/transform-result.tsx';
import { NUM_INITIAL_ITEMS_TO_LOAD } from '@atlassian/jira-issue-view-common-constants/src/activity-feed.tsx';
import type { commentFetchAggServerQuery$data } from '@atlassian/jira-relay/src/__generated__/commentFetchAggServerQuery.graphql';
import {
	computeAggCommentsToUse,
	transformAggCommentsToLegacyGiraV2Helper,
} from '@atlassian/jira-issue-agg-field-transformers/src/common/utils/comments-transformer.tsx';
import type { CommentsPageInfo } from '@atlassian/jira-issue-shared-types/src/common/types/comment-transformer-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { calculateNumberOfCommentsToLoad } from '@atlassian/jira-issue-view-common-utils/src/epics/comment-fetch-epic.tsx';
import type { commentFetchAggServerChildCommentsQuery$data } from '@atlassian/jira-relay/src/__generated__/commentFetchAggServerChildCommentsQuery.graphql.ts';
import unsetIn from '@atlassian/jira-common-icepick/src/utils/unset-in/index.tsx';
import {
	type ProjectType,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	transformComments,
	normalizeComments,
	normalizeCommentsV2,
	normalizeGiraComments,
} from '../../comment-transformer.tsx';
import { getCommentsQuery } from './graphql.tsx';

const CREATED_ORDER_DESCENDING = '-created';

export const getQuery = () =>
	getCommentsQuery(NUM_INITIAL_ITEMS_TO_LOAD, 0, CREATED_ORDER_DESCENDING);

export const transformData = (
	issue: ViewIssueData | IssueServiceViewIssueData,
): CommentsTransformResult => {
	const { comments } = issue;
	const emptyComments = {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		users: {} as any,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		comments: {} as any,
		commentsStartIndex: 0,
		loadedComments: 0,
		totalComments: 0,
	};

	if (!comments) {
		return emptyComments;
	}

	const { totalCount: totalComments, startIndex: commentsStartIndex } = comments;

	const response = {
		data: {
			issueComments: comments,
		},
	};

	if (fg('jira_comments_agg_pagination')) {
		return {
			...emptyComments,
			totalComments,
		};
	}

	return {
		...normalizeComments(transformComments(response), 0, commentsStartIndex),
		totalComments,
		commentsStartIndex,
	};
};

export const transformGiraData = (
	issue: ViewIssueData | null,
	loadedComments: number,
	commentsStartIndex: number,
	type?: string,
	orderBy?: string,
): CommentsTransformResult => {
	const emptyComments = {
		totalComments: 0,
		users: {},
		comments: {},
		commentsStartIndex: 0,
		loadedComments: 0,
	};

	if (issue?.comments == null) {
		return emptyComments;
	}

	const { comments } = issue;
	const { totalCount: totalComments } = comments;
	const response = {
		data: {
			issueComments: comments,
		},
	};

	if (fg('jira_comments_agg_pagination')) {
		return {
			...emptyComments,
			totalComments,
		};
	}

	return {
		...normalizeGiraComments(
			transformComments(response),
			loadedComments,
			commentsStartIndex,
			type,
			orderBy,
		),
		totalComments,
	};
};

/**
 *  To be used when graphql is only returning child comments and we need to establish parent comment's context
 */
export const transformChildCommentsAggData = (
	parentId: string,
	data?: commentFetchAggServerChildCommentsQuery$data,
	oldPageInfo?: CommentsPageInfo,
	isOlderButton?: boolean,
): NormalizedLoadedCommentsV2 => {
	const dummyParentCommentData: commentFetchAggServerQuery$data = {
		node: {
			threadedComments: {
				edges: [
					{
						cursor: '',
						node: {
							author: null,
							created: '',
							permissionLevel: null,
							richText: null,
							updateAuthor: null,
							updated: null,
							__typename: '',
							commentId: parentId,
							isDeleted: false,
							childComments: data?.node?.childComments,
						},
					},
				],
				pageInfo: {
					endCursor: null,
					hasNextPage: false,
					hasPreviousPage: false,
					startCursor: null,
					...data?.node?.childComments?.pageInfo,
				},
			},
		},
	};

	const dummyProject = SOFTWARE_PROJECT;

	const {
		users,
		commentsPageInfo,
		comments: transformedComments,
	} = transformAggData(dummyParentCommentData, dummyProject, oldPageInfo, isOlderButton);

	return { users, commentsPageInfo, comments: unsetIn(transformedComments, [parentId]) };
};

export const transformAggData = (
	data: commentFetchAggServerQuery$data | undefined,
	projectType: ProjectType | undefined,
	oldPageInfo?: CommentsPageInfo,
	isOlderButton?: boolean,
): NormalizedLoadedCommentsV2 => {
	const emptyComments = {
		users: {},
		comments: {},
		commentsPageInfo: {},
	};

	const { comments, isThreadedCommentsEnabled } = computeAggCommentsToUse(
		data?.node?.comments,
		data?.node?.threadedComments,
		projectType,
	);

	if (comments?.pageInfo) {
		const transformedPageInfo = { ...comments.pageInfo };

		// the relay query will gave pageInfo for just the current returned set of comments, whereas
		// we need pageInfo for the currently displayed comments, hence
		// use old pageInfo and combine with new pageInfo to create the accurate representation
		if (oldPageInfo) {
			if (isOlderButton) {
				transformedPageInfo.startCursor = oldPageInfo.startCursor;
				transformedPageInfo.hasPreviousPage = !!oldPageInfo.hasPreviousPage;
			} else {
				transformedPageInfo.endCursor = oldPageInfo.endCursor;
				transformedPageInfo.hasNextPage = !!oldPageInfo.hasNextPage;
			}
		}

		const transformedComments = transformAggCommentsToLegacyGiraV2Helper(
			comments,
			isThreadedCommentsEnabled,
		);
		if (transformedComments?.comments) {
			const normalizedComments = normalizeCommentsV2(
				transformedComments.comments,
				transformedPageInfo,
			);

			return normalizedComments;
		}
	}

	return emptyComments;
};

type LogFun = (attribute: string, giraString?: string, aggString?: string) => void;
type ExceptionMatched = (giraString?: string, aggString?: string) => boolean;
type Attribute = { [key: string]: ExceptionMatched | null };

const logError = (msg: string) =>
	log.safeErrorWithoutCustomerData('issue.agg-transformers.comments', msg);

const logResult = (isSuccess: boolean) =>
	log.safeInfoWithoutCustomerData('issue.agg-transformers.comments', 'result', { isSuccess });

const logCommentMismatch =
	(commentId: string) => (attribute: string, giraString?: string, aggString?: string) =>
		logError(
			`comment id ${commentId} mismatch for ${attribute}: gira: ${giraString} vs. agg: ${aggString}`,
		);

const commentAttributes: Attribute[] = [
	{
		authorId: (giraValue?: string, aggValue?: string) =>
			giraValue === 'unknown' || aggValue === 'unknown',
	},
	{ edited: null },
	{ id: null },
	{
		isInternal: (giraValue?: string, aggValue?: string) =>
			String(giraValue) === 'true' && String(aggValue) === 'false',
	},
	{
		updateAuthorId: (giraValue?: string, aggValue?: string) =>
			giraValue === 'unknown' || aggValue === 'unknown',
	},
	{ visibility: null },
	{ eventOccurredAt: null },
	{ jsdIncidentActivityViewHidden: null },
	{ jsdAuthorCanSeeRequest: (giraValue?: string, aggValue?: string) => aggValue === undefined },
];

const paginationCommentAttributes: Attribute[] = [
	{
		authorId: (giraValue?: string, aggValue?: string) =>
			giraValue === 'unknown' || aggValue === 'unknown',
	},
	{ edited: null },
	{ id: null },
	{
		isInternal: (giraValue?: string, aggValue?: string) =>
			String(giraValue) === 'true' && String(aggValue) === 'false',
	},
	{
		updateAuthorId: (giraValue?: string, aggValue?: string) =>
			giraValue === 'unknown' || aggValue === 'unknown',
	},
	{ visibility: null },
	{ jsdIncidentActivityViewHidden: null },
	{ jsdAuthorCanSeeRequest: (giraValue?: string, aggValue?: string) => aggValue === undefined },
	{
		bodyHtml: (giraValue?: string, aggValue?: string) =>
			String(giraValue) === 'undefined' && String(aggValue) === '',
	},
	{
		createdDate: (giraValue?: string, aggValue?: string) =>
			new Date(String(giraValue)).toString() === new Date(String(aggValue)).toString(),
	},
	{
		updatedDate: (giraValue?: string, aggValue?: string) =>
			new Date(String(giraValue)).toString() === new Date(String(aggValue)).toString(),
	},
	{
		eventOccurredAt: (giraValue?: string, aggValue?: string) =>
			new Date(String(giraValue)).toString() === new Date(String(aggValue)).toString(),
	},
];

const THIRTY_SECONDS = 30 * 10000;
export const compareComment = (
	giraCommentResult: TransformedGiraData,
	aggCommentResult: CommentsTransformResult,
) => {
	let isSuccess = true;
	try {
		const { users: aggUsers, comments: aggComments } = aggCommentResult;
		const { users: giraUsers, comments: giraComments } = giraCommentResult;

		if (!giraUsers || !giraComments || !aggUsers || !aggComments) {
			return;
		}

		const aggCommentIds = Object.keys(aggComments);
		const giraCommentIds = Object.keys(giraComments);

		if (JSON.stringify(aggCommentIds) !== JSON.stringify(giraCommentIds)) {
			if (getPermalinkId(COMMENTS) && aggCommentIds.length === 0) {
				logError('comments id mismatch due to targeted comment id not exist');
				return;
			}
			const difference = aggCommentIds
				.filter((commentId) => !giraCommentIds.includes(commentId))
				.concat(giraCommentIds.filter((commentId) => !aggCommentIds.includes(commentId)));
			const hasActualMismatch = difference.filter(
				(commentId) =>
					new Date().getTime() -
						new Date((aggComments[commentId] || giraComments[commentId]).createdDate).getTime() >
					THIRTY_SECONDS,
			);

			if (hasActualMismatch.length) {
				logError(`comments id mismatch agg: ${aggCommentIds} vs. gira: ${giraCommentIds}`);
				isSuccess = false;
				return;
			}
			if (!difference.length) {
				logError(`comments id order mismatch agg: ${aggCommentIds} vs. gira: ${giraCommentIds}`);
				isSuccess = false;
				return;
			}
		}

		Object.entries(giraComments).forEach(([commentId, giraCommentValue]) => {
			const aggCommentValue = aggComments[commentId];
			const attributeMatched = attributeComparison(
				commentAttributes,
				giraCommentValue,
				aggCommentValue,
				logCommentMismatch(commentId),
			);
			if (!attributeMatched) {
				isSuccess = false;
			}
		});
	} catch (error) {
		if (error instanceof Error) {
			logError(error.message);
		}
	} finally {
		logResult(isSuccess);
	}
};

type PaginationApiCallSource =
	| 'initialRender'
	| 'fetchCommentsRequest'
	| 'fetchSortedCommentsRequest(focused)'
	| 'fetchSortedCommentsRequest'
	| 'fetchAttachmentCommentRequest';

let top5Inconsistency = false;
const logPaginationError = (msg: string) =>
	log.safeErrorWithoutCustomerData('issue.agg-transformers.comments.pagination', msg);

const logPaginationResult = (isSuccess: boolean) =>
	log.safeInfoWithoutCustomerData('issue.agg-transformers.comments.pagination', 'result', {
		isSuccess,
	});

const logPaginationCommentMismatch =
	(commentId: string, apiCallSource: PaginationApiCallSource, issueId: string) =>
	(attribute: string, giraString?: string, aggString?: string) => {
		if (apiCallSource === 'initialRender') {
			top5Inconsistency = true;
		}
		logPaginationError(
			`top5Inconsistency: ${top5Inconsistency} issueId: ${issueId} focusedCommentId: ${getPermalinkId(COMMENTS)} comment id ${commentId} mismatch for ${attribute}: gira: ${giraString} vs. agg: ${aggString} from: ${apiCallSource}`,
		);
	};

export const comparePaginationComment = (
	giraCommentResult: CommentsTransformResult,
	aggCommentResult: NormalizedLoadedCommentsV2,
	apiCallSource: PaginationApiCallSource,
	issueId = 'unknown',
) => {
	let isSuccess = true;
	try {
		const { users: aggUsers, comments: aggComments, commentsPageInfo } = aggCommentResult;
		const {
			users: giraUsers,
			comments: giraComments,
			totalComments,
			loadedComments,
			commentsStartIndex,
		} = giraCommentResult;

		if (!giraUsers || !giraComments || !aggUsers || !aggComments) {
			return;
		}

		const aggCommentIds = Object.keys(aggComments);
		const giraCommentIds = Object.keys(giraComments);

		const isInitialRender = apiCallSource === 'initialRender';

		if (JSON.stringify(aggCommentIds) !== JSON.stringify(giraCommentIds)) {
			if (getPermalinkId(COMMENTS) && aggCommentIds.length === 0) {
				logPaginationError(
					`top5Inconsistency: ${top5Inconsistency} issueId: ${issueId} focusedCommentId: ${getPermalinkId(COMMENTS)} comments id mismatch due to targeted comment id not exist from: ${apiCallSource}`,
				);
				return;
			}
			const difference = aggCommentIds
				.filter((commentId) => !giraCommentIds.includes(commentId))
				.concat(giraCommentIds.filter((commentId) => !aggCommentIds.includes(commentId)));
			const hasActualMismatch = difference.filter(
				(commentId) =>
					new Date().getTime() -
						new Date((aggComments[commentId] || giraComments[commentId]).createdDate).getTime() >
					THIRTY_SECONDS,
			);

			if (hasActualMismatch.length) {
				if (isInitialRender) {
					top5Inconsistency = true;
				}
				logPaginationError(
					`top5Inconsistency: ${top5Inconsistency} issueId: ${issueId} focusedCommentId: ${getPermalinkId(COMMENTS)} comments id mismatch agg: ${aggCommentIds} vs. gira: ${giraCommentIds} from: ${apiCallSource}`,
				);
				isSuccess = false;
				return;
			}
			if (!difference.length) {
				if (isInitialRender) {
					top5Inconsistency = true;
				}
				logPaginationError(
					`top5Inconsistency: ${top5Inconsistency} issueId: ${issueId} focusedCommentId: ${getPermalinkId(COMMENTS)} comments id order mismatch agg: ${aggCommentIds} vs. gira: ${giraCommentIds} from: ${apiCallSource}`,
				);
				isSuccess = false;
				return;
			}
		}

		if (!isInitialRender)
			Object.entries(giraComments).forEach(([commentId, giraCommentValue]) => {
				const aggCommentValue = aggComments[commentId];
				const attributeMatched = attributeComparison(
					paginationCommentAttributes,
					giraCommentValue,
					aggCommentValue,
					logPaginationCommentMismatch(commentId, apiCallSource, issueId),
				);
				if (!attributeMatched) {
					isSuccess = false;
				}
			});

		if (
			!compareCommentsPageInfo(
				totalComments,
				loadedComments,
				commentsStartIndex,
				commentsPageInfo,
				apiCallSource,
				issueId,
			)
		) {
			isSuccess = false;
		}
	} catch (error) {
		if (error instanceof Error) {
			logPaginationError(
				`top5Inconsistency: ${top5Inconsistency} ${error.message} from: ${apiCallSource}`,
			);
		}
	} finally {
		logPaginationResult(isSuccess);
	}
};

const attributeComparison = (
	attributes: Attribute[],
	giraResponse: NormalizedComment | NormalizedUser,
	aggResponse: NormalizedComment | NormalizedUser,
	logFunc: LogFun,
) => {
	let matched = true;
	attributes.forEach((attribute) => {
		const field = Object.keys(attribute)[0];
		const exceptionMatched = attribute[field];
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- PLEASE FIX - ENABLING FLAT LINT CONFIG
		const giraValue = giraResponse?.[field as keyof typeof giraResponse];
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- PLEASE FIX - ENABLING FLAT LINT CONFIG
		const aggValue = aggResponse?.[field as keyof typeof aggResponse];
		if (
			((giraValue !== aggValue && typeof giraValue !== 'object') ||
				(typeof giraValue === 'object' &&
					JSON.stringify(giraValue) !== JSON.stringify(aggValue))) &&
			(!exceptionMatched || !exceptionMatched(giraValue, aggValue))
		) {
			logFunc(field, JSON.stringify(giraValue), JSON.stringify(aggValue));
			matched = false;
		}
	});
	return matched;
};

export const compareCommentsPageInfo = (
	totalComments: number,
	loadedComments: number,
	commentsStartIndex: number | null,
	commentsPageInfo: CommentsPageInfo,
	apiCallSource: PaginationApiCallSource,
	issueId = 'unknown',
): boolean => {
	if (!commentsPageInfo) {
		logPaginationError(
			`top5Inconsistency: ${top5Inconsistency} issueId: ${issueId} focusedCommentId: ${getPermalinkId(COMMENTS)} commentsPageInfo not received from: ${apiCallSource}`,
		);
		return false;
	}

	const { hasNextPage: aggHasNextPage, hasPreviousPage: aggHasPreviousPage } = commentsPageInfo;

	if (!['true', 'false'].includes(aggHasNextPage?.toString() ?? '')) {
		logPaginationError(
			`top5Inconsistency: ${top5Inconsistency} issueId: ${issueId} focusedCommentId: ${getPermalinkId(COMMENTS)} invalid hasNextPage: ${aggHasNextPage} from: ${apiCallSource}`,
		);
		return false;
	}

	if (!['true', 'false'].includes(aggHasPreviousPage?.toString() ?? '')) {
		logPaginationError(
			`top5Inconsistency: ${top5Inconsistency} issueId: ${issueId} focusedCommentId: ${getPermalinkId(COMMENTS)} invalid hasPreviousPage: ${aggHasPreviousPage} from: ${apiCallSource}`,
		);
		return false;
	}

	const { numPrevCommentsToLoad, numNextCommentsToLoad } = calculateNumberOfCommentsToLoad(
		totalComments,
		loadedComments,
		commentsStartIndex ?? 0,
	);
	const giraHasNextPage = numNextCommentsToLoad > 0;
	const giraHasPreviousPage = numPrevCommentsToLoad > 0;

	if (giraHasNextPage !== aggHasNextPage) {
		logPaginationError(
			`top5Inconsistency: ${top5Inconsistency} issueId: ${issueId} focusedCommentId: ${getPermalinkId(COMMENTS)} hasNextPage values did not match agg: ${aggHasNextPage} vs. gira: [totalComments: ${totalComments}, loadedComments: ${loadedComments}, commentsStartIndex: ${commentsStartIndex}] from: ${apiCallSource}`,
		);
		return false;
	}

	if (giraHasPreviousPage !== aggHasPreviousPage) {
		logPaginationError(
			`top5Inconsistency: ${top5Inconsistency} issueId: ${issueId} focusedCommentId: ${getPermalinkId(COMMENTS)} hasPrevPage values did not match agg: ${aggHasPreviousPage} vs. gira: [totalComments: ${totalComments}, loadedComments: ${loadedComments}, commentsStartIndex: ${commentsStartIndex}] from: ${apiCallSource}`,
		);
		return false;
	}

	return true;
};
