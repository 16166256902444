import React, { useState, useEffect } from 'react';
import { TeamValue } from '@atlassian/jira-polaris-lib-team-value/src/ui/index.tsx';
import { useAllTeamInformationOnIssues } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/team-hooks.tsx';
import type { TeamInformation } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/team.tsx';

type ColumnHeaderComponentProps = {
	groupIdentity: string;
};

const TeamColumnHeader = ({ groupIdentity }: ColumnHeaderComponentProps) => {
	const issueTeamInfos = useAllTeamInformationOnIssues();
	const [teamInfo, setTeamInfo] = useState<TeamInformation[0]>();

	useEffect(() => {
		const teamByGroupIdentity = issueTeamInfos[groupIdentity];
		if (teamByGroupIdentity) {
			setTeamInfo(teamByGroupIdentity);
		}
	}, [groupIdentity, issueTeamInfos]);

	const teamName = teamInfo?.name ?? '';

	return <TeamValue name={teamName} />;
};
const EmptyTeamColumnHeader = () => <TeamValue />;

export const teamColumnHeaderConfig = {
	isHideable: true,
	isDecoratable: false,
	Component: TeamColumnHeader,
	EmptyComponent: EmptyTeamColumnHeader,
} as const;
