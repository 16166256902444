/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { Flex } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import {
	useFieldEmoji,
	useFieldTypeIcon,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIssueTypesForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { IssueTypeIcon } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-icon.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import type { SelectOption } from '../../../../../filters/filter-component/select/content/index.tsx';
import { SelectFilterComponent } from '../../../../../filters/filter-component/select/index.tsx';

const useIssueTypeOptions = (): SelectOption[] => {
	const container = useEnvironmentContainer();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectId : '';
	const issueTypes = useIssueTypesForProject({ projectId });

	return issueTypes.map(({ name, id }) => ({
		label: name,
		id,
		OptionRenderComponent: () => (
			<Flex alignItems="center" gap="space.075">
				<IssueTypeIcon issueTypeId={id} />
				{name}
			</Flex>
		),
	}));
};

type FilterComponentProps = {
	field: Field;
	isDisabled?: boolean;
	values: string[];
	onChange: (values: string[]) => void;
};

export const FilterComponent = ({
	field,
	isDisabled = false,
	values,
	onChange,
}: FilterComponentProps) => {
	const fieldTypeIcon = useFieldTypeIcon(field.key, undefined);
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);
	const allOptions = useIssueTypeOptions();
	const selectedOptions = values
		.map((value) => allOptions?.find((option) => option.id === value)?.id)
		.filter(Boolean);

	const handleChange = (newOptionsIds: (string | undefined)[]) => {
		onChange(newOptionsIds.filter(Boolean));
	};

	return (
		<div css={filterComponentWrapperStyles}>
			<SelectFilterComponent
				label={field.label}
				fieldType={field.type}
				fieldTypeIcon={fieldTypeIcon}
				emoji={emoji}
				selected={selectedOptions}
				onChange={handleChange}
				isDisabled={isDisabled}
				isLastFilter={false}
				options={allOptions}
			/>
		</div>
	);
};

const filterComponentWrapperStyles = css({
	flexGrow: 1,
	marginTop: token('space.050'),
	marginRight: 0,
	marginBottom: token('space.050'),
	marginLeft: 0,
	minWidth: '0',
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		width: '100%',
		textAlign: 'left',
	},
});
