import React, { type FocusEvent, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EditorAddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import EditorHorizontalRuleIcon from '@atlaskit/icon/core/migration/minus--editor-horizontal-rule';
import LikeIcon from '@atlaskit/icon/core/migration/thumbs-up--like';
import { Flex, Stack } from '@atlaskit/primitives';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { getVoteAmountInRange } from '../utils.tsx';
import messages from './messages.tsx';

type VoteInputProps = {
	isDisabled: boolean;
	maxVotes: number;
	localAmount: number;
	votesLeft: number;
	onChange: (arg1: number) => void;
};

export const VoteInput = ({
	isDisabled,
	localAmount,
	maxVotes,
	votesLeft,
	onChange,
}: VoteInputProps) => {
	const { formatMessage } = useIntl();

	const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
		const value = parseInt(event.target.value, 10);

		onChange(getVoteAmountInRange(value, maxVotes));
	};

	return (
		<Stack space="space.050">
			<Flex gap="space.025" alignItems="center">
				<LikeIcon LEGACY_size="small" label={formatMessage(messages.votesTitle)} />
				<Title>{formatMessage(messages.votesTitle)}</Title>
			</Flex>
			<Flex gap="space.100" alignItems="center">
				<InputContainer>
					<Button
						isDisabled={localAmount === 0 || isDisabled}
						iconBefore={
							<EditorHorizontalRuleIcon
								spacing="spacious"
								label={formatMessage(messages.addVoteButtonLabel)}
							/>
						}
						onClick={() => onChange(localAmount - 1)}
					/>
					<Input
						disabled={isDisabled}
						type="number"
						inputMode="numeric"
						value={localAmount}
						min={0}
						max={maxVotes}
						onBlur={handleBlur}
						onChange={(e) => onChange(parseInt(e.target.value, 10))}
					/>
					<Button
						isDisabled={localAmount === maxVotes || isDisabled}
						iconBefore={
							<EditorAddIcon
								spacing="spacious"
								label={formatMessage(messages.removeVoteButtonLabel)}
							/>
						}
						onClick={() => onChange(localAmount + 1)}
					/>
				</InputContainer>
				<Stack>
					<Text>{formatMessage(messages.votesHelpText)}</Text>
					<Text>
						<FormattedI18nMessage
							message={formatMessage(messages.votesText, {
								numberStart: '{numberStart}',
								numberEnd: '{numberEnd}',
								number: Math.max(0, votesLeft),
							})}
							componentsMapping={{
								number: ({ children }: { children: ReactNode }) => <b>{children}</b>,
							}}
						/>
					</Text>
				</Stack>
			</Flex>
		</Stack>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.p({
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.bold'),
	margin: 0,
	color: token('color.text.subtle', N500),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Text = styled.p({
	font: token('font.body.small'),
	color: token('color.text.subtle', N500),
	margin: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InputContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: `${token('color.border')}`,
	borderRadius: '3px',
	overflow: 'hidden',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& button': {
		borderRadius: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Input = styled.input({
	width: '50px',
	borderColor: token('color.border'),
	borderWidth: '0 1px 0 1px',
	borderStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	borderRadius: '0 !important',
	boxSizing: 'border-box',
	height: '32px',
	paddingTop: token('space.075'),
	paddingRight: token('space.025'),
	paddingBottom: token('space.075'),
	paddingLeft: token('space.025'),
	textAlign: 'center',
	font: token('font.body'),
	fontWeight: token('font.weight.semibold'),
	color: token('color.text'),
	'-moz-appearance': 'textfield',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
		'-webkit-appearance': 'none',
	},
});
