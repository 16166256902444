import { fg } from '@atlassian/jira-feature-gating';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ArchivedFilter } from '../../../common/types.tsx';
import type { JpdJiraSearchApiIssue, JpdJiraSearchApiPublicIssue } from '../types.tsx';
import { translateToPublicIssue } from '../util/index.tsx';
import type { JpdJiraSearchApiResponse, JpdJiraIssueApiResponse } from './types.tsx';

const POLARIS_SEARCH_URL = '/rest/polaris/issues/search';
const POLARIS_SEARCH_V3_URL = '/rest/polaris/issues/v3/search';
const POLARIS_ISSUE_URL = '/rest/polaris/issues/get';

type GetJpdIssuesProps = {
	projectId: string;
	issueIdsOrKeys?: string[];
	fields?: string[];
	archivedFilter?: ArchivedFilter;
	maxResults?: number;
	startAt?: number;
	include?: {
		projectData?: boolean;
		connections?: boolean;
		metadata?: boolean;
	};
};

const getJpdIssuesLegacy = ({
	projectId,
	issueIdsOrKeys,
	fields,
	archivedFilter,
	maxResults,
	startAt,
}: GetJpdIssuesProps): Promise<{
	total: number;
	issues: JpdJiraSearchApiPublicIssue[];
}> =>
	fetchJson(POLARIS_SEARCH_URL, {
		method: 'POST',
		body: JSON.stringify({
			projectId,
			fields: fg('jpd_issues_relationships')
				? [...new Set([...(fields || []), 'issuelinks'])]
				: fields,
			includeProjectData: true,
			archived: archivedFilter,
			maxResults,
			startAt,
			...(issueIdsOrKeys ? { issueIdsOrKeys } : {}),
			includeAllFields: fg('jpd_issues_relationships'),
		}),
	}).then((response: JpdJiraSearchApiResponse) => ({
		total: response.issues.length,
		issues: response.issues.map((issue) => translateToPublicIssue(response, issue)),
	}));

export const getJpdIssues = ({
	projectId,
	issueIdsOrKeys,
	fields,
	archivedFilter,
	maxResults,
	startAt,
	include,
}: GetJpdIssuesProps): Promise<{
	total: number;
	issues: JpdJiraSearchApiPublicIssue[];
	issueLists?: {
		primary?: IssueId[];
		connections?: IssueId[];
	};
}> => {
	if (!fg('jpd_issue_search_v3')) {
		return getJpdIssuesLegacy({
			projectId,
			issueIdsOrKeys,
			fields,
			archivedFilter,
			maxResults,
			startAt,
		});
	}

	const jql = issueIdsOrKeys ? `issue in (${issueIdsOrKeys.join(',')})` : `project = ${projectId}`;

	return fetchJson(POLARIS_SEARCH_V3_URL, {
		method: 'POST',
		body: JSON.stringify({
			jql,
			fields: {
				fieldKeys: fields,
			},
			include: {
				projectData: include?.projectData ?? true,
				archived: archivedFilter,
				metadata: include?.metadata ?? true,
				connections: fg('jpd_issues_relationships') && (include?.connections ?? true),
			},
			pagination: {
				max: maxResults,
			},
		}),
	}).then((response: JpdJiraSearchApiResponse) => ({
		total: response.issues.length,
		issues: response.issues.map((issue) => translateToPublicIssue(response, issue)),
		issueLists: response.issueLists
			? {
					primary: response.issueLists.primary?.map(String),
					connections: response.issueLists.connections?.map(String),
				}
			: undefined,
	}));
};

export const getJpdIssue = (
	projectId: string,
	issueIdOrKey: string,
	fields: string[],
): Promise<JpdJiraSearchApiPublicIssue> => {
	if (!fg('jpd_issue_search_v3')) {
		return fetchJson(POLARIS_ISSUE_URL, {
			method: 'POST',
			body: JSON.stringify({
				projectId,
				issueIdOrKey,
				fields: [...new Set([...fields, 'description', 'comment', 'issuelinks', 'project'])],
				includeAllFields: fields.length === 0,
				includeProjectData: true,
			}),
		}).then((response: JpdJiraIssueApiResponse) =>
			translateToPublicIssue(response, response.issue),
		);
	}

	return fetchJson(POLARIS_SEARCH_V3_URL, {
		method: 'POST',
		body: JSON.stringify({
			jql: `issue = ${issueIdOrKey}`,
			fields: {
				fieldKeys: [...new Set([...fields, 'description', 'comment', 'issuelinks', 'project'])],
				all: fields.length === 0,
			},
			include: {
				projectData: true,
				metadata: true,
				connections: fg('jpd_issues_relationships'),
			},
			pagination: {
				max: 1,
			},
		}),
	}).then((response: JpdJiraSearchApiResponse) => {
		if (response.issueLists?.primary !== undefined && response.issueLists.primary.length > 0) {
			const issuesById: { [key: string]: JpdJiraSearchApiIssue } = {};
			response.issues.forEach((issue) => {
				issuesById[issue.id] = issue;
			});

			return translateToPublicIssue(response, issuesById[response.issueLists.primary[0]]);
		}
		return translateToPublicIssue(response, response.issues[0]);
	});
};
