import React, { useCallback, useState } from 'react';
import { Anchor, Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIssueLink } from '@atlassian/jira-polaris-common/src/common/utils/issue-link/index.tsx';
import { useIsEmbedded } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import { useIsEditingSummary } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import {
	useIssueType,
	useSafeIssueKey,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useIsFieldVisibleInCurrentView } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { IssueTypeSelect } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-select/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import {
	ISSUETYPE_FIELDKEY,
	KEY_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useFieldUpdate, useIsEditable } from '../../utils/index.tsx';
import { EditShortTextField } from './edit/main.tsx';
import { ReadOnlySummaryField } from './read-only/index.tsx';
import { SummaryViewField } from './view/index.tsx';

type EditableSummaryFieldProps = {
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
	interactive: boolean;
};

const IssueKey = ({ issueKey, embedded }: { issueKey: string; embedded: boolean }) => {
	const isIssueKeyVisibleInCurrentView = useIsFieldVisibleInCurrentView(KEY_FIELDKEY);
	const { link, onClick } = useIssueLink({ issueKey });

	if (!isIssueKeyVisibleInCurrentView) {
		return null;
	}

	if (embedded) {
		return <Box xcss={issueKeyStyles}>{issueKey}</Box>;
	}

	return (
		<Anchor xcss={issueKeyStyles} href={link} onClick={onClick}>
			{issueKey}
		</Anchor>
	);
};

export const EditableSummaryField = ({
	localIssueId,
	interactive,
	fieldKey,
}: EditableSummaryFieldProps) => {
	const isIssueTypeVisibleInCurrentView = useIsFieldVisibleInCurrentView(ISSUETYPE_FIELDKEY);
	const issueKey = useSafeIssueKey(localIssueId) ?? '';
	const [canEditIssues] = useCanEditIssues();
	const issueType = useIssueType(localIssueId);
	const onUpdate = useFieldUpdate(localIssueId, ISSUETYPE_FIELDKEY);

	const isEditing = useIsEditingSummary(localIssueId);
	// Storing the editing state locally additionally to global state to avoid having edit view open
	// the same idea in multiple groups in list view
	const [isLocalEditing, setIsLocalEditing] = useState(false);
	const { setSummaryEditing } = useIdeaActions();
	const isEditable = useIsEditable(fieldKey);
	const embedded = useIsEmbedded();

	const onCancelEditing = useCallback(() => {
		setIsLocalEditing(false);
		setSummaryEditing(localIssueId, false);
	}, [localIssueId, setSummaryEditing]);

	const onStartEditing = useCallback(() => {
		setIsLocalEditing(true);
		setSummaryEditing(localIssueId, true);
	}, [localIssueId, setSummaryEditing]);

	if (isEditable && isEditing && isLocalEditing) {
		return (
			<EditShortTextField
				fieldKey={fieldKey}
				localIssueId={localIssueId}
				onCancel={onCancelEditing}
			/>
		);
	}

	return (
		<Flex xcss={containerStyles} gap="space.075" alignItems="center">
			{isIssueTypeVisibleInCurrentView && issueType && (
				<IssueTypeSelect
					readonly={!canEditIssues}
					issueTypeId={issueType.id}
					onChange={(newIssueType) => onUpdate(newIssueType)}
				/>
			)}

			<IssueKey issueKey={issueKey} embedded={embedded} />

			<Box xcss={summaryStyles}>
				{!interactive || embedded ? (
					<ReadOnlySummaryField fieldKey={fieldKey} localIssueId={localIssueId} />
				) : (
					<SummaryViewField
						fieldKey={fieldKey}
						localIssueId={localIssueId}
						onEdit={onStartEditing}
					/>
				)}
			</Box>
		</Flex>
	);
};

export const EditableLinkField = ({
	localIssueId,
	fieldKey,
	interactive,
}: EditableSummaryFieldProps) => {
	const isEditing = useIsEditingSummary(localIssueId);
	// Storing the editing state locally additionally to global state to avoid having edit view open
	// the same idea in multiple groups in list view
	const [isLocalEditing, setIsLocalEditing] = useState(false);
	const { setSummaryEditing } = useIdeaActions();
	const isEditable = useIsEditable(fieldKey);
	const embedded = useIsEmbedded();

	const onCancelEditing = useCallback(() => {
		setIsLocalEditing(false);
		setSummaryEditing(localIssueId, false);
	}, [localIssueId, setSummaryEditing]);

	const onStartEditing = useCallback(() => {
		setIsLocalEditing(true);
		setSummaryEditing(localIssueId, true);
	}, [localIssueId, setSummaryEditing]);

	if (isEditable && isEditing && isLocalEditing) {
		return (
			<EditShortTextField
				fieldKey={fieldKey}
				localIssueId={localIssueId}
				onCancel={onCancelEditing}
			/>
		);
	}

	if (!interactive || embedded) {
		return <ReadOnlySummaryField fieldKey={fieldKey} localIssueId={localIssueId} />;
	}

	return (
		<SummaryViewField fieldKey={fieldKey} localIssueId={localIssueId} onEdit={onStartEditing} />
	);
};

const summaryStyles = xcss({
	flex: 1,
	overflow: 'hidden',
});

const issueKeyStyles = xcss({
	color: 'color.text.subtle',
	font: token('font.body.UNSAFE_small'),
	textDecoration: 'none',
	minWidth: 'fit-content',
	':hover': {
		color: 'color.text.subtle',
	},
});

const containerStyles = xcss({
	overflow: 'hidden',
	border: '2px solid transparent',
	paddingInlineEnd: 'space.075',
	paddingInlineStart: 'space.025',
	paddingBlockStart: 'space.100',
	paddingBlockEnd: 'space.075',
});
