import React from 'react';
import { styled } from '@compiled/react';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

export const VotingHint = () => {
	const { formatMessage } = useIntl();

	return (
		<>
			<Line>{formatMessage(messages.hint)}</Line>
			<Line>{formatMessage(messages.example)}</Line>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Line = styled.div({
	font: token('font.body.small'),
	color: token('color.text.subtle', N500),
	marginTop: token('space.150'),
});
