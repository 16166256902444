/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useLocalIssueIdForJiraIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { RenderCell } from '../../../../../view-content/idea-list/cell/cells/main.tsx';

type SelectedFieldCellRendererProps = {
	issueId: number;
	fieldKey: string;
};

export const SelectedFieldCellRenderer = ({
	fieldKey,
	issueId,
}: SelectedFieldCellRendererProps) => {
	const localIssueId = useLocalIssueIdForJiraIssueId(issueId.toString());
	const [isHovered, setIsHovered] = useState(false);

	if (localIssueId === undefined) {
		return null;
	}

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			css={containerStyles}
			// eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
			onMouseOver={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<RenderCell fieldKey={fieldKey} isHovered={isHovered} localIssueId={localIssueId} />
		</div>
	);
};

const containerStyles = css({
	paddingLeft: token('space.075'),
	paddingRight: token('space.100'),
	height: '100%',
	display: 'flex',
	alignItems: 'center',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> *': {
		width: '100%',
		boxSizing: 'border-box',
	},
});
