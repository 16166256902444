import { useEffect, useState } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { useFetchEarliestOnboardedProject } from './services/get-earliest-onboarded-project-timestamp/index.tsx';

export const JPD_TEMPLATE_DEFAULT = 'v20';
export const JPD_TEMPLATE_LOOM_INTRO = 'v20-loom-intro'; // GALILEO-1899
export const JPD_TEMPLATE_V21 = 'v21';
export const JPD_TEMPLATE_V21_LOOM_INTRO = 'v21-loom-intro';

export const JPD_ONBOARDING_TEMPLATES = [
	JPD_TEMPLATE_DEFAULT,
	JPD_TEMPLATE_LOOM_INTRO, // GALILEO-1899
	JPD_TEMPLATE_V21,
	JPD_TEMPLATE_V21_LOOM_INTRO,
];

type TemplateVersionMap = {
	[version: string]: {
		default: string;
		loom: string;
	};
};

const DEFAULT_TEMPLATE_TO_VERSION: TemplateVersionMap = {
	v20: {
		default: JPD_TEMPLATE_DEFAULT,
		loom: JPD_TEMPLATE_LOOM_INTRO, // GALILEO-1899
	},
	v21: {
		default: JPD_TEMPLATE_V21,
		loom: JPD_TEMPLATE_V21_LOOM_INTRO, // GALILEO-1899
	},
};

export const getDefaultProjectTemplateVersion = () => {
	// GALILEO-1899 START
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [onboardingVideoExp] = UNSAFE_noExposureExp('loom_intro_about_this_view');
	const templateVersionVariant = onboardingVideoExp.get('onboardingVideoEnabled', false)
		? 'loom'
		: 'default';
	// GALILEO-1899 END

	const templateVersion = fg('polaris-issue-terminology-refresh') ? 'v21' : 'v20';
	return DEFAULT_TEMPLATE_TO_VERSION[templateVersion][templateVersionVariant];
};

// Returns null in case of loading status
export const useShouldShowOnboardingExperience = (projectAri: Ari | null): boolean | null => {
	const [fetchEarliestOnboardedProjectTimeStamp] = useFetchEarliestOnboardedProject();
	const [earliestOnboardedProjectTemplate, setEarliestOnboardedProjectTemplate] = useState<
		undefined | null | string
	>();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (!projectAri) {
			return;
		}

		fetchEarliestOnboardedProjectTimeStamp(projectAri)
			.then(({ template }) => setEarliestOnboardedProjectTemplate(template))
			.catch((error) => {
				log.safeErrorWithoutCustomerData(
					'polaris.onboarding.experiment-check.error',
					'Failed to fetch earliest onboarded project for current cloud id',
					error,
				);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [fetchEarliestOnboardedProjectTimeStamp, projectAri]);

	if (isLoading) {
		return null;
	}

	return (
		earliestOnboardedProjectTemplate === null ||
		JPD_ONBOARDING_TEMPLATES.includes(earliestOnboardedProjectTemplate ?? '')
	);
};
