import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	heading: {
		id: 'polaris-ideas.common.send-to-backlog.heading',
		defaultMessage: 'Create a delivery ticket',
		description: 'Dialog heading.',
	},
	headingIssueTermRefresh: {
		id: 'polaris-ideas.common.send-to-backlog.heading-issue-term-refresh',
		defaultMessage: 'Create a delivery work item',
		description: 'Dialog heading.',
	},
	create: {
		id: 'polaris-ideas.common.send-to-backlog.create',
		defaultMessage: 'Create',
		description: 'Button label for creation.',
	},
	description: {
		id: 'polaris-ideas.common.send-to-backlog.description',
		defaultMessage: 'Create a delivery ticket',
		description: 'Description for creating a delivery ticket in Jira.',
	},
	descriptionIssueTermRefresh: {
		id: 'polaris-ideas.common.send-to-backlog.description-issue-term-refresh',
		defaultMessage: 'Create a delivery work item',
		description: 'Description for creating a delivery work item in Jira.',
	},
	cancel: {
		id: 'polaris-ideas.common.send-to-backlog.cancel',
		defaultMessage: 'Cancel',
		description: 'Button label for cancellation.',
	},
	projectLabel: {
		id: 'polaris-ideas.common.send-to-backlog.project-label',
		defaultMessage: 'Search for a project',
		description: 'Label for the project select.',
	},
	issueTypeLabel: {
		id: 'polaris-ideas.common.send-to-backlog.issue-type-label',
		defaultMessage: 'Issue type',
		description: 'Label for the issue type select',
	},
	issueTypeLabelIssueTermRefresh: {
		id: 'polaris-ideas.common.send-to-backlog.issue-type-label-issue-term-refresh',
		defaultMessage: 'Work type',
		description: 'Label for the issue type select',
	},
	successTitle: {
		id: 'polaris-ideas.common.send-to-backlog.success-title',
		defaultMessage: 'Delivery tickets added.',
		description: 'Flag description to communicate a successful linking operation',
	},
	successDescription: {
		id: 'polaris-ideas.common.send-to-backlog.success-description',
		defaultMessage:
			'We created delivery tickets in the selected project and added them to your ideas.',
		description: 'Flag description to communicate a general "sent to backlog" error.',
	},
	errorTitle: {
		id: 'polaris-ideas.common.send-to-backlog.error-title',
		defaultMessage: 'Something went wrong.',
		description: 'Flag title to communicate an error.',
	},
	errorDescription: {
		id: 'polaris-ideas.common.send-to-backlog.error-description',
		defaultMessage:
			'An error occurred while sending your ideas to the backlog. Our team has been notified. Please try again.',
		description: 'Flag description to communicate a general "sent to backlog" error.',
	},
	resultDescription: {
		id: 'polaris-ideas.common.send-to-backlog.result-description',
		defaultMessage:
			'We’ve created these delivery tickets in your backlog and added them to the ideas.',
		description: 'Description for the operation status.',
	},
	issueCreationError: {
		id: 'polaris-ideas.common.send-to-backlog.issue-creation-error',
		defaultMessage: 'We failed to create the delivery tickets given the available data.',
		description: 'Error message displayed when the issue creation failed.',
	},
	issueCreationErrorIssueTermRefresh: {
		id: 'polaris-ideas.common.send-to-backlog.issue-creation-error-issue-term-refresh',
		defaultMessage: 'We failed to create the delivery work items given the available data.',
		description: 'Error message displayed when the work item creation failed.',
	},
	ideaEmbedCheckboxLabel: {
		id: 'polaris-ideas.common.send-to-backlog.idea-embed-checkbox-label',
		defaultMessage: 'Embed idea description and fields into delivery ticket',
		description: 'Label for the checkbox to embed idea into delivery ticket.',
	},
	ideaEmbedCheckboxLabelIssueTermRefresh: {
		id: 'polaris-ideas.common.send-to-backlog.idea-embed-checkbox-label-issue-term-refresh',
		defaultMessage: 'Embed idea description and fields into delivery work item',
		description: 'Label for the checkbox to embed idea into delivery work item.',
	},
});
