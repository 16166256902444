import { di } from 'react-magnetic-di';

import { useAdjustmentsEnabled } from '@atlassian/ui-modifications-core/src/controllers/adjustments-context';

import { useHiddenFields } from '../../issue-adjustments/hooks/use-hidden-fields';

export const useEnabledHiddenFields = () => {
	di(useAdjustmentsEnabled, useHiddenFields);
	const adjustmentsAreEnabled = useAdjustmentsEnabled();
	const hiddenFields = useHiddenFields();

	if (!adjustmentsAreEnabled) {
		return [];
	}
	return hiddenFields;
};
