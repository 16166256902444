import React from 'react';
import { styled } from '@compiled/react';
import { PopupMenuGroup, Section } from '@atlaskit/menu';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { MeatballMenuGroup } from './types.tsx';

type Props = {
	isFirst: boolean;
	dropdownGroupData: MeatballMenuGroup;
	onClick: () => void;
};

const DropdownGroup = ({ dropdownGroupData: { items, name }, isFirst, onClick }: Props) => {
	const showTitle = name && name.length > 0 ? name : undefined;
	const dropdownItemGroup = (
		<PopupMenuGroup testId="issue-meatball-menu.ui.dropdown-group.popup-menu-group" minWidth={0}>
			<StyledSection
				testId="issue-meatball-menu.ui.dropdown-group.styled-section"
				title={showTitle}
			>
				{items.map((item, i) => (
					// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
					<div key={i} onClick={onClick}>
						{item}
					</div>
				))}
			</StyledSection>
		</PopupMenuGroup>
	);

	if (isFirst) {
		return dropdownItemGroup;
	}
	return <TopBorder>{dropdownItemGroup}</TopBorder>;
};

export default DropdownGroup;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledSection = styled(Section)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"[data-ds--menu--heading-item='true']": {
		paddingTop: 0,
		paddingRight: token('space.150'),
		paddingBottom: 0,
		paddingLeft: token('space.150'),
		marginTop: token('space.100'),
		marginRight: 0,
		marginBottom: token('space.100'),
		marginLeft: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TopBorder = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${token('color.border', colors.N30)}`,
});
TopBorder.displayName = 'TopBorder';
