import React, { useCallback, useEffect, useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import LinkExternalIcon from '@atlaskit/icon/core/link-external';
import QuestionCircleIcon from '@atlaskit/icon/core/question-circle';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Popup } from '@atlaskit/popup';
import { Stack, Anchor, Box, Text, xcss, Flex } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useHelpPanelLink } from '@atlassian/jira-help-panel-button/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	useUserPropertiesActions,
	useUserProperty,
} from '@atlassian/jira-polaris-common/src/controllers/user-properties/index.tsx';
import {
	DELIVERY_RESOURCES_POPUP_CLOSED_KEY,
	HANDBOOK_URL,
	COMMUNITY_URL,
	LINK_IDEA_TO_ISSUE_SUPPORT_URL,
	LINK_IDEA_TO_ISSUE_ARTICLE_ID,
	AUTOFILL_DATE_FIELDS_ARTICLE_ID,
	AUTOFILL_DATE_FIELDS_SUPPORT_URL,
	JPD_PLANS_INTEGRATION_ARTICLE_ID,
	JPD_PLANS_INTEGRATION_SUPPORT_URL,
} from './constants.tsx';
import messages from './messages.tsx';

export const DeliveryResourcesPopup = () => {
	const [isOpen, setIsOpen] = useState(false);
	const { formatMessage } = useIntl();
	const onOpenInProductHelpArticle = useHelpPanelLink();

	const [, { loadProperty, saveProperty }] = useUserPropertiesActions();

	useEffect(() => {
		loadProperty(DELIVERY_RESOURCES_POPUP_CLOSED_KEY);
	}, [loadProperty]);

	const [
		{
			value: deliveryResourcesPopupClosed,
			isLoading: isLoadingDeliveryResourcesPopupClosed,
			error: deliveryResourcesPopupClosedError,
		},
	] = useUserProperty(DELIVERY_RESOURCES_POPUP_CLOSED_KEY);

	useEffect(() => {
		if (
			!deliveryResourcesPopupClosed &&
			!isLoadingDeliveryResourcesPopupClosed &&
			!deliveryResourcesPopupClosedError
		) {
			setIsOpen(true);
		}
	}, [
		deliveryResourcesPopupClosed,
		isLoadingDeliveryResourcesPopupClosed,
		deliveryResourcesPopupClosedError,
	]);

	const handleCloseDeliveryResourcesPopup = useCallback(() => {
		if (!deliveryResourcesPopupClosed) {
			saveProperty(DELIVERY_RESOURCES_POPUP_CLOSED_KEY, true);
		}
		setIsOpen(false);
	}, [saveProperty, deliveryResourcesPopupClosed]);

	const onClickResourceLink = useCallback(
		(articleId: string, fallbackUrl: string) => {
			if (onOpenInProductHelpArticle !== undefined && articleId) {
				onOpenInProductHelpArticle(articleId);
			} else {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.open(fallbackUrl);
			}
			handleCloseDeliveryResourcesPopup();
		},
		[onOpenInProductHelpArticle, handleCloseDeliveryResourcesPopup],
	);

	return (
		<Popup
			placement="bottom-end"
			isOpen={isOpen}
			onClose={handleCloseDeliveryResourcesPopup}
			content={() => (
				<Stack xcss={contentStyles}>
					<Stack xcss={resourcesSectionStyles}>
						<Text weight="semibold" color="color.text">
							{formatMessage(messages.helpPopupTitle)}
						</Text>
						<Flex direction="column" gap="space.100">
							<Box
								xcss={resourceSectionStyles}
								onClick={() =>
									onClickResourceLink(LINK_IDEA_TO_ISSUE_ARTICLE_ID, LINK_IDEA_TO_ISSUE_SUPPORT_URL)
								}
							>
								<Flex direction="column" gap="space.050">
									<Text weight="semibold" color="color.text">
										{formatMessage(
											fg('polaris-issue-terminology-refresh')
												? messages.linkIdeaHelpTitleIssueTermRefresh
												: messages.linkIdeaHelpTitle,
										)}
									</Text>
									<Text color="color.text.subtlest">
										{formatMessage(messages.linkIdeaHelpDescription)}
									</Text>
								</Flex>
								<ChevronRightIcon label="" />
							</Box>
							<Box
								xcss={resourceSectionStyles}
								onClick={() =>
									onClickResourceLink(
										AUTOFILL_DATE_FIELDS_ARTICLE_ID,
										AUTOFILL_DATE_FIELDS_SUPPORT_URL,
									)
								}
							>
								<Stack space="space.050">
									<Text weight="semibold" color="color.text">
										{formatMessage(messages.autofillDeliveryDatesHelpTitle)}
									</Text>
									<Text color="color.text.subtlest">
										{formatMessage(
											fg('polaris-issue-terminology-refresh')
												? messages.autofillDeliveryDatesHelpDescriptionIssueTermRefresh
												: messages.autofillDeliveryDatesHelpDescription,
										)}
									</Text>
								</Stack>
								<ChevronRightIcon label="" />
							</Box>
							<Box
								xcss={resourceSectionStyles}
								onClick={() =>
									onClickResourceLink(
										JPD_PLANS_INTEGRATION_ARTICLE_ID,
										JPD_PLANS_INTEGRATION_SUPPORT_URL,
									)
								}
							>
								<Flex direction="column" gap="space.050">
									<Text weight="semibold" color="color.text">
										{formatMessage(messages.plansIntegrationHelpTitle)}
									</Text>
									<Text color="color.text.subtlest">
										{formatMessage(messages.plansIntegrationHelpDescription)}
									</Text>
								</Flex>
								<ChevronRightIcon label="" />
							</Box>
						</Flex>
					</Stack>
					<Box xcss={dividerStyles} />
					<Stack>
						<Anchor xcss={linkStyles} href={HANDBOOK_URL} target="_blank">
							<Text weight="semibold" color="color.text">
								{formatMessage(messages.handbookLink)}
							</Text>
							<LinkExternalIcon label="" />
						</Anchor>
						<Anchor xcss={linkStyles} href={COMMUNITY_URL} target="_blank">
							<Text weight="semibold" color="color.text">
								{formatMessage(messages.communityLink)}
							</Text>
							<LinkExternalIcon label="" />
						</Anchor>
					</Stack>
				</Stack>
			)}
			trigger={({ ref, ...props }) => (
				<IconButton
					{...props}
					ref={ref}
					icon={QuestionCircleIcon}
					label={formatMessage(messages.helpIconLabel)}
					onClick={() => setIsOpen(!isOpen)}
					isSelected={isOpen}
				/>
			)}
		/>
	);
};

const contentStyles = xcss({
	width: '352px',
	gap: 'space.100',
});

const resourcesSectionStyles = xcss({
	padding: 'space.200',
	gap: 'space.200',
});

const dividerStyles = xcss({
	width: 'auto',
	height: '2px',
	backgroundColor: 'color.background.neutral',
	alignSelf: 'stretch',
	flexGrow: 0,
});

const resourceSectionStyles = xcss({
	display: 'flex',
	borderRadius: '3px',
	boxShadow: 'elevation.shadow.raised',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: 'space.150',
	':hover': {
		cursor: 'pointer',
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
});

const linkStyles = xcss({
	textDecoration: 'none',
	color: 'color.text',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingRight: 'space.200',
	paddingLeft: 'space.200',
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
	// @ts-expect-error - Object literal may only specify known properties, and '':visited, :active, :focus, :hover'' does not exist in type 'SafeCSSObject'.
	':visited, :active, :focus, :hover': {
		textDecoration: 'none',
		color: 'color.text',
	},
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
});
