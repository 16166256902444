import React, { useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import { startOfQuarter, endOfQuarter, isBefore, isAfter } from 'date-fns';
import Button, { CustomThemeButton } from '@atlaskit/button';
import ChevronLeftLargeIcon from '@atlaskit/icon/utility/migration/chevron-left--chevron-left-large';
import ChevronRightLargeIcon from '@atlaskit/icon/utility/migration/chevron-right--chevron-right-large';
import { Box, xcss } from '@atlaskit/primitives';
import { N70 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { formatIsoLocalDate } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import type { CalendarProps, CalendarState } from '../../../../common/types/date-picker/index.tsx';
import { getInitialMonthPickerDate } from '../../../../common/utils/date/date-picker.tsx';
import { format } from '../../../../common/utils/date/date.tsx';
import { pickerButtonStyle } from '../../utils.tsx';

const QUARTER_DATA = (() => {
	const today = new Date();
	const quarters = [];
	for (let i = 0; i < 12; i += 3) {
		quarters.push({
			months: [i, i + 1, i + 2],
			name: `${format(new Date(today.getFullYear(), i), 'MMM')}-${format(
				new Date(today.getFullYear(), i + 2),
				'MMM',
			)}`,
		});
	}
	return quarters;
})();

const QuarterPicker = (props: CalendarProps) => {
	const [date, setDate] = useState(getInitialMonthPickerDate(props));

	const { selectedDate, dateType, onSelect } = props;
	const { month, year, initialYear } = date;

	const onDateChange = useCallback(
		(newDate: CalendarState) => {
			setDate(newDate);
			const newDateObject = new Date(newDate.year, newDate.month);
			const newDateInterval = {
				start: formatIsoLocalDate(startOfQuarter(newDateObject)),
				end: formatIsoLocalDate(endOfQuarter(newDateObject)),
			};
			onSelect(newDateInterval);
		},
		[onSelect],
	);

	const isQuarterDisabled = useCallback(
		(isSelected: boolean, endQuarter: number) => {
			if (!selectedDate) {
				return false;
			}
			const currentDate = new Date(year, endQuarter);
			return (
				!isSelected &&
				(dateType === 'start'
					? isBefore(selectedDate, currentDate)
					: isAfter(selectedDate, currentDate))
			);
		},
		[dateType, selectedDate, year],
	);

	return (
		<Box xcss={quarterPickerWrapperStyles}>
			<YearPickerContainer>
				<Button
					appearance="subtle"
					spacing="none"
					onClick={() => setDate({ initialYear, month, year: year - 1 })}
					iconAfter={
						<ChevronLeftLargeIcon
							spacing="spacious"
							label="See previous year"
							color={token('color.icon.subtle', N70)}
						/>
					}
				/>
				<div>
					<b>{year}</b>
				</div>
				<Button
					appearance="subtle"
					spacing="none"
					onClick={() => setDate({ initialYear, month, year: year + 1 })}
					iconAfter={
						<ChevronRightLargeIcon
							spacing="spacious"
							label="See next year"
							color={token('color.icon.subtle', N70)}
						/>
					}
				/>
			</YearPickerContainer>
			<QuarterPickerContainer>
				{QUARTER_DATA.map((item) => {
					const startQuarter = item.months[0];
					const endQuarter = item.months[2];
					const isSelected = date.initialYear === year && item.months.includes(month);
					return (
						<CustomThemeButton
							key={startQuarter}
							appearance="subtle"
							onClick={() => onDateChange({ initialYear, year, month: endQuarter })}
							isSelected={isSelected}
							isDisabled={isQuarterDisabled(isSelected, endQuarter)}
							theme={pickerButtonStyle(isSelected)}
						>
							{item.name}
						</CustomThemeButton>
					);
				})}
			</QuarterPickerContainer>
		</Box>
	);
};

export default QuarterPicker;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const YearPickerContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingLeft: token('space.100'),
	paddingRight: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const QuarterPickerContainer = styled.div({
	marginTop: token('space.200'),
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-around',
	height: '225px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *, > button': {
		flex: '0 0 50%',
		height: '105px',
		margin: '0 0 5px',
		'&:hover, &:disabled': {
			height: '105px',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> button > span': {
		alignSelf: 'center',
	},
});

const quarterPickerWrapperStyles = xcss({
	padding: 'space.200',
});
