import React, { type ReactNode, useMemo } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import {
	DONE,
	IN_PROGRESS,
	TODO,
} from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import { DELIVERY_CALCULATION_MODE } from '@atlassian/jira-polaris-domain-field/src/presentation/constants.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { LinkedIssuesFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/linked-issues/types.tsx';
import {
	useFieldFormula,
	useFieldKeysOfType,
} from '../../controllers/field/selectors/field-hooks.tsx';
import {
	useLinkedDeliveryIssuesExist,
	useLinkedProgress,
	useSingleLinkedStatus,
} from '../../controllers/issue/selectors/properties/hooks.tsx';
import { useStatusCategories } from '../../controllers/workflow/selectors/status-categories-hook.tsx';
import { StatusLozenge } from '../common/status/index.tsx';
import messages from './messages.tsx';

type Props = {
	localIssueId: LocalIssueId;
	hideTooltip?: boolean;
	gap?: number;
};

type MaybeTooltipProps = {
	hideTooltip?: boolean;
	content: ReactNode;
	children: ReactNode;
};

export const MaybeTooltip = ({ hideTooltip, content, children }: MaybeTooltipProps) => {
	if (hideTooltip === true) {
		return <>{children}</>;
	}

	return <Tooltip content={content}>{children}</Tooltip>;
};

export const LinkedStatus = ({ localIssueId, hideTooltip, gap }: Props) => {
	const { formatMessage } = useIntl();
	const statusCategories = useStatusCategories();
	const singleStatus = useSingleLinkedStatus(localIssueId);
	const { total, distribution } = useLinkedProgress(localIssueId);
	const deliveryStatusFieldKeys = useFieldKeysOfType(FIELD_TYPES.DELIVERY_STATUS);
	const fieldKey = useMemo(() => deliveryStatusFieldKeys[0], [deliveryStatusFieldKeys]);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const formula = useFieldFormula(fieldKey) as LinkedIssuesFormula;
	const deliveryIssuesExist = useLinkedDeliveryIssuesExist(localIssueId);

	const isUnestimatedStatus = useMemo(
		() => !total && formula?.parameters?.calculationMode === DELIVERY_CALCULATION_MODE.STORY_POINTS,
		[formula?.parameters?.calculationMode, total],
	);

	const messageKeyForTooltip = useMemo(
		() =>
			formula?.parameters?.calculationMode === DELIVERY_CALCULATION_MODE.STORY_POINTS
				? 'tooltipStoryPoints'
				: 'tooltipIssues',
		[formula?.parameters?.calculationMode],
	);

	if (!deliveryIssuesExist) {
		return null;
	}

	if (isUnestimatedStatus) {
		const content = (
			<>
				<TodoContainer>{0}</TodoContainer>
				<InProgressContainer>{0}</InProgressContainer>
				<DoneContainer>{0}</DoneContainer>
			</>
		);

		return (
			<MaybeTooltip content={formatMessage(messages.unestimatedStatus)} hideTooltip={hideTooltip}>
				<Container data-testid="polaris-common.ui.linked-issue-status.container">
					{content}
				</Container>
			</MaybeTooltip>
		);
	}

	if (statusCategories === undefined || isEmpty(statusCategories) || total === 0) {
		return null;
	}

	const content = singleStatus ? (
		<StatusLozenge
			statusCategoryKey={singleStatus.category}
			statusName={statusCategories[singleStatus.category].name}
		/>
	) : (
		<>
			<MaybeTooltip
				content={formatMessage(messages[messageKeyForTooltip], {
					count: distribution[statusCategories[TODO].key] || 0,
					category: statusCategories[TODO].name,
					total,
				})}
				hideTooltip={hideTooltip}
			>
				<TodoContainer>{distribution[statusCategories[TODO].key] || 0}</TodoContainer>
			</MaybeTooltip>
			<MaybeTooltip
				content={formatMessage(messages[messageKeyForTooltip], {
					count: distribution[statusCategories[IN_PROGRESS].key] || 0,
					category: statusCategories[IN_PROGRESS].name,
					total,
				})}
				hideTooltip={hideTooltip}
			>
				<InProgressContainer>
					{distribution[statusCategories[IN_PROGRESS].key] || 0}
				</InProgressContainer>
			</MaybeTooltip>
			<MaybeTooltip
				content={formatMessage(messages[messageKeyForTooltip], {
					count: distribution[statusCategories[DONE].key] || 0,
					category: statusCategories[DONE].name,
					total,
				})}
				hideTooltip={hideTooltip}
			>
				<DoneContainer>{distribution[statusCategories[DONE].key] || 0}</DoneContainer>
			</MaybeTooltip>
		</>
	);

	return (
		<Container
			data-testid="polaris-common.ui.linked-issue-status.container"
			gap={singleStatus ? 0 : gap}
		>
			{content}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ gap?: number }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'div:not(:first-child)': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		marginLeft: ({ gap }) => (gap ? `${gap}px` : undefined),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BadgeContainer = styled.div({
	borderRadius: '10px',
	paddingTop: '0px',
	paddingRight: token('space.075'),
	paddingBottom: '0px',
	paddingLeft: token('space.075'),
	marginLeft: token('space.025'),
	height: '20px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '20px',
	minWidth: '20px',
	boxSizing: 'border-box',
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TodoContainer = styled(BadgeContainer)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.border', colors.N50),

	color: token('color.text'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InProgressContainer = styled(BadgeContainer)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.border.information', colors.B400),

	color: token('color.text.inverse'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DoneContainer = styled(BadgeContainer)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.border.success', colors.G300),

	color: token('color.text.inverse'),
});
