import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { Edge } from '@atlassian/jira-polaris-lib-list/src/types.tsx';
import { messages } from './messages.tsx';

type DropHintProps = {
	hiddenNumber: number;
	dropEdge: Edge;
};

export const DropHint = memo<DropHintProps>(({ hiddenNumber, dropEdge }: DropHintProps) => {
	const { formatMessage } = useIntl();
	return (
		<>
			{hiddenNumber > 0 && dropEdge === 'top' && (
				<DropBeforeText>
					{formatMessage(messages.dropBeforeHint, { count: hiddenNumber })}
				</DropBeforeText>
			)}
			{hiddenNumber > 0 && dropEdge === 'bottom' && (
				<DropAfterText>
					{formatMessage(messages.dropAfterHint, { count: hiddenNumber })}
				</DropAfterText>
			)}
		</>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropBeforeText = styled.div({
	position: 'absolute',
	left: 0,
	top: 0,
	bottom: 'auto',
	borderRadius: '0 0 8px 8px',
	paddingTop: token('space.025'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingRight: '10px',
	paddingBottom: token('space.025'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingLeft: '10px',
	zIndex: 200,
	// Typography
	color: 'white',
	font: token('font.body.small'),
	backgroundColor: token('color.border.brand', B400),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropAfterText = styled(DropBeforeText)({
	top: 'auto',
	bottom: 0,
	borderRadius: '8px 8px 0 0',
});
