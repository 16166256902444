import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	COMMENT_VISIBILITY_TYPE_PUBLIC,
	COMMENT_VISIBILITY_TYPE_GROUP,
	COMMENT_VISIBILITY_TYPE_ROLE,
	type RawComment,
	type RawUser,
	type CommentVisibility,
} from '@atlassian/jira-issue-shared-types/src/common/types/comment-transformer-types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	JIRA_PROJECT_TYPE_SOFTWARE_PROJECT,
	JIRA_PROJECT_TYPE_CORE_PROJECT,
	type ProjectType,
	SOFTWARE_PROJECT,
	CORE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { JiraProjectType } from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql.js';
import type {
	AggResponseData,
	AGGPermissionLevel,
	AGGCommentUserNode,
	AGGCommentsPageInfo,
	AGGComments,
	AGGThreadedComments,
	AGGThreadedCommentNode,
} from '../types/issue-type.tsx';

export const COMMENT_VISIBILITY_PUBLIC: CommentVisibility = {
	type: COMMENT_VISIBILITY_TYPE_PUBLIC,
	value: '',
};

const logError = (msg: string) =>
	log.safeErrorWithoutCustomerData('issue.agg-transformers.comments', msg);

export const transformAggCommentsToLegacyGira = (data: AggResponseData): RawComment[] => {
	const edges = data.jira.issueByKey?.comments?.edges;
	if (!edges) {
		return [];
	}
	const comments = edges.reduce((acc: RawComment[], edge) => {
		const { node } = edge ?? {};
		if (!node) {
			logError('Comment was null or undefined');
			return acc;
		}

		const comment: RawComment = {
			id: node.commentId,
			author: transformAggUser(node.author),
			updateAuthor: transformAggUser(node.updateAuthor),
			visibility: transformAggPermissionToCommentVisibility(node.permissionLevel),
			createdDate: node.created,
			updatedDate: node.updated || '',
			bodyAdf: node.richText?.adfValue?.json,
			bodyHtml: '',
			isInternal: !!(node.visibility && node.visibility !== 'VISIBLE_TO_HELPSEEKER'),
			edited: !!node.updated && node.updated !== node.created,
		};
		if (node.authorCanSeeRequest !== undefined && node.authorCanSeeRequest !== null) {
			comment.jsdAuthorCanSeeRequest = node.authorCanSeeRequest;
		}
		if (fg('jsm-timelines-phase-2')) {
			comment.eventOccurredAt = node.eventOccurredAt ?? null;
			comment.jsdIncidentActivityViewHidden = node.jsdIncidentActivityViewHidden ?? null;
		}

		acc.push(comment);
		return acc;
	}, []);

	return comments;
};

export const defaultPageInfo: AGGCommentsPageInfo = {
	startCursor: null,
	endCursor: null,
	hasNextPage: false,
	hasPreviousPage: false,
};

// remove this totally once threaded_comments_ga is shipped
const hasAnyChildComment = (threadedComments: AGGThreadedComments): boolean => {
	if (!threadedComments?.edges || threadedComments.edges.length === 0) {
		return false;
	}

	return threadedComments.edges.some((edge) => {
		const { node } = edge ?? {};
		if (!node) {
			return false;
		}

		const childComments = node.targetChildComments?.edges?.length
			? node.targetChildComments
			: node.childComments;

		const { edges: childEdges } = childComments ?? {};
		if (!childEdges || childEdges.length === 0) {
			return false;
		}

		return childEdges.length > 0;
	});
};

// remove this totally once threaded_comments_ga is shipped
export const computeAggCommentsToUse = (
	comments: AGGComments,
	threadedComments: AGGThreadedComments,
	projectType: JiraProjectType | ProjectType | null | undefined,
): { isThreadedCommentsEnabled: boolean; comments: AGGComments | AGGThreadedComments } => {
	const isBusinessOrSoftwareProject = !!(
		projectType === JIRA_PROJECT_TYPE_CORE_PROJECT ||
		projectType === JIRA_PROJECT_TYPE_SOFTWARE_PROJECT ||
		projectType === CORE_PROJECT ||
		projectType === SOFTWARE_PROJECT
	);

	const isThreadedCommentsGAEnabled =
		isBusinessOrSoftwareProject &&
		!!threadedComments?.edges?.length &&
		expVal('threaded_comments_ga', 'isEnabled', false);

	// as threadedComments response will become default prod with threaded_comments_ga shipped,
	// use the same response for all other scenarios as well except for issues
	// which had participated in threaded comments experiment and are not part of test variant for GA experiment
	const stillUseThreadedCommentsResponse =
		!isThreadedCommentsGAEnabled &&
		!hasAnyChildComment(threadedComments) &&
		fg('threaded_comments_use_only');

	const isThreadedCommentsEnabled = isThreadedCommentsGAEnabled || stillUseThreadedCommentsResponse;

	if (isThreadedCommentsEnabled) {
		return { isThreadedCommentsEnabled, comments: threadedComments };
	}

	return { isThreadedCommentsEnabled, comments };
};

export const transformAggCommentsToLegacyGiraV2 = (
	data: AggResponseData,
): { comments?: RawComment[]; pageInfo?: AGGCommentsPageInfo } => {
	const { comments, isThreadedCommentsEnabled } = computeAggCommentsToUse(
		data.jira.issueByKey?.comments,
		data.jira.issueByKey?.threadedComments,
		data.jira.issueByKey?.projectField?.project?.projectType,
	);

	const edges = comments?.edges;
	if (!edges) {
		return { comments: [], pageInfo: defaultPageInfo };
	}

	return transformAggCommentsToLegacyGiraV2Helper(comments, isThreadedCommentsEnabled);
};

export const transformAggCommentsToLegacyGiraV2Helper = (
	data: AGGComments | AGGThreadedComments,
	isThreadedCommentsEnabled: boolean,
): { comments?: RawComment[]; pageInfo?: AGGCommentsPageInfo } => {
	const edges = data?.edges;
	if (!edges) {
		return { comments: [], pageInfo: defaultPageInfo };
	}

	const comments = edges.reduce((acc: RawComment[], edge) => {
		const { node, cursor } = edge ?? {};
		if (!node) {
			logError('Comment was null or undefined');
			return acc;
		}

		// remove this eslint disable when threaded_comments_ga is shipped
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const threadedCommentNode = node as AGGThreadedCommentNode;

		const comment: RawComment = {
			id: node.commentId,
			author: transformAggUser(node.author),
			updateAuthor: transformAggUser(node.updateAuthor),
			visibility: transformAggPermissionToCommentVisibility(node.permissionLevel),
			createdDate: node.created,
			updatedDate: node.updated || '',
			bodyAdf: node.richText?.adfValue?.json,
			bodyHtml: '',
			isInternal: !!(node.visibility && node.visibility !== 'VISIBLE_TO_HELPSEEKER'),
			edited: !!node.updated && node.updated !== node.created,
			cursor,
		};

		const aggChildComments = threadedCommentNode?.targetChildComments?.edges?.length
			? threadedCommentNode?.targetChildComments
			: threadedCommentNode?.childComments;
		if (isThreadedCommentsEnabled && aggChildComments) {
			comment.isDeleted = threadedCommentNode?.isDeleted ?? false;

			const { comments: childComments, pageInfo: childCommentsPageInfo } =
				transformAggCommentsToLegacyGiraV2Helper(aggChildComments, isThreadedCommentsEnabled);
			comment.childComments = childComments;
			comment.childCommentsPageInfo = childCommentsPageInfo;
		}
		if (node.authorCanSeeRequest !== undefined && node.authorCanSeeRequest !== null) {
			comment.jsdAuthorCanSeeRequest = node.authorCanSeeRequest;
		}
		if (fg('jsm-timelines-phase-2')) {
			comment.eventOccurredAt = node.eventOccurredAt ?? null;
			comment.jsdIncidentActivityViewHidden = node.jsdIncidentActivityViewHidden ?? null;
		}

		acc.push(comment);
		return acc;
	}, []);

	return { comments, pageInfo: data?.pageInfo };
};

/**
 * Transforms JiraCommentVisibilities from the Gira Endpoint
 */
export const transformAggPermissionToCommentVisibility = (
	permissionLevel: AGGPermissionLevel,
): CommentVisibility => {
	if (!permissionLevel) {
		return COMMENT_VISIBILITY_PUBLIC;
	}
	if (permissionLevel.role) {
		return {
			type: COMMENT_VISIBILITY_TYPE_ROLE,
			value: permissionLevel.role.name || '',
		};
	}
	if (permissionLevel.group) {
		return {
			type: COMMENT_VISIBILITY_TYPE_GROUP,
			groupId: permissionLevel.group.groupId,
			value: permissionLevel.group.name,
		};
	}
	return COMMENT_VISIBILITY_PUBLIC;
};

/**
 * Transforms user data from the AGG endpoint.
 */
export const transformAggUser = (user: AGGCommentUserNode | null | undefined): RawUser | null => {
	if (!user) return null;
	const { accountId, name, picture } = user;

	return {
		avatarUrl: picture,
		displayName: name,
		id: accountId,
	};
};
