import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { issueRedactionTableEntrypoint } from '@atlassian/jira-issue-redaction-table/entrypoint.tsx';
import type { Props } from './types.tsx';

export const Redactions = ({ onClick, label, itemKey }: Props) => {
	const handleClick = useCallback(() => {
		onClick?.(itemKey);
	}, [onClick, itemKey]);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={issueRedactionTableEntrypoint}
			modalProps={modalProps}
			useInternalModal
		>
			{({ ref }) => (
				<DropdownItem ref={ref} key="clone" onClick={handleClick}>
					{label}
				</DropdownItem>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

const modalProps = {
	width: 'large',
};
