import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ArrowLeftCircleIcon from '@atlaskit/icon/core/migration/arrow-left--arrow-left-circle';
import { N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useMatrixActions } from '../../../controllers/index.tsx';
import { messages } from './messages.tsx';

type SidebarSelectedHeaderProps = {
	selectedAmount: number;
};

export const SidebarSelectedHeader = (props: SidebarSelectedHeaderProps) => {
	const { formatMessage } = useIntl();
	const [, { setSelectedItems }] = useMatrixActions();

	const onClearSelected = useCallback(() => {
		setSelectedItems([]);
	}, [setSelectedItems]);

	return (
		<SidebarHeaderContainer>
			<Button
				onClick={onClearSelected}
				iconBefore={<ArrowLeftCircleIcon label="back icon" />}
				appearance="subtle"
			>
				{formatMessage(messages.clearSelectedButton)}
			</Button>
			<SelectedAmount>
				{formatMessage(messages.selectedAmount, { amount: props.selectedAmount })}
			</SelectedAmount>
		</SidebarHeaderContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SidebarHeaderContainer = styled.div({
	display: 'flex',
	width: '100%',
	marginBottom: token('space.200'),
	justifyContent: 'space-between',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectedAmount = styled.span({
	color: token('color.text.subtlest', N200),
});
