import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { fg } from '@atlassian/jira-feature-gating';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { IssueViewSection } from '../../../../common/types/issue/index.tsx';
import { getPolarisQuerySelector } from '../../selectors/index.tsx';
import {
	DEFAULT_ISSUE_VIEW_SECTION_SIDEBAR,
	DEFAULT_ISSUE_VIEW_SECTION_FULLSCREEN,
	type State,
	type POLARIS_QUERY_ISSUE_VIEW_SIDEBAR,
	type Props,
} from '../../types.tsx';
import { setQuery } from '../set-query/index.tsx';

type OpenOptions = Partial<{
	layout?: typeof POLARIS_QUERY_ISSUE_VIEW_SIDEBAR;
	section?: IssueViewSection;
}>;

const getNextSection = (
	{ layout, section: ideaViewSection }: OpenOptions,
	currSidebarSection?: IssueViewSection,
) => {
	if (layout === 'sidebar') {
		return ideaViewSection ?? currSidebarSection ?? DEFAULT_ISSUE_VIEW_SECTION_SIDEBAR;
	}

	return ideaViewSection ?? DEFAULT_ISSUE_VIEW_SECTION_FULLSCREEN;
};

export const openIssueView =
	(issueKey: IssueKey, { layout, section: ideaViewSection }: OpenOptions = {}) =>
	({ dispatch, getState }: StoreActionApi<State>, { section }: Props) => {
		const query = getPolarisQuerySelector(getState());

		// replace state instead of push if only change tab
		const updateQueryMethod =
			issueKey === query?.selectedIssue &&
			layout === query?.issueViewLayout &&
			section !== query?.issueViewSection
				? 'replace'
				: 'push';

		if (updateQueryMethod === 'push') {
			experience.ideaView.pageSegmentLoad.abort();
			experience.ideaView.pageSegmentLoad.start();
		} else {
			// Track idea tab change
			fg('jpd-trace-ufo-transition') &&
				traceUFOPress(`jpd.idea-view.tab-${ideaViewSection ?? 'overview'}`);
		}

		dispatch(
			setQuery(
				{
					selectedIssue: issueKey,
					issueViewLayout: layout,
					issueViewSection: getNextSection(
						{ layout, section: ideaViewSection },
						query.issueViewSection,
					),
					viewCommentId: undefined,
				},
				updateQueryMethod,
			),
		);
	};
