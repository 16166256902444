import React, { useMemo, useCallback, type ReactNode, type MouseEvent } from 'react';
import noop from 'lodash/noop';
import { Box } from '@atlaskit/primitives';
import type { ConfigureSource } from '@atlassian/jira-issue-status-and-types-common/src/types.tsx';
import type { UFOExperience } from '@atlassian/ufo';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { defaultModalProps } from '@atlassian/jira-issue-status-and-types-common/src/index.tsx';
import { asyncEditIssueTypeModalEntrypoint } from '@atlassian/jira-edit-issue-type-modal/entrypoint.tsx';

export type EditIssueTypeModalEntrypointTriggerProps = {
	children: ReactNode;
	queryParams: {
		issueTypeId: string;
		projectId: number;
		projectKey: string;
		ufoExperience: UFOExperience;
		calledFrom: ConfigureSource;
		onRefetchIssueTypesWhenIssueTypeUpdated: () => void;
		onEditModalClose: () => void;
	};
	onClick?: (event: MouseEvent<HTMLElement>) => void;
};

export const EditIssueTypeModalEntrypointTrigger = ({
	children,
	queryParams,
	onClick = noop,
}: EditIssueTypeModalEntrypointTriggerProps) => {
	const { projectKey, onEditModalClose, ...props } = queryParams;

	const cloudId = useCloudId();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const entryPointParams = useMemo(
		() => ({
			projectKey,
			cloudId,
		}),
		[cloudId, projectKey],
	);

	const onCloseEditIssueTypeModal = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'inlineEditIssueTypeModal',
				action: 'closed',
			}),
			{
				calledFrom: props.calledFrom,
			},
		);
		onEditModalClose();
	}, [createAnalyticsEvent, onEditModalClose, props.calledFrom]);

	const entryPointProps = useMemo(
		() => ({
			...props,
			onClose: onCloseEditIssueTypeModal,
		}),
		[props, onCloseEditIssueTypeModal],
	);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={asyncEditIssueTypeModalEntrypoint}
			entryPointParams={entryPointParams}
			entryPointProps={entryPointProps}
			interactionName="edit-issue-type-modal"
			useInternalModal
			modalProps={defaultModalProps}
		>
			{({ ref }) => (
				<Box ref={ref} onClick={onClick}>
					{children}
				</Box>
			)}
		</ModalEntryPointPressableTrigger>
	);
};
