import forEach from 'lodash/forEach';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	getLocalIssueIdForJiraIssueId,
	getLocalIssueIdToJiraId,
	getLocalIssueIdsByJiraIssueId,
} from '../../../selectors/issue-ids.tsx';
import {
	createGetIssueType,
	getConnectionProperties,
} from '../../../selectors/properties/index.tsx';
import type { PropertyMaps, Props, State } from '../../../types.tsx';
import type { FieldMapping } from '../../../utils/field-mapping/types.tsx';
import { createGetFieldMapping } from '../../../selectors/fields.tsx';
import { isMatchingConnectionFieldFilter } from '../../../utils/connection-field-filters.tsx';

export const updateConnectionsProperties = ({
	issuesToConnect = [],
	issuesToDisconnect = [],
	localIssueId,
	state,
	props,
}: {
	localIssueId: LocalIssueId;
	issuesToConnect?: ConnectionFieldValue[];
	issuesToDisconnect?: ConnectionFieldValue[];
	state: State;
	props: Props;
}): PropertyMaps => {
	const localIssueIdToJiraId = getLocalIssueIdToJiraId(state, props);
	const localIssueIdsByJiraIssueId = getLocalIssueIdsByJiraIssueId(state, props);
	const connectionProperties = getConnectionProperties(state);
	let newProperties = { ...state.properties }; // don't need to create copy

	const update = (
		issueFromLocalId: LocalIssueId,
		issueToJiraId: string, // Jira issue id
		fieldKey: FieldKey,
		action: 'link' | 'unlink',
	) => {
		const mapping: FieldMapping<ConnectionFieldValue[]> | undefined = createGetFieldMapping(
			fieldKey,
		)(state, props);

		if (!mapping) return;

		const issueType = createGetIssueType(localIssueIdsByJiraIssueId[issueToJiraId])(state, props);
		const connectionFieldFilters = mapping.field?.configuration?.issueTypeFilters || [];

		if (
			!isMatchingConnectionFieldFilter({
				filters: connectionFieldFilters,
				issueType: issueType?.id,
				issueTypeNameFilter: props.getIssueTypeNameFilter,
			})
		) {
			return;
		}

		newProperties = mapping.modifyImmutableIfMultiValueField(
			newProperties,
			issueFromLocalId,
			action === 'link' ? [{ id: issueToJiraId }] : [],
			action === 'unlink' ? [{ id: issueToJiraId }] : [],
		);
	};

	const iterate = (id: string, key: string, action: 'link' | 'unlink') => {
		const localIssueId2 = getLocalIssueIdForJiraIssueId(id)(state, props);

		update(localIssueId, id, key, action);

		if (localIssueId2) {
			update(localIssueId2, localIssueIdToJiraId[localIssueId], key, action);
		}
	};

	forEach(connectionProperties, (_, key) => {
		issuesToConnect.forEach(({ id }) => iterate(id, key, 'link'));
		issuesToDisconnect.forEach(({ id }) => iterate(id, key, 'unlink'));
	});

	return newProperties;
};
