import React from 'react';
import { ConnectionField as ConnectionFieldComponent } from '@atlassian/jira-polaris-common/src/ui/fields/connection/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useCanConnectIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useIsIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useListPortalElement } from '../../../portal/index.tsx';
import { CellPaddingAdjust } from '../utils/cell-padding-adjust.tsx';
import { useFieldUpdate } from '../../utils/index.tsx';

type Props = {
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
	interactive: boolean;
};

export const ConnectionField = ({ fieldKey, localIssueId, interactive }: Props) => {
	const portalElement = useListPortalElement();
	const canConnectIssues = useCanConnectIssues();
	const isIssueArchived = useIsIssueArchived(localIssueId);
	const onUpdate = useFieldUpdate(localIssueId, fieldKey);

	return (
		<CellPaddingAdjust right={4}>
			<ConnectionFieldComponent
				fieldKey={fieldKey}
				localIssueId={localIssueId}
				menuPortalTarget={portalElement}
				isActive={interactive}
				isEditable={canConnectIssues && !isIssueArchived}
				onUpdate={onUpdate}
			/>
		</CellPaddingAdjust>
	);
};
