/** @jsx jsx */
import React, { useState, useEffect, type SyntheticEvent, useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import noop from 'lodash/noop';
import Button, { IconButton } from '@atlaskit/button/new';
import MoreVerticalIcon from '@atlaskit/icon/core/migration/show-more-vertical--more-vertical';
import { DragHandleButtonSmall } from '@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button-small';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { DragHandleThin } from '@atlassian/jira-polaris-lib-custom-icons/src/ui/icons/drag-handle-thin/index.tsx';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';
import type { MenuOptionProps } from './types.tsx';

export const MenuOption = ({
	children,
	menuComponent = null,
	isActive = false,
	isDragEnabled,
	hideActionMenu,
	outerSpacing,
	setOpenOption,
	setIsMenuOpen,
}: MenuOptionProps) => {
	const { formatMessage } = useIntl();
	const [open, setOpen] = useState(false);

	const onToggleOpen = useCallback(() => {
		hideActionMenu !== true && setOpen((prev) => !prev);
	}, [hideActionMenu]);

	useEffect(() => {
		setIsMenuOpen?.(open);
	}, [open, setIsMenuOpen]);

	return (
		<Popup
			zIndex={1000}
			autoFocus={false}
			placement="bottom-end"
			isOpen={open}
			onClose={() => setOpen(false)}
			messageId="polaris-lib-decoration.ui.menu-option.popup"
			messageType="transactional"
			content={() => (
				<div
					onClick={() => setOpen(false)}
					onKeyPress={() => setOpen(false)}
					role="menu"
					tabIndex={0}
				>
					{menuComponent}
				</div>
			)}
			trigger={(triggerProps) => (
				<div {...triggerProps}>
					<div
						data-component-selector="option-item-wrapper-kC88"
						data-testid={`polaris-lib-decoration.ui.menu-option.option-item-wrapper-${isActive ? 'active' : 'inactive'}`}
						css={[
							optionItemWrapperStyles,
							isActive && optionItemWrapperActiveStyles,
							isDragEnabled && optionItemWrapperDragEnabledStyles,
						]}
					>
						<div
							onClick={({ target, currentTarget }: SyntheticEvent<HTMLElement>) => {
								let isInteractiveEl = false;
								let node: unknown = target;

								while (!isInteractiveEl && node instanceof HTMLElement && node !== currentTarget) {
									isInteractiveEl = !!node.getAttribute('data-interactive');
									node = node.parentNode;
								}

								// Do not open menu if element is interactive, e.g. input
								if (isInteractiveEl) {
									return setOpen(false);
								}
								return isActive ? setOpenOption(null) : onToggleOpen();
							}}
							onKeyDown={noop}
							role="menuitem"
							tabIndex={0}
							css={[
								optionItemStyles,
								open && hideActionMenu !== true && optionItemSelectedStyles,
								!(open && hideActionMenu !== true) && optionItemNotSelectedStyles,
								isDragEnabled && optionItemIsDraggableStyles,
							]}
							// eslint-disable-next-line jira/react/no-style-attribute
							style={{
								paddingLeft: isDragEnabled ? '0' : outerSpacing,
								paddingRight: outerSpacing,
							}}
						>
							{isDragEnabled && (
								// eslint-disable-next-line jira/react/no-style-attribute
								<div css={dragHandleWrapperStyles} style={{ width: outerSpacing }}>
									{isVisualRefreshEnabled() && fg('pol-11373_replace_drag_icons_in_panels') ? (
										<DragHandleThin
											label={formatMessage(messages.dragHandleLabel)}
											color={token('color.icon.subtle')}
										/>
									) : (
										<DragHandleButtonSmall
											appearance="subtle"
											label={formatMessage(messages.dragHandleLabel)}
										/>
									)}
								</div>
							)}
							<Flex xcss={itemWrapperStyles}>{children}</Flex>
							{isActive && (
								<Button
									testId="polaris-lib-decoration.ui.menu-option.done-button"
									spacing="compact"
									appearance="primary"
								>
									{formatMessage(messages.saveOption)}
								</Button>
							)}
							{hideActionMenu !== true && !isActive && (
								<Box xcss={[!open && hiddenStyles]}>
									<IconButton
										testId="polaris-lib-decoration.ui.menu-option.more-button"
										data-component-selector="more-button-9Kk3"
										isSelected={open}
										spacing="compact"
										label="btn.more"
										icon={(iconProps) => <MoreVerticalIcon LEGACY_size="small" {...iconProps} />}
									/>
								</Box>
							)}
						</div>
					</div>
				</div>
			)}
		/>
	);
};

const hiddenStyles = xcss({
	visibility: 'hidden',
});

const optionItemWrapperStyles = css({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover [data-component-selector="more-button-9Kk3"]': {
		visibility: 'visible',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:active [data-component-selector="more-button-9Kk3"]': {
		visibility: 'visible',
	},
});

const optionItemWrapperActiveStyles = css({
	paddingTop: token('space.150'),
	backgroundColor: token('color.background.disabled'),
});

const optionItemWrapperDragEnabledStyles = css({
	'&:hover': {
		backgroundColor: token('color.background.disabled'),
	},
	'&:active': {
		backgroundColor: token('color.background.disabled'),
	},
});

const optionItemStyles = css({
	position: 'relative',
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	color: token('color.text.subtle'),
	overflow: 'hidden',
	padding: '0',
	backgroundColor: 'transparent',
	'&:hover': {
		color: token('color.text'),
		cursor: 'pointer',
		textDecoration: 'none',
	},
	'&:focus': {
		outline: 'none',
		boxShadow: 'none',
	},
	'&:active': {
		borderRadius: token('border.radius'),
		color: token('color.text.subtle'),
	},
});

const optionItemSelectedStyles = css({
	backgroundColor: token('color.background.disabled'),
});

const optionItemNotSelectedStyles = css({
	backgroundColor: 'transparent',
});

const optionItemIsDraggableStyles = css({
	'&:active': {
		backgroundColor: token('color.background.disabled'),
	},
});

const itemWrapperStyles = xcss({
	flex: '1',
	alignItems: 'center',
	minWidth: '0',
	overflow: 'hidden',
});

const dragHandleWrapperStyles = css({
	display: 'flex',
	justifyContent: 'center',
});
