import { useEffect } from 'react';

import { useChoreographerContext } from '../create-placement-component/providers/stable-choreographer';

export const useDisableChoreographer = () => {
	const { setChoreographerEnabled } = useChoreographerContext();

	useEffect(() => {
		setChoreographerEnabled(false);
	}, []);
};
