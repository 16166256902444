import { AnyAri } from '@atlassian/ari/any-ari';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { isFakeId } from '@atlassian/jira-polaris-component-navigation-store/src/controllers/views/actions/utils.tsx';
import type { NavigationView } from '@atlassian/jira-polaris-component-navigation-store/src/controllers/views/types.tsx';
import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import type { View, ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { type State, type Props, ViewSectionTypeViews } from '../../types.tsx';
import { loadView } from '../load/index.tsx';
import { setViewSets } from '../set/index.tsx';
import {
	createNewViewEntity,
	internRemoteView,
	doesViewMatchSlug,
	getFirstAvailableViewIdInViewSet,
} from '../utils/views/index.tsx';

const createNewViewSet = (cloudId: string, id: string, name: string) => {
	const viewSetAri = AnyAri.create({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'viewset',
		resourceId: String(id),
	}).toString();
	const newViewSet: ViewSet = {
		id: viewSetAri,
		name,
		type: 'SECTION',
		rank: 0,
		collapsed: false,
		views: [],
	};
	return newViewSet;
};

const createNewView = ({
	cloudId,
	viewSetId,
	id,
	name,
	kind,
	emoji,
	viewLegacyId,
}: {
	cloudId: string;
	viewSetId: string;
	id: string;
	name: string;
	kind: ViewKind;
	emoji: string | undefined;
	viewLegacyId?: number;
}) => {
	const viewAri = AnyAri.create({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'view',
		resourceId: String(id),
	}).toString();

	const newView: View = createNewViewEntity(viewSetId, kind, name, 0, false);
	newView.viewId = viewAri;
	newView.emoji = emoji;

	if (getWillShowNav4() && fg('jpd-sidebar-v4-for-roadmaps')) {
		newView.viewLegacyId = viewLegacyId || parseInt(id, 10);
	} else {
		newView.viewLegacyId = parseInt(id, 10);
	}

	newView.modified = false;
	return newView;
};

const updateExistingView = (view: View, name: string, emoji: string | undefined) => {
	if (view.title === name && (view.emoji || '') === (emoji || '')) {
		return view;
	}
	const updatedView: View = {
		...view,
		title: name,
		emoji,
	};
	return updatedView;
};

export const syncViewsWithNavigation =
	(navigationViews: NavigationView[]): Action<State, Props> =>
	(
		{ getState, dispatch },
		{ cloudId, router, fields, currentViewSlug, isSharedView, isCollectionView },
	) => {
		if (
			getState().meta.loading ||
			!getState().meta.loadingProps?.containerAri ||
			!getWillShowNav4()
		) {
			return;
		}

		let hasChanges = false;
		const viewSets = getState().viewSets;
		const changedViews: Record<string, View> = {};
		let manuallyCreatedView: View | undefined;
		const deletedViews: Record<string, View> = {};
		const updatedViewSets = viewSets.map((viewSet) => {
			if (viewSet.type !== 'PRIORITIZE') {
				return viewSet;
			}

			const updatedViewSet: ViewSet = {
				...viewSet,
				viewSets: viewSet.viewSets || [],
				views: viewSet.views || [],
			};

			const viewsMap: Record<string, View> = updatedViewSet.views?.reduce((acc, view) => {
				const { viewId } = view;
				if (!viewId) {
					return acc;
				}
				deletedViews[viewId] = view;
				return Object.assign(acc, { [viewId]: view });
			}, {});

			const viewSetsMap: Record<string, ViewSet> =
				viewSet.viewSets?.reduce((acc, innerViewSet) => {
					const { id } = innerViewSet;
					if (!id) {
						return acc;
					}
					innerViewSet.views?.forEach((view) => {
						const { viewId } = view;
						if (!viewId) {
							return;
						}
						deletedViews[viewId] = view;
						viewsMap[viewId] = view;
					});
					return Object.assign(acc, { [id]: innerViewSet });
				}, {}) || {};

			navigationViews.forEach((navView) => {
				if (navView.viewType === 'SECTION') {
					if (isFakeId(navView.id)) {
						return;
					}
					const newViewSet = createNewViewSet(cloudId, String(navView.polarisId), navView.name);
					if (!viewSetsMap[newViewSet.id]) {
						updatedViewSet.viewSets = [...(updatedViewSet.viewSets || []), newViewSet];
						viewSetsMap[newViewSet.id] = newViewSet;
						hasChanges = true;
					}
					navView.views?.forEach((innerNavView: NavigationView) => {
						if (!innerNavView.viewType) {
							return;
						}
						if (isFakeId(innerNavView.id)) {
							return;
						}
						const newView = createNewView({
							cloudId,
							viewSetId: newViewSet.id,
							id:
								isCollectionView && getWillShowNav4() && fg('jpd-sidebar-v4-for-roadmaps')
									? innerNavView.id
									: String(innerNavView.polarisId),
							name: innerNavView.name,
							kind: innerNavView.viewType,
							emoji: innerNavView.emoji,
							viewLegacyId:
								isCollectionView && getWillShowNav4() && fg('jpd-sidebar-v4-for-roadmaps')
									? innerNavView.polarisId
									: undefined,
						});
						if (!newView.viewId) {
							return;
						}
						if (viewsMap[newView.viewId]) {
							const updatedView = updateExistingView(
								viewsMap[newView.viewId],
								innerNavView.name,
								innerNavView.emoji,
							);
							if (updatedView !== viewsMap[newView.viewId]) {
								changedViews[newView.viewId] = updatedView;
								hasChanges = true;
							}
							delete deletedViews[newView.viewId];
						} else {
							viewSetsMap[newViewSet.id].views = [
								...(viewSetsMap[newViewSet.id].views || []),
								newView,
							];
							hasChanges = true;
							if (innerNavView.isManuallyCreated) {
								manuallyCreatedView = newView;
							}
						}
					});
					return;
				}
				if (!navView.viewType) {
					return;
				}
				if (isFakeId(navView.id)) {
					return;
				}
				const newView = createNewView({
					cloudId,
					viewSetId: viewSet.id,
					id:
						isCollectionView && getWillShowNav4() && fg('jpd-sidebar-v4-for-roadmaps')
							? navView.id
							: String(navView.polarisId),
					name: navView.name,
					kind: navView.viewType,
					emoji: navView.emoji,
					viewLegacyId:
						isCollectionView && getWillShowNav4() && fg('jpd-sidebar-v4-for-roadmaps')
							? navView.polarisId
							: undefined,
				});
				if (!newView.viewId) {
					return;
				}
				if (viewsMap[newView.viewId]) {
					const updatedView = updateExistingView(
						viewsMap[newView.viewId],
						navView.name,
						navView.emoji,
					);
					if (updatedView !== viewsMap[newView.viewId]) {
						changedViews[newView.viewId] = updatedView;
						hasChanges = true;
					}
					delete deletedViews[newView.viewId];
				} else {
					updatedViewSet.views = [...(viewSet.views || []), newView];
					hasChanges = true;
					if (navView.isManuallyCreated) {
						manuallyCreatedView = newView;
					}
				}
			});

			return {
				...updatedViewSet,
				views: updatedViewSet.views
					?.map((view) => {
						const { viewId } = view;
						if (!viewId) {
							return view;
						}
						if (deletedViews[viewId]) {
							return undefined;
						}
						if (changedViews[viewId]) {
							return { ...changedViews[viewId] };
						}
						return view;
					})
					.filter(Boolean),
				viewSets: updatedViewSet.viewSets?.map((innerViewSet) => ({
					...innerViewSet,
					views: innerViewSet.views
						?.map((view) => {
							const { viewId } = view;
							if (!viewId) {
								return view;
							}
							if (deletedViews[viewId]) {
								return undefined;
							}
							if (changedViews[viewId]) {
								return { ...changedViews[viewId] };
							}
							return view;
						})
						.filter(Boolean),
				})),
			};
		});

		if (hasChanges || Object.keys(deletedViews).length) {
			dispatch(setViewSets(updatedViewSets));

			const viewIdToLoad =
				isCollectionView &&
				manuallyCreatedView?.viewId &&
				getWillShowNav4() &&
				fg('jpd-sidebar-v4-for-roadmaps')
					? AnyAri.parse(manuallyCreatedView.viewId).resourceId
					: manuallyCreatedView?.viewLegacyId;

			if (viewIdToLoad) {
				dispatch(
					loadView(viewIdToLoad, undefined, () => {
						if (manuallyCreatedView === undefined) {
							return;
						}

						const modRemoteView = internRemoteView(
							fields,
							manuallyCreatedView,
							isSharedView,
							isCollectionView,
						);

						if (
							currentViewSlug !== undefined &&
							!doesViewMatchSlug(modRemoteView, currentViewSlug) &&
							isSharedView !== true
						) {
							router.routeTo({
								section: ViewSectionTypeViews,
								resource: modRemoteView.slug,
							});
						}
					}),
				);
			} else {
				const isCurrentViewDeleted = Object.values(deletedViews).some((view) => {
					return (
						isSharedView !== true &&
						currentViewSlug !== undefined &&
						doesViewMatchSlug(view, currentViewSlug)
					);
				});
				if (currentViewSlug !== undefined && isCurrentViewDeleted && isSharedView !== true) {
					const getFirstAvailableViewIdInViewSetWithType =
						getFirstAvailableViewIdInViewSet(updatedViewSets);

					const newCurrentViewData =
						getFirstAvailableViewIdInViewSetWithType('CAPTURE') ??
						getFirstAvailableViewIdInViewSetWithType('PRIORITIZE');

					newCurrentViewData?.view?.slug &&
						router.routeTo({
							section: ViewSectionTypeViews,
							resource: newCurrentViewData?.view?.slug,
						});
				}
			}
		}
	};
