import React, { useCallback, memo, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Checkbox } from '@atlassian/jira-polaris-lib-checkbox/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { RowGroupId, RowId } from '../../../common/types/index.tsx';
import { useListActions } from '../../../controllers/index.tsx';
import {
	useHasAllRowsInGroupSelected,
	useHasAllRowsSelected,
	useHasRowsInGroupSelected,
	useHasSelectedRows,
	useIsSelected,
	useIsSelectionEnabled,
} from '../../../controllers/selectors/rows-hooks.tsx';

type Props = {
	rowId: RowId;
};

export const SelectionCheckBox = memo<Props>(({ rowId }: Props) => {
	const isSelectionEnabled = useIsSelectionEnabled();
	const isSelected = useIsSelected(rowId);
	const { setRowSelection, extendSelectionFromLastSelectedRow, setLastSelectedRow } =
		useListActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick = useCallback(
		(e: MouseEvent<HTMLInputElement>) => {
			if (e.shiftKey) {
				extendSelectionFromLastSelectedRow(rowId);
			}
			setLastSelectedRow(rowId);
		},
		[rowId, setLastSelectedRow, extendSelectionFromLastSelectedRow],
	);

	const onToggleIssueSelection = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'changed', actionSubject: 'checkbox' }),
			'selectIdea',
			{
				checkboxChecked: !isSelected,
			},
		);
		setRowSelection(rowId, !isSelected);
	}, [isSelected, setRowSelection, rowId, createAnalyticsEvent]);

	return isSelectionEnabled ? (
		// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<CheckboxContainer className="hide-from-export" style={{ userSelect: 'none' }}>
			<Checkbox
				testId="polaris-lib-list.ui.cell.selection-checkbox.checkbox"
				onChange={onToggleIssueSelection}
				onClick={onClick}
				isChecked={isSelected}
			/>
		</CheckboxContainer>
	) : null;
});

export const SelectAllCheckBox = () => {
	const isSelectionEnabled = useIsSelectionEnabled();
	const hasRowsSelected = useHasSelectedRows();
	const hasAllRowsSelected = useHasAllRowsSelected();

	const { selectAllRows } = useListActions();

	const onChange = useCallback(() => {
		selectAllRows(!hasRowsSelected);
	}, [selectAllRows, hasRowsSelected]);

	return isSelectionEnabled ? (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<CheckboxContainer className="hide-from-export">
			<Checkbox
				testId="polaris-lib-list.ui.cell.selection-checkbox.select-all"
				onChange={onChange}
				isChecked={hasRowsSelected}
				isIndeterminate={!hasAllRowsSelected && hasRowsSelected}
			/>
		</CheckboxContainer>
	) : null;
};

type SelectAllInGroupCheckboxProps = {
	groupId: RowGroupId;
};

export const SelectAllInGroupCheckBox = ({ groupId }: SelectAllInGroupCheckboxProps) => {
	const isSelectionEnabled = useIsSelectionEnabled();
	const hasRowsInGroupSelected = useHasRowsInGroupSelected(groupId);
	const hasAllRowsInGroupSelected = useHasAllRowsInGroupSelected(groupId);

	const { selectAllRowsInGroup } = useListActions();

	const onChange = useCallback(() => {
		selectAllRowsInGroup(groupId, !hasRowsInGroupSelected);
	}, [selectAllRowsInGroup, groupId, hasRowsInGroupSelected]);

	return isSelectionEnabled ? (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<CheckboxContainerRowGroup className="hide-from-export">
			<Checkbox
				testId="polaris-lib-list.ui.cell.selection-checkbox.select-group"
				onChange={onChange}
				isChecked={hasRowsInGroupSelected}
				isIndeterminate={!hasAllRowsInGroupSelected && hasRowsInGroupSelected}
			/>
		</CheckboxContainerRowGroup>
	) : null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckboxContainer = styled.div({
	maxHeight: '36px',
	marginLeft: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckboxContainerRowGroup = styled.div({
	maxHeight: '36px',
	width: '18px',
});
