import React, { type SyntheticEvent, useCallback, useMemo, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { Box, xcss, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import {
	BreadcrumbSkeleton,
	SkeletonIcon,
} from '@atlassian/jira-polaris-common/src/common/ui/skeleton/index.tsx';
import { shouldOpenInNewTab } from '@atlassian/jira-polaris-common/src/common/utils/events/index.tsx';
import {
	getDirectIssueLink,
	getIssueLink,
} from '@atlassian/jira-polaris-common/src/common/utils/issue-link/index.tsx';
import {
	useIsCollectionView,
	useIsSharedView,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { usePreloadedIssueTypeIcon } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/index.tsx';
import {
	useIsSelectedIssueArchived,
	useSelectedIssue,
	useSelectedIssueIssueType,
	useSelectedIssueKey,
	useSelectedIssueProject,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useCloseRightSidebar } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { CopyLink } from '@atlassian/jira-polaris-common/src/ui/copy-link/index.tsx';
import { IssueTypeSelect } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-select/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { ISSUETYPE_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

export const IssueKey = () => {
	const isSharedView = useIsSharedView();
	const isCollectionView = useIsCollectionView();
	const issueKey = useSelectedIssueKey();
	const preloadedIssueTypeIcon = usePreloadedIssueTypeIcon();
	const localIssueId = useSelectedIssue();
	const issueType = useSelectedIssueIssueType();
	const closeRightSidebar = useCloseRightSidebar();
	const { openIssueView } = usePolarisRouter();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const projectKey = useSelectedIssueProject()?.key;
	const [canEditIssues] = useCanEditIssues();
	const { updateFieldValue } = useIssueActions();
	const isArchived = useIsSelectedIssueArchived();

	const linkUrl = useMemo(
		() => getIssueLink(projectKey, issueKey, isSharedView),
		[isSharedView, issueKey, projectKey],
	);
	const issueLinkUrl = getDirectIssueLink(issueKey, isSharedView);

	const issueTypeIcon = useMemo(
		() => issueType?.iconUrl || preloadedIssueTypeIcon,
		[preloadedIssueTypeIcon, issueType],
	);

	const onOpenIdeaOverlay = useCallback(
		(evt: SyntheticEvent) => {
			evt.preventDefault();
			if (!issueKey) return;

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if ((shouldOpenInNewTab(evt as MouseEvent) || isCollectionView) && linkUrl) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.open(linkUrl, '_blank');
				return;
			}

			openIssueView(issueKey);

			closeRightSidebar();
		},
		[closeRightSidebar, isCollectionView, issueKey, linkUrl, openIssueView],
	);

	const onCopyLink = () => {
		fireCompoundAnalyticsEvent.IdeaView.copyIdeaLinkButtonClicked(createAnalyticsEvent({}));
	};

	if (issueKey === undefined) {
		return <BreadcrumbSkeleton />;
	}

	return issueTypeIcon === undefined ? (
		<BreadcrumbsItem
			testId="polaris-ideas.ui.idea-view.breadcrumbs.key.key"
			text={issueKey}
			iconBefore={
				<Box xcss={skeletonIconWrapperStyles}>
					<SkeletonIcon />
				</Box>
			}
		/>
	) : (
		<BreadcrumbsItemWrapper data-component-selector="breadcrumbs-item-wrapper-hX88">
			{issueType && localIssueId && fg('jpd_issues_relationships') && (
				<Inline
					testId="polaris-ideas.ui.idea-view.breadcrumbs.key.editable-issue-type"
					xcss={canEditIssues && !isArchived && issueTypeWrapperStyles}
				>
					<IssueTypeSelect
						readonly={!canEditIssues || isArchived}
						issueTypeId={issueType.id}
						onChange={(newValue) =>
							updateFieldValue({
								fieldKey: ISSUETYPE_FIELDKEY,
								localIssueIds: [localIssueId],
								newValue,
							})
						}
					/>
				</Inline>
			)}

			<BreadcrumbsItem
				testId="polaris-ideas.ui.idea-view.breadcrumbs.key.key"
				text={issueKey}
				iconBefore={
					fg('jpd_issues_relationships') ? undefined : (
						<Avatar appearance="square" src={issueTypeIcon} size="xsmall" />
					)
				}
				onClick={onOpenIdeaOverlay}
				href={issueLinkUrl}
				iconAfter={
					<LinkIconWrapper>
						<CopyLink
							size="small"
							appearance="subtle-link"
							onCopy={onCopyLink}
							url={issueLinkUrl}
						/>
					</LinkIconWrapper>
				}
			/>
		</BreadcrumbsItemWrapper>
	);
};

const skeletonIconWrapperStyles = xcss({
	marginRight: 'space.050',
});

const issueTypeWrapperStyles = xcss({
	marginInlineStart: 'space.negative.075',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadcrumbsItemWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values
	gap: () => (fg('jpd_issues_relationships') ? token('space.025') : undefined),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkIconWrapper = styled.div({
	visibility: 'hidden',
	marginLeft: token('space.negative.025'),
	paddingLeft: 0,
	cursor: 'pointer',
	opacity: 0,
	transition: 'opacity 150ms, visibility 300ms, margin-left 300ms, padding-left 300ms',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="breadcrumbs-item-wrapper-hX88"]:hover &': {
		visibility: 'visible',
		opacity: 1,
		marginLeft: 0,
		paddingLeft: token('space.050'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& > div[role='presentation']": {
		display: 'flex',
	},
});
