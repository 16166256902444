import React from 'react';

import { di } from 'react-magnetic-di';
import type { ContainerComponent } from 'react-sweet-state';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { UiModificationContextContainer } from '@atlassian/ui-modifications-core/src/controllers/adjustments-context';

import type { ViewConfiguration } from '../../common/types';
import type { ViewType } from '../../common/types/context';
import { LoadedUiModificationsContainer } from '../issue-adjustments/container';
import { useLoadViewConfiguration } from '../view-configuration/store';

type Props = {
	scope: string;
	viewType: ViewType;
	viewConfiguration: new () => ViewConfiguration;
};

export const BaseUiModificationsContainer: ContainerComponent<Props> = ({
	children,
	scope,
	viewType,
	viewConfiguration,
}) => {
	di(LoadedUiModificationsContainer, UiModificationContextContainer, useAnalyticsEvents);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	useLoadViewConfiguration(viewType, viewConfiguration);

	return (
		<LoadedUiModificationsContainer
			scope={scope}
			viewType={viewType}
			createAnalyticsEvent={createAnalyticsEvent}
		>
			<UiModificationContextContainer scope={scope}>{children}</UiModificationContextContainer>
		</LoadedUiModificationsContainer>
	);
};
