import React, { useCallback } from 'react';
import MediaServicesAddCommentIcon from '@atlaskit/icon/core/migration/comment-add--media-services-add-comment';
import { useIntl } from '@atlassian/jira-intl';
import { commentGlyph } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/comment/index.tsx';
import { useCanAddComments } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useIsExportingViewImage } from '@atlassian/jira-polaris-component-view-export/src/controllers/selectors.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIsEmbedded } from '../../../controllers/environment/index.tsx';
import { useIssueActions } from '../../../controllers/issue/main.tsx';
import {
	useIssueCommentCount,
	useUnseenCommentsAvailableForIssue,
} from '../../../controllers/issue/selectors/properties/comments/hooks.tsx';
import {
	useSafeJiraIssueId,
	useSafeIssueKey,
} from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { usePolarisRouter } from '../../../controllers/route/index.tsx';
import { ValueButtonField } from '../value-button-field/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	localIssueId: LocalIssueId;
	appearance: 'list' | 'board';
	showPlaceholderLabel?: 'always' | 'hovered';
	testId?: string;
};

export const CommentsField = ({
	localIssueId,
	appearance,
	showPlaceholderLabel,
	testId = 'polaris-common.ui.fields.comments.value-button-field',
}: Props) => {
	const { formatMessage } = useIntl();
	const commentCount = useIssueCommentCount(localIssueId);
	const hasUnread = useUnseenCommentsAvailableForIssue(localIssueId);
	const [canAddComments] = useCanAddComments();
	const jiraIssueId = useSafeJiraIssueId(localIssueId);
	const { setTimestampIssueCommentsSeen } = useIssueActions();
	const { openIssueView } = usePolarisRouter();
	const issueKey = useSafeIssueKey(localIssueId);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isExportingViewImage = useIsExportingViewImage();

	const embedded = useIsEmbedded();

	const handleShow = useCallback(() => {
		if (jiraIssueId === undefined || issueKey === undefined) {
			return;
		}
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
			'issueField',
			{
				issueField: 'comments',
			},
		);
		openIssueView(issueKey, { layout: 'sidebar', section: 'comments' });

		setTimestampIssueCommentsSeen(localIssueId, new Date().valueOf());
	}, [
		jiraIssueId,
		issueKey,
		setTimestampIssueCommentsSeen,
		localIssueId,
		openIssueView,
		createAnalyticsEvent,
	]);

	return (
		<ValueButtonField
			testId={testId}
			appearance={appearance === 'board' ? 'compact' : 'normal'}
			addGlyph={() => <MediaServicesAddCommentIcon LEGACY_size="small" label="add" />}
			glyph={commentGlyph}
			onClick={handleShow}
			hasUnread={hasUnread}
			placeholder={formatMessage(messages.placeholder)}
			placeholderAppearance={showPlaceholderLabel ? 'verbose' : undefined}
			isReadOnly={embedded}
			itemCount={commentCount}
			label="comments"
			isExporting={isExportingViewImage}
			canAdd={canAddComments}
		/>
	);
};
