import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueWatchersList } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import PeopleGroup from '../assets/people-group.svg';
import { UsersList } from '../users-list/index.tsx';
import messages from './messages.tsx';

export const WatchersList = () => {
	const { formatMessage } = useIntl();
	const watchersList = useIssueWatchersList();

	if (!watchersList.length) {
		return (
			<Container>
				<EmptyListContainer>
					<EmptyListImage role="img" />
					{formatMessage(messages.noWatchersYet)}
				</EmptyListContainer>
			</Container>
		);
	}

	return (
		<Container>
			<UsersList
				users={watchersList}
				headerText={formatMessage(
					fg('polaris-issue-terminology-refresh')
						? messages.watchingThisIssueIssueTermRefresh
						: messages.watchingThisIssue,
				)}
			/>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	maxHeight: '190px',
	marginBottom: token('space.100'),
	overflowY: 'auto',
	borderTop: `1px solid ${token('color.border', '#ebecf0')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyListContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: token('space.250'),
	marginRight: 0,
	marginBottom: token('space.250'),
	marginLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyListImage = styled.div({
	height: '55px',
	width: '128px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '10px',
	backgroundPosition: 'center',
	backgroundSize: 'contain',
	backgroundRepeat: 'no-repeat',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backgroundImage: `url(${PeopleGroup})`,
});
