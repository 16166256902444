import React, { memo, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { styled } from '@compiled/react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { PermalinkType } from '@atlassian/jira-polaris-common/src/common/utils/permalink/index.tsx';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import {
	useSortedCommentIds,
	useComment,
} from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/comments/hooks.tsx';
import { useIsLoading } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	useIsSelectedIssueArchived,
	useSelectedIssueId,
	useSelectedIssueKey,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { AddCommentComponent } from '@atlassian/jira-polaris-common/src/ui/comments/add-comment/index.tsx';
import { CommentComponent } from '@atlassian/jira-polaris-common/src/ui/comments/comment/index.tsx';
import {
	useCanAddComments,
	useCanEditAllComments,
	useCanEditOwnComments,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import ExperienceFailErrorBoundary from '@atlassian/jira-polaris-lib-analytics/src/ui/index.tsx';
import { useIsAdfLoading } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useInfiniteScrollEmulator } from '../../../common/utils/infinite-scroll-emulator.tsx';
import { EmptyComments } from './empty-state/index.tsx';
import { CommentsStreamSkeleton } from './skeleton/index.tsx';

type IdeaCommentProps = {
	commentId: string;
	onDeleteRequested: (arg1: string) => void;
	onCommentUpdateRequested: (arg1: string, arg2: ADF) => Promise<void>;
	onDirty?: (_: boolean) => void;
	onError?: (error: Error) => void;
};

const IdeaComment = memo(
	({
		commentId,
		onDeleteRequested,
		onCommentUpdateRequested,
		onDirty,
		onError,
	}: IdeaCommentProps) => {
		const cloudId = useCloudId();
		const comment = useComment(commentId);
		const issueId = useSelectedIssueId();
		const isIdeaArchived = useIsSelectedIssueArchived();

		const [canAddComments] = useCanAddComments();
		const canEditOwnComments = useCanEditOwnComments();
		const canEditAllComments = useCanEditAllComments();

		if (comment === undefined || issueId === undefined) {
			return null;
		}

		return (
			<CommentComponent
				comment={comment}
				reactionContainerId={`ari:cloud:jira:${cloudId}:issue/${issueId}`}
				reactionCommentAri={`ari:cloud:jira:${cloudId}:comment/${comment.id}`}
				canAdd={canAddComments && !isIdeaArchived}
				canEditOwn={canEditOwnComments && !isIdeaArchived}
				canEditAll={canEditAllComments && !isIdeaArchived}
				onCommentUpdateRequested={onCommentUpdateRequested}
				onDeleteRequested={onDeleteRequested}
				onDirty={onDirty}
				onError={onError}
			/>
		);
	},
);

type IdeaCommentStreamProps = {
	scrollableRef: React.RefObject<HTMLElement>;
};

const IdeaCommentStreamInternal = memo(({ scrollableRef }: IdeaCommentStreamProps) => {
	const { setUnsavedChanges } = useViewActions();
	const { setTimestampCommentsSeen } = useIssueActions();
	const [adfLoading] = useIsAdfLoading();

	const comments = useSortedCommentIds();
	const reversedComments = useMemo(() => [...comments].reverse(), [comments]);
	const visibleCount = useInfiniteScrollEmulator(scrollableRef, reversedComments, {
		permalinkType: PermalinkType.ISSUE_COMMENTS,
	});
	const { addCommentToIssue, deleteCommentFromIssue, updateCommentOfIssue } = useIdeaActions();

	const [isEditMode, setEditMode] = useState(false);
	const [canAddComments] = useCanAddComments();
	const issueKey = useSelectedIssueKey();
	const ideaId = useSelectedIssueId();
	const isIdeaArchived = useIsSelectedIssueArchived();

	const isLoading = useIsLoading();

	const commentsLoadedButVisibleCountIsZero = comments?.length !== 0 && visibleCount === 0;
	const displayCommentsActions = canAddComments && (!isIdeaArchived || !!comments.length);
	const displayEmptyState = !comments?.length && !isEditMode;

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (!isLoading || (commentsLoadedButVisibleCountIsZero && !adfLoading)) {
			experience.ideaView.commentsSegmentLoad.success();
		}
	}, [isLoading, commentsLoadedButVisibleCountIsZero, adfLoading]);

	useEffect(() => {
		if (!isLoading) {
			// when another user added/deleted a comment, we need to update the timestamp
			setTimestampCommentsSeen(new Date().valueOf());
		}
	}, [isLoading, comments, setTimestampCommentsSeen]);

	const onError = useCallback((error: Error) => {
		if (!isClientFetchError(error)) {
			experience.ideaView.commentsSegmentLoad.failure(error);
		}
	}, []);

	const onSave = useCallback(
		(comment: ADF) => {
			if (ideaId === undefined || comment.content.length === 0) {
				return Promise.reject(new Error('No ideaId or comment content'));
			}
			return addCommentToIssue(comment).then(() => {
				fireCompoundAnalyticsEvent.IdeaCommentCreated(createAnalyticsEvent({}));
				return true;
			});
		},
		[addCommentToIssue, createAnalyticsEvent, ideaId],
	);

	const onUpdate = useCallback(
		(commentId: string, comment: ADF) => {
			if (comment.content.length === 0) {
				return Promise.resolve();
			}
			return updateCommentOfIssue(commentId, comment).then(() => {
				fireCompoundAnalyticsEvent.IdeaCommentUpdated(createAnalyticsEvent({}));
			});
		},
		[updateCommentOfIssue, createAnalyticsEvent],
	);

	const onDeleteRequested = useCallback(
		(commentId: string) => {
			deleteCommentFromIssue(commentId).then(() => {
				fireCompoundAnalyticsEvent.IdeaCommentDeleted(createAnalyticsEvent({}));
			});
		},
		[deleteCommentFromIssue, createAnalyticsEvent],
	);

	const onEnterEditMode = useCallback(() => {
		setEditMode(true);
	}, []);

	const onCloseEditMode = useCallback(() => {
		setEditMode(false);
	}, []);

	if (isLoading) {
		return (
			<UFOLoadHold name="idea-comment-stream">
				<CommentsStreamSkeleton />
			</UFOLoadHold>
		);
	}

	return (
		<CommentsContainer>
			{displayCommentsActions && (
				<CommentsActionsContainer>
					<AddCommentComponent
						onSave={onSave}
						onDirty={setUnsavedChanges}
						issueKey={issueKey}
						onEnterEditMode={onEnterEditMode}
						onCloseEditMode={onCloseEditMode}
						disabled={isIdeaArchived}
					/>
				</CommentsActionsContainer>
			)}
			{displayEmptyState ? (
				<EmptyComments />
			) : (
				<CommentsStreamContainer>
					{reversedComments.slice(0, visibleCount).map((id) => (
						<IdeaComment
							key={id}
							commentId={id}
							onDirty={setUnsavedChanges}
							onError={onError}
							onDeleteRequested={onDeleteRequested}
							onCommentUpdateRequested={onUpdate}
						/>
					))}
				</CommentsStreamContainer>
			)}
			{adfLoading && fg('jpd_idea_view_tabs_ufo_fixes') && (
				<UFOLoadHold name="comments-adf-loading" />
			)}
		</CommentsContainer>
	);
});

IdeaCommentStreamInternal.displayName = 'IdeaCommentStream';

const IdeaCommentStreamErrorBoundary = (props: IdeaCommentStreamProps) => {
	const isMounted = useRef(false);

	useEffect(() => {
		isMounted.current = true;
	}, []);

	if (!isMounted.current) {
		experience.ideaView.commentsSegmentLoad.start();
	}

	return (
		<ExperienceFailErrorBoundary
			experience={[experience.ideaView.commentsSegmentLoad, experience.ideaView.commentCreate]}
		>
			<IdeaCommentStreamInternal {...props} />
		</ExperienceFailErrorBoundary>
	);
};

export { IdeaCommentStreamErrorBoundary as IdeaCommentStream };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentsContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentsStreamContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentsActionsContainer = styled.div({
	flex: '0 0 auto',
	boxSizing: 'border-box',
});
