/** @jsx jsx */
import { memo, useEffect, useMemo } from 'react';
import { styled, css, jsx } from '@compiled/react';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	GenericError,
	ProjectNotFoundError,
	ProjectNotOnboardedError,
	ViewUnavailableError,
} from '@atlassian/jira-polaris-common/src/common/utils/errors/main.tsx';
import { startCurrentViewLoadExperience } from '@atlassian/jira-polaris-common/src/common/utils/experience/index.tsx';
import {
	jpdProjectPageLoad,
	PAGE_LOAD_MARK_RENDER_VIEW_MAIN_START,
	PAGE_LOAD_MARK_RENDER_VIEW_MAIN_END,
} from '@atlassian/jira-polaris-component-metrics/src/project.tsx';
import { useIsAtlasInitalLoading } from '@atlassian/jira-polaris-common/src/controllers/atlas/index.tsx';
import {
	useIsSharedView,
	useIsCollectionView,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import {
	useIsCreatingIdeasForGroupingSupported,
	useIsReadOnlyFieldForGrouping,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIsSharedViewUnavailable } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/meta-hooks.tsx';
import {
	useArchivedError,
	useError,
	useIsArchivedIssuesLoading,
	useIsInitialized,
	useIsIssuesLoading,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import {
	useIsProjectAvailable,
	useIsProjectLoading,
	useIsProjectOnboarded,
} from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks.tsx';
import {
	useCurrentViewVerticalGroupBy,
	useCurrentViewGroupBy,
	useCurrentViewId,
	useCurrentViewContainsArchived,
	useCurrentViewKind,
	useCurrentViewUUID,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useAreStatusesInitialized } from '@atlassian/jira-polaris-common/src/controllers/workflow/selectors/statuses-hooks.tsx';
import {
	useHasNoProjectPermissions,
	useIsLoadedPermissions,
	useCanEditIssues,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useViewExportActions } from '@atlassian/jira-polaris-component-view-export/src/controllers/main.tsx';
import { useIsExportingViewImage } from '@atlassian/jira-polaris-component-view-export/src/controllers/selectors.tsx';
import { LastSeenTimestampUpdater } from '@atlassian/jira-polaris-component-view-visitors/src/ui/update/index.tsx';
import {
	VIEW_KIND_BOARD,
	VIEW_KIND_MATRIX,
	VIEW_KIND_TABLE,
	VIEW_KIND_TIMELINE,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { useOnboardingFlowsActions } from '@atlassian/jira-polaris-lib-onboarding-flows/src/controllers/index.tsx';
import { useRunOnce } from '@atlassian/jira-polaris-lib-run-once/src/index.tsx';
import {
	ContextualAnalyticsData,
	FireOperationalAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useIsIssueOpenInFullscreen } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { ViewMainContentErrorBoundary } from '../main-content/view-main-content-error-boundary/index.tsx';
import { FilteredIssueModal } from './filtered-issue-modal/index.tsx';
import { IdeaBoard as IdeaBoardNext } from './idea-board-next/main.tsx';
import { IdeaBoard } from './idea-board/main.tsx';
import { IdeaList } from './idea-list/index.tsx';
import { IdeaMatrix } from './idea-matrix/index.tsx';
import { IdeaTimeline } from './idea-timeline/index.tsx';
import { PublishViewAnalyticsHandler } from './published-view-analytics-handler/index.tsx';

export type ViewContentProps = {
	isHidden: boolean;
	onReady: () => void;
};

export const ViewContentInnerComponent = ({ isHidden, onReady }: ViewContentProps) => {
	const viewKind = useCurrentViewKind();
	const isSharedView = useIsSharedView();
	const [canEditIssues] = useCanEditIssues();
	const isExportingViewImage = useIsExportingViewImage();
	const { setIsReadyToExportViewImage } = useViewExportActions();

	useEffect(() => {
		const OPTIMIZED_VIEWS = [VIEW_KIND_TABLE, VIEW_KIND_BOARD];
		if (viewKind && !OPTIMIZED_VIEWS.includes(viewKind) && isExportingViewImage) {
			setIsReadyToExportViewImage(true);
		}
	}, [viewKind, isExportingViewImage, setIsReadyToExportViewImage]);

	useEffect(() => {
		if (viewKind === VIEW_KIND_TABLE) {
			return;
		}
		onReady();
	}, [onReady, viewKind]);

	const groupBy = useCurrentViewGroupBy();
	const verticalGroupBy = useCurrentViewVerticalGroupBy();

	const isGroupByReadyOnly = useIsReadOnlyFieldForGrouping(groupBy?.key);
	const isVerticalGroupByReadyOnly = useIsReadOnlyFieldForGrouping(verticalGroupBy?.key);

	const groupByFieldCreatingIdeasSupported = useIsCreatingIdeasForGroupingSupported(groupBy?.key);
	const verticalGroupByFieldCreatingIdeasSupported = useIsCreatingIdeasForGroupingSupported(
		verticalGroupBy?.key,
	);
	const creatingIdeasSupported =
		groupByFieldCreatingIdeasSupported && verticalGroupByFieldCreatingIdeasSupported;

	switch (viewKind) {
		case VIEW_KIND_MATRIX:
			return <IdeaMatrix />;

		case VIEW_KIND_TIMELINE:
			return <IdeaTimeline />;

		case VIEW_KIND_BOARD:
			return fg('polaris_board_lib_refactoring') ? (
				<IdeaBoardNext
					isSharedView={isSharedView}
					isReadOnly={!canEditIssues || isGroupByReadyOnly || isVerticalGroupByReadyOnly}
					isFooterVisible={creatingIdeasSupported}
				/>
			) : (
				<IdeaBoard
					isSharedView={isSharedView}
					isReadOnly={!canEditIssues || isGroupByReadyOnly || isVerticalGroupByReadyOnly}
					isFooterVisible={creatingIdeasSupported}
				/>
			);
		case VIEW_KIND_TABLE:
		default:
			return <IdeaList isHidden={isHidden} isExporting={isExportingViewImage} onReady={onReady} />;
	}
};

const ViewContentReady = ({ isHidden, onReady }: ViewContentProps) => {
	const viewKind = useCurrentViewKind();
	const isSharedView = useIsSharedView();
	const isCollectionView = useIsCollectionView();
	const viewUUID = useCurrentViewUUID();
	const { loadViewPermissionsSpotlightDismissed } = useOnboardingFlowsActions();

	useRunOnce(() => {
		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_VIEW_MAIN_START);
		startCurrentViewLoadExperience(viewKind);
		loadViewPermissionsSpotlightDismissed();
	});

	const analyticsSourceName = useMemo(() => {
		switch (viewKind) {
			case VIEW_KIND_MATRIX:
				return 'ideasMatrixView';
			case VIEW_KIND_TIMELINE:
				return 'ideasTimelineView';
			case VIEW_KIND_BOARD:
				return 'ideasBoardView';
			case VIEW_KIND_TABLE:
			default:
				return 'ideasListView';
		}
	}, [viewKind]);

	useEffect(() => {
		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_VIEW_MAIN_END);
	}, [onReady]);

	return (
		<ViewContentWrapper data-testid="polaris-ideas.ui.view-content.view-content-wrapper">
			<FireOperationalAnalytics
				eventName="JpdIdeasViewContent loaded"
				componentName="ViewContent"
			/>
			<ContextualAnalyticsData sourceName={analyticsSourceName} sourceType={SCREEN}>
				{(isSharedView || isCollectionView) && (
					<LastSeenTimestampUpdater viewUUID={viewUUID} force />
				)}
				<ViewContentInnerComponent isHidden={isHidden} onReady={onReady} />
				{isSharedView && <PublishViewAnalyticsHandler />}
			</ContextualAnalyticsData>
			<FilteredIssueModal />
		</ViewContentWrapper>
	);
};

const ViewContentInternal = (props: ViewContentProps) => {
	const viewKind = useCurrentViewKind();
	const viewId = useCurrentViewId();
	const error = useError();
	const containsArchived = useCurrentViewContainsArchived();
	const archivedError = useArchivedError();
	const isIssuesLoading = useIsIssuesLoading();
	const isArchivedIssuesLoading = useIsArchivedIssuesLoading();
	const [isAtlasInitalLoading] = useIsAtlasInitalLoading();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const [isProjectOnboarded] = useIsProjectOnboarded();
	const [isProjectLoading] = useIsProjectLoading();
	const [permissionsLoaded] = useIsLoadedPermissions();
	const [isAvailable] = useIsProjectAvailable();
	const { isAdmin, isSiteAdmin } = useTenantContext();
	const isSharedViewUnavailable = useIsSharedViewUnavailable();
	const isSharedView = useIsSharedView();
	const isCollectionView = useIsCollectionView();
	const isInitialized = useIsInitialized();
	const areStatusesInitialized = useAreStatusesInitialized();
	const isIssueOpenInFullscreen = useIsIssueOpenInFullscreen();

	if (!isSharedView && permissionsLoaded && hasNoProjectPermissions) {
		return <ProjectNotFoundError />;
	}

	// project is not onboarded due to missing permissions / license, show that the project is not onboarded yet.
	if (isAvailable && !isProjectLoading && !isProjectOnboarded && (isSiteAdmin || isAdmin)) {
		return <ProjectNotOnboardedError isAdmin />;
	}

	if (isSharedView && isSharedViewUnavailable) {
		return (
			<ErrorScreenWrapper>
				<ViewUnavailableError />
			</ErrorScreenWrapper>
		);
	}

	if (containsArchived && archivedError !== undefined) {
		return (
			<ErrorScreenWrapper>
				<GenericError error={archivedError} />
			</ErrorScreenWrapper>
		);
	}

	if (containsArchived && isArchivedIssuesLoading) {
		if (isIssueOpenInFullscreen && fg('jpd-rename-ufo-project-transition')) {
			return null;
		}

		return (
			<UFOLoadHold name="jpd.project-load-archived">
				<div css={loadingWrapperStyles} data-testid="polaris-ideas.ui.view-content.archived-view">
					<Spinner />
				</div>
			</UFOLoadHold>
		);
	}

	if (error !== undefined) {
		return (
			<ErrorScreenWrapper>
				<GenericError error={error} />
			</ErrorScreenWrapper>
		);
	}

	if (
		!isInitialized ||
		!viewId ||
		!viewKind ||
		isIssuesLoading ||
		(!isIssuesLoading && isAtlasInitalLoading) ||
		(!isCollectionView && !areStatusesInitialized)
	) {
		if (isIssueOpenInFullscreen && fg('jpd-rename-ufo-project-transition')) {
			return null;
		}

		return (
			<UFOLoadHold name="jpd.project-load-all-data">
				<div css={loadingWrapperStyles} data-testid="polaris-ideas.ui.view-content.loading-wrapper">
					<Spinner />
				</div>
			</UFOLoadHold>
		);
	}

	return (
		<ViewMainContentErrorBoundary key={viewId}>
			<ViewContentReady {...props} />
		</ViewMainContentErrorBoundary>
	);
};

export const ViewContent = memo<ViewContentProps>(ViewContentInternal);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewContentWrapper = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	minHeight: 0,
	width: '100%',
	position: 'relative',
});

const loadingWrapperStyles = css({
	'--viewControlsContainerHeight': '130px',
	display: 'flex',
	width: '100%',
	height:
		'calc(100vh - var(--topNavigationHeight, 0px) - var(--viewControlsContainerHeight, 0px) - var(--bannerHeight, 0px))',
	alignItems: 'center',
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorScreenWrapper = styled.div({
	margin: '137px auto',
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div': {
		margin: 0,
	},
});
