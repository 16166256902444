import React, {
	type ReactElement,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	type ReactNode,
} from 'react';
import { styled } from '@compiled/react';
import Badge from '@atlaskit/badge';
import Button from '@atlaskit/button';
import UFOSegment from '@atlaskit/react-ufo/segment';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { IssueViewSection } from '@atlassian/jira-polaris-common/src/common/types/issue/index.tsx';
import { markIdeaViewTTI } from '@atlassian/jira-polaris-component-metrics/src/idea-view.tsx';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useFieldsSidebarConfig } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import { useSelectedIssueConfiguredConnectionFieldsCount } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/connection-hooks.tsx';
import {
	useIssueCommentCount,
	useUnseenCommentsAvailableForIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/comments/hooks.tsx';
import {
	useSelectedIssue,
	useLinkedDeliveryIssuesCount,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import {
	useInsightsCountForIssue,
	useUnseenInsightsAvailableForIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/insights/hooks.tsx';
import {
	usePolarisRouter,
	useIssueViewLayout,
	useIssueViewSection,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useCurrentViewSharingSettings } from '@atlassian/jira-polaris-common/src/controllers/sharing/selectors/hooks.tsx';
import { ScrollableContainerContextProvider } from '@atlassian/jira-polaris-common/src/ui/common/scrollable-container/main.tsx';
import { useCanAddComments } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { SharingSettings } from '@atlassian/jira-polaris-domain-view/src/sharing/settings/index.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { CreateDeliveryTicketSpotlight } from '@atlassian/jira-polaris-lib-onboarding/src/ui/create-delivery-ticket/index.tsx';
import { CreateInsightSpotlight } from '@atlassian/jira-polaris-lib-onboarding/src/ui/create-insight/index.tsx';
import { FireUiAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useInsightsCount } from '../../../controllers/insights/selectors/insights-hooks.tsx';
import { InsightsUI, InsightsActionsWrapper } from '../../insights/insights/index.tsx';
import { IdeaCommentStream } from '../comments/index.tsx';
import { ConnectionsList } from '../connections/connections-list.tsx';
import { Fields } from '../fields/index.tsx';
import { History } from '../history/index.tsx';
import { Deliver } from '../sections/deliver/index.tsx';
import { messages } from './messages.tsx';
import { OverviewTab } from './overview/index.tsx';

type TabData = {
	name: IssueViewSection;
	label: string;
	content: ReactNode;
	count: number;
	hasUnseen: boolean;
	testId?: string;
};

const useTabs: (
	isSharedView: boolean,
	issueId: IssueId,
	issueKey: IssueKey,
	localIssueId: LocalIssueId,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	scrollableRef: any,
	sharingOptions?: SharingSettings,
) => TabData[] = (isSharedView, issueId, issueKey, localIssueId, scrollableRef, sharingOptions) => {
	const { formatMessage } = useIntl();
	const isCollectionView = useIsCollectionView();
	const commentCount = useIssueCommentCount(localIssueId);
	const hasUnseenComments =
		useUnseenCommentsAvailableForIssue(localIssueId) &&
		(!isCollectionView || fg('jpd-aurora-roadmap-insights-field'));
	const [canAddComments] = useCanAddComments();
	// used on project view
	const insightsCountForIssue = useInsightsCountForIssue(localIssueId);
	const hasUnseenInsights =
		useUnseenInsightsAvailableForIssue(localIssueId) &&
		(!isCollectionView || fg('jpd-aurora-roadmap-insights-field'));
	const deliveryIssuesCount = useLinkedDeliveryIssuesCount(localIssueId);
	// used on collection view
	const insightsCount = useInsightsCount() || insightsCountForIssue;

	const { mode, fieldKey } = useFieldsSidebarConfig();
	const fieldType = useFieldType(fieldKey);
	const isDeliveryFieldsSidebarOpen =
		mode === 'FIELD' &&
		(fieldType === FIELD_TYPES.DELIVERY_STATUS || fieldType === FIELD_TYPES.DELIVERY_PROGRESS);

	const selectedIssueConfiguredConnectionFieldsCount =
		useSelectedIssueConfiguredConnectionFieldsCount();

	const overviewContentElement = useMemo(
		() => (
			<UFOSegment name="jpd.idea-view.overview-tab">
				<FireUiAnalytics eventName="tab viewed" actionSubjectId="ideaOverview" />
				<OverviewTab isSharedView={isSharedView} />
			</UFOSegment>
		),
		[isSharedView],
	);

	const commentsContentElement = useMemo(
		() => (
			<UFOSegment name="jpd.idea-view.comments-tab">
				<TabContent>
					<FireUiAnalytics eventName="tab viewed" actionSubjectId="ideaComments" />
					<IdeaCommentStream scrollableRef={scrollableRef} />
				</TabContent>
			</UFOSegment>
		),
		[scrollableRef],
	);

	const captureContentElement = useMemo(
		() => (
			<UFOSegment name="jpd.idea-view.insights-tab">
				<TabContent>
					<FireUiAnalytics eventName="tab viewed" actionSubjectId="ideaInsights" />
					<InsightsActionsContainer>
						<InsightsActionsWrapper />
					</InsightsActionsContainer>
					<InsightsUI
						isCompact
						scrollableRef={scrollableRef}
						issueId={issueId}
						localIssueId={localIssueId}
					/>
				</TabContent>
			</UFOSegment>
		),
		[issueId, localIssueId, scrollableRef],
	);

	const deliverContentElement = useMemo(
		() => (
			<UFOSegment name="jpd.idea-view.delivery-tab">
				<TabContent>
					<FireUiAnalytics eventName="tab viewed" actionSubjectId="ideaDelivery" />
					<Deliver />
					{isDeliveryFieldsSidebarOpen && <Fields isCompact />}
				</TabContent>
			</UFOSegment>
		),
		[isDeliveryFieldsSidebarOpen],
	);

	const historyContentElement = useMemo(
		() => (
			<UFOSegment name="jpd.idea-view.history-tab">
				<TabContent>
					<FireUiAnalytics eventName="tab viewed" actionSubjectId="ideaHistory" />
					<History issueKey={issueKey} />
				</TabContent>
			</UFOSegment>
		),
		[issueKey],
	);

	const connectionsContentElement = useMemo(
		() => (
			<UFOSegment name="jpd.idea-view.connections-tab">
				<TabContent>
					<ConnectionsList />
				</TabContent>
			</UFOSegment>
		),
		[],
	);

	return useMemo(() => {
		const connectionsTab: TabData = {
			name: 'connections',
			label: formatMessage(messages.connectionsNonFinal),
			count: 0,
			hasUnseen: false,
			content: connectionsContentElement,
			testId: 'polaris.idea.src.ui.tab-view.connections',
		};

		const tabs: TabData[] = [
			{
				name: 'overview',
				label: formatMessage(messages.overview),
				count: 0,
				hasUnseen: false,
				content: overviewContentElement,
				testId: 'polaris.idea.src.ui.tab-view.overview',
			},
			...(selectedIssueConfiguredConnectionFieldsCount > 0 && fg('jpd_issues_relationships')
				? [connectionsTab]
				: []),
			{
				name: 'comments',
				label: formatMessage(messages.comments),
				count: commentCount,
				hasUnseen: hasUnseenComments,
				content: commentsContentElement,
				testId: 'polaris.idea.src.ui.tab-view.comments',
			},
			{
				name: 'capture',
				label: formatMessage(messages.insights),
				count: insightsCount,
				hasUnseen: hasUnseenInsights,
				content: captureContentElement,
				testId: 'polaris.idea.src.ui.tab-view.capture',
			},
			{
				name: 'deliver',
				label: formatMessage(messages.delivery),
				count: deliveryIssuesCount,
				hasUnseen: false,
				content: deliverContentElement,
				testId: 'polaris.idea.src.ui.tab-view.deliver',
			},
			...(fg('jpd_issues_relationships') // in case you wonder: we are moving the history tab to the "..." on top of the sidebar
				? []
				: [
						{
							name: 'history' as const,
							label: formatMessage(messages.history),
							count: 0,
							hasUnseen: false,
							content: historyContentElement,
							testId: 'polaris.idea.src.ui.tab-view.history',
						},
					]),
		];

		return tabs.filter((tab) => {
			if (isSharedView) {
				if (tab.name === 'overview') {
					return true;
				}
				if (
					tab.name === 'comments' &&
					sharingOptions?.showIdeaComments &&
					(commentCount || canAddComments)
				) {
					return true;
				}
				if (tab.name === 'capture' && sharingOptions?.showIdeaInsights) {
					return true;
				}
				return false;
			}
			return true;
		});
	}, [
		formatMessage,
		connectionsContentElement,
		overviewContentElement,
		selectedIssueConfiguredConnectionFieldsCount,
		commentCount,
		hasUnseenComments,
		commentsContentElement,
		insightsCount,
		hasUnseenInsights,
		captureContentElement,
		deliveryIssuesCount,
		deliverContentElement,
		historyContentElement,
		isSharedView,
		sharingOptions?.showIdeaComments,
		sharingOptions?.showIdeaInsights,
		canAddComments,
	]);
};

const TabSpotlightWrapper = ({ name, children }: { name: string; children: ReactElement }) => {
	switch (name) {
		case 'capture':
			return <CreateInsightSpotlight>{children}</CreateInsightSpotlight>;
		case 'deliver':
			return (
				<CreateDeliveryTicketSpotlight placement="bottom-end">
					{children}
				</CreateDeliveryTicketSpotlight>
			);
		default:
			return children;
	}
};

type TabViewProps = {
	isSharedView: boolean;
	issueId: IssueId;
	issueKey: IssueKey;
};

const TabView = memo<TabViewProps>((props: TabViewProps) => {
	const { isSharedView, issueId, issueKey } = props;

	const localIssueId = useSelectedIssue() ?? '';
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { openIssueView } = usePolarisRouter();
	const issueViewSection = useIssueViewSection();
	const issueViewLayout = useIssueViewLayout();
	let selectedTab: IssueViewSection = issueViewSection ?? 'overview';
	const sharingOptions = useCurrentViewSharingSettings();
	const tabsContentContainerRef = useRef<HTMLDivElement | null>(null);
	const tabs = useTabs(
		isSharedView,
		issueId,
		issueKey,
		localIssueId,
		tabsContentContainerRef,
		sharingOptions,
	);
	const [canAddComments] = useCanAddComments();
	const commentsCount = useIssueCommentCount(localIssueId);

	if (isSharedView && issueViewSection === 'comments' && !canAddComments && commentsCount === 0) {
		selectedTab = 'overview';
	}

	const selectedTabRef = useRef(selectedTab);
	selectedTabRef.current = selectedTab;

	const onSelect = useCallback(
		(tabName: IssueViewSection) => {
			fireCompoundAnalyticsEvent.IdeaView.tabClicked(createAnalyticsEvent({}), tabName);
			if (fg('polaris_idea_list_performance_improvements')) {
				// There is no need to call `scrollTo` when tab is changed
				// as scroll position after re-rendering will always be set to 0
				// and thanks to that we avoid relayout when switching tabs
				if (tabName === selectedTabRef.current) {
					tabsContentContainerRef.current?.scrollTo(0, 0);
				}
			} else {
				tabsContentContainerRef.current?.scrollTo(0, 0);
			}

			openIssueView(issueKey, { layout: issueViewLayout, section: tabName });

			if (tabName === 'capture') {
				experience.ideaView.insightsSegmentLoad.abort();
				experience.ideaView.insightsSegmentLoad.start();
			}
		},
		[createAnalyticsEvent, issueKey, issueViewLayout, openIssueView],
	);

	useEffect(() => {
		experience.ideaView.pageSegmentLoad.success({
			metadata: {
				layout: issueViewLayout,
				section: issueViewSection,
			},
		});

		experience.ideaView.directPageSegmentLoad.success({
			metadata: {
				layout: issueViewLayout,
				section: issueViewSection,
			},
		});
		markIdeaViewTTI();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{tabs.length > 1 &&
				(isVisualRefreshEnabled() ? (
					<TabList role="tablist">
						{tabs.map(({ name, label, count, hasUnseen }) => (
							<TabSpotlightWrapper key={name} name={name}>
								<TabButton
									id={`tab-${name}`}
									aria-selected={name === selectedTab}
									aria-controls={`tabpanel-${name}`}
									isSelected={name === selectedTab}
									onClick={() => onSelect(name)}
									iconAfter={
										count > 0 ? (
											<UnseenCounterContainer>
												<Badge appearance={hasUnseen ? 'primary' : 'default'}>{count}</Badge>
											</UnseenCounterContainer>
										) : undefined
									}
								>
									{label}
								</TabButton>
							</TabSpotlightWrapper>
						))}
					</TabList>
				) : (
					<TabList role="tablist">
						{tabs.map(({ name, label, count, hasUnseen }) => (
							<TabSpotlightWrapper key={name} name={name}>
								<TabButton
									role="tab"
									id={`tab-${name}`}
									aria-selected={name === selectedTab}
									aria-controls={`tabpanel-${name}`}
									isSelected={name === selectedTab}
									onClick={() => onSelect(name)}
								>
									{label}
									{count > 0 && (
										<UnseenCounterContainer>
											<Badge appearance={hasUnseen ? 'primary' : 'default'}>{count}</Badge>
										</UnseenCounterContainer>
									)}
								</TabButton>
							</TabSpotlightWrapper>
						))}
					</TabList>
				))}
			<TabsContentContainer ref={tabsContentContainerRef}>
				<ScrollableContainerContextProvider scrollableContainerRef={tabsContentContainerRef}>
					{tabs
						.filter(({ name }) => name === selectedTab)
						.map(({ name, content, testId }) => (
							<div
								key={`tabpanel-${name}`}
								id={`tabpanel-${name}`}
								role="tabpanel"
								tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
								aria-labelledby={`tab-${name}`}
								data-testid={testId}
							>
								{content}
							</div>
						))}
				</ScrollableContainerContextProvider>
			</TabsContentContainer>
		</>
	);
});

export default TabView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabsContentContainer = styled.div({
	overflow: 'auto',
	flex: '1 1 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabContent = styled.div({
	paddingTop: 0,
	paddingRight: token('space.100'),
	paddingBottom: token('space.400'),
	paddingLeft: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabList = styled.div({
	display: 'flex',
	position: 'relative',
	marginTop: 0,
	marginRight: token('space.075'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '10px',
	marginLeft: token('space.075'),
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabButton = styled(Button)({
	marginTop: token('space.025'),
	marginRight: token('space.025'),
	marginBottom: token('space.025'),
	marginLeft: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InsightsActionsContainer = styled.div({
	position: 'sticky',
	top: 0,
	zIndex: 10,
	paddingBottom: token('space.050'),
	backgroundColor: token('elevation.surface'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UnseenCounterContainer = styled.span({
	marginLeft: token('space.075'),
});
