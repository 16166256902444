import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { STATUS_ONLY } from '@atlassian/jira-polaris-common/src/ui/linked-issues-progress/index.tsx';
import type { StatusCategoryKey } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import messages from '../../messages.tsx';
import { ProgressItem } from '../../progress-item/index.tsx';

type DeliveryTicketItemSkeletonProps = {
	issueKey: string;
	status: {
		label: string;
		type: StatusCategoryKey;
	};
	nameLengthPx: number;
};

const DeliveryTicketItemSkeleton = (props: DeliveryTicketItemSkeletonProps) => (
	<DeliveryTicketContainer>
		<DeliveryTicketIcon />
		<DeliveryTicketKeyContainer>{props.issueKey}</DeliveryTicketKeyContainer>
		<DeliveryTicketNameContainer>
			<DeliveryTicketName width={props.nameLengthPx} />
		</DeliveryTicketNameContainer>
		<DeliveryTicketStatusContainer>
			<ProgressItem
				distribution={{}}
				status={{
					name: props.status.label,
					statusCategoryKey: props.status.type,
				}}
				appearance={STATUS_ONLY}
			/>
		</DeliveryTicketStatusContainer>
	</DeliveryTicketContainer>
);

export const DeliveryTicketsSkeleton = () => {
	const { formatMessage } = useIntl();

	return (
		<DeliveryTicketsContainer>
			<SectionHeading>{formatMessage(messages.sectionHeading)}</SectionHeading>
			<DeliveryTicketItemsWrapper>
				<DeliveryTicketItemSkeleton
					issueKey="KEY-123"
					status={{
						label: 'Parking lot',
						type: 'new',
					}}
					nameLengthPx={140}
				/>
				<DeliveryTicketItemSkeleton
					issueKey="KEY-124"
					status={{
						label: 'In progress',
						type: 'indeterminate',
					}}
					nameLengthPx={230}
				/>
				<DeliveryTicketItemSkeleton
					issueKey="KEY-125"
					status={{
						label: 'Done',
						type: 'done',
					}}
					nameLengthPx={140}
				/>
			</DeliveryTicketItemsWrapper>
			<FadeOverlay />
		</DeliveryTicketsContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryTicketsContainer = styled.div({
	marginTop: token('space.300'),
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionHeading = styled.div({
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.semibold'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.disabled', colors.N500),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryTicketItemsWrapper = styled.div({
	marginTop: token('space.100'),
	height: '220px', // pinned height here is needed to make theme-aware FadeOverlay bottom part softer
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryTicketContainer = styled.div({
	display: 'flex',

	borderBottom: `1px solid ${token('color.border')}`,
	paddingTop: token('space.150'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.150'),
	paddingLeft: token('space.150'),
	alignItems: 'center',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-of-type': {
		borderBottom: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryTicketIcon = styled.div({
	width: '16px',
	height: '16px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.accent.gray.subtler', colors.N300),
	borderRadius: '3px',
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryTicketKeyContainer = styled.div({
	paddingTop: 0,
	paddingRight: token('space.150'),
	paddingBottom: 0,
	paddingLeft: token('space.150'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.disabled', colors.N500),
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.semibold'),
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryTicketNameContainer = styled.div({
	paddingTop: 0,
	paddingRight: token('space.150'),
	paddingBottom: 0,
	paddingLeft: token('space.150'),
	flexGrow: '1',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryTicketName = styled.div<{ width: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => `${props.width}px`,
	height: '8px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.accent.gray.subtler', colors.N300),
	borderRadius: '10px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryTicketStatusContainer = styled.div({
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FadeOverlay = styled.div({
	position: 'absolute',
	bottom: '0px',
	width: '100%',
	height: '100%',

	backgroundImage: `linear-gradient(to bottom, transparent, ${token('elevation.surface')})`,
});
