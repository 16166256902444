import React, { useState, useMemo, useEffect, useCallback, type ChangeEvent, useRef } from 'react';
import { createPortal } from 'react-dom';
import { styled } from '@compiled/react';
import defer from 'lodash/defer';
import Button, { ButtonGroup } from '@atlaskit/button';
import LoadingButton from '@atlaskit/button/new';
import { Field as FormField, Fieldset } from '@atlaskit/form';
import { Box, Flex, Inline, Stack, xcss } from '@atlaskit/primitives';
import Select, { type FormatOptionLabelMeta } from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { getAppName } from '@atlassian/jira-polaris-component-field-configuration/src/common/utils/formula/app-name/index.tsx';
import { createFormula } from '@atlassian/jira-polaris-component-field-configuration/src/common/utils/formula/formula-create/index.tsx';
import { ExpressionTypeIn } from '@atlassian/jira-polaris-component-field-configuration/src/ui/configuration/formula/expression-type-in/index.tsx';
import {
	PropertyItemIcon,
	toNumDataPointsRollupField,
	toPropertyRollupField,
} from '@atlassian/jira-polaris-component-field-configuration/src/ui/configuration/formula/rollup/data-options/index.tsx';
import { Rollup } from '@atlassian/jira-polaris-component-field-configuration/src/ui/configuration/formula/rollup/index.tsx';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import {
	CreateFieldForm,
	FieldLabelContainer,
} from '@atlassian/jira-polaris-lib-create-field-form/src/ui/index.tsx';
import { DuplicateNameError } from '@atlassian/jira-polaris-lib-errors/src/common/utils/duplicate-name-error/index.tsx';
import { EntityLimitError } from '@atlassian/jira-polaris-lib-errors/src/common/utils/entity-limit-error/index.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { isPermissionError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { ErrorContainer } from '@atlassian/jira-polaris-lib-inputs-error/src/ui/styled.tsx';
import { CreateEntityLimitTooltip } from '@atlassian/jira-polaris-lib-limits/src/ui/index.tsx';
import { RightSidebarBody } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/body/index.tsx';
import { RightSidebarFooter } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/footer/index.tsx';
import { RightSidebarHeader } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/header/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { MAX_FIELD_LENGTH, MAX_NUMBER } from '../../../../common/constants.tsx';
import { useFieldActions } from '../../../../controllers/field/main.tsx';
import {
	useHasReachedFieldsLimit,
	useVisibleFieldsByKey,
	useFieldsArray,
} from '../../../../controllers/field/selectors/field-hooks.tsx';
import { useSnippetProviders } from '../../../../controllers/field/selectors/snippet-providers-hooks.tsx';
import { useSortedSnippetLabels } from '../../../../controllers/issue/selectors/properties/insights/hooks.tsx';
import { useProjectActions } from '../../../../controllers/project/main.tsx';
import { useRightSidebarShowing } from '../../../../controllers/right-sidebar/selectors/hooks.tsx';
import { RightSidebarShowingCreateField } from '../../../../controllers/right-sidebar/types.tsx';
import { dotStartingDecimalRegex } from '../common/constants.tsx';
import { messages } from '../common/messages.tsx';
import { VotingHint } from '../common/voting-hint/index.tsx';
import {
	getFieldTypeOptionGroups,
	getCustomFormulaOptions,
	getAppPropertyOptions,
	PolarisIdeaAppPropertyFieldType,
	type FieldTypeOption,
	type FormValues,
	type AppPropertyOption,
	type CustomFormulaOption,
	type AvailableFieldType,
} from './utils.tsx';

const DEFAULT_MAX_SPEND = 10;
const CREATE_FIELD_FORM_ID = 'create-field-form';

type OptionTooltipProps = {
	children: React.ReactNode;
	tooltipMessage?: string | null;
};

const OptionTooltip = ({ children, tooltipMessage }: OptionTooltipProps) =>
	tooltipMessage ? (
		<CreateEntityLimitTooltip message={tooltipMessage}>{children}</CreateEntityLimitTooltip>
	) : (
		<>{children}</>
	);

const OptionComponent = ({
	label,
	value,
	app,
	selectValue,
	tooltipMessage,
}: FieldTypeOption & {
	selectValue: FormatOptionLabelMeta<FieldTypeOption>['selectValue'];
}) => {
	const { formatMessage } = useIntl();

	const icon =
		app !== undefined && selectValue?.[0]?.app?.oauthClientId !== app.oauthClientId ? (
			<PropertyItemIcon
				appName={getAppName(app.oauthClientId, app.name)}
				label={label}
				avatarUrl={app.avatarUrl}
			/>
		) : (
			iconForPolarisFieldType(value === PolarisIdeaAppPropertyFieldType ? undefined : value)
		);

	return (
		<OptionTooltip tooltipMessage={tooltipMessage}>
			<Option data-testid={`polaris.common.src.ui.config.fields.create-field.option.${value}`}>
				{icon !== null ? <IconStyle>{icon}</IconStyle> : null}
				<SingleValueLabel>
					{selectValue?.[0]?.app?.oauthClientId === app?.oauthClientId &&
					app?.oauthClientId !== undefined
						? formatMessage(messages.insightsPlaceholder, {
								label,
							})
						: label}
				</SingleValueLabel>
			</Option>
		</OptionTooltip>
	);
};

const customFormulaOptionComponent = ({ label, value }: CustomFormulaOption) => (
	<Option data-testid={`polaris.common.src.ui.config.fields.create-field.custom-formula.${value}`}>
		{label}
	</Option>
);

const appPropertyOptionComponent = ({ label, value, app }: AppPropertyOption) => (
	<Option
		data-testid={`polaris.common.src.ui.config.fields.create-field.app-property.${
			value?.key || 'num-data-points'
		}`}
	>
		{app !== undefined ? (
			<IconStyle>
				<PropertyItemIcon
					appName={getAppName(app.avatarUrl, app.name)}
					label={label}
					avatarUrl={app.avatarUrl}
				/>
			</IconStyle>
		) : null}
		<SingleValueLabel>{label}</SingleValueLabel>
	</Option>
);

const customStyles = {
	// @ts-expect-error - TS7006 - Parameter 'provided' implicitly has an 'any' type.
	singleValue: (provided) => ({
		...provided,
		width: '100%',
	}),
} as const;

const Buttons = ({
	onCancel,
	isSubmitting,
	fitButtonsInContainer = false,
}: {
	onCancel: () => void;
	isSubmitting: boolean;
	fitButtonsInContainer?: boolean;
}) => {
	const { formatMessage } = useIntl();
	return (
		<>
			<Box xcss={buttonWrapperStyles}>
				<Button
					id="polaris.common.src.ui.config.fields.create-field.cancel-button"
					testId="polaris-common.ui.config.fields.create-field.cancel-button"
					shouldFitContainer={fitButtonsInContainer}
					onClick={onCancel}
				>
					{formatMessage(messages.cancel)}
				</Button>
			</Box>
			<Box xcss={buttonWrapperStyles}>
				<LoadingButton
					id="polaris.common.src.ui.config.fields.create-field.submit-button"
					testId="polaris-common.ui.config.fields.create-field.submit-button"
					type="submit"
					appearance="primary"
					shouldFitContainer={fitButtonsInContainer}
					form={CREATE_FIELD_FORM_ID}
					isLoading={isSubmitting}
				>
					{formatMessage(messages.createField)}
				</LoadingButton>
			</Box>
		</>
	);
};

const getCreateErrorMessage = (isEntityLimitError: boolean, isDuplicateNameError: boolean) => {
	if (isEntityLimitError) {
		return messages.fieldEntityLimitError;
	}
	if (isDuplicateNameError) {
		return messages.duplicateNameError;
	}
	return messages.fieldGenericError;
};

export const CreateNewField = ({
	onFieldCreate,
	onOpenFieldConfig,
	onCancel,
	onClose,
	preselectedField,
	defaultFieldName = '',
	highlightCreateField,
}: {
	onFieldCreate: (field: Field) => void;
	onOpenFieldConfig: (key: FieldKey) => void;
	onCancel: () => void;
	onClose?: () => void;
	preselectedField?: {
		type: AvailableFieldType;
	};
	defaultFieldName?: string;
	highlightCreateField?: boolean;
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const {
		createField,
		createCalculatedField,
		createPlayField,
		throttledFetchSnippetProviders,
		setHighlightedFields,
		createConnectionField,
	} = useFieldActions();
	const { generalDataUpdateFailedError } = useErrorHandlers();
	const { createPlay } = useProjectActions();

	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [sidebarShowing] = useRightSidebarShowing();
	const isOpenedFromProjectFieldsPage =
		sidebarShowing.mode === RightSidebarShowingCreateField &&
		sidebarShowing.origin === 'projectFieldsPage';
	const [formula, setFormula] = useState<DynamicFieldFormula | undefined>(undefined);
	const [numInputs, setNumInputs] = useState(0);
	const [maxSpend, setMaxSpend] = useState(DEFAULT_MAX_SPEND);
	const [fields] = useFieldsArray();
	const [visibleFieldsByKey] = useVisibleFieldsByKey();

	// Fields hard limit
	const fieldsLimit = useHasReachedFieldsLimit();

	const [snippetProviders] = useSnippetProviders();
	const sortedSnippetLabels = useSortedSnippetLabels();

	useEffect(() => {
		// re-fetch the snippet providers when we mount this component, which covers create of app property field
		throttledFetchSnippetProviders(true);
	}, [throttledFetchSnippetProviders]);

	const fieldTypeOptionGroups = useMemo(
		() => getFieldTypeOptionGroups(formatMessage, snippetProviders, fieldsLimit),
		[fieldsLimit, formatMessage, snippetProviders],
	);

	const customFormulaOptions = useMemo(
		() => getCustomFormulaOptions(formatMessage),
		[formatMessage],
	);

	const [formulaOption, setFormulaOption] = useState<CustomFormulaOption | null>(
		customFormulaOptions[0],
	);
	const [appProperty, setAppProperty] = useState<AppPropertyOption | undefined | null>(undefined);
	const [fieldType, setFieldType] = useState<AvailableFieldType | undefined>(undefined);
	const [app, setApp] = useState<AppPropertyOption['app'] | undefined>(undefined);

	const portalContainerRef = useRef<HTMLDivElement | null>(null);

	const appPropertyOptions = useMemo(
		() => (app ? getAppPropertyOptions(formatMessage, app.oauthClientId, snippetProviders) : []),
		[formatMessage, app, snippetProviders],
	);

	const validateFieldName = useCallback(
		(value: string | undefined) => {
			// Prevent race condition error, for a newly created field
			if (isSubmitLoading) {
				return undefined;
			}
			if (value === undefined || value.trim().length === 0) {
				return formatMessage(messages.fieldNameRequiredError);
			}
			if (value.length > MAX_FIELD_LENGTH) {
				return formatMessage(messages.fieldNameMaxLengthError);
			}

			const nameIsAlreadyOccupied = fields.some(
				(field) =>
					field && field.label.toLocaleLowerCase().trim() === value.toLocaleLowerCase().trim(),
			);

			if (nameIsAlreadyOccupied) {
				return formatMessage(messages.fieldNameInUseError);
			}
			return undefined;
		},
		[fields, formatMessage, isSubmitLoading],
	);

	const validateFieldType = useCallback(
		(selectedFieldType?: FieldTypeOption) =>
			selectedFieldType === undefined ? formatMessage(messages.fieldTypeRequiredError) : undefined,
		[formatMessage],
	);

	const validateAppProperty = useCallback(
		(fieldTypeValue: string | undefined, selectedAppProperty: AppPropertyOption | undefined) => {
			if (fieldTypeValue !== PolarisIdeaAppPropertyFieldType) {
				return undefined;
			}
			return selectedAppProperty === undefined
				? formatMessage(messages.dataRequiredError)
				: undefined;
		},
		[formatMessage],
	);

	const validateIsInteger = useCallback(
		(value?: number | string) => {
			if (!Number.isInteger(Number(value))) {
				return formatMessage(messages.fieldIsIntegerError);
			}
			if (Number(value) > MAX_NUMBER) {
				return formatMessage(messages.votesMaxNumberTypeError);
			}
			return undefined;
		},
		[formatMessage],
	);

	const validateCustomFormula = useCallback(
		(
			fieldTypeValue: string | null | undefined,
			formulaToValidate: DynamicFieldFormula | null | undefined,
			inputCount: number,
		) => {
			if (fieldTypeValue !== FIELD_TYPES.FORMULA) {
				return undefined;
			}
			// @ts-expect-error - TS2339 - Property 'expression' does not exist on type 'DynamicFieldFormula.parameters' which is a Union type.
			if (formulaToValidate?.parameters?.expression?.match(dotStartingDecimalRegex)) {
				return formatMessage(messages.customFormulaDecimalError);
			}
			if (formulaToValidate === undefined || inputCount === 0) {
				return formatMessage(messages.customFormulaInputError);
			}
			return undefined;
		},
		[formatMessage],
	);

	const handleFormulaChange = useCallback(
		(updatedFormula: DynamicFieldFormula | undefined, updatedNumInputs: number) => {
			setFormula(updatedFormula);
			setNumInputs(updatedNumInputs);
		},
		[],
	);

	useEffect(() => {
		handleFormulaChange(undefined, 0);
	}, [formulaOption, handleFormulaChange]);

	const onMaxSpendValueChange =
		(onFormFieldChange: (arg1: ChangeEvent<HTMLInputElement>) => void) =>
		(event: ChangeEvent<HTMLInputElement>) => {
			onFormFieldChange(event);
			setMaxSpend(parseInt(event.currentTarget.value, 10));
		};

	const handleSubmit = useCallback(
		async (
			formContent: FormValues,
		): Promise<
			| {
					fieldName?: string;
					fieldType?: string;
					appProperty?: string;
					formulaType?: string;
					maxSpend?: string;
			  }
			| undefined
		> => {
			fireUIAnalytics(createAnalyticsEvent({}), 'createFieldSubmitButton clicked', 'view-controls');

			const errors = {
				fieldName: validateFieldName(formContent.fieldName),
				fieldType: validateFieldType(formContent.fieldType),
				appProperty: validateAppProperty(formContent.fieldType?.value, formContent.appProperty),
				formulaType: validateCustomFormula(formContent.fieldType?.value, formula, numInputs),
			};

			if (Object.values(errors).some((val) => val !== undefined)) {
				return errors;
			}

			// fixing typescript (already checked in validations)
			if (formContent.fieldName === undefined || formContent.fieldType === undefined) {
				return;
			}

			setIsSubmitLoading(true);

			try {
				let newField: Field;
				if (formContent.fieldType.value === FIELD_TYPES.FORMULA) {
					if (formContent.formulaType?.value === 'rollup' && formula) {
						experience.fieldsSidebar.fieldFormulaCreate.start();
						newField = await createCalculatedField(formContent.fieldName, formula);
					}
				} else if (formContent.fieldType.value === PolarisIdeaAppPropertyFieldType) {
					if (formContent.appProperty?.value !== undefined) {
						experience.fieldsSidebar.fieldFormulaCreate.start();
						const appName = getAppName(
							formContent.appProperty.app.oauthClientId,
							formContent.appProperty.app.name,
						);
						newField = await createCalculatedField(
							formContent.fieldName,
							createFormula([
								formContent.appProperty.isNumDataPoints === true
									? toNumDataPointsRollupField(
											formContent.appProperty.label,
											appName,
											formContent.appProperty.app.oauthClientId,
											formContent.appProperty.app.avatarUrl,
										)
									: toPropertyRollupField(
											formContent.appProperty.value,
											appName,
											formContent.appProperty.app.oauthClientId,
											formContent.appProperty.app.avatarUrl,
										),
							]),
						);
					}
				} else if (formContent.fieldType.value === FIELD_TYPES.VOTES) {
					experience.fieldsSidebar.fieldPlayCreate.start();
					const newPlay = await createPlayField(formContent.fieldName, maxSpend);
					if (newPlay) {
						newField = newPlay;
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						createPlay(newPlay.play!);
					}
				} else {
					experience.fieldsSidebar.fieldRegularCreate.start();
					newField =
						formContent.fieldType.value === FIELD_TYPES.CONNECTION
							? await createConnectionField(formContent.fieldName, formContent.fieldType.value)
							: await createField(formContent.fieldName, formContent.fieldType.value);
				}

				experience.fieldsSidebar.fieldFormulaCreate.success();
				experience.fieldsSidebar.fieldPlayCreate.success();
				experience.fieldsSidebar.fieldRegularCreate.success();

				fireCompoundAnalyticsEvent.Field.createdSuccess(createAnalyticsEvent({}), {
					// the newField variables are guaranteed to be defined here but typescript cannot infer that
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					issueFieldKey: newField!.key,
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					issueFieldType: newField!.type,
				});

				if (highlightCreateField) {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					setHighlightedFields([newField!.key]);
				}

				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				onOpenFieldConfig(newField!.key);

				defer(() => onFieldCreate(newField));
			} catch (err) {
				const error = err instanceof Error ? err : new Error('Unknown error');

				fireCompoundAnalyticsEvent.Field.createdFailure(
					createAnalyticsEvent({}),
					formContent.fieldType.value,
				);

				const isDuplicateNameError = error instanceof DuplicateNameError;
				const isEntityLimitError = error instanceof EntityLimitError;
				const notAllowed = isPermissionError(error);
				const isClientError = isClientFetchError(error);

				// ignore duplicate field name, permissions errors, entity limit errors and client fetch errors for SLO metric
				if (!isDuplicateNameError && !isEntityLimitError && !notAllowed && !isClientError) {
					experience.fieldsSidebar.fieldFormulaCreate.failure(error.message);
					experience.fieldsSidebar.fieldPlayCreate.failure(error.message);
					experience.fieldsSidebar.fieldRegularCreate.failure(error.message);
				} else {
					experience.fieldsSidebar.fieldFormulaCreate.abort(error.message);
					experience.fieldsSidebar.fieldPlayCreate.abort(error.message);
					experience.fieldsSidebar.fieldRegularCreate.abort(error.message);
				}

				if (notAllowed) {
					generalDataUpdateFailedError(error);
				}

				const errorMessage = getCreateErrorMessage(isEntityLimitError, isDuplicateNameError);
				return { fieldName: formatMessage(errorMessage) };
			} finally {
				defer(() => setIsSubmitLoading(false));
			}
			return undefined;
		},
		[
			createAnalyticsEvent,
			validateFieldName,
			validateFieldType,
			validateAppProperty,
			validateCustomFormula,
			formula,
			numInputs,
			highlightCreateField,
			onOpenFieldConfig,
			createCalculatedField,
			createPlayField,
			maxSpend,
			createPlay,
			createField,
			setHighlightedFields,
			onFieldCreate,
			formatMessage,
			generalDataUpdateFailedError,
			createConnectionField,
		],
	);

	const renderExpressionTypeIn = useCallback(
		() => (
			<ExpressionTypeIn
				fields={fields}
				onChange={handleFormulaChange}
				readonly={false}
				onThrottledFetchSnippetProviders={throttledFetchSnippetProviders}
			/>
		),
		[fields, handleFormulaChange, throttledFetchSnippetProviders],
	);

	const renderRollup = useCallback(
		() => (
			<Rollup
				fieldsByKey={visibleFieldsByKey}
				snippetProviders={snippetProviders}
				onChange={handleFormulaChange}
				initAsWeightedScore={formulaOption?.value === 'weightedScore'}
				readonly={false}
				onThrottledFetchSnippetProviders={throttledFetchSnippetProviders}
				sortedSnippetLabels={sortedSnippetLabels}
				displayGlobalFieldIcons
			/>
		),
		[
			visibleFieldsByKey,
			formulaOption?.value,
			handleFormulaChange,
			throttledFetchSnippetProviders,
			snippetProviders,
			sortedSnippetLabels,
		],
	);

	const defaultFieldTypeValue = fieldTypeOptionGroups
		.map(({ options }) => options)
		.flat()
		.find(({ value }) => value === preselectedField?.type);

	const createFieldForm = useMemo(
		() => (
			<CreateFieldForm<FieldTypeOption, FormValues>
				testId="polaris-common.ui.config.fields.create-field"
				defaultFieldName={defaultFieldName}
				defaultFieldTypeValue={defaultFieldTypeValue}
				fieldTypeOptionGroups={fieldTypeOptionGroups}
				formId={CREATE_FIELD_FORM_ID}
				isFieldTypeSelected={(option, selectedOptions) => {
					if (selectedOptions.length > 0) {
						return (
							option.value === selectedOptions[0].value &&
							(option.app === undefined ||
								option.app.oauthClientId === selectedOptions?.[0]?.app?.oauthClientId)
						);
					}
					return false;
				}}
				onFieldTypeChange={(type: FieldTypeOption | undefined) => {
					setFieldType(type?.value);
					setApp(type?.app);
					setAppProperty(undefined);
				}}
				onSubmit={handleSubmit}
				validators={{
					fieldName: validateFieldName,
					fieldType: validateFieldType,
				}}
				renderFieldTypeOption={(props) => <OptionComponent {...props} />}
				renderAdditionalFields={() => (
					<>
						{fieldType === FIELD_TYPES.FORMULA && (
							<>
								<FormField
									name="formulaType"
									testId="polaris-common.ui.config.fields.create-field.formula-type"
									label={
										<FieldLabelContainer>
											{formatMessage(messages.customFormulaTypeLabel)}
										</FieldLabelContainer>
									}
									defaultValue={customFormulaOptions[0]}
								>
									{({ fieldProps, error }) => (
										<Box testId="polaris-common.ui.config.fields.create-field.formula-type">
											<Select
												{...fieldProps}
												formatOptionLabel={customFormulaOptionComponent}
												options={customFormulaOptions}
												classNamePrefix="formula-type-select"
												// eslint-disable-next-line @typescript-eslint/no-explicit-any
												onChange={(value: any) => {
													setFormulaOption(value);
												}}
												value={formulaOption}
											/>
											{formulaOption?.value === 'expression' ? (
												renderExpressionTypeIn()
											) : (
												<>
													{renderRollup()}
													{error !== undefined && numInputs === 0 && (
														<ErrorContainer>{error}</ErrorContainer>
													)}
												</>
											)}
										</Box>
									)}
								</FormField>
							</>
						)}
						{fieldType === PolarisIdeaAppPropertyFieldType && (
							<>
								<FormField
									name="appProperty"
									isRequired
									label={
										<FieldLabelContainer>
											{formatMessage(messages.dataTypeLabel)}
										</FieldLabelContainer>
									}
									defaultValue={appProperty}
								>
									{({ fieldProps, error }) => (
										<>
											<Select
												{...fieldProps}
												styles={customStyles}
												placeholder={formatMessage(messages.dataTypePlaceholder)}
												formatOptionLabel={appPropertyOptionComponent}
												options={appPropertyOptions}
												// eslint-disable-next-line @typescript-eslint/no-explicit-any
												onChange={(value: any) => {
													fieldProps.onChange(value);
													setAppProperty(value);
												}}
												initialValue={appProperty}
												value={fieldProps.value || null}
											/>
											{error !== undefined && numInputs === 0 && (
												<ErrorContainer>{error}</ErrorContainer>
											)}
										</>
									)}
								</FormField>
							</>
						)}
						{fieldType === FIELD_TYPES.VOTES && (
							<Fieldset
								legend={
									<FieldLabelContainer>{formatMessage(messages.voteHeader)}</FieldLabelContainer>
								}
							>
								<FormField
									name="maxSpend"
									isRequired
									defaultValue={DEFAULT_MAX_SPEND}
									validate={validateIsInteger}
								>
									{({ fieldProps: { onChange: onFieldChange, ...otherFieldProps }, error }) => (
										<>
											<Flex alignItems="center">
												<Textfield
													{...otherFieldProps}
													testId="polaris-common.ui.config.fields.create-field.vote-amount"
													value={maxSpend}
													appearance="standard"
													type="number"
													onChange={onMaxSpendValueChange(onFieldChange)}
													label={formatMessage(messages.settingsMaxSpendPerUser)}
													min={1}
													isInvalid={!!error}
												/>
											</Flex>

											{error !== undefined && <ErrorContainer>{error}</ErrorContainer>}
										</>
									)}
								</FormField>

								<VotingHint />
							</Fieldset>
						)}
					</>
				)}
				renderControls={(isSubmitting) =>
					isOpenedFromProjectFieldsPage ? (
						portalContainerRef.current &&
						createPortal(
							<Inline space="space.100">
								<Buttons onCancel={onCancel} isSubmitting={isSubmitting} fitButtonsInContainer />
							</Inline>,
							portalContainerRef.current,
						)
					) : (
						<ButtonGroup>
							<Buttons onCancel={onCancel} isSubmitting={isSubmitting} />
						</ButtonGroup>
					)
				}
			/>
		),
		[
			appProperty,
			appPropertyOptions,
			customFormulaOptions,
			defaultFieldName,
			defaultFieldTypeValue,
			fieldType,
			fieldTypeOptionGroups,
			formatMessage,
			formulaOption,
			handleSubmit,
			maxSpend,
			isOpenedFromProjectFieldsPage,
			numInputs,
			onCancel,
			validateFieldName,
			validateFieldType,
			validateIsInteger,
			renderExpressionTypeIn,
			renderRollup,
		],
	);

	return (
		<>
			{isOpenedFromProjectFieldsPage ? (
				<Stack xcss={containerStyles}>
					<RightSidebarHeader title={formatMessage(messages.heading)} onClose={onClose} />
					<RightSidebarBody>{createFieldForm}</RightSidebarBody>
					<RightSidebarFooter>
						<div ref={portalContainerRef} />
					</RightSidebarFooter>
				</Stack>
			) : (
				<Box paddingBlockStart="space.0" paddingBlockEnd="space.150" paddingInline="space.200">
					{createFieldForm}
				</Box>
			)}
		</>
	);
};

const buttonWrapperStyles = xcss({
	width: '50%',
});

const containerStyles = xcss({
	height: '100%',
	paddingBlockStart: 'space.300',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Option = styled.div({
	alignItems: 'center',
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SingleValueLabel = styled.div({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconStyle = styled.div({
	marginRight: token('space.100'),
	display: 'flex',
});
