import React from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import Avatar from '@atlaskit/avatar';
import PeopleIcon from '@atlaskit/icon/core/migration/people-group--people';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import Tooltip from '@atlaskit/tooltip';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { type Responder, RESPONDER_TYPE_USER } from '../../../../../types.tsx';
import messages from './messages.tsx';

export type Props = {
	responders: Responder[];
};

export const RespondersList = ({ responders }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<ListWrapper>
			{responders.map(({ ari, avatarUrl, name, type }) => (
				<ResponderContainer key={ari}>
					{type === RESPONDER_TYPE_USER ? (
						<>
							<AvatarContainer>
								<Avatar src={avatarUrl} size="small" />
							</AvatarContainer>
							{name}
						</>
					) : (
						<>
							<TeamIconContainer>
								<PeopleIcon
									color="currentColor"
									spacing="spacious"
									label={formatMessage(messages.teamIconLabel)}
								/>
							</TeamIconContainer>
							{!isEmpty(name) ? (
								name
							) : (
								<Tooltip
									testId="issue-history.ui.history-items.history-item.responders-history-item.responders-list.responders-list-tooltip"
									content={formatMessage(messages.noOpgenieAccessTeamNameTooltipMessage)}
								>
									<DisabledWrapper>{formatMessage(messages.noTeamNameMessage)}</DisabledWrapper>
								</Tooltip>
							)}
						</>
					)}
				</ResponderContainer>
			))}
		</ListWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DisabledWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.disabled', colors.N50),
	cursor: 'default',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ListWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResponderContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	marginBottom: token('space.075'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3.5}px`,
	marginRight: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TeamIconContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 3}px`,
	marginRight: token('space.100'),
	borderRadius: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral', colors.N30),
});
