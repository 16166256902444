/* eslint-disable jira/js/no-reduce-accumulator-spread */

import React, {
	useState,
	Children,
	isValidElement,
	cloneElement,
	useEffect,
	useCallback,
} from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EditorAddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import { Box, xcss } from '@atlaskit/primitives';
import { components } from '@atlaskit/select';
import { N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useCanEditFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { CreateOptionEntry } from '@atlassian/jira-polaris-lib-option-utils/src/ui/option-entry/index.tsx';
import { SearchBoxComponent } from '@atlassian/jira-polaris-lib-search-box/src/ui/index.tsx';
import type { SelectOption } from '../types.tsx';
import messages from './messages.tsx';

export const HAS_MORE_GLOBAL_LABELS_FLAG_ID = 'jpd_has_more_options_system_id';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MenuListComponentProps = any;

// this function needed for align format of data with new version react-select(@atlaskit/select)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformToFilterOption = (option: any) => ({
	label: '',
	value: option.value,
	data: {},
});

type CreateMenuListComponentProps = {
	showCreateButton: boolean;
	showEditButton?: boolean;
	onAddOption?: (arg1: string) => Promise<string | undefined>;
	onConfigRequested?: () => void;
	showOnlyConfig?: boolean;
};

type ConfigFieldComponentProps = {
	canEditFields: boolean;
	onClickConfig: () => void;
};

const ConfigFieldComponent = ({ canEditFields, onClickConfig }: ConfigFieldComponentProps) => {
	const { formatMessage } = useIntl();

	return (
		<EditEntryWrapper>
			<Button
				appearance="subtle-link"
				onClick={onClickConfig}
				interactionName="jpd.select-field-dropdown.edit-field"
			>
				{!canEditFields ? formatMessage(messages.fieldSettings) : formatMessage(messages.editField)}
			</Button>
		</EditEntryWrapper>
	);
};

export const createMenuListComponent =
	({
		showCreateButton,
		showEditButton,
		onAddOption,
		onConfigRequested,
		showOnlyConfig = false,
	}: CreateMenuListComponentProps) =>
	(props: MenuListComponentProps) => {
		const { children, selectProps, options } = props;
		const { inputValue, filterOption, onInputChange, onMenuInputFocus, isFocused } = selectProps;
		const canEditFields = useCanEditFields();
		const [createdId, setCreatedId] = useState<unknown>(undefined);
		const [menuListRef, setMenuListRef] = useState<Element | undefined>(undefined);
		const { formatMessage } = useIntl();

		useEffect(() => {
			if (onMenuInputFocus) {
				onMenuInputFocus();
			}
		}, [onMenuInputFocus]);

		const onClickConfig = useCallback(() => {
			// removing onclick event from the params
			onConfigRequested?.();
		}, []);

		if (showOnlyConfig) {
			// is last option in "unselected" section special "option-flag"?
			const moreOptionsAvailable =
				props.options?.[1]?.options?.at(-1)?.id === HAS_MORE_GLOBAL_LABELS_FLAG_ID;

			return (
				<>
					<Box xcss={optionsGroupWrapperStyles}>
						{children}
						{moreOptionsAvailable && (
							<Box xcss={moreLabelsNotificationStyles}>{formatMessage(messages.searchHint)}</Box>
						)}
					</Box>
					{showEditButton && (
						<ConfigFieldComponent canEditFields={canEditFields} onClickConfig={onClickConfig} />
					)}
				</>
			);
		}

		const isNoOptionVisible =
			options
				.reduce(
					// @ts-expect-error - TS7006 - Parameter 'acc' implicitly has an 'any' type. | TS7031 - Binding element 'nestedOptions' implicitly has an 'any' type.
					(acc, { options: nestedOptions }) => [...acc, ...nestedOptions],
					[],
				)
				// @ts-expect-error - TS7006 - Parameter 'option' implicitly has an 'any' type.
				.filter((option) => filterOption(transformToFilterOption(option), inputValue)).length === 0;

		const { innerRef, ...rest } = props;

		const handleOptionAdded = () => {
			if (menuListRef !== undefined) {
				menuListRef.scrollTo(0, menuListRef.scrollHeight);
			}
		};

		const updatedMenuListComponent = (
			<components.MenuList
				{...rest}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				ref={(ref: any) => {
					setMenuListRef(ref);
					innerRef(ref);
				}}
			>
				{Children.map(children, (child) => {
					if (isValidElement(child)) {
						// @ts-expect-error - TS2339 - Property 'data' does not exist on type 'unknown'.
						const { data } = child.props;
						// can happen during creation on an isNoOptionVisible list
						if (data !== undefined) {
							const { id } = data;
							if (id === createdId) {
								// @ts-expect-error - TS2769 - No overload matches this call.
								return cloneElement(child, { isNewlyCreated: true });
							}
						}
					}
					return child;
				})}
			</components.MenuList>
		);

		return (
			<>
				<SearchBoxWrapper>
					<SearchBoxComponent
						value={inputValue}
						onChange={(val) =>
							onInputChange(val, {
								action: 'input-change',
							})
						}
						onFocus={onMenuInputFocus}
						isFocused={isFocused}
					/>
				</SearchBoxWrapper>
				{!isNoOptionVisible && (
					<MenuListWrapper>
						<ShadowElementTop />
						{updatedMenuListComponent}
					</MenuListWrapper>
				)}
				{canEditFields && showCreateButton && (
					<CreateOptionEntry
						existingOptionValues={options.map((option: SelectOption) => option.value)}
						setCreatedId={setCreatedId}
						inputValue={inputValue}
						onAddOption={onAddOption}
						onOptionAdded={handleOptionAdded}
					/>
				)}
				{showEditButton && (
					<ConfigFieldComponent canEditFields={canEditFields} onClickConfig={onClickConfig} />
				)}
			</>
		);
	};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NoOptionsMessageProps = any;

export const createNoOptionCreate =
	(onAddOption?: (arg1: string) => Promise<string | undefined>) =>
	(props: NoOptionsMessageProps) => {
		const { formatMessage } = useIntl();
		const { selectProps, options } = props;
		const { inputValue, filterOption } = selectProps;

		const isNoOptionVisible =
			// @ts-expect-error - TS7006 - Parameter 'option' implicitly has an 'any' type.
			options.filter((option) => filterOption(transformToFilterOption(option), inputValue))
				.length === 0;

		return (
			<NoOptionCreateEntryWrapper {...props} isStandalone={isNoOptionVisible}>
				<NoOptionCreateButtonWrapper>
					<Button
						testId="polaris-common.ui.common.decoration.select.menu-list.button"
						onClick={() => {
							if (onAddOption !== undefined) {
								onAddOption(inputValue);
							}
						}}
						iconBefore={<EditorAddIcon label="add" LEGACY_size="small" />}
						appearance="subtle-link"
					>
						{formatMessage(messages.addValueBasedOnSearch, { value: inputValue })}
					</Button>
					<KeyboardKey>{formatMessage(messages.enterKey)}</KeyboardKey>
				</NoOptionCreateButtonWrapper>
			</NoOptionCreateEntryWrapper>
		);
	};

const optionsGroupWrapperStyles = xcss({
	maxHeight: '260px',
	overflowY: 'auto',
});

const moreLabelsNotificationStyles = xcss({
	width: 'auto',
	paddingTop: 'space.0',
	paddingBottom: 'space.075',
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
	display: 'flex',
	alignItems: 'center',
	color: 'color.text.subtle',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuListWrapper = styled.div({
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShadowElement = styled.div({
	position: 'relative',
	zIndex: 7,
	height: '4px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShadowElementTop = styled(ShadowElement)({
	boxShadow: `0px 8px 8px -8px ${token('elevation.surface')} inset`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditEntryWrapper = styled.div({
	borderTop: `1px solid ${token('color.border', N40)}`,
	paddingTop: token('space.100'),
	paddingRight: token('space.025'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SearchBoxWrapper = styled.div({
	marginTop: token('space.150'),
	marginRight: token('space.150'),
	marginBottom: 0,
	marginLeft: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoOptionCreateEntryWrapper = styled.div({
	flexDirection: 'column',
	display: 'flex',
	paddingTop: token('space.100'),
	paddingRight: token('space.025'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoOptionCreateButtonWrapper = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		minWidth: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const KeyboardKey = styled.div({
	paddingTop: token('space.025'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.025'),
	paddingLeft: token('space.050'),
	font: token('font.body.small'),
	borderRadius: '3px',
	fontWeight: token('font.weight.semibold'),
	marginRight: token('space.100'),
	color: token('color.text.disabled'),
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: `${token('color.border.disabled')}`,
	backgroundColor: `1px solid ${token('color.background.input')}`,
});
