import React, { useState } from 'react';
import differenceBy from 'lodash/differenceBy';
import type { FilterOptionOption } from '@atlaskit/react-select';
import Select, {
	type SelectProps,
	type SelectComponentsConfig,
	type StylesConfig,
	type GroupedOptionsType,
} from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { MAX_OPTIONS } from '../edit/constants.tsx';
import { ConnectionFieldSelectContext } from '../edit/context/index.tsx';
import { ConnectionFieldGroupHeading } from '../edit/group-heading/index.tsx';
import { ConnectionFieldMenuList } from '../edit/menu-list/index.tsx';
import commonMessages from '../edit/messages.tsx';
import { NoOptionsMessage } from '../edit/no-options-message/index.tsx';
import { ConnectionFieldIssueOption } from '../edit/option/index.tsx';
import { isCreatableOption, type ConnectionFieldOption } from '../types.tsx';
import { filterOption, useConnectionFieldOptions, useCreatableOptions } from '../utils.tsx';
import { ControlComponent } from './control/index.tsx';

type Props = {
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
	menuPortalTarget?: HTMLElement;
};

const customComponents: SelectComponentsConfig<ConnectionFieldOption> = {
	Control: ControlComponent,
	MenuList: ConnectionFieldMenuList,
	Option: ConnectionFieldIssueOption,
	IndicatorsContainer: () => null,
	GroupHeading: ConnectionFieldGroupHeading,
	NoOptionsMessage,
};

export const ConnectionFieldEditableSingleSelect = ({
	localIssueId,
	fieldKey,
	menuPortalTarget,
}: Props) => {
	const { formatMessage } = useIntl();
	const [allIssues, connectedIssues] = useConnectionFieldOptions(localIssueId, fieldKey);
	const [inputValue, setInputValue] = useState('');
	const creatableOptions = useCreatableOptions(fieldKey);
	const { updateIssueConnections, createAndConnect } = useIssueActions();
	const [isInCreation, setIsInCreation] = useState(false);

	const allAvailableOptions = differenceBy(
		allIssues.filter((issue) => filterOption(issue, inputValue)),
		connectedIssues,
		'value',
	);
	const projectOptions = allAvailableOptions.slice(0, MAX_OPTIONS);

	const localOptions: GroupedOptionsType<ConnectionFieldOption> = [
		{
			label: formatMessage(commonMessages.projectGroupHeadingNonFinal),
			options: inputValue ? [...projectOptions, ...creatableOptions] : projectOptions,
		},
	];

	const handleChange: SelectProps<ConnectionFieldOption>['onChange'] = async (newValue) => {
		if (!newValue) {
			return;
		}

		if (isCreatableOption(newValue)) {
			setIsInCreation(true);
			await createAndConnect({
				localIssueIdToConnect: localIssueId,
				summary: inputValue,
				issueType: newValue.issueType,
				analyticsSource: 'connectionsFieldDropdown',
			});
			setIsInCreation(false);
		} else {
			updateIssueConnections({
				localIssueId,
				issuesToConnect: [
					{
						id: newValue.value,
					},
				],
			});
		}
	};

	return (
		<ConnectionFieldSelectContext.Provider value={{ isInCreation }}>
			<Select<ConnectionFieldOption>
				isSearchable
				isClearable
				hideSelectedOptions
				components={customComponents}
				onChange={handleChange}
				value={null}
				onInputChange={setInputValue}
				inputValue={inputValue}
				options={localOptions}
				filterOption={(option: FilterOptionOption<ConnectionFieldOption | null>) => !!option.data}
				enableAnimation={false}
				menuPlacement="auto"
				maxMenuHeight={280}
				styles={stylesConfig}
				menuPortalTarget={menuPortalTarget}
				placeholder=""
				closeMenuOnSelect
				blurInputOnSelect
			/>
		</ConnectionFieldSelectContext.Provider>
	);
};

const stylesConfig: StylesConfig<ConnectionFieldOption> = {
	menu: (styles) => ({
		...styles,
		minWidth: '400px',
		marginTop: '-6px',
		marginBottom: '-6px',
		zIndex: 100,
		width: 'calc(100% - 8px)',
		right: '4px',
	}),
	menuList: (styles) => ({
		...styles,
		padding: '8px 0',
	}),
	group: (styles) => ({
		...styles,
		padding: '6px 0',
	}),
	groupHeading: (styles) => ({
		...styles,
		marginBottom: '7px',
		'&:empty': {
			display: 'none',
		},
	}),
	control: (styles) => ({
		...styles,
		border: 0,
		'&:focus-within': {
			boxShadow: `inset 0 0 0 2px ${token('color.border.focused', '#4C9AFF')}`,
		},
	}),
};
