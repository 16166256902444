import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-ideas.idea-view.sections.deliver.empty-state.title',
		defaultMessage: 'Deliver the idea',
		description: 'Title for delivery empty state',
	},
	description: {
		id: 'polaris-ideas.idea-view.sections.deliver.empty-state.description',
		defaultMessage: 'Create or link epics to track work related to this idea.',
		description:
			'Description displayed when no work items (Jira issues) are yet linked to this Idea (Issue)',
	},
	learnMoreLink: {
		id: 'polaris-ideas.idea-view.sections.deliver.empty-state.learn-more-link',
		defaultMessage: 'Learn how to link ideas to epic',
		description: 'Link capture to open help panel with article about delivery tab',
	},
	descriptionReadOnly: {
		id: 'polaris-ideas.idea-view.sections.deliver.empty-state.description-read-only',
		defaultMessage:
			'When this idea is in delivery, you will be able to track its progress here. Check back in later!',
		description:
			'Description displayed when no work items (Jira issues) are yet linked to this Idea (Issue) and the current user does have read-only permissions',
	},
	descriptionArchived: {
		id: 'polaris-ideas.idea-view.sections.deliver.empty-state.description-archived',
		defaultMessage:
			'The idea was archived before anyone linked delivery tickets to it. To link a delivery ticket, you must restore the idea first.',
		description:
			'Description displayed when no delivery tickets (Jira issues) are yet linked to this Idea (Issue) and the current idea is archived',
	},
	descriptionArchivedIssueTermRefresh: {
		id: 'polaris-ideas.idea-view.sections.deliver.empty-state.description-archived-issue-term-refresh',
		defaultMessage:
			'The idea was archived before anyone linked delivery work items to it. To link a delivery work item, you must restore the idea first.',
		description:
			'Description displayed when no delivery work items (Jira issues) are yet linked to this Idea (Issue) and the current idea is archived',
	},
	createDeliveryTicket: {
		id: 'polaris-ideas.idea-view.sections.deliver.empty-state.create-delivery-ticket',
		defaultMessage: 'Create delivery ticket',
		description: 'Button caption for creating a delivery ticket and link it to this idea.',
	},
	createDeliveryTicketIssueTermRefresh: {
		id: 'polaris-ideas.idea-view.sections.deliver.empty-state.create-delivery-ticket-issue-term-refresh',
		defaultMessage: 'Create a work item',
		description: 'Button caption for creating a delivery work item and link it to this idea.',
	},
	linkToIssue: {
		id: 'polaris-ideas.idea-view.sections.deliver.empty-state.link-to-issue',
		defaultMessage: 'Link existing ticket',
		description: 'Button caption for linking an existing Jira work item to this idea.',
	},
	linkToIssueIssueTermRefresh: {
		id: 'polaris-ideas.idea-view.sections.deliver.empty-state.link-to-issue-issue-term-refresh',
		defaultMessage: 'Link existing work item',
		description: 'Button caption for linking an existing Jira work item to this idea.',
	},
});
