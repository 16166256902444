import React, { type PropsWithChildren, type MouseEvent, memo, useCallback } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip, { TooltipPrimitive, type TooltipPrimitiveProps } from '@atlaskit/tooltip';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { VIEW_KIND_TIMELINE } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import {
	ContextualAnalyticsData,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { shouldOpenInNewTab } from '../../common/utils/events/index.tsx';
import { getIssueLink } from '../../common/utils/issue-link/index.tsx';
import { useIsSharedView } from '../../controllers/environment/index.tsx';
import {
	useSafeIssueKey,
	useIssueAnalitycsAttributes,
	useLinkedDeliveryIssuesExist,
	useSelectedIssue,
} from '../../controllers/issue/selectors/properties/hooks.tsx';
import { useRightSidebarActions } from '../../controllers/right-sidebar/actions/hooks.tsx';
import { usePolarisRouter } from '../../controllers/route/index.tsx';
import {
	useCurrentViewKind,
	useCurrentViewVisibleIssueActionFields,
} from '../../controllers/views/selectors/view-hooks.tsx';
import {
	ROW_GAP,
	TOOLTIP_DELAY,
	CARD_SUMMARY_TOOLTIP_WIDTH,
	CARD_HORIZONTAL_PADDING,
	CARD_PADDING,
	DISPLAYING_FIELDS_MIN_CARD_WIDTH,
	SUMMARY_CARD_RIGHT_PADDING,
} from './constants.tsx';
import { DetailedCard } from './detailed-card/index.tsx';
import { SummaryCard } from './summary-card/index.tsx';
import type { IdeaCardProps } from './types.tsx';
import { getCardBottomPadding, useIdeaCardContext, useSummaryTooltip } from './utils.tsx';

const IdeaCardAnalyticsWrapper = memo((props: PropsWithChildren<Pick<IdeaCardProps, 'id'>>) => {
	const analyticsData = useIssueAnalitycsAttributes(props.id);

	return (
		<ContextualAnalyticsData attributes={analyticsData}>{props.children}</ContextualAnalyticsData>
	);
});

IdeaCardAnalyticsWrapper.displayName = 'IdeaCardAnalyticsWrapper';

const withIdeaAnalyticsContext =
	<P extends Pick<IdeaCardProps, 'id'>>(Component: React.ComponentType<P>) =>
	(props: P) => (
		<IdeaCardAnalyticsWrapper id={props.id}>
			<Component {...props} />
		</IdeaCardAnalyticsWrapper>
	);

export const CONTAINER_CLASS_NAME = 'idea-card';

export const IdeaCard = withIdeaAnalyticsContext(
	memo<IdeaCardProps>((props: IdeaCardProps) => {
		const {
			id,
			isFillFullWidth = false,
			isDisabled = false,
			isFocused = false,
			isResizing = false,
			isHovered = false,
			hasHoverState = false,
			isGrayedOut = false,
			hoverBackgroundColor,
			viewLayoutType,
		} = props;

		const isSharedView = useIsSharedView();
		const actionFields = useCurrentViewVisibleIssueActionFields();
		const issueKey = useSafeIssueKey(id);
		const deliveryIssuesExist = useLinkedDeliveryIssuesExist(id);
		const { deliveryProgressField, deliveryStatusField } = useIdeaCardContext();
		const projectKey = useProjectKeyUnsafe();
		const analyticsData = useIssueAnalitycsAttributes(id);
		const [, { close }] = useRightSidebarActions();
		const { openIssueView } = usePolarisRouter();
		const selectedIssue = useSelectedIssue();
		const isHighlighted = selectedIssue === id;
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const viewKind = useCurrentViewKind();
		const { summaryTooltipHidden, hideSummaryTooltip, showSummaryTooltip } = useSummaryTooltip();

		const isCompact = viewLayoutType === ViewLayoutType.COMPACT;
		const isSummary = viewLayoutType === ViewLayoutType.SUMMARY;
		const hasActionFields = actionFields.length > 0;
		const shouldShowDeliveryStatus = deliveryStatusField && deliveryIssuesExist;
		const shouldShowDeliveryProgress = deliveryProgressField && deliveryIssuesExist;
		const paddingBottom = getCardBottomPadding(
			isCompact,
			hasActionFields,
			!!shouldShowDeliveryProgress || !!shouldShowDeliveryStatus,
		);
		const paddingRight =
			viewLayoutType === ViewLayoutType.SUMMARY
				? SUMMARY_CARD_RIGHT_PADDING
				: CARD_HORIZONTAL_PADDING;

		const handleClick = useCallback(
			(e: MouseEvent<HTMLElement>) => {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				if ((e.target as HTMLElement).tagName === 'A' || isDisabled || !issueKey) {
					return;
				}
				e.preventDefault();

				fireUIAnalytics(
					createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
					'ideaCard',
					analyticsData ?? {},
				);
				sendPendoTrackEvent({
					actionSubjectAndAction: 'button clicked',
					actionSubjectId: 'ideaCard',
				});

				const linkUrl = getIssueLink(projectKey, issueKey, isSharedView);

				if (shouldOpenInNewTab(e) && linkUrl) {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					window.open(linkUrl, '_blank');
					return;
				}

				// Closing existing sidebar here to set sidebarShowing.mode === "NOTHING"
				close();
				openIssueView(issueKey, { layout: 'sidebar' });
			},
			[
				isDisabled,
				issueKey,
				createAnalyticsEvent,
				projectKey,
				close,
				openIssueView,
				analyticsData,
				isSharedView,
			],
		);

		const cardContent = (
			<Container
				id="pendo.idea-card.container-button"
				data-testid="polaris-common.ui.idea-card-v2.container"
				// used by image export
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={CONTAINER_CLASS_NAME}
				onClick={handleClick}
				isHighlighted={isHighlighted}
				isDisabled={isDisabled}
				isFocused={isFocused}
				isHovered={isHovered}
				isResizing={isResizing}
				isSummary={isSummary}
				hoverBackgroundColor={hoverBackgroundColor}
				hasHoverState={hasHoverState}
				isGrayedOut={isGrayedOut}
				data-component-selector="container-J2q9"
				shouldHideFieldsWhenSmall={viewKind === VIEW_KIND_TIMELINE}
			>
				<InnerContainer
					isSummary={isSummary}
					paddingRight={paddingRight}
					paddingBottom={paddingBottom}
				>
					{isSummary ? (
						<SummaryCard
							{...props}
							onHideSummaryTooltip={hideSummaryTooltip}
							onShowSummaryTooltip={showSummaryTooltip}
						/>
					) : (
						<DetailedCard {...props} />
					)}
				</InnerContainer>
			</Container>
		);

		return isSummary ? (
			<Tooltip
				content={
					!isResizing &&
					!summaryTooltipHidden && (
						<TooltipContentWrapper>
							<DetailedCard {...props} isHovered={false} isDisabled isSummary hasMultilineStrings />
						</TooltipContentWrapper>
					)
				}
				component={TooltipComponent}
				delay={TOOLTIP_DELAY}
				position={isFillFullWidth ? 'bottom-end' : 'right-start'}
				hideTooltipOnClick
			>
				{cardContent}
			</Tooltip>
		) : (
			cardContent
		);
	}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{
	isHighlighted?: boolean;
	isFocused?: boolean;
	isDisabled?: boolean;
	isHovered?: boolean;
	isResizing?: boolean;
	hoverBackgroundColor?: string;
	hasHoverState?: boolean;
	isGrayedOut?: boolean;
	isSummary?: boolean;
	shouldHideFieldsWhenSmall: boolean;
}>({
	/* eslint-disable no-nested-ternary */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isHighlighted, isFocused, isHovered }) =>
		isHighlighted
			? token('color.background.selected')
			: isFocused
				? token('color.background.accent.blue.subtlest')
				: isHovered
					? token('elevation.surface.hovered')
					: token('elevation.surface'),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ isHighlighted, hasHoverState, hoverBackgroundColor, isResizing }) =>
			isResizing
				? undefined
				: isHighlighted
					? token('color.background.selected.hovered')
					: hasHoverState && hoverBackgroundColor
						? hoverBackgroundColor
						: undefined,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		boxShadow: ({ hasHoverState, hoverBackgroundColor, isResizing }) =>
			hasHoverState && !isResizing && !hoverBackgroundColor
				? '0 8px 16px -6px rgba(9,30,66,0.25),0 0 1px 1px rgba(9,30,66,0.13)'
				: undefined,
	},

	// amount-wrapper-aw1 and content-wrapper-aw1 are coming from : src/packages/polaris/apps/common/src/ui/idea-card-v2/body/connection/label-item/index.tsx
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'&:hover [data-component-selector="amount-wrapper-aw1"]': {
		backgroundColor: `${token('color.background.accent.gray.subtlest.hovered')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'&:hover [data-component-selector="content-wrapper-aw1"]': {
		backgroundColor: `${token('color.background.accent.gray.subtlest.pressed')}`,
	},

	/* eslint-enable no-nested-ternary */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transition: ({ isFocused }) => `box-shadow 0.3s ease${isFocused ? ', background 0.3s ease' : ''}`,
	borderRadius: token('border.radius.050', '4px'),
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	boxShadow: ({ isHighlighted }) =>
		isHighlighted
			? `${token(
					'elevation.shadow.raised',
					'0 1px 1px rgba(9,30,66,0.25),0 0 1px 1px rgba(9,30,66,0.13)',
				)}, inset 0px 0px 0px 1px ${token('color.border.selected')}`
			: token(
					'elevation.shadow.raised',
					'0 1px 1px rgba(9,30,66,0.25),0 0 1px 1px rgba(9,30,66,0.13)',
				),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isDisabled }) => !isDisabled && 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > *': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		filter: ({ isGrayedOut }) => isGrayedOut && 'saturate(0)',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	containerType: ({ shouldHideFieldsWhenSmall }) => shouldHideFieldsWhenSmall && 'inline-size',
	containerName: 'cardContainer',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InnerContainer = styled.div<{
	paddingBottom: number;
	paddingRight: number;
	isSummary?: boolean;
}>(
	{
		display: 'flex',
		flexDirection: 'column',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		padding: `${CARD_PADDING}px ${CARD_HORIZONTAL_PADDING}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingBottom: ({ paddingBottom }) => `${paddingBottom}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingRight: ({ paddingRight }) => `${paddingRight}px`,
		boxSizing: 'border-box',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		gap: `${ROW_GAP}px 0`,
		overflow: 'hidden',
		height: '100%',
		width: '100%',
		borderRadius: token('border.radius.050', '4px'),
		position: 'relative',

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-container-queries, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		[`@container cardContainer (max-width: ${DISPLAYING_FIELDS_MIN_CARD_WIDTH}px)`]: {
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
			paddingLeft: '10px',
			paddingRight: 0,
		},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isSummary }) =>
		isSummary && {
			paddingBottom: 0,
			paddingTop: 0,
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContentWrapper = styled.div({
	borderRadius: '4px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `${CARD_PADDING}px ${CARD_HORIZONTAL_PADDING}px`,
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipComponent = styled<TooltipPrimitiveProps>(TooltipPrimitive)({
	backgroundColor: token('elevation.surface'),
	transition: 'box-shadow 0.3s ease, background 0.3s ease',
	borderRadius: '4px',
	boxShadow: token(
		'elevation.shadow.overlay',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`0px 8px 12px 0px ${colors.N40A},
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        0px 0px 1px 0px ${colors.N60A}`,
	),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${CARD_SUMMARY_TOOLTIP_WIDTH}px`,
	overflow: 'hidden',
});
