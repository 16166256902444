import React, { useCallback } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { ConnectionField as ConnectionFieldComponent } from '@atlassian/jira-polaris-common/src/ui/fields/connection/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId, Value } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useCanConnectIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useIsIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';

type Props = {
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
	portalElement?: HTMLElement;
};

export const ConnectionField = ({ fieldKey, localIssueId, portalElement }: Props) => {
	const canConnectIssues = useCanConnectIssues();
	const isIssueArchived = useIsIssueArchived(localIssueId);
	const { updateFieldValue } = useIssueActions();

	const onFieldUpdate = useCallback(
		(newValue: Value) => {
			updateFieldValue({
				fieldKey,
				localIssueIds: [localIssueId],
				newValue,
			});
		},
		[fieldKey, localIssueId, updateFieldValue],
	);

	return (
		<Box xcss={containerHoveredStyles}>
			<ConnectionFieldComponent
				fieldKey={fieldKey}
				localIssueId={localIssueId}
				menuPortalTarget={portalElement}
				isEditable={canConnectIssues && !isIssueArchived}
				onUpdate={onFieldUpdate}
				issueCardType="default"
			/>
		</Box>
	);
};

const containerHoveredStyles = xcss({
	':hover': {
		cursor: 'pointer',
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
});
