import React, { useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ButtonNew, { IconButton } from '@atlaskit/button/new';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import WatchIcon from '@atlaskit/icon/core/migration/eye-open--watch';
import WatchFilledIcon from '@atlaskit/icon/core/migration/eye-open-filled--watch-filled';
import Lozenge from '@atlaskit/lozenge';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	useIsWatching,
	useWatchCount,
} from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import { useToggleIssueWatching } from '@atlassian/jira-polaris-common/src/controllers/idea/utils/idea-view.tsx';
import { useCurrentUser } from '@atlassian/jira-polaris-common/src/controllers/user/index.tsx';
import {
	useCanManageWatchers,
	useCanViewWatchers,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import TransitiveNumber from '@atlassian/react-transitive-number';
import { TOGGLE_ISSUE_WATCHING_KEY } from '../constants.tsx';
import AddWatchers from './add-watchers/index.tsx';
import messages from './messages.tsx';
import { getCounterDisplay } from './utils.tsx';
import { WatchersList } from './watchers-list/index.tsx';

export const WatchersDropdown = () => {
	const { data: currentUser } = useCurrentUser();
	const isWatching = useIsWatching(currentUser?.accountId);
	const watchersCount = useWatchCount();
	const toggleWatching = useToggleIssueWatching();

	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [canManageWatchers] = useCanManageWatchers();
	const [canViewWatchers] = useCanViewWatchers();
	const canOpenWatchersDropdown = canManageWatchers || canViewWatchers;

	const [isOpen, setOpen] = useState(false);

	const onOpenClose = useCallback(() => {
		setOpen(false);
	}, []);

	const icon = isWatching ? (
		<WatchFilledIcon label="" LEGACY_size="medium" spacing="spacious" />
	) : (
		<WatchIcon label="" LEGACY_size="medium" spacing="spacious" />
	);

	const message = isWatching
		? formatMessage(messages.stopWatching)
		: formatMessage(messages.startWatching);

	const onWatcherBtnClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'toggled', actionSubject: 'dropdown' }),
			'watchers',
		);
		if (canOpenWatchersDropdown) {
			setOpen(!isOpen);
		} else {
			toggleWatching();
		}
	}, [canOpenWatchersDropdown, isOpen, toggleWatching, createAnalyticsEvent]);

	return (
		<Container>
			<Popup
				id="watchers-popup"
				boundary="clippingParents"
				isOpen={isOpen}
				placement="auto-end"
				trigger={(triggerProps) => (
					<Tooltip
						content={
							canOpenWatchersDropdown ? formatMessage(messages.tooltipWatchOptions) : message
						}
						delay={100}
						position="top"
					>
						{/* eslint-disable-next-line no-nested-ternary */}
						{isVisualRefreshEnabled() ? (
							watchersCount !== 0 ? (
								<ButtonNew
									{...triggerProps}
									isSelected={isOpen || Boolean(isWatching)}
									onClick={onWatcherBtnClick}
									appearance="default"
									iconBefore={() => icon}
								>
									<Text {...(isWatching ? { color: 'color.text.selected' } : {})}>
										<TransitiveNumber>{getCounterDisplay(watchersCount)}</TransitiveNumber>
									</Text>
								</ButtonNew>
							) : (
								<IconButton
									label=""
									{...triggerProps}
									appearance="default"
									spacing="default"
									onClick={onWatcherBtnClick}
									isSelected={isOpen}
									icon={() => icon}
								/>
							)
						) : (
							<StyledButton
								{...triggerProps}
								isSelected={isOpen}
								onClick={onWatcherBtnClick}
								appearance="subtle"
								iconBefore={icon}
							>
								{watchersCount !== 0 && <Counter>{getCounterDisplay(watchersCount)}</Counter>}
							</StyledButton>
						)}
					</Tooltip>
				)}
				onClose={onOpenClose}
				content={() => (
					<Content>
						<DropdownItem
							elemAfter={<Lozenge>{TOGGLE_ISSUE_WATCHING_KEY}</Lozenge>}
							elemBefore={icon}
							onClick={() => {
								toggleWatching();
								setOpen(false);
							}}
						>
							{message}
						</DropdownItem>
						<WatchersList />
						{canManageWatchers && <AddWatchers />}
					</Content>
				)}
				zIndex={layers.modal}
			/>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"[data-role='droplistContent']": {
		overflow: 'visible',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	width: '240px',
	paddingTop: token('space.050'),
	paddingRight: 0,
	paddingBottom: token('space.050'),
	paddingLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Counter = styled.span({
	marginLeft: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(Button)({
	paddingTop: 0,
	paddingRight: token('space.075'),
	paddingBottom: 0,
	paddingLeft: token('space.050'),
});
