import React, { useState, useEffect, useRef, cloneElement, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@compiled/react';
import {
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { N30A, N40A, N50A, B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useListActions } from '../../../controllers/index.tsx';
import { useSelectedRows } from '../../../controllers/selectors/rows-hooks.tsx';
import { useHighlightedRow, useIsDraggingRow } from '../../../controllers/selectors/ui-hooks.tsx';
import type { TableRowProps } from '../../../types.tsx';
import {
	rowHoveredBackgroundColor,
	ROW_BORDER_WIDTH,
	rowSelectedBackgroundColor,
} from '../../constants.tsx';
import { RowPreview } from '../../row-preview/index.tsx';

const Row = (props: TableRowProps) => {
	const { id, children, groupId, type, ...rest } = props;
	const { trackRowDraggingInfo } = useListActions();

	const isDraggingRow = useIsDraggingRow(id);
	const rowRef = useRef<HTMLDivElement>(null);
	const dragHandleRef = useRef<HTMLDivElement | undefined>();
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
	const [dragStatus, setDragStatus] = useState<'idle' | 'preview' | 'dragging'>('idle');
	const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(null);

	const selectedRows = useSelectedRows();
	const isSelected = selectedRows.includes(id);
	const highlightedRow = useHighlightedRow();
	const isHighlighted = id === highlightedRow;

	useEffect(() => {
		if (!rowRef.current) return undefined;

		const cleanupDragAndDrop = combine(
			draggable({
				element: rowRef.current,
				dragHandle: dragHandleRef.current,
				onGenerateDragPreview: ({ nativeSetDragImage }) => {
					setDragStatus('preview');
					setCustomNativeDragPreview({
						getOffset: pointerOutsideOfPreview({ x: '0px', y: '0px' }),
						render: ({ container }) => {
							setPreviewContainer(container);
							return () => {
								setPreviewContainer(null);
							};
						},
						nativeSetDragImage,
					});
				},
				getInitialData() {
					return { id, groupId, type };
				},
				onDragStart() {
					setDragStatus('dragging');
				},
				onDrop() {
					setDragStatus('idle');
				},
				canDrag: () => type === 'ITEM',
			}),
			dropTargetForElements({
				element: rowRef.current,
				getData({ input, element }) {
					return attachClosestEdge(
						{ id, groupId, type },
						{
							input,
							element,
							allowedEdges: ['top', 'bottom'],
						},
					);
				},
				canDrop({ source }) {
					return source.data.type === 'ITEM' || source.data.type === 'GROUP';
				},
				onDrag(args) {
					if (args.source.data.id !== id) {
						const dropEdge = extractClosestEdge(args.self.data);
						if (closestEdge !== dropEdge) {
							setClosestEdge(dropEdge);
							trackRowDraggingInfo({
								dropTarget: id,
								type: 'ITEM',
								dropEdge,
							});
						}
					}
				},
				onDragLeave() {
					setClosestEdge(null);
					trackRowDraggingInfo({
						dropEdge: null,
					});
				},
				onDrop() {
					setClosestEdge(null);
					trackRowDraggingInfo({
						dropEdge: null,
					});
				},
			}),
		);

		return () => {
			cleanupDragAndDrop?.();
		};
	}, [closestEdge, groupId, id, trackRowDraggingInfo, type]);

	const rowChildren = useMemo(() => {
		// To allow dragging only by drag handle we need to add ref to the drag handle cell
		// which is the first cell in the row
		children.props.cells[0] = cloneElement(children.props.cells[0], {
			ref: dragHandleRef,
		});
		return cloneElement(children, children.props);
	}, [children, dragHandleRef]);

	return (
		<>
			<RowWrapper
				{...rest}
				ref={rowRef}
				data-testid="polaris-lib-list.ui.dnd.row.row-wrapper"
				role="row"
				closestEdge={closestEdge}
				dragStatus={dragStatus === 'dragging' || isDraggingRow ? 'dragging' : dragStatus}
			>
				{rowChildren}
			</RowWrapper>
			{previewContainer
				? ReactDOM.createPortal(
						<RowWrapperClone
							isSelectedOrHighlighted={isSelected || isHighlighted}
							{...rest}
							role="row"
						>
							<RowPreview {...props} />
						</RowWrapperClone>,
						previewContainer,
					)
				: null}
		</>
	);
};

const Group = (props: TableRowProps) => {
	const { id, children, groupId, type, ...rest } = props;
	const { trackRowDraggingInfo } = useListActions();

	const rowRef = useRef<HTMLDivElement>(null);
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);

	useEffect(() => {
		if (!rowRef.current) return undefined;

		const resetEdge = () => {
			setClosestEdge(null);
			trackRowDraggingInfo({
				dropEdge: null,
			});
		};

		const cleanupDragAndDrop = combine(
			dropTargetForElements({
				element: rowRef.current,
				getData({ input, element }) {
					return attachClosestEdge(
						{ id, groupId, type },
						{
							input,
							element,
							allowedEdges: ['top', 'bottom'],
						},
					);
				},
				canDrop({ source }) {
					return source.data.type === 'ITEM' || source.data.type === 'GROUP';
				},
				onDrag(args) {
					const dropEdge = extractClosestEdge(args.self.data);
					if (closestEdge !== dropEdge) {
						setClosestEdge(dropEdge);
						trackRowDraggingInfo({
							dropTarget: id,
							type: 'GROUP',
							dropEdge,
						});
					}
				},
				onDragLeave: resetEdge,
				onDrop: resetEdge,
			}),
		);

		return () => {
			cleanupDragAndDrop?.();
		};
	}, [id, closestEdge, type, trackRowDraggingInfo, groupId]);

	return (
		<GroupRowWrapper {...rest} ref={rowRef} role="rowgroup" closestEdge={closestEdge}>
			{children}
		</GroupRowWrapper>
	);
};

export const DraggableRow = (props: TableRowProps) => {
	if (props.type === 'ITEM') {
		return <Row {...props} />;
	}

	if (props.type === 'GROUP') {
		return <Group {...props} />;
	}

	return null;
};

export const NoneDraggableRow = (props: TableRowProps) => {
	const { id, children, type, ...rest } = props;

	return (
		<div {...rest} role={type === 'GROUP' ? 'rowgroup' : 'row'}>
			{children}
		</div>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowWrapperClone = styled.div<{ isSelectedOrHighlighted: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isSelectedOrHighlighted }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		isSelectedOrHighlighted ? rowSelectedBackgroundColor : rowHoveredBackgroundColor,
	height: '100%',
	width: 'fit-content',
	overflow: 'hidden',
	boxShadow: token('elevation.shadow.overlay', `0 1px 1px ${N50A}, 0 0 1px 1px ${N40A}`),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderBottom: `${ROW_BORDER_WIDTH}px solid ${token('color.border')}`,
	opacity: 0.93,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowWrapper = styled.div<{
	closestEdge: Edge | null;
	dragStatus: string;
}>({
	'&::before': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ dragStatus, closestEdge }) => (!closestEdge || dragStatus !== 'idle') && 'none',
		content: '',
		width: '100%',
		height: '3px',
		position: 'absolute',
		zIndex: 200,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		top: ({ closestEdge }) => (closestEdge === 'top' ? '-1.5px' : 'auto'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		bottom: ({ closestEdge }) => (closestEdge === 'bottom' ? '-1.5px' : 'auto'),
		backgroundColor: token('color.border.brand', B400),
	},
	'&::after': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ dragStatus }) => dragStatus !== 'dragging' && 'none',
		content: '',
		width: '100%',
		height: '100%',
		position: 'absolute',
		top: '0',
		left: '0',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		backgroundColor: rowHoveredBackgroundColor,
		zIndex: 100,
		boxShadow: `inset ${token('elevation.shadow.overflow', N30A)}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:nth-child(2)::before': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		top: ({ closestEdge }) => (closestEdge === 'top' ? '0' : 'auto'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child::before': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		bottom: ({ closestEdge }) => (closestEdge === 'bottom' ? '0' : 'auto'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupRowWrapper = styled.div<{
	closestEdge: Edge | null;
}>({
	'&::before': {
		content: '',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ closestEdge }) => !closestEdge && 'none',
		position: 'absolute',
		top: 'auto',
		bottom: '-1.5px',
		width: '100%',
		height: '3px',
		zIndex: 200,
		backgroundColor: token('color.border.brand', B400),
	},
});
