import React, { useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useFieldEmoji,
	useFieldTypeIcon,
} from '../../../../controllers/field/selectors/field-hooks.tsx';
import { useIssuesWithBasicProperties } from '../../../../controllers/issue/selectors/issue-ids-hooks.tsx';
import { useViewActions } from '../../../../controllers/views/main.tsx';
import { useFieldFilter } from '../../../../controllers/views/selectors/filters-hooks.tsx';
import { AlignedShortLabelContext } from '../../../issue-short-label/index.tsx';
import { messages } from '../messages.tsx';
import { BasicIssueOptionRenderer } from '../options/renderers/basic-issue/index.tsx';
import { SelectFilterComponent } from '../select/index.tsx';
import type { FilterComponentProps } from '../types.tsx';
import { FilterWrapper } from '../filter-wrapper/filter-wrapper.tsx';
import { useIssueIdField } from '../../../../controllers/issue/selectors/fields-hooks.tsx';

const PolarisIdeaIssueIdFilterInner = ({
	isLastFilter,
	field,
	isDisabled,
	isUsingCustomColor,
}: FilterComponentProps) => {
	const allIssues = useIssuesWithBasicProperties();
	const filter = useFieldFilter(field.key);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { updateFieldFilter } = useViewActions();
	const { formatMessage } = useIntl();
	const fieldTypeIcon = useFieldTypeIcon(field.key, undefined);
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);

	const componentOptions = useMemo(
		() =>
			allIssues
				.filter((issue) => !issue.isArchived)
				.map((issue) => ({
					label: `${issue.key} ${issue.summary}`,
					previewLabel: issue.key,
					id: String(issue.id),
					OptionRenderComponent: () => <BasicIssueOptionRenderer basicIssue={issue} />,
				})),
		[allIssues],
	);

	return (
		<FilterWrapper>
			<AlignedShortLabelContext>
				<SelectFilterComponent
					label={formatMessage(messages.excludeIdeas)}
					fieldTypeIcon={fieldTypeIcon}
					emoji={emoji}
					selected={
						filter.type === 'FIELD' ? filter.values.map(({ stringValue }) => stringValue) : []
					}
					isLastFilter={isLastFilter}
					onChange={(values) => {
						fireUIAnalytics(
							createAnalyticsEvent({}),
							'excludedIssuesFilter updated',
							'view-controls',
							{
								fieldKey: field.key,
								fieldType: field.type,
							},
						);
						experience.headerView.viewFilter.start();
						updateFieldFilter(
							{
								...filter,
								values: values.map((stringValue) => ({ stringValue })),
							},
							() => {
								experience.headerView.viewFilter.success();
							},
							(error?: Error) => {
								experience.headerView.viewFilter.failure(error);
							},
						);
					}}
					options={componentOptions}
					isDisabled={isDisabled}
					isUsingCustomColor={isUsingCustomColor}
				/>
			</AlignedShortLabelContext>
		</FilterWrapper>
	);
};

export const PolarisIdeaIssueIdFilter = ({ field, ...props }: FilterComponentProps) => {
	const idField = useIssueIdField();

	return idField ? <PolarisIdeaIssueIdFilterInner field={idField} {...props} /> : null;
};
