import React, { memo, useMemo, useCallback } from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { useIntl } from '@atlassian/jira-intl';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { useCollectionName } from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import { getCollectionsPageUrl } from '@atlassian/jira-polaris-component-collections/src/common/utils/collections.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

export type ViewBreadcrumbsProps = {
	isCollectionView: boolean;
	projectName?: string;
	projectsPath: string;
};

export const ViewBreadcrumbs = memo(
	({ projectName, projectsPath, isCollectionView }: ViewBreadcrumbsProps) => {
		const { formatMessage } = useIntl();
		const { push } = useRouterActions();
		const environmentContainer = useEnvironmentContainer();
		const collectionUUID = environmentContainer?.id;
		const collectionName = useCollectionName({ collectionUUID });

		const routeToRoadmaps = useCallback(
			(e: React.MouseEvent) => {
				e.preventDefault();
				push(getCollectionsPageUrl());
			},
			[push],
		);

		const routeToProjects = useCallback(
			(e: React.MouseEvent) => {
				e.preventDefault();
				push(projectsPath);
			},
			[projectsPath, push],
		);

		const firstBreadcrumbData = useMemo(() => {
			if (isCollectionView) {
				return {
					href: getCollectionsPageUrl(),
					text: formatMessage(messages.roadmaps),
					onclick: routeToRoadmaps,
				};
			}

			return {
				href: projectsPath,
				text: formatMessage(messages.projects),
				onclick: routeToProjects,
			};
		}, [formatMessage, isCollectionView, projectsPath, routeToProjects, routeToRoadmaps]);

		const secondBreadcrumbText = useMemo(() => {
			if (isCollectionView) {
				return collectionName;
			}

			return projectName;
		}, [collectionName, isCollectionView, projectName]);

		return (
			<Breadcrumbs>
				<BreadcrumbsItem
					href={firstBreadcrumbData.href}
					text={firstBreadcrumbData.text}
					onClick={firstBreadcrumbData.onclick}
				/>
				{secondBreadcrumbText && <BreadcrumbsItem text={secondBreadcrumbText} />}
			</Breadcrumbs>
		);
	},
);
