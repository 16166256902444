import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

export type FilterOperator = 'LT' | 'LTE' | 'GT' | 'GTE' | 'EQ' | 'NEQ';

/** filter type ID for numeric field filter */
export const NUMERIC_FIELD_FILTER = 'NUMBER';

export const GENERIC_FIELD_FILTER = 'FIELD';

export const TEXT_FIELD_FILTER = 'TEXT';

export const CONNECTION_FIELD_FILTER = 'CONNECTION_FIELD';

/**
 * Common field value filter. Selects the values (reduced to string) that should be shown for a particular field.
 */
export type FieldValueFilter = {
	type: typeof GENERIC_FIELD_FILTER;
	field: FieldKey;
	fieldType?: FieldType;
	values: {
		stringValue: string | undefined;
	}[];
};

/**
 * A generic text filter not bound to a single field.
 */
export type TextFilter = {
	type: typeof TEXT_FIELD_FILTER;
	values: {
		stringValue: string | undefined;
	}[];
	localId?: string;
};

export type NumericFieldFilterValue = {
	numericValue: number | undefined;
	operator: FilterOperator;
};

/**
 * Filter for numeric ranges on an individual number field
 */
export type NumericFieldFilter = {
	type: typeof NUMERIC_FIELD_FILTER;
	fieldType?: FieldType;
	field: FieldKey;
	values: NumericFieldFilterValue[];
};

/**
 * Filter for interval field
 */
export const END_AFTER_NOW = 'END_AFTER_NOW';
export const END_BEFORE_NOW = 'END_BEFORE_NOW';
export const START_AFTER_NOW = 'START_AFTER_NOW';
export const START_BEFORE_NOW = 'START_BEFORE_NOW';

export const INTERVAL_FILTER_OPERATOR_CODES = [
	END_AFTER_NOW,
	END_BEFORE_NOW,
	START_AFTER_NOW,
	START_BEFORE_NOW,
] as const;

export const INTERVAL_FILTER_PERIOD_DAY = 'day';
export const INTERVAL_FILTER_PERIOD_WEEK = 'week';
export const INTERVAL_FILTER_PERIOD_MONTH = 'month';
export const INTERVAL_FILTER_PERIOD_YEAR = 'year';

export type IntervalFilterPeriod =
	| typeof INTERVAL_FILTER_PERIOD_DAY
	| typeof INTERVAL_FILTER_PERIOD_WEEK
	| typeof INTERVAL_FILTER_PERIOD_MONTH
	| typeof INTERVAL_FILTER_PERIOD_YEAR;

export const INTERVAL_FILTER_DATE_TYPE_START = 'startIntervalDate';
export const INTERVAL_FILTER_DATE_TYPE_END = 'endIntervalDate';

export type IntervalFilterDateType =
	| typeof INTERVAL_FILTER_DATE_TYPE_START
	| typeof INTERVAL_FILTER_DATE_TYPE_END;

export type IntervalFilterOperatorCode =
	| typeof END_AFTER_NOW
	| typeof END_BEFORE_NOW
	| typeof START_AFTER_NOW
	| typeof START_BEFORE_NOW;

export const INTERVAL_FILTER_TIME_PAST = 'past';
export const INTERVAL_FILTER_TIME_NEXT = 'next';
export const INTERVAL_FILTER_TIME_CURRENT = 'current';

export type IntervalFilterTime =
	| typeof INTERVAL_FILTER_TIME_PAST
	| typeof INTERVAL_FILTER_TIME_NEXT
	| typeof INTERVAL_FILTER_TIME_CURRENT;

export type IntervalFieldFilterLegacyValue = {
	numericValue: number;
	operator: IntervalFilterOperatorCode;
};

export type IntervalFieldFilterAbsoluteDatesValue = {
	type: 'ABSOLUTE_DATES';
	start?: string;
	end?: string;
};

export type IntervalFieldFilterRollingDatesPastNextValue = {
	type: 'ROLLING_DATES';
	intervalDateType: IntervalFilterDateType;
	time: Exclude<IntervalFilterTime, 'current'>;
	numericValue: number;
	period: IntervalFilterPeriod;
};

export type IntervalFieldFilterRollingDatesCurrentValue = {
	type: 'ROLLING_DATES';
	intervalDateType: IntervalFilterDateType;
	time: Extract<IntervalFilterTime, 'current'>;
	period: IntervalFilterPeriod;
};

export type IntervalFieldFilterEmptyValue = {
	operator: 'EQ';
	value: null;
	text: null;
};

export type IntervalFieldFilterNotEmptyValue = {
	operator: 'NEQ';
	value: null;
	text: null;
};

export type IntervalFieldFilterValue =
	| IntervalFieldFilterLegacyValue
	| IntervalFieldFilterAbsoluteDatesValue
	| IntervalFieldFilterRollingDatesPastNextValue
	| IntervalFieldFilterRollingDatesCurrentValue
	| IntervalFieldFilterEmptyValue
	| IntervalFieldFilterNotEmptyValue;

/** filter type ID for interval field filter */
export const INTERVAL_FIELD_FILTER = 'INTERVAL';

export type IntervalFieldFilterLegacy = {
	type: typeof INTERVAL_FIELD_FILTER;
	field: FieldKey;
	values: IntervalFieldFilterLegacyValue[];
};

type IntervalFieldFilterAbsoluteDates = {
	type: typeof INTERVAL_FIELD_FILTER;
	field: FieldKey;
	values: IntervalFieldFilterAbsoluteDatesValue[];
};

type IntervalFieldFilterRollingDates = {
	type: typeof INTERVAL_FIELD_FILTER;
	field: FieldKey;
	values:
		| IntervalFieldFilterRollingDatesPastNextValue[]
		| IntervalFieldFilterRollingDatesCurrentValue[];
};

export type IntervalFieldFilterEmpty = {
	type: typeof INTERVAL_FIELD_FILTER;
	field: FieldKey;
	values: IntervalFieldFilterEmptyValue[];
};

export type IntervalFieldFilterNotEmpty = {
	type: typeof INTERVAL_FIELD_FILTER;
	field: FieldKey;
	values: IntervalFieldFilterNotEmptyValue[];
};

export type IntervalFieldFilter =
	| IntervalFieldFilterLegacy
	| IntervalFieldFilterAbsoluteDates
	| IntervalFieldFilterRollingDates
	| IntervalFieldFilterEmpty
	| IntervalFieldFilterNotEmpty;

const QUARTER = 'QUARTER';
export const MONTH = 'MONTH';
export const DAY = 'DAY';

export type INTERVAL_PERIOD = typeof QUARTER | typeof MONTH | typeof DAY;

/**
 * Filter for connection field
 */
export const CONNECTION_FIELD_FILTER_BOARD_COLUMN = 'BOARD_COLUMN';
export const CONNECTION_FIELD_FILTER_VIEW_GROUP = 'VIEW_GROUP';

export type ConnectionFieldFilterEnumValue =
	| typeof CONNECTION_FIELD_FILTER_BOARD_COLUMN
	| typeof CONNECTION_FIELD_FILTER_VIEW_GROUP;

export type ConnectionFieldFilter = {
	type: typeof CONNECTION_FIELD_FILTER;
	field: FieldKey;
	fieldType?: FieldType;
	values: {
		enumValue: ConnectionFieldFilterEnumValue;
	}[];
};

/**
 * all possible filter types. future extension point for other filters, potentially even a custom user JQL
 */
export type Filter =
	| FieldValueFilter
	| TextFilter
	| NumericFieldFilter
	| IntervalFieldFilter
	| ConnectionFieldFilter;

/**
 * @see https://www.typescriptlang.org/play/?#code/C4TwDgpgBAGlC8UDOwBOBLAdgcwNwChRIoBNBKTAVwFsAjCVAo6ALXLgB9SDDxoBBdgG0AulC4lRTPlABC5FlPz4AxgHtMKKAEMAXFEGIhAcm1IAJsYA0UAIwAmAMwiC6zcCi198o6YvW7JxdlfAB6UKgACQgAG0hUKGZEtShzdBQMWkpgaGAAC1zUbU0AMzVUam1gdA0oNQA3BkSCqEpMGsxeYgARdLR0LJyAHgAVAD5yEagIAA8czHMkVswAa0w1AHdMKAB+KBHRKH1MCEbGZXD9mWAUtGKkMoqoRTEb2EOJUS7oXoyB7Ig-FQRRAowmiF+-UGEFG0zmEAWSwAFFgSk0AKoASkOe3RRwopwYYx4l3RSG02Ag3zk9nIkMyAKBIKGL2Jqg0Wlo9m8tN8ZksNgczlwQA
 */
type Distribute<T> = T extends unknown ? T[] : never;
export type DistributeArray<T> = Distribute<T extends (infer U)[] ? U : never>;
