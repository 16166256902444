import React, { useState, useEffect, useCallback } from 'react';
import type { User } from '@atlassian/jira-polaris-common/src/common/types/user/index.tsx';
import {
	useIsIssueArchived,
	useSafeIssueKey,
	useUser,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import {
	UserField as UserFieldCommon,
	UserFieldReadView,
} from '@atlassian/jira-polaris-common/src/ui/fields/user/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { callWhenIdle } from '@atlassian/jira-call-when-idle/src/index.tsx';
import { useListPortalElement } from '../../../portal/index.tsx';
import { useFieldUpdate, useIsEditable } from '../../utils/index.tsx';

interface UserFieldProps {
	interactive?: boolean;
	fieldKey: string;
	localIssueId: LocalIssueId;
}

interface UserFieldInteractiveProps extends UserFieldProps {
	onEdit: () => void;
	onCancel: () => void;
}

export const UserFieldInteractive = ({
	localIssueId,
	fieldKey,
	interactive,
	onEdit,
	onCancel,
}: UserFieldInteractiveProps) => {
	const issueUserProperty = useUser(fieldKey, localIssueId);
	const issueKey = useSafeIssueKey(localIssueId);
	const portalElement = useListPortalElement();
	const isEditable = useIsEditable(fieldKey);
	const isIdeaArchived = useIsIssueArchived(localIssueId);
	const onUpdate = useFieldUpdate(localIssueId, fieldKey);

	const onUpdateForCustomField = useCallback(
		(user?: User) => {
			const displayUser = user
				? {
						accountId: user.id,
						displayName: user.name || '',
						avatarUrls: {
							'48x48': user.avatarUrl || '',
						},
					}
				: undefined;
			onUpdate(displayUser);
		},
		[onUpdate],
	);

	return (
		<UserFieldCommon
			issueKey={issueKey}
			isEditable={isEditable && !isIdeaArchived}
			fieldKey={fieldKey}
			user={issueUserProperty}
			onEdit={onEdit}
			onCancel={onCancel}
			onUpdate={onUpdateForCustomField}
			portalElement={portalElement}
			interactive={interactive}
		/>
	);
};

export const UserField = ({ interactive, localIssueId, fieldKey }: UserFieldProps) => {
	const [initialized, setInitialized] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const isEditable = useIsEditable(fieldKey);
	const user = useUser(fieldKey, localIssueId);

	const onEditingCancel = useCallback(() => setIsEditing(false), []);
	const onEditingStart = useCallback(() => setIsEditing(true), []);

	useEffect(() => callWhenIdle(() => setInitialized(true)), []);

	if (isEditable && (isEditing || interactive)) {
		return (
			<UserFieldInteractive
				localIssueId={localIssueId}
				fieldKey={fieldKey}
				onCancel={onEditingCancel}
				onEdit={onEditingStart}
				interactive={interactive}
			/>
		);
	}

	return (
		// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<div style={{ border: '2px solid transparent' }}>
			<UserFieldReadView user={user} isImmediateRendering={initialized} />
		</div>
	);
};
