import React, { type RefObject, forwardRef } from 'react';
import { styled } from '@compiled/react';
import type { PopupComponentProps, TriggerProps } from '@atlaskit/popup';
import { colors } from '@atlaskit/theme';
import { layers } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';
import { TooltipPrimitive, type TooltipPrimitiveProps } from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

export const TooltipComponent = forwardRef<HTMLElement, TooltipPrimitiveProps>((props, ref) => (
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	<StyledTooltipPrimitive {...props} ref={ref as RefObject<HTMLDivElement>} />
));

export const TriggerComponent = forwardRef<HTMLDivElement, TriggerProps>((props, ref) => {
	const { formatMessage } = useIntl();
	return (
		<TooltipTrigger
			{...props}
			role="button"
			ref={ref}
			aria-label={formatMessage(messages.tooltipTriggerAriaLabel)}
		/>
	);
});

export const PopupComponent = forwardRef<HTMLDivElement, PopupComponentProps>(
	({ children, ...props }, ref) => (
		<StyledPopupPrimitive {...props} ref={ref}>
			{children}
		</StyledPopupPrimitive>
	),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTooltipPrimitive = styled(TooltipPrimitive)({
	zIndex: layers.tooltip(),
	width: '240px',
	paddingTop: token('space.025'),
	paddingRight: token('space.075'),
	paddingBottom: token('space.025'),
	paddingLeft: token('space.075'),

	backgroundColor: token('color.background.input'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	boxShadow: token('elevation.shadow.overlay', `4px 8px 14px 0 ${colors.N50A}`),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledPopupPrimitive = styled.div({
	zIndex: layers.tooltip(),
	width: '240px',
	paddingTop: token('space.025'),
	paddingRight: token('space.075'),
	paddingBottom: token('space.025'),
	paddingLeft: token('space.075'),

	backgroundColor: token('color.background.input'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	boxShadow: token('elevation.shadow.overlay', `4px 8px 14px 0 ${colors.N50A}`),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TruncateWithTooltipContainer = styled.div({
	position: 'relative',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textTransform: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TooltipContent = styled.div({
	color: token('color.text', 'inherit'),
	font: token('font.body.UNSAFE_small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TooltipTrigger = styled.div({
	overflow: 'hidden',
});
