import React, { useCallback } from 'react';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { GENERIC_FIELD_FILTER } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { useViewActions } from '../../../../../controllers/views/main.tsx';
import { SelectFilterContentComponent } from '../../select/content/index.tsx';
import { useFieldFilter } from '../../../../../controllers/views/selectors/filters-hooks.tsx';
import { VariableOptionRenderer, useOptionsWithLabels } from '../../options/index.tsx';
import { useFilterOptions } from '../../hooks/use-filter-options.tsx';
import { TogglableSection } from './togglable-section.tsx';
import messages from './messages.tsx';

type Props = {
	field: Field;
	togglable?: boolean;
};
export const InViewConnectionFilter = ({ field, togglable = false }: Props) => {
	const filter = useFieldFilter(field.key);
	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { updateFieldFilter, clearFieldFilter } = useViewActions();

	const handleChange = useCallback<(value: (string | undefined)[]) => void>(
		(values) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'connectionFilter updated', 'view-controls', {
				fieldKey: field.key,
				fieldType: field.type,
			});

			if (values.length) {
				experience.headerView.viewFilter.start();
				updateFieldFilter(
					{
						...filter,
						values: values.map((stringValue) => ({ stringValue })),
					},
					() => {
						experience.headerView.viewFilter.success();
					},
					(error?: Error) => {
						experience.headerView.viewFilter.failure(error);
					},
				);
			} else {
				experience.headerView.viewFilter.start();
				clearFieldFilter(
					filter.field,
					() => {
						experience.headerView.viewFilter.success();
					},
					(error?: Error) => {
						experience.headerView.viewFilter.failure(error);
					},
				);
			}
		},
		[clearFieldFilter, createAnalyticsEvent, field.key, field.type, filter, updateFieldFilter],
	);

	const selected =
		filter.type === GENERIC_FIELD_FILTER ? filter.values.map(({ stringValue }) => stringValue) : [];

	const filterOptions = useFilterOptions(field);
	const optionsWithLabels = useOptionsWithLabels(field, filterOptions);

	const options = optionsWithLabels.map((option) => ({
		label: option.label,
		id: option.groupIdentity,
		OptionRenderComponent: () => (
			<VariableOptionRenderer
				field={field}
				groupIdentity={option.groupIdentity}
				value={option.value}
			/>
		),
	}));

	return (
		<TogglableSection
			initiallyOpen={selected.length > 0}
			label={formatMessage(messages.filterInView)}
			description={formatMessage(messages.filterInViewDescription)}
			controlsVisible={togglable}
		>
			<SelectFilterContentComponent options={options} selected={selected} onChange={handleChange} />
		</TogglableSection>
	);
};
