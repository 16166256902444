import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { sendPendoTrackEvent } from '../../pendo/index.tsx';
import { getViewViewedPendoEventAttributes } from '../../../common/utils/get-view-viewed-pendo-event-attributes.tsx';

export const TimelineView = {
	opened: (
		event: UIAnalyticsEvent,
		itemCount: number,
		timelineLength: number,
		accessLevel?: string,
	) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/zzYGsTOijWyY1VfJ56VtLUI-Rzw
		sendPendoTrackEvent({
			actionSubjectAndAction: 'timelineView opened',
			attributes: { accessLevel: accessLevel || '', ...getViewViewedPendoEventAttributes(event) },
		});

		if (itemCount <= 10) {
			sendPendoTrackEvent({ actionSubjectAndAction: 'timeline[0to10] opened' });
		} else if (itemCount <= 25) {
			sendPendoTrackEvent({ actionSubjectAndAction: 'timeline(10to25] opened' });
		} else if (itemCount <= 50) {
			sendPendoTrackEvent({ actionSubjectAndAction: 'timeline(25to50] opened' });
		} else if (itemCount <= 100) {
			sendPendoTrackEvent({ actionSubjectAndAction: 'timeline(50to100] opened' });
		} else {
			sendPendoTrackEvent({ actionSubjectAndAction: 'timeline>100 opened' });
		}

		// https://data-portal.internal.atlassian.com/analytics/registry/51090
		fireScreenAnalytics(event, { itemCount, timelineLength, accessLevel });
	},
};
