import React, {
	type RefObject,
	type ReactElement,
	type ComponentPropsWithoutRef,
	memo,
	useRef,
	useCallback,
	useEffect,
	useMemo,
} from 'react';
import { styled } from '@compiled/react';
import { Box } from '@atlaskit/primitives';
import Tabs, { type TabData, Tab, TabList } from '@atlaskit/tabs';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueViewSection } from '@atlassian/jira-polaris-common/src/common/types/issue/index.tsx';
import { markIdeaViewTTI } from '@atlassian/jira-polaris-component-metrics/src/idea-view.tsx';
import { useSelectedIssueKey } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import {
	usePolarisRouter,
	useIssueViewLayout,
	useIssueViewSection,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useCurrentViewSharingSettings } from '@atlassian/jira-polaris-common/src/controllers/sharing/selectors/hooks.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { CreateDeliveryTicketSpotlight } from '@atlassian/jira-polaris-lib-onboarding/src/ui/create-delivery-ticket/index.tsx';
import { CreateInsightSpotlight } from '@atlassian/jira-polaris-lib-onboarding/src/ui/create-insight/index.tsx';
import { FireUiAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { IdeaCommentStream } from '../comments/index.tsx';
import { ConnectionsList } from '../connections/connections-list.tsx';
import { TABS_CONTAINER_HEIGHT } from '../constants.tsx';
import { History } from '../history/index.tsx';
import { Capture } from './capture/index.tsx';
import { Deliver } from './deliver/index.tsx';
import { useTabs } from './utils.tsx';

type SectionProps = {
	issueId: IssueId;
	localIssueId: LocalIssueId;
	isSharedView: boolean;
	scrollableContainerRef: React.RefObject<HTMLElement>;
};

const TabSpotlightWrapper = ({ name, children }: { name: string; children: ReactElement }) => {
	switch (name) {
		case 'capture':
			return <CreateInsightSpotlight leftShift={60}>{children}</CreateInsightSpotlight>;
		case 'deliver':
			return (
				<CreateDeliveryTicketSpotlight placement="bottom">{children}</CreateDeliveryTicketSpotlight>
			);
		default:
			return children;
	}
};

export const Sections = memo<SectionProps>(
	({ issueId, localIssueId, isSharedView, scrollableContainerRef }: SectionProps) => {
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const tabAnchorRef = useRef<HTMLDivElement>(null);
		const sharingOptions = useCurrentViewSharingSettings();
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const tabs = useTabs({ localIssueId, isSharedView, sharingOptions }) as TabData[];
		const issueKey = useSelectedIssueKey();
		const { openIssueView } = usePolarisRouter();
		const issueViewSection = useIssueViewSection();
		const issueViewLayout = useIssueViewLayout();

		const scrollTo = (ref: RefObject<HTMLElement>) => {
			ref.current?.scrollIntoView({ inline: 'center', behavior: 'smooth' });
		};

		const handleChange = useCallback<
			NonNullable<ComponentPropsWithoutRef<typeof Tabs>['onChange']>
		>(
			(index) => {
				const selectedItem = tabs[index];
				fireCompoundAnalyticsEvent.IdeaView.tabClicked(
					createAnalyticsEvent({}),
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					selectedItem.name as IssueViewSection,
				);

				if (!issueKey) return;
				openIssueView(issueKey, {
					layout: issueViewLayout,
					section: selectedItem.name,
				});

				scrollTo(tabAnchorRef);
			},
			[createAnalyticsEvent, issueKey, issueViewLayout, openIssueView, tabs],
		);

		useEffect(() => {
			markIdeaViewTTI();
		}, [issueViewLayout, issueViewSection]);

		const selectedIndex = useMemo(
			() => tabs.findIndex(({ name }) => name === issueViewSection),
			[issueViewSection, tabs],
		);

		if (tabs.length === 0) {
			return null;
		}

		return (
			<>
				<TabsContainer id="polaris.idea.src.ui.sections.tabs">
					<Tabs
						selected={selectedIndex}
						onChange={handleChange}
						id="idea-view-section-tabs"
						shouldUnmountTabPanelOnChange
					>
						<TabList>
							{tabs.map((tab, index) => (
								<TabSpotlightWrapper key={index} name={tab.name}>
									<Tab testId={tab.testId}>{tab.label}</Tab>
								</TabSpotlightWrapper>
							))}
						</TabList>
					</Tabs>
				</TabsContainer>
				<TabContainer>
					<TabAnchor ref={tabAnchorRef} />
					{issueViewSection === 'comments' &&
						(!isSharedView || (isSharedView && sharingOptions?.showIdeaComments)) && (
							<IdeaCommentStreamWrapper>
								<FireUiAnalytics eventName="tab viewed" actionSubjectId="ideaComments" />
								<UFOSegment name="idea-view.tab.comments">
									<IdeaCommentStream scrollableRef={scrollableContainerRef} />
								</UFOSegment>
							</IdeaCommentStreamWrapper>
						)}
					{issueViewSection === 'connections' && fg('jpd_issues_relationships') && (
						<Box paddingInline="space.100">
							<UFOSegment name="idea-view.tab.connections">
								<ConnectionsList />
							</UFOSegment>
						</Box>
					)}
					{issueViewSection === 'capture' &&
						(!isSharedView || (isSharedView && sharingOptions?.showIdeaInsights)) && (
							<>
								<FireUiAnalytics eventName="tab viewed" actionSubjectId="ideaInsights" />
								<UFOSegment name="idea-view.tab.insights">
									<Capture
										issueId={issueId}
										localIssueId={localIssueId}
										scrollableContainerRef={scrollableContainerRef}
									/>
								</UFOSegment>
							</>
						)}
					{!isSharedView && issueViewSection === 'deliver' && (
						<DeliveryContainer>
							<FireUiAnalytics eventName="tab viewed" actionSubjectId="ideaDelivery" />
							<UFOSegment name="idea-view.tab.deliver">
								<Deliver />
							</UFOSegment>
						</DeliveryContainer>
					)}
					{!isSharedView && issueViewSection === 'history' && issueKey && (
						<>
							<FireUiAnalytics eventName="tab viewed" actionSubjectId="ideaHistory" />
							<UFOSegment name="idea-view.tab.history">
								<History issueKey={issueKey} />
							</UFOSegment>
						</>
					)}
				</TabContainer>
			</>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabsContainer = styled.div({
	position: 'sticky',
	top: 0,
	bottom: 0,
	backgroundColor: token('elevation.surface'),
	paddingBottom: token('space.150'),
	paddingInline: token('space.100'),
	// Menus of comments/insights editors are rendered with z-index 100, so we need to set z-index lower not to overlap popups from editor
	zIndex: 99,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryContainer = styled.div({
	paddingTop: 0,
	paddingRight: token('space.100'),
	paddingBottom: 0,
	paddingLeft: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabContainer = styled.div({
	minHeight: '300px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabAnchor = styled.div({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `-${TABS_CONTAINER_HEIGHT}px`,
	display: 'block',
	height: '0',
	width: '100%',
	pointerEvents: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdeaCommentStreamWrapper = styled.div({
	marginTop: 0,
	marginRight: token('space.100'),
	marginBottom: 0,
	marginLeft: token('space.100'),
});
