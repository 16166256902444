import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import {
	isValueDecorationRules,
	type ValueDecoration,
	type ValueRule,
} from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { isDecorationWithLogic } from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { isGlobalSystemField } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { FieldRemote } from '@atlassian/jira-polaris-remote-field/src/types.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../../types.tsx';
import {
	dualWriteValueDecorationsBasedOnFieldType,
	performJiraConfigurationUpdate,
} from '../utils.tsx';
import type { UpdateValueDecorationProps } from './types.tsx';

/**
 * Removes obsolete and duplicated decorations originating from select and multiselect fields.
 * https://pi-dev-sandbox.atlassian.net/browse/POL-7929
 */
export const sanitizeSelectDecorations = (
	decorations: ValueDecoration[],
	filterOutDecorationRuleFn: UpdateValueDecorationProps['filterOutDecorationRuleFn'],
) => {
	if (!filterOutDecorationRuleFn) {
		return decorations;
	}

	const sanitizedDecorations: ValueDecoration[] = [];
	const existingOptionIds: Record<string, boolean> = {};

	decorations.forEach((decoration) => {
		if (isValueDecorationRules(decoration)) {
			const hasRules = decoration.rules.length > 0;
			const ruleFirstOptionId = decoration.rules[0]?.value;
			const optionIdExistsOnAnotherDecoration = existingOptionIds[ruleFirstOptionId] !== undefined;
			const isDuplicatedDecoration = hasRules && optionIdExistsOnAnotherDecoration;

			if (isDuplicatedDecoration) {
				return;
			}

			if (!hasRules) {
				log.safeErrorWithoutCustomerData(
					'polaris.decorations-update-error',
					'encountered decoration without any rules defined. deleting decoration',
				);
				return;
			}

			// select and multiselect fields should only have a single rule defined
			existingOptionIds[decoration.rules[0].value] = true;
			const filteredRules = decoration.rules.filter(filterOutDecorationRuleFn);

			if (filteredRules.length === 1) {
				sanitizedDecorations.push({
					...decoration,
					rules: [...filteredRules],
				});
			} else if (filteredRules.length > 1) {
				log.safeErrorWithoutCustomerData(
					'polaris.decorations-update-error',
					'encountered decoration with more than 1 rule defined',
				);
			}
		} else {
			sanitizedDecorations.push(decoration);
		}
	});

	return sanitizedDecorations;
};

export const updateInternal = async (
	updateProps: UpdateValueDecorationProps,
	fieldRemote: FieldRemote,
	getState: StoreActionApi<State>['getState'],
	setState: StoreActionApi<State>['setState'],
	onDecorationUpdateFailed: (arg1: Error) => void,
	issueTypeId: string | undefined,
	createAnalyticsEvent: CreateUIAnalyticsEvent,
) => {
	const { fieldKey, localDecorationId, filterOutDecorationRuleFn } = updateProps;
	const state = getState();
	const { fieldValueDecorations, fields } = state;
	const field = fields[fieldKey];
	const existingValueDecorations = fieldValueDecorations[fieldKey] || [];
	const itemIndex = existingValueDecorations.findIndex(
		(decoration) => decoration.localDecorationId === localDecorationId,
	);

	if (itemIndex === -1) {
		// noop update of non-existing id
		return;
	}

	const oldDecoration = existingValueDecorations[itemIndex];

	let newValueDecorations = [...existingValueDecorations];
	newValueDecorations[itemIndex] = {
		...oldDecoration,
		...updateProps,
	};

	if (
		field.type === FIELD_TYPES.SINGLE_SELECT ||
		field.type === FIELD_TYPES.MULTI_SELECT ||
		field.type === FIELD_TYPES.JSW_MULTI_SELECT
	) {
		newValueDecorations = sanitizeSelectDecorations(newValueDecorations, filterOutDecorationRuleFn);
	}

	setState({
		fieldValueDecorations: {
			...fieldValueDecorations,
			[fieldKey]: newValueDecorations,
		},
	});

	try {
		try {
			await performJiraConfigurationUpdate({
				fieldKey,
				fieldType: field.type,
				issueTypeId,
				fieldRemote,
				existingDecorations: existingValueDecorations,
				newDecorations: newValueDecorations,
				isGlobalSystemField: fg('polaris_new_custom_types_global_system_fields')
					? isGlobalSystemField(field)
					: false,
			});

			fireCompoundAnalyticsEvent.FieldConfig.valueDecorationChanged(createAnalyticsEvent({}), {
				issueFieldKey: fieldKey,
				issueFieldType: field.type,
			});
		} catch (error) {
			setState({
				fieldValueDecorations: {
					...getState().fieldValueDecorations,
					[fieldKey]: existingValueDecorations,
				},
			});

			throw error;
		}

		if (
			field.type === FIELD_TYPES.LINKED_ISSUES &&
			fg('polaris_new_custom_types_global_system_fields')
		) {
			await dualWriteValueDecorationsBasedOnFieldType({
				fieldKey,
				fieldType: field.type,
				issueTypeId,
				fieldRemote,
				newValueDecorations,
				getState,
				setState,
			});
		}
	} catch (error) {
		onDecorationUpdateFailed(
			error instanceof Error ? error : new Error('Failed to update value decoration'),
		);
	}
};

export const getUpdatePayloadWithUpdatedRules = (
	fieldKey: FieldKey,
	decoration: ValueDecoration,
	gteValue?: string,
	ltValue?: string,
): UpdateValueDecorationProps => {
	const currentGteRule = isDecorationWithLogic(decoration)
		? undefined // TODO: Handle Decoration Logic
		: decoration.rules.find(
				(rule) => rule.operator === ValueRuleOperator.GTE || rule.operator === ValueRuleOperator.GT,
			);
	const currentLtRule = isDecorationWithLogic(decoration)
		? undefined // TODO: Handle Decoration Logic
		: decoration.rules.find(
				(rule) => rule.operator === ValueRuleOperator.LT || rule.operator === ValueRuleOperator.LTE,
			);

	const rules: Array<ValueRule> = [];
	if (currentGteRule !== undefined) {
		if (gteValue !== undefined) {
			rules.push({ ...currentGteRule, value: gteValue });
		} else {
			rules.push(currentGteRule);
		}
	}
	if (currentLtRule !== undefined) {
		if (ltValue !== undefined) {
			rules.push({ ...currentLtRule, value: ltValue });
		} else {
			rules.push(currentLtRule);
		}
	}

	return {
		fieldKey,
		localDecorationId: decoration.localDecorationId,
		backgroundColor: decoration.backgroundColor,
		emoji: decoration.emoji,
		highlightContainer: !!decoration.highlightContainer,
		rules,
	};
};
