import React, { useState, useRef, memo, useCallback, useEffect } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import usePressTracing from '@atlaskit/react-ufo/use-press-tracing';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type {
	LocalIssueId,
	OptionProperty,
} from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals/src/index.tsx';
import { OutsideClickAlerter } from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsEmbedded } from '../../../controllers/environment/index.tsx';
import { SelectEdit } from './edit/index.tsx';
import type { InnerProps } from './types.tsx';
import { SelectView } from './view/index.tsx';

interface SelectFieldInternalViewProps {
	testId?: string;
	compactForBoard?: boolean;
	fieldKey: FieldKey;
	isActive: boolean;
	isEditable: boolean;
	isMultiline: boolean;
	options: OptionProperty[];
	placeholder?: string | undefined;
	onActivate: (isActive: boolean) => void;
}

const SelectFieldInternalView = memo<SelectFieldInternalViewProps>(
	({
		compactForBoard = false,
		fieldKey,
		isActive = false,
		isMultiline,
		isEditable,
		options = [],
		placeholder,
		onActivate,
	}: SelectFieldInternalViewProps) => {
		const containerRef = useRef<HTMLDivElement | null>(null);
		const embedded = useIsEmbedded();
		const pressTracing = usePressTracing('jpd.select-field-open');
		const listeners = isActive
			? {
					onClick: () => {
						onActivate(true);
						fg('jpd-trace-ufo-press') && pressTracing();
					},
				}
			: {};

		return (
			<ReadViewContainer
				ref={containerRef}
				isMultiline={isMultiline}
				showHoverStyle={!embedded && isEditable}
				compactForBoard={compactForBoard}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="select-readview"
				{...listeners}
			>
				<SelectView
					isMultiline={isMultiline}
					containerRef={containerRef}
					fieldKey={fieldKey}
					isActive={isActive}
					isEditable={isEditable}
					options={options}
					placeholder={placeholder}
				/>
			</ReadViewContainer>
		);
	},
);

interface SelectFieldInternalEditProps {
	testId?: string;
	compactForBoard?: boolean;
	fieldKey: FieldKey;
	isEditable: boolean;
	isMulti?: boolean;
	localIssueId: LocalIssueId;
	menuPortalTarget?: HTMLElement;
	options: OptionProperty[];
	placeholder?: string | undefined;
	onActivate: (isActive: boolean) => void;
	onConfigRequested?: () => void;
	onUpdate: (options?: OptionProperty | OptionProperty[]) => void;
}

const SelectFieldInternalEdit = memo<SelectFieldInternalEditProps>(
	({
		testId,
		localIssueId,
		fieldKey,
		isMulti = false,
		menuPortalTarget,
		options: externalOptions = [],
		onActivate,
		onUpdate,
		onConfigRequested,
	}: SelectFieldInternalEditProps) => {
		// value is stored in a state to trigger a rerender when it gets updated
		const [value, setValue] = useState<OptionProperty[]>(externalOptions);

		// value is also stored in a ref to not recreate a function pointer for onCloseRequested everytime it changes
		// otherwise Components (memoized with thois callback) passed to react-select will be recreated
		// and re-instenciated. That will reset their internal state
		const self = useRef({
			previousValue: externalOptions,
			value: externalOptions,
		}).current;

		// overwrite local state if the value changes from the outside
		useEffect(() => {
			self.previousValue = externalOptions;
			self.value = externalOptions;
		}, [self, externalOptions]);

		const closeAndCommit = useCallback(
			(newValue: OptionProperty[]) => {
				onActivate(false);

				if (
					isShallowEqual(
						self.previousValue?.map(({ id }) => id),
						newValue?.map(({ id }: { id: string }) => id),
					)
				) {
					return;
				}

				self.previousValue = self.value;
				if (!newValue.length) {
					onUpdate(undefined);
				} else {
					const newValuePolimorphic = isMulti ? newValue : newValue[0];
					onUpdate(newValuePolimorphic);
				}
			},
			[isMulti, onActivate, onUpdate, self],
		);

		const onCloseRequested = useCallback(() => {
			closeAndCommit(self.value);
		}, [closeAndCommit, self]);

		const updateInternal = useCallback(
			(newValue: OptionProperty[], allowClose = true) => {
				self.value = newValue;
				setValue(newValue);
				if (!isMulti && allowClose) {
					closeAndCommit(newValue);
				}
			},
			[closeAndCommit, isMulti, self, setValue],
		);

		return (
			<OutsideClickAlerter onClickOutside={onCloseRequested}>
				{(outsideClickAlerterProps) => (
					<div {...outsideClickAlerterProps}>
						<SelectEdit
							testId={testId}
							localIssueId={localIssueId}
							value={value}
							isMulti={isMulti}
							fieldKey={fieldKey}
							onUpdate={updateInternal}
							onCloseRequested={onCloseRequested}
							onConfigRequested={onConfigRequested}
							menuPortalTarget={menuPortalTarget}
						/>
					</div>
				)}
			</OutsideClickAlerter>
		);
	},
);

export const SelectField = memo<InnerProps>(
	({
		testId,
		compactForBoard = false,
		fieldKey,
		isEditable,
		isActive = false,
		isMulti = false,
		isMultiline = false,
		localIssueId,
		menuPortalTarget,
		options,
		placeholder,
		onConfigRequested,
		onUpdate,
	}: InnerProps) => {
		const [isEditting, setIsEditting] = useState(false);

		return isEditable && isEditting ? (
			<SelectFieldInternalEdit
				testId={testId}
				fieldKey={fieldKey}
				isEditable={isEditable}
				isMulti={isMulti}
				localIssueId={localIssueId}
				menuPortalTarget={menuPortalTarget}
				options={options}
				onActivate={setIsEditting}
				onUpdate={onUpdate}
				onConfigRequested={onConfigRequested}
			/>
		) : (
			<SelectFieldInternalView
				compactForBoard={compactForBoard}
				fieldKey={fieldKey}
				isActive={isActive}
				isEditable={isEditable}
				isMultiline={isMultiline}
				options={options}
				placeholder={placeholder}
				onActivate={setIsEditting}
			/>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div<{
	showHoverStyle: boolean;
	compactForBoard: boolean;
	isMultiline?: boolean;
	className?: string;
}>({
	position: 'relative',
	borderRadius: '4px',
	marginLeft: token('space.negative.050'),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ showHoverStyle, compactForBoard }) =>
			!(!showHoverStyle || compactForBoard) &&
			token('color.background.neutral.subtle.hovered', '#ebecf0'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		padding: ({ compactForBoard }) => (compactForBoard ? 0 : undefined),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxHeight: ({ isMultiline }) => (isMultiline ? '90px' : 'initial'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	overflow: ({ isMultiline }) => (isMultiline ? 'hidden' : 'initial'),
});
