import type { ConnectionFieldFilter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';

import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { getCurrentViewFilter } from '../../selectors/filters.tsx';
import { isCurrentViewAutosaveEnabled } from '../../selectors/view/autosave/index.tsx';
import type { State, Props } from '../../types.tsx';
import { saveViewWithAutoSave } from '../save/index.tsx';
import { fireViewUpdatedEvent } from '../utils/analytics.tsx';
import { updateViewState } from '../utils/state/index.tsx';
import { currentViewFilter } from '../utils/views/index.tsx';

export const updateConnectionFieldFilter =
	(
		filter: ConnectionFieldFilter,
		onSuccess?: () => void,
		onError?: (error: Error) => void,
	): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();
		const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);
		const allFiltersAppliedOnView = getCurrentViewFilter(state, props);

		const indexOfFilter = allFiltersAppliedOnView.findIndex(
			(appliedFilter) => appliedFilter.type === filter.type && appliedFilter.field === filter.field,
		);
		const filterExistsWithinAppliedFilters = indexOfFilter !== -1;

		const newFilters = filterExistsWithinAppliedFilters
			? allFiltersAppliedOnView.map((appliedFilter, index) =>
					index === indexOfFilter ? filter : appliedFilter,
				)
			: [...allFiltersAppliedOnView, filter];

		const viewMutation = (view: View): View => {
			return {
				...view,
				filter: isAutosaveEnabled ? newFilters : view.filter,
				modified: isAutosaveEnabled,
				draft: {
					...view.draft,
					filter: newFilters,
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });

			dispatch(
				saveViewWithAutoSave(changedView.id, (view) => {
					if (view === undefined) {
						return;
					}

					if (view.saveError === undefined) {
						return onSuccess?.();
					}

					return onError?.(view.saveError);
				}),
			);

			fireViewUpdatedEvent(props.createAnalyticsEvent, changedView, {
				updatedItems: [{ name: 'filter' }],
			});
		}
	};
