import React from 'react';
import { styled } from '@compiled/react';
import type { MediaClientConfig } from '@atlaskit/media-core';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { CardContent } from '@atlassian/jira-polaris-domain-insight/src/snippet/card/types.tsx';
import { CardPreview } from './preview/index.tsx';

type CardProps = {
	content?: CardContent;
	url: string;
	mediaClientConfig: MediaClientConfig;
};

export const Card = ({ content, url, mediaClientConfig }: CardProps) => {
	const onClick = (result: { mediaItemDetails?: unknown }) => {
		if (result.mediaItemDetails === undefined || result.mediaItemDetails === null) {
			return;
		}

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(url, '_blank');
	};
	return (
		<>
			{content?.description !== undefined ? (
				<Description>
					<Content>{content.description}</Content>
				</Description>
			) : null}
			{content?.preview !== undefined ? (
				<CardPreview
					preview={content?.preview}
					onClick={onClick}
					mediaClientConfig={mediaClientConfig}
				/>
			) : null}
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.div({
	wordBreak: 'break-word',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N700),
	paddingBottom: '0px',
	paddingRight: token('space.200'),

	font: token('font.body'),
	marginTop: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	display: '-webkit-box',
	WebkitLineClamp: '4',
	WebkitBoxOrient: 'vertical',
	overflow: 'hidden',
});
