import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals/src/index.tsx';
import {
	isPermissionError,
	isRateLimitError,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import {
	jpdProjectPageLoad,
	PAGE_LOAD_MARK_LOAD_PROJECT_END,
	PAGE_LOAD_MARK_LOAD_PROJECT_START,
} from '@atlassian/jira-polaris-component-metrics/src/project.tsx';
import { EMPTY_PROJECT_STATE, EMPTY_STATE } from '../constants.tsx';
import type { State, Props } from '../types.tsx';
import { fetch } from './fetch.tsx';

export const loadProject =
	(reloadMode = false, fireAnalyticsEvent = false): Action<State, Props> =>
	async (
		{ getState, setState },
		{ containerAri, projectFetcher, onProjectLoadingError, isSiteAdmin },
	) => {
		const loadingProps = { containerAri };
		const {
			meta: { loadingProps: previousLoadingProps },
		} = getState();
		const isLoadingPropsChanged = !isShallowEqual(loadingProps, previousLoadingProps);

		// Only load again when the props this function depends on have changed
		if (!reloadMode && !isLoadingPropsChanged) {
			return;
		}

		setState({
			...EMPTY_STATE,
			meta: {
				...getState().meta,
				loadingProps,
				project: { loading: true, error: undefined },
			},
			projectTemplate: getState().projectTemplate,
		});

		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_PROJECT_START);

		try {
			const project = await fetch({
				projectFetcher,
				containerAri,
				noCache: reloadMode || isLoadingPropsChanged,
				fireAnalyticsEvent,
				isSiteAdmin,
			});
			jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_PROJECT_END);

			setState({
				meta: {
					...getState().meta,
					loadingProps,
					project: { loading: false, error: undefined },
				},
				project: {
					...project,
					plays: getState().project.plays,
					insights: getState().project.insights,
				},
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_PROJECT_END);

			setState({
				meta: {
					...getState().meta,
					loadingProps: undefined,
					project: { loading: false, error },
				},
				project: {
					...EMPTY_PROJECT_STATE,
					plays: getState().project.plays,
					insights: getState().project.insights,
				},
			});

			onProjectLoadingError(error);
			if (!isPermissionError(error) && !isClientFetchError(error.networkError)) {
				if (isRateLimitError(error) || isRateLimitError(error.networkError)) {
					experience.project.pageLoadWithRateLimit.failure(error);
					experience.project.pageLoad.abort(error);
				} else {
					experience.project.pageLoad.failure(error);
					experience.project.pageLoadWithRateLimit.abort(error);
				}
			} else {
				experience.project.pageLoad.abort(error);
			}
		}
	};
