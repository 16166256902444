import React, { type SyntheticEvent, useCallback, useMemo, useState, useEffect } from 'react';
import { IconButton } from '@atlaskit/button/new';
import { Checkbox } from '@atlaskit/checkbox';
import EditorSearchIcon from '@atlaskit/icon/core/migration/search--editor-search';
import SelectClearIcon from '@atlaskit/icon/core/migration/cross-circle--select-clear';
import { JiraProductDiscoveryIcon, JiraIcon } from '@atlaskit/logo';
import { Box, Flex, Inline, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { RoundedButton } from '@atlassian/jira-polaris-lib-rounded-button/src/ui/index.tsx';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { FieldType } from '../../types.tsx';
import { Explanation } from './explanation/index.tsx';
import { GlobalFieldItem } from './global-field-item/index.tsx';
import messages from './messages.tsx';
import { GlobalFieldsSkeleton, SelectionRowSkeleton } from './skeleton/index.tsx';
import type { GlobalFieldsSelectorProps } from './types.tsx';

export const GlobalFieldsSelector = ({
	loading,
	fields,
	selectedFields,
	onFieldSelectionChange,
	fieldTypeFilter,
	setFieldTypeFilter,
	onSelectAllFields,
	onUnselectAllFields,
	onOpenFieldDetails,
}: GlobalFieldsSelectorProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const [searchValue, setSearchValue] = useState<string>('');
	const [selectedFieldsSet, updateSelectedFieldsSet] = useState<Set<string>>(
		new Set(selectedFields),
	);
	const [selectAll, setSelectAll] = useState<boolean>(false);

	useEffect(() => {
		updateSelectedFieldsSet(new Set(selectedFields));
	}, [selectedFields]);

	const filteredFields = useMemo(() => {
		const filteredByOrigin = fields.filter(
			(field) =>
				(fieldTypeFilter === 'jpd' && field.origin === 'jpd') ||
				(fieldTypeFilter === 'jira' && field.origin === undefined),
		);

		const normalizedSearchValue = searchValue.trim().toLocaleLowerCase();
		if (!normalizedSearchValue) {
			return filteredByOrigin;
		}

		return filteredByOrigin.filter((field) =>
			field.label.toLocaleLowerCase().includes(normalizedSearchValue),
		);
	}, [fields, searchValue, fieldTypeFilter]);

	const onSelectAllToggled = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'selectAllGlobalFields', {
				checked: !selectAll,
			});

			setSelectAll(!selectAll);

			if (selectAll) {
				onUnselectAllFields();
			} else {
				onSelectAllFields(fieldTypeFilter);
			}
		},
		[selectAll, onSelectAllFields, fieldTypeFilter, onUnselectAllFields],
	);

	useEffect(() => {
		if (!selectedFields.length) {
			return;
		}
		if (!selectAll && selectedFields.length === filteredFields.length) {
			setSelectAll(true);
			return;
		}
		if (selectAll && selectedFields.length !== filteredFields.length) {
			setSelectAll(false);
		}
	}, [selectedFields, filteredFields.length, selectAll]);

	const handleTypeFilterClick = useCallback(
		(type: FieldType, analyticsEvent: UIAnalyticsEvent) => {
			if (fieldTypeFilter === type) {
				return;
			}
			fireUIAnalytics(analyticsEvent, 'originFilter', { originFilterValue: type });
			setSelectAll(false);
			onUnselectAllFields();
			setFieldTypeFilter(type);
		},
		[fieldTypeFilter, setFieldTypeFilter, onUnselectAllFields],
	);

	const noSearchResults = !loading && filteredFields.length === 0;

	return (
		<Flex direction="column" xcss={sectionStyles}>
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<p>{formatMessage(messages.description)}</p>
			<Box xcss={searchWrapperStyles}>
				<Textfield
					value={searchValue}
					placeholder={formatMessage(messages.searchFields)}
					isCompact
					onChange={(event) => {
						fireUIAnalytics(createAnalyticsEvent({}), 'input updated', 'searchFields');
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const target = event.target as HTMLInputElement;
						setSearchValue(target.value);
					}}
					elemBeforeInput={
						<EditorSearchIcon
							spacing="spacious"
							color={token('color.icon', colors.N100)}
							label=""
						/>
					}
					elemAfterInput={
						searchValue && (
							<IconButton
								icon={() => (
									<SelectClearIcon label="" LEGACY_size="small" color={token('color.icon')} />
								)}
								appearance="subtle"
								spacing="compact"
								onClick={(_event, analyticsEvent) => {
									fireUIAnalytics(analyticsEvent, 'clearSearchFields');
									setSearchValue('');
								}}
								label={formatMessage(messages.resetSearchAriaLabel)}
							/>
						)
					}
				/>
			</Box>
			{noSearchResults && (
				<Box xcss={fieldsCountContainerStyles}>
					{formatMessage(messages.fieldsCount, { fieldsCount: 0 })}
				</Box>
			)}
			<Inline space="space.100">
				<RoundedButton
					testId="polaris-ideas.ui.right-sidebar.global-fields.global-field-list.global-fields-selector.jpd-filter"
					isSelected={fieldTypeFilter === 'jpd'}
					iconBefore={
						<JiraProductDiscoveryIcon
							appearance={fieldTypeFilter === 'jpd' ? 'brand' : 'neutral'}
							size="small"
						/>
					}
					onClick={(_event, analyticsEvent) => handleTypeFilterClick('jpd', analyticsEvent)}
				>
					{formatMessage(messages.discoveryFields)}
				</RoundedButton>
				<RoundedButton
					testId="polaris-ideas.ui.right-sidebar.global-fields.global-field-list.global-fields-selector.jira-filter"
					isSelected={fieldTypeFilter === 'jira'}
					iconBefore={
						<JiraIcon appearance={fieldTypeFilter === 'jira' ? 'brand' : 'neutral'} size="small" />
					}
					onClick={(_event, analyticsEvent) => handleTypeFilterClick('jira', analyticsEvent)}
				>
					{formatMessage(messages.jiraFields)}
				</RoundedButton>
				<Explanation />
			</Inline>
			{noSearchResults ? (
				<Box xcss={noFieldsStyles}>{formatMessage(messages.noFieldsFound)}</Box>
			) : (
				<>
					<Box xcss={selectAllFieldsStyles}>
						{!loading ? (
							<Checkbox
								label={
									filteredFields.length === 1
										? formatMessage(
												fieldTypeFilter === 'jpd'
													? messages.selectOneDiscovery
													: messages.selectOneJira,
											)
										: formatMessage(
												fieldTypeFilter === 'jpd'
													? messages.selectAllDiscovery
													: messages.selectAllJira,
												{
													fieldsCount: filteredFields.length,
												},
											)
								}
								onChange={onSelectAllToggled}
								isChecked={selectAll}
							/>
						) : (
							<SelectionRowSkeleton />
						)}
					</Box>
					<Box xcss={fieldsContainerStyles}>
						{loading ? (
							<GlobalFieldsSkeleton />
						) : (
							filteredFields.map((field) => (
								<GlobalFieldItem
									key={field.key}
									field={field}
									isChecked={selectedFieldsSet.has(field.key)}
									onFieldSelectionChange={onFieldSelectionChange}
									onOpenFieldDetails={onOpenFieldDetails}
								/>
							))
						)}
					</Box>
				</>
			)}
		</Flex>
	);
};

const sectionStyles = xcss({
	height: '100%',
});

const fieldsContainerStyles = xcss({
	overflowY: 'auto',
});

const searchWrapperStyles = xcss({
	marginTop: 'space.150',
	marginBottom: 'space.200',
});

const selectAllFieldsStyles = xcss({
	borderBottom: '1px solid #dee0e4',
	paddingTop: 'space.200',
	paddingBottom: 'space.150',
	paddingLeft: 'space.150',
	color: 'color.text.subtle',
	fontWeight: token('font.weight.medium'),
});

const fieldsCountContainerStyles = xcss({
	marginTop: 'space.negative.100',
	marginBottom: 'space.150',
	color: 'color.text.subtlest',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.UNSAFE_small', fontFallback.body.UNSAFE_small),
});

const noFieldsStyles = xcss({
	paddingTop: 'space.150',
	color: 'color.text.subtlest',
	fontWeight: token('font.weight.medium'),
});
