/** @jsx jsx */
import { type ReactNode, createContext, useCallback, useContext, memo, useState } from 'react';
import { css, styled, jsx } from '@compiled/react';
import noop from 'lodash/noop';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Inline } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	issueKey: string;
	summary: string;

	// Introduced with FG: jpd_issues_relationships, should become obligatory during cleanup,
	// otherwise it means we still have some places in the UI where we do not use issue type icons
	icon?: ReactNode;
};

type ShortLabelContextType = {
	notifyOfKeyContainerWidth: (arg1: number) => void;
};

const ShortLabelContext = createContext<ShortLabelContextType>({
	notifyOfKeyContainerWidth: noop,
});

/**
 * needs to have an AlignedShortLabelContext somewhere above in the dom hierarchy
 * in order to correctly align the key divs.
 */
export const ShortLabel = memo<Props>(({ issueKey, summary, icon }: Props) => {
	const { notifyOfKeyContainerWidth } = useContext(ShortLabelContext);

	return fg('jpd_issues_relationships') ? (
		<Inline space="space.050" alignBlock="center">
			{icon}
			<div
				ref={(ref) => {
					if (ref !== null) {
						notifyOfKeyContainerWidth(ref.getBoundingClientRect().width);
					}
				}}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="short-label-key-container"
				css={keyStyles}
			>
				{issueKey}
			</div>
			<Summary>{summary}</Summary>
		</Inline>
	) : (
		<Container>
			<Key
				ref={(ref) => {
					if (ref !== null) {
						notifyOfKeyContainerWidth(ref.getBoundingClientRect().width);
					}
				}}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="short-label-key-container"
			>
				{issueKey}
			</Key>
			<Summary>{summary}</Summary>
		</Container>
	);
});

/**
 * use this anywhere in the dom hierarchy above the place where you want to coordinate
 * issue short labels. This provides context to all ShortLabel elements below and computes
 * the correct issue key div width.
 */
export const AlignedShortLabelContext = ({ children }: { children: ReactNode }) => {
	const [maxKeyContainerWidth, setMaxKeyContainerWidth] = useState(0);

	const notifyOfKeyContainerWidth = useCallback(
		(width: number) => {
			if (width > maxKeyContainerWidth) {
				setMaxKeyContainerWidth(width);
			}
		},
		[maxKeyContainerWidth, setMaxKeyContainerWidth],
	);

	return (
		<AlignedShortLabelContextContainer minWidth={maxKeyContainerWidth}>
			<ShortLabelContext.Provider
				value={{
					notifyOfKeyContainerWidth,
				}}
			>
				{children}
			</ShortLabelContext.Provider>
		</AlignedShortLabelContextContainer>
	);
};

const keyStyles = css({
	color: token('color.text.subtle'),
	flex: '0 0 auto',
	font: token('font.body.small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Key = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N200),
	flex: '0 0 auto',
	marginRight: token('space.050'),

	font: token('font.body.UNSAFE_small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Summary = styled.div({
	flex: '1 1 auto',

	font: token('font.body'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AlignedShortLabelContextContainer = styled.div<{ minWidth: string | number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div.short-label-key-container': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		minWidth: ({ minWidth }) => `${minWidth}px`,
	},
});
