import React, { useEffect, useState } from 'react';
import differenceBy from 'lodash/differenceBy';
import isEqual from 'lodash/isEqual';
import type { MultiValue } from '@atlaskit/react-select';
import Select, { type SelectComponentsConfig, type StylesConfig } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { OutsideClickAlerter } from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';
import { useOpenIdeaViewFieldSidebar } from '../../../../controllers/idea/utils/idea-view.tsx';
import {
	isSelectableOption,
	type ConnectionFieldOption,
	type ConnectionFieldProps,
} from '../types.tsx';
import { filterOption, useConnectionFieldOptions } from '../utils.tsx';
import { MAX_OPTIONS } from './constants.tsx';
import { ConnectionFieldSelectContext } from './context/index.tsx';
import { ConnectionFieldControl } from './control/index.tsx';
import { ConnectionFieldGroupHeading } from './group-heading/index.tsx';
import { ConnectionFieldMenuList } from './menu-list/index.tsx';
import messages from './messages.tsx';
import { NoOptionsMessage } from './no-options-message/index.tsx';
import { ConnectionFieldIssueOption } from './option/index.tsx';

type Props = ConnectionFieldProps & {
	onClose: () => void;
};

const customComponents: SelectComponentsConfig<ConnectionFieldOption, true> = {
	MultiValueContainer: () => null,
	Input: () => null,
	ClearIndicator: () => null,
	Control: ConnectionFieldControl,
	MenuList: ConnectionFieldMenuList<true>,
	Option: ConnectionFieldIssueOption<true>,
	GroupHeading: ConnectionFieldGroupHeading<true>,
	NoOptionsMessage: NoOptionsMessage<true>,
};

export const ConnectionFieldEditView = ({
	onClose,
	localIssueId,
	fieldKey,
	menuPortalTarget,
	onUpdate,
	issueCardType,
}: Props) => {
	const { formatMessage } = useIntl();

	const openIdeaViewFieldSidebar = useOpenIdeaViewFieldSidebar(localIssueId);
	const [allIssues, linkedIssues] = useConnectionFieldOptions(
		localIssueId,
		fieldKey,
		issueCardType,
	);

	const [value, setValue] = useState<MultiValue<ConnectionFieldOption>>(linkedIssues);
	const [inputValue, setInputValue] = useState('');
	const [menuIsOpen, setMenuIsOpen] = useState(false);

	// opens menu faster rather then passing menuIsOpen={true} to Select
	// investigate if it can be improved https://pi-dev-sandbox.atlassian.net/browse/POL-12366
	useEffect(() => {
		setMenuIsOpen(true);
	}, []);

	const selectedOptions = value.filter((issue) => filterOption(issue, inputValue));
	const allOptions = allIssues
		.filter((issue) => filterOption(issue, inputValue))
		.slice(0, MAX_OPTIONS);

	const localOptions = [
		{ options: selectedOptions },
		{
			label: formatMessage(messages.projectGroupHeadingNonFinal),
			options: differenceBy(allOptions, selectedOptions, 'value'),
		},
	];

	const onCloseRequested = () => {
		if (!isEqual(linkedIssues, value)) {
			onUpdate(
				value.filter(isSelectableOption).map((option) => ({
					id: option.value,
				})),
			);
		}

		onClose();
	};

	const onKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Esc' || event.key === 'Escape') {
			onCloseRequested();
		}
	};

	const onEditFieldRequested = () => {
		openIdeaViewFieldSidebar(fieldKey);
		onCloseRequested();
	};

	return (
		<OutsideClickAlerter onClickOutside={onCloseRequested}>
			{(outsideClickAlerterProps) => (
				<div {...outsideClickAlerterProps}>
					<ConnectionFieldSelectContext.Provider value={{ onEditFieldRequested }}>
						<Select<ConnectionFieldOption, true>
							isMulti
							autoFocus
							isSearchable
							isClearable
							menuIsOpen={menuIsOpen}
							hideSelectedOptions={false}
							components={customComponents}
							onChange={setValue}
							value={value}
							onKeyDown={onKeyDown}
							onInputChange={setInputValue}
							inputValue={inputValue}
							options={localOptions}
							filterOption={() => true}
							enableAnimation={false}
							menuPlacement="auto"
							maxMenuHeight={390}
							styles={stylesConfig}
							menuPortalTarget={menuPortalTarget}
						/>
					</ConnectionFieldSelectContext.Provider>
				</div>
			)}
		</OutsideClickAlerter>
	);
};

const stylesConfig: StylesConfig<ConnectionFieldOption, true> = {
	menu: (styles) => ({
		...styles,
		zIndex: 100,
		right: 0,
		minWidth: '400px',
	}),
	menuList: (styles) => ({
		...styles,
		padding: 0,
	}),
	groupHeading: (styles) => ({
		...styles,
		marginBottom: '7px',
		':empty': {
			display: 'none',
		},
	}),
	group: (styles) => ({
		...styles,
		padding: `${token('space.075')} 0 ${token('space.075')} 0`,
		':not(:first-of-type)': {
			borderTop: `1px solid ${token('color.border')}`,
		},
	}),
	valueContainer: (styles) => ({
		...styles,
		display: 'flex',
	}),
	noOptionsMessage: (styles) => ({
		...styles,
		padding: `${token('space.150')}`,
	}),
	control: (styles) => ({
		...styles,
		flexWrap: 'nowrap',
		'&&': {
			paddingLeft: token('space.050'),
			paddingRight: token('space.050'),
		},
	}),
};
