import React, { useRef, useState, useEffect, useCallback, type RefObject } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { ensureNonNullable } from '@atlassian/jira-polaris-lib-ts-utils/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import type { DropResult } from '../../types/draggable.tsx';
import type { BoardWithoutGroupsProps } from '../../types/ui.tsx';
import { useBoardDND, type UseBoardDNDProps } from '../../utils/draggable.tsx';
import { useDropCallback } from '../../utils/drop-handler.tsx';
import { useBoardVirtualization } from '../../utils/use-board-virtualization/main.tsx';
import { AddColumn, type AddColumnProps } from '../add-column/index.tsx';
import { BoardDimensionsProvider } from '../board-dimensions-provider/index.tsx';
import { Column } from '../column/index.tsx';

type BoardWithoutGroupsPropsWithRef = Omit<BoardWithoutGroupsProps, 'mode'> & {
	scrollContainerRef: RefObject<HTMLDivElement>;
};

const BoardWithoutGroups = ({
	scrollContainerRef,
	extendedOptions,
	isExporting,
	isReadOnly,
	isFooterVisible,
	canAddColumns,
	isMoveBetweenColumnsDisabled,
	isGlobalCustomField,
	isCardDropDisabledForColumn,
	onCardDrag,
	onCardDrop,
	onColumnDrop,
	unfilteredIdsByColumn,
	idsByColumn,
	onCardDropChange,
	onCardDropClear,
	onCardDropStart,
	rankAfter,
	rankBefore,
	onAddColumn,
	components,
	hideEmptyColumns,
	columnsHighlightColors,
}: BoardWithoutGroupsPropsWithRef) => {
	const [newlyCreatedOptionId, setNewlyCreatedOptionId] = useState<unknown>(undefined);
	const addColumnRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (newlyCreatedOptionId !== undefined && addColumnRef.current) {
			addColumnRef.current.scrollIntoView();
			setNewlyCreatedOptionId(undefined);
		}
	}, [newlyCreatedOptionId]);

	const handleDropCallback = useDropCallback({
		extendedOptions,
		onColumnDrop,
		unfilteredIdsByColumn,
		idsByColumn,
		onCardDropChange,
		onCardDropClear,
		onCardDropStart,
		rankAfter,
		rankBefore,
	});

	const handleCardDrop: UseBoardDNDProps['onDrop'] = useCallback(
		(...args) => {
			onCardDrop();
			handleDropCallback(...args);
		},
		[handleDropCallback, onCardDrop],
	);

	const shouldInterruptDnd = useCallback(
		({ source, destination }: DropResult) => {
			const sourceColumnId = source?.droppableId;
			const destinationColumnId = destination?.droppableId;

			return isMoveBetweenColumnsDisabled && sourceColumnId !== destinationColumnId;
		},
		[isMoveBetweenColumnsDisabled],
	);

	useBoardDND({
		boardContainerRef: scrollContainerRef,
		onDrag: onCardDrag,
		onDrop: handleCardDrop,
		shouldInterruptDnd,
	});

	const handleAddColumn: AddColumnProps['onAddColumn'] = useCallback(
		async (event, analyticsEvent) => {
			const newOptionId = await onAddColumn(event, analyticsEvent);
			setNewlyCreatedOptionId(newOptionId);
		},
		[onAddColumn],
	);

	return (
		<UFOSegment name="jpd.board-content">
			<BoardWrapper
				ref={scrollContainerRef}
				data-testid="polaris-lib-board.ui.board-without-groups.board-wrapper"
				isExporting={isExporting}
			>
				<BoardContainer>
					{extendedOptions.map((option, index) => (
						<Column
							key={`${index}-${option.groupIdentity || ''}`}
							droppableId={option.droppableId}
							groupIdentity={option.groupIdentity}
							index={index}
							fieldValue={option.value}
							isCardDropDisabled={isReadOnly || isCardDropDisabledForColumn(option.groupIdentity)}
							isReadOnly={isReadOnly}
							isFooterVisible={isFooterVisible}
							isLastColumn={index === extendedOptions.length - 1}
							components={components}
							idsByColumn={idsByColumn}
							unfilteredIdsByColumn={unfilteredIdsByColumn}
							hideEmptyColumns={hideEmptyColumns}
							highlightColor={ensureNonNullable(columnsHighlightColors.get(option.groupIdentity))}
						/>
					))}
					{canAddColumns && !isGlobalCustomField && (
						<div
							ref={addColumnRef}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className="hide-from-export"
						>
							<AddColumn onAddColumn={handleAddColumn} />
						</div>
					)}
				</BoardContainer>
			</BoardWrapper>
		</UFOSegment>
	);
};

BoardWithoutGroups.displayName = 'BoardWithoutGroups';

export const BoardWithoutGroupsWrapper = (props: BoardWithoutGroupsProps) => {
	const {
		groups,
		extendedOptions,
		isReadOnly,
		isFooterVisible,
		canAddColumns,
		isExporting,
		isMoveBetweenColumnsDisabled,
		isGlobalCustomField,
		isCardDropDisabledForColumn,
		onCardDrag,
		onCardDrop,
		idsByColumn,
		unfilteredIdsByColumn,
		onCardDropChange,
		onCardDropClear,
		onCardDropStart,
		onColumnDrop,
		rankAfter,
		rankBefore,
		onAddColumn,
		columnsHighlightColors,
		components,
		hideEmptyColumns,
	} = props;

	const scrollContainerRef = useRef<HTMLDivElement | null>(null);

	const { groupColumnContentHeights, subscribeToColumnChanges, unsubscribeToColumnChanges } =
		useBoardVirtualization({
			groups,
			containerRef: scrollContainerRef,
			isReadOnly,
			isFooterVisible,
			isExporting,
			hasColumnWithHeaders: true,
		});

	return (
		<>
			<BoardDimensionsProvider
				groupColumnContentHeights={groupColumnContentHeights}
				subscribeToColumnChanges={subscribeToColumnChanges}
				unsubscribeToColumnChanges={unsubscribeToColumnChanges}
			>
				<BoardWithoutGroups
					scrollContainerRef={scrollContainerRef}
					canAddColumns={canAddColumns}
					columnsHighlightColors={columnsHighlightColors}
					components={components}
					extendedOptions={extendedOptions}
					groups={groups}
					hideEmptyColumns={hideEmptyColumns}
					idsByColumn={idsByColumn}
					unfilteredIdsByColumn={unfilteredIdsByColumn}
					isExporting={isExporting}
					isFooterVisible={isFooterVisible}
					isGlobalCustomField={isGlobalCustomField}
					isMoveBetweenColumnsDisabled={isMoveBetweenColumnsDisabled}
					isReadOnly={isReadOnly}
					isCardDropDisabledForColumn={isCardDropDisabledForColumn}
					onAddColumn={onAddColumn}
					onCardDrag={onCardDrag}
					onCardDrop={onCardDrop}
					onCardDropChange={onCardDropChange}
					onCardDropClear={onCardDropClear}
					onCardDropStart={onCardDropStart}
					onColumnDrop={onColumnDrop}
					rankAfter={rankAfter}
					rankBefore={rankBefore}
				/>
			</BoardDimensionsProvider>
		</>
	);
};

BoardWithoutGroupsWrapper.displayName = 'BoardWithoutGroupsWrapper';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-start',
	width: '100%',
	height: 'calc(100% - 16px)',
	paddingBottom: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardWrapper = styled.div<{ isExporting: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	overflow: ({ isExporting }) => (isExporting ? 'visible' : 'auto'),
	display: 'flex',
	width: '100%',
	flexDirection: 'row',
});
