import React from 'react';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import {
	isArrayIntervalFieldFilterLegacyValue,
	isIntervalFieldFilterLegacy,
} from '@atlassian/jira-polaris-domain-view/src/filter/utils.tsx';
import {
	useFieldEmoji,
	useFieldTypeIcon,
} from '../../../../controllers/field/selectors/field-hooks.tsx';
import {
	useIntervalFilter,
	useNumericFilter,
} from '../../../../controllers/views/selectors/filters-hooks.tsx';
import type { FilterComponentProps } from '../types.tsx';

import { useViewActions } from '../../../../controllers/views/main.tsx';
import { FilterWrapper } from '../filter-wrapper/filter-wrapper.tsx';
import { NumDataPointsFilterComponent } from '../num-data-points/index.tsx';

export const InsightsFilter = ({
	field,
	isLastFilter,
	defaultOpen,
	isDisabled,
	isUsingCustomColor,
}: FilterComponentProps) => {
	const numericFilter = useNumericFilter(field.key);
	const intervalFilter = useIntervalFilter(field.key);

	const { updateFilters } = useViewActions();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fieldTypeIcon = useFieldTypeIcon(field.key, undefined);
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);

	return (
		<FilterWrapper>
			<NumDataPointsFilterComponent
				label={field.label}
				values={numericFilter.values}
				intervalValues={
					isArrayIntervalFieldFilterLegacyValue(intervalFilter.values) ? intervalFilter.values : []
				}
				fieldTypeIcon={fieldTypeIcon}
				emoji={emoji}
				isLastFilter={isLastFilter}
				defaultOpen={defaultOpen}
				onChange={(values) => {
					fireUIAnalytics(createAnalyticsEvent({}), 'insightsFilter updated', 'view-controls', {
						fieldKey: field.key,
					});
					experience.headerView.viewFilter.start();
					updateFilters(
						[
							{
								...numericFilter,
								values,
							},
							{
								...intervalFilter,
								values: [],
							},
						],
						() => {
							experience.headerView.viewFilter.success();
						},
						(error?: Error) => {
							experience.headerView.viewFilter.failure(error);
						},
					);
				}}
				onChangeIntervalValue={(values) => {
					if (!isIntervalFieldFilterLegacy(intervalFilter)) {
						throw new Error('Expected IntervalFieldFilterLegacy');
					}

					fireUIAnalytics(createAnalyticsEvent({}), 'intervalFilter updated', 'view-controls', {
						fieldKey: field.key,
					});
					experience.headerView.viewFilter.start();

					updateFilters(
						[
							{
								...numericFilter,
								values: [],
							},
							{
								...intervalFilter,
								values,
							},
						],
						() => {
							experience.headerView.viewFilter.success();
						},
						(error?: Error) => {
							experience.headerView.viewFilter.failure(error);
						},
					);
				}}
				isDisabled={isDisabled}
				isUsingCustomColor={isUsingCustomColor}
			/>
		</FilterWrapper>
	);
};
