import type { StylesConfig } from '@atlaskit/select';
import { N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export type SelectFieldValue = {
	id: string;
};

export const stylesConfig: StylesConfig<SelectFieldValue, true> = {
	menu: (styles) => ({
		...styles,
		width: '240px',
		zIndex: 100,
	}),
	groupHeading: (styles) => ({
		...styles,
		':empty': {
			display: 'none',
		},
	}),
	group: (styles) => ({
		...styles,
		padding: `${token('space.075')} 0 ${token('space.075')} 0`,
		':not(:first-of-type)': {
			borderTop: `1px solid ${token('color.border', N40)}`,
		},
	}),
};
