import React, { type SyntheticEvent, useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import * as colors from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { useIsSelectedIssueArchived } from '../../../../../controllers/issue/selectors/properties/hooks.tsx';
import messages from './messages.tsx';

type ConnectProps = {
	onConfiguration: () => void;
};

export const Connect = ({ onConfiguration }: ConnectProps) => {
	const { formatMessage } = useIntl();
	const isIdeaArchived = useIsSelectedIssueArchived();
	const icon = iconForPolarisFieldType(FIELD_TYPES.ATLAS_PROJECT);
	const onClick = useCallback(
		(evt: SyntheticEvent) => {
			onConfiguration();
			evt.preventDefault();
			evt.stopPropagation();
		},
		[onConfiguration],
	);
	return (
		<Container>
			{icon}
			<Button
				isDisabled={isIdeaArchived}
				onClick={onClick}
				appearance="subtle-link"
				spacing="none"
				interactionName="jpd.right-sidebar.field-edit"
			>
				{formatMessage(messages.atlasConnect)}
			</Button>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.icon.subtle', colors.N100),
	height: '28px',
	padding: '0 3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > *:first-child': {
		marginRight: token('space.075'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > *:last-child': {
		minWidth: 0,
	},
});
