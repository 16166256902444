import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useStringValue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { whyDidYouRenderPlaceholder } from '@atlassian/jira-polaris-why-did-you-render-placeholder/src/index.tsx';

type Props = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
};

export const ReadOnlySummaryField = ({ fieldKey, localIssueId }: Props) => {
	const value = useStringValue(fieldKey, localIssueId);

	if (fg('jpd_issues_relationships')) {
		return (
			<Box
				testId="polaris-ideas.ui.view-content.idea-list.cell.cells.link-field.read-only.container"
				xcss={summaryStyles}
			>
				{value}
			</Box>
		);
	}

	return (
		<>
			{whyDidYouRenderPlaceholder('ReadOnlySummaryField')}
			<div
				data-testid="polaris-ideas.ui.view-content.idea-list.cell.cells.link-field.read-only.container"
				// eslint-disable-next-line jira/react/no-style-attribute
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					overflow: 'hidden',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					textOverflow: 'ellipsis',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					whiteSpace: 'nowrap',
					padding: `${token('space.100')} ${token('space.075')} ${token('space.075')}`,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					border: '2px solid transparent',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					boxSizing: 'border-box',
				}}
			>
				{value}
			</div>
		</>
	);
};

const summaryStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
