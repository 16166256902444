import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { isIssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/index.tsx';
import { hasTeamName } from '@atlassian/jira-polaris-domain-field/src/field-types/team/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { TeamValue } from '@atlassian/jira-polaris-lib-team-value/src/ui/index.tsx';
import { useAllExternalReferenceEntities } from '../../../../controllers/issue/selectors/external-reference-hooks.tsx';
import { useFieldMapping } from '../../../../controllers/issue/selectors/fields-hooks.tsx';
import { useSummaries } from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { useLocalIssueIdsByJiraIssueId } from '../../../../controllers/issue/selectors/issue-ids-hooks.tsx';
import messages from './messages.tsx';
import {
	ConnectionOptionRenderer,
	connectionNoValueOptionMessage,
} from './renderers/connection/index.tsx';
import { DefaultOptionRenderer, defaultNoValueOptionMessage } from './renderers/default/index.tsx';
import { ExternalReferencePropertyOptionRenderer } from './renderers/external-reference-property/index.tsx';
import { ExternalReferenceOptionRenderer } from './renderers/external-reference/index.tsx';
import { GoalsOptionRenderer } from './renderers/goals/index.tsx';
import { IssueTypeOptionRenderer } from './renderers/issue-type/index.tsx';
import { LabelOptionRenderer, labelNoValueOptionMessage } from './renderers/label/index.tsx';
import { OptionOptionRenderer, optionNoValueOptionMessage } from './renderers/option/index.tsx';
import { ProjectOptionRenderer } from './renderers/project/index.tsx';
import { ReactionOptionRenderer } from './renderers/reactions/index.tsx';
import { StatusOptionRenderer } from './renderers/status/index.tsx';
import { UserOptionRenderer, userNoValueOptionMessage } from './renderers/user/index.tsx';

type Props = {
	field: Field;
	groupIdentity: string | undefined;
	value: unknown;
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { BasicIssueOptionRenderer } from './renderers/basic-issue/index.tsx';

export const VariableOptionRenderer = ({ field, groupIdentity, value }: Props) => {
	switch (field.type) {
		case FIELD_TYPES.ASSIGNEE:
		case FIELD_TYPES.CREATOR:
		case FIELD_TYPES.REPORTER:
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
			return <UserOptionRenderer groupIdentity={groupIdentity} />;
		case FIELD_TYPES.STATUS:
			return <StatusOptionRenderer groupIdentity={groupIdentity} />;
		case FIELD_TYPES.SINGLE_SELECT:
		case FIELD_TYPES.MULTI_SELECT:
		case FIELD_TYPES.JSW_MULTI_SELECT:
			return <OptionOptionRenderer field={field} groupIdentity={groupIdentity} />;
		case FIELD_TYPES.LABELS:
		case FIELD_TYPES.CUSTOM_LABELS:
			return <LabelOptionRenderer field={field} groupIdentity={groupIdentity} />;
		case FIELD_TYPES.ATLAS_GOAL:
		case FIELD_TYPES.ATLAS_PROJECT:
			return <ExternalReferenceOptionRenderer field={field} groupIdentity={groupIdentity} />;
		case FIELD_TYPES.ATLAS_PROJECT_STATUS:
			return (
				<ExternalReferencePropertyOptionRenderer field={field} groupIdentity={groupIdentity} />
			);
		case FIELD_TYPES.REACTIONS:
			return <ReactionOptionRenderer groupIdentity={groupIdentity} />;
		case FIELD_TYPES.PROJECT:
			return <ProjectOptionRenderer groupIdentity={groupIdentity} />;
		case FIELD_TYPES.CONNECTION:
			return <ConnectionOptionRenderer groupIdentity={groupIdentity} />;
		case FIELD_TYPES.PLATFORM_GOALS: {
			if (!fg('jpd_platform_goals_field_support')) {
				if (field.type === FIELD_TYPES.PLATFORM_GOALS) {
					break;
				}
			}
			return <GoalsOptionRenderer field={field} groupIdentity={groupIdentity} />;
		}
		case FIELD_TYPES.ISSUE_TYPE: {
			// You can safely remove this whole if statement during FG cleanup
			if (!fg('jpd_issues_relationships')) {
				if (field.type === FIELD_TYPES.ISSUE_TYPE) {
					break;
				}
			}

			if (!isIssueTypeFieldValue(value)) {
				throw new Error('The value is not of type IssueTypeFieldValue');
			}

			return <IssueTypeOptionRenderer field={field} groupIdentity={value.id} value={value} />;
		}
		case FIELD_TYPES.TEAM:
			if (hasTeamName(value)) {
				return <TeamValue name={value.name} />;
			}
			break;
		case FIELD_TYPES.NUMBER:
		case FIELD_TYPES.FORMULA:
		case FIELD_TYPES.INSIGHTS:
		case FIELD_TYPES.LINKED_ISSUES:
		case FIELD_TYPES.SLIDER:
		case FIELD_TYPES.CHECKBOX:
		case FIELD_TYPES.RATING:
		case FIELD_TYPES.CREATED:
		case FIELD_TYPES.UPDATED:
		case FIELD_TYPES.DATE:
		default:
			break;
	}

	// We do not return this in the default switch case to make
	// putting extra cases behind a feature gate simpler, eg.
	// case X:
	//   if (fieldType === X) {
	//     if (!fg(...)) {
	//       break; // to exit the switch, therefore fall back to the default return
	//     }
	//   }
	return <DefaultOptionRenderer field={field} groupIdentity={groupIdentity} value={value} />;
};

type BasicOption = {
	groupIdentity: string | undefined;
	value?: unknown;
};

type OptionWithLabel = BasicOption & {
	label: string;
};

export const useOptionsWithLabels = (field: Field, options: BasicOption[]): OptionWithLabel[] => {
	const mapping = useFieldMapping(field.key);
	const allExternalReferenceEntities = useAllExternalReferenceEntities();
	const summaries = useSummaries();
	const localIssueIdByJiraIssueId = useLocalIssueIdsByJiraIssueId();
	const { formatMessage } = useIntl();

	const transformedOptions = options.map((option: BasicOption): OptionWithLabel => {
		if (option.groupIdentity !== undefined) {
			switch (field.type) {
				case FIELD_TYPES.ATLAS_PROJECT:
				case FIELD_TYPES.ATLAS_GOAL:
				case FIELD_TYPES.PLATFORM_GOALS:
					return {
						...option,
						label:
							allExternalReferenceEntities[option.groupIdentity]?.name ||
							mapping?.getLabel(option.groupIdentity, option.value) ||
							formatMessage(messages.unknownOption),
					};
				case FIELD_TYPES.CONNECTION:
					return {
						...option,
						label:
							summaries[localIssueIdByJiraIssueId[option.groupIdentity]] ||
							formatMessage(messages.unknownOption),
					};
				default:
					return {
						...option,
						label:
							mapping?.getLabel(option.groupIdentity, option.value) ||
							formatMessage(messages.unknownOption),
					};
			}
		}

		switch (field.type) {
			case FIELD_TYPES.ASSIGNEE:
			case FIELD_TYPES.CREATOR:
			case FIELD_TYPES.REPORTER:
			case FIELD_TYPES.PEOPLE:
			case FIELD_TYPES.JSW_PEOPLE:
				return {
					...option,
					label: formatMessage(userNoValueOptionMessage),
				};
			case FIELD_TYPES.NUMBER:
			case FIELD_TYPES.FORMULA:
			case FIELD_TYPES.INSIGHTS:
			case FIELD_TYPES.LINKED_ISSUES:
			case FIELD_TYPES.SLIDER:
			case FIELD_TYPES.CHECKBOX:
			case FIELD_TYPES.RATING:
			case FIELD_TYPES.STATUS:
				return {
					...option,
					label: formatMessage(messages.unknownOption),
				};
			case FIELD_TYPES.SINGLE_SELECT:
			case FIELD_TYPES.MULTI_SELECT:
			case FIELD_TYPES.JSW_MULTI_SELECT:
			case FIELD_TYPES.REACTIONS:
				return {
					...option,
					label: formatMessage(optionNoValueOptionMessage),
				};
			case FIELD_TYPES.LABELS:
			case FIELD_TYPES.CUSTOM_LABELS:
				return {
					...option,
					label: formatMessage(labelNoValueOptionMessage),
				};
			case FIELD_TYPES.CONNECTION:
				return {
					...option,
					label: formatMessage(connectionNoValueOptionMessage),
				};
			case FIELD_TYPES.CREATED:
			case FIELD_TYPES.UPDATED:
			case FIELD_TYPES.DATE:
			default:
				return {
					...option,
					label: formatMessage(defaultNoValueOptionMessage),
				};
		}
	});

	return transformedOptions;
};
