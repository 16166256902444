import React, { useRef } from 'react';
import { keyframes, styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	useXAxisValueMapper,
	useYAxisValueMapper,
} from '../../../controllers/selectors/axis-hooks.tsx';
import { useDnDTooltipRendererComponent } from '../../../controllers/selectors/components-hooks.tsx';
import { useZAxisPositionForItems } from '../../../controllers/selectors/positions-hooks.tsx';
import type { ItemId } from '../../../types.tsx';
import { messages } from './messages.tsx';

export type Props = {
	itemIds: ItemId[];
	leftPercentage: number;
	topPercentage: number;
};

export const DnDTooltip = ({ itemIds, leftPercentage, topPercentage }: Props) => {
	const { formatMessage } = useIntl();
	const zPosition = useZAxisPositionForItems(itemIds);
	const containerRef = useRef<HTMLDivElement>(null);

	const Tooltip = useDnDTooltipRendererComponent();
	const xAxisValueMapper = useXAxisValueMapper();
	const yAxisValueMapper = useYAxisValueMapper();
	if (!Tooltip || !xAxisValueMapper || !yAxisValueMapper) {
		return null;
	}

	const xValue = xAxisValueMapper(leftPercentage);
	const yValue = yAxisValueMapper(100 - topPercentage);
	if (xValue === undefined || yValue === undefined) {
		return null;
	}

	return (
		<Container
			leftPerc={leftPercentage}
			topPerc={topPercentage}
			zPosition={zPosition}
			ref={containerRef}
			width={containerRef.current?.clientWidth || 0}
		>
			<Tooltip
				itemIds={itemIds}
				xValue={xValue}
				yValue={yValue}
				tooltipTitle={
					itemIds.length > 1
						? formatMessage(messages.groupedIdeas, { count: itemIds.length })
						: undefined
				}
			/>
		</Container>
	);
};

const visibility = keyframes({
	'0%': {
		opacity: 0,
	},
	'100%': {
		opacity: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{
	topPerc: number;
	zPosition: number;
	leftPerc: number;
	width: number;
}>({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ topPerc, zPosition }) =>
		topPerc < 20
			? `calc(${topPerc}% + ${zPosition}px)`
			: `calc(${topPerc}% - ${zPosition + 120}px)`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	left: ({ width, leftPerc }) =>
		`min(max(calc(${leftPerc}% - ${width / 2}px), 0px), calc(100% - ${width}px))`,
	zIndex: 101,
	paddingTop: token('space.200'),
	paddingRight: token('space.250'),
	paddingBottom: token('space.200'),
	paddingLeft: token('space.200'),
	display: 'inline-block',
	opacity: 1,
	animation: `${visibility} 1s`,
	backgroundColor: token('elevation.surface.overlay'),
	boxShadow: token(
		'elevation.shadow.overlay',
		'0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
	),
	borderRadius: '3px',
});
