import React, { useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { useSortingAwareIssueCreationAction } from '@atlassian/jira-polaris-common/src/controllers/idea-ranking/index.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useCreatedIssues } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/created-issues.tsx';
import { useRankedIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { useIssueAnalitycsAttributes } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import {
	useHiddenByFilter,
	useSortedIssueIndex,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/sort-hooks.tsx';
import {
	type IssueCreatedPropertyItem,
	IssueCreateStatusInCreation,
	IssueCreateStatusInTransition,
	IssueCreateGroupTypeSpecified,
	IssueCreateGroupTypeEmpty,
	type IssueCreatedPropertyItemGroupType,
	IssueCreateGroupFromLocationTop,
	IssueCreateGroupFromLocationBottom,
	IssueCreateGroupTypeNoGroup,
} from '@atlassian/jira-polaris-common/src/controllers/issue/types.tsx';
import { useProjectId } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIssueTypeIdsForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { RowRendererComponentProps } from '@atlassian/jira-polaris-lib-list/src/types.tsx';
import { ValidValidationError } from '@atlassian/jira-polaris-remote-issue/src/services/jira/create-idea-rest/index.tsx';
import {
	useAnalyticsEvents,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { DropHint } from '../../../../common/ui/dnd-v2/index.tsx';
import {
	useIsDropTargetItem,
	useDraggingInfo,
} from '../../../../controllers/dnd-v2/selectors/hooks.tsx';
import { useOnCreatedOrMovedIssueFiltered } from '../../common/on-created-filtered-issue/index.tsx';
import { SavingSpinner } from '../cell/cells/saving-spinner/index.tsx';
import { RowSticky } from '../row-sticky/index.tsx';
import { AddIdeaInput } from './add-idea-input/main.tsx';
import { InlineIssueCreate } from './inline-issue-create/inline-issue-create.tsx';
import { GroupAwareWrapper } from './group-aware-wrapper.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DragHandleWithHighlight } from './drag-handle';

function getGrouping(createdProperty: IssueCreatedPropertyItem): IssueCreatedPropertyItemGroupType {
	const { status, anchorBefore, anchorAfter, ...grouping } = createdProperty;
	return grouping;
}

const getIssueTypeIdFromGrouping = (grouping: IssueCreatedPropertyItemGroupType) => {
	const isGroupedByIssueType =
		grouping.groupType === IssueCreateGroupTypeSpecified &&
		grouping.fieldKey === FIELD_TYPES.ISSUE_TYPE;

	return isGroupedByIssueType ? grouping.groupIdentity : undefined;
};

type CreateIdeaRowProps = {
	issueId: LocalIssueId;
	createdProperty: IssueCreatedPropertyItem;
};

const CreateIdeaRow = ({ issueId, createdProperty }: CreateIdeaRowProps) => {
	const ids = useRankedIssueIds();

	const projectId = useProjectId();
	const issueTypeIds = useIssueTypeIdsForProject({ projectId });

	const sortedIssueIndex = useSortedIssueIndex(issueId);
	const {
		createIssue,
		createIssueAfter,
		safelySwapRowInCreationWithCreated,
		cancelCreateIssue: cancelCreateAction,
	} = useIssueActions();
	const saveIssueAction = useSortingAwareIssueCreationAction();
	const onCreatedIssueFiltered = useOnCreatedOrMovedIssueFiltered();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onCreateIssue = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'localIssueId' implicitly has an 'any' type. | TS7006 - Parameter 'positionIndex' implicitly has an 'any' type. | TS7006 - Parameter 'issueType' implicitly has an 'any' type. | TS7006 - Parameter 'summary' implicitly has an 'any' type. | TS7006 - Parameter 'createdByBlur' implicitly has an 'any' type.
		(localIssueId, positionIndex, issueTypeId, summary, createdByBlur) => {
			saveIssueAction(
				localIssueId,
				issueTypeId,
				summary,
				createdProperty,
				ids,
				onCreatedIssueFiltered,
				positionIndex,
				({ issueId: id, createdProperty: createdPropertyAfterSave }) => {
					if (!createdByBlur) {
						safelySwapRowInCreationWithCreated(localIssueId, createdPropertyAfterSave);
					}

					const analyticsEvent = createAnalyticsEvent({});
					analyticsEvent.context.push({ objectId: String(id), objectType: 'issue' });
					fireCompoundAnalyticsEvent.IdeaCreated(analyticsEvent, id, {
						issueId: String(id),
					});
					experience.listView.createIdeaInline.success();
				},
				(error?: Error) => {
					cancelCreateAction(localIssueId);
					if (!(error && error instanceof ValidValidationError)) {
						fireErrorAnalytics(
							createErrorAnalytics(
								'polaris.error.createIdeaInline',
								error || new Error('Unknown error'),
							),
						);
						if (!isClientFetchError(error)) {
							experience.listView.createIdeaInline.failure(error);
						}
					}
				},
			);

			if (!createdByBlur) {
				const grouping = getGrouping(createdProperty);

				if (
					grouping.groupType === IssueCreateGroupTypeSpecified ||
					grouping.groupType === IssueCreateGroupTypeEmpty ||
					grouping.groupType === IssueCreateGroupTypeNoGroup
				) {
					if (grouping.rankingAllowed) {
						createIssueAfter(localIssueId, undefined, grouping);
						return;
					}

					if (grouping.fromLocation === IssueCreateGroupFromLocationTop) {
						createIssue(0, undefined, grouping);
						return;
					}

					if (grouping.fromLocation === IssueCreateGroupFromLocationBottom) {
						createIssue(positionIndex + 2, undefined, grouping);
						return;
					}
				}

				createIssue(positionIndex);
			}
		},
		[
			createdProperty,
			ids,
			cancelCreateAction,
			createAnalyticsEvent,
			createIssue,
			createIssueAfter,
			onCreatedIssueFiltered,
			safelySwapRowInCreationWithCreated,
			saveIssueAction,
		],
	);

	const onCancelCreateIssue = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'localIssueId' implicitly has an 'any' type. | TS7006 - Parameter 'positionIndex' implicitly has an 'any' type. | TS7006 - Parameter 'blur' implicitly has an 'any' type.
		(localIssueId) => {
			cancelCreateAction(localIssueId);
			experience.listView.createIdeaInline.abort();
		},
		[cancelCreateAction],
	);

	const onCreate = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'issueType' implicitly has an 'any' type. | TS7006 - Parameter 'summary' implicitly has an 'any' type. | TS7006 - Parameter 'createdByBlur' implicitly has an 'any' type.
		(issueTypeId, summary, createdByBlur) => {
			onCreateIssue(issueId, sortedIssueIndex, issueTypeId, summary, createdByBlur);
		},
		[issueId, onCreateIssue, sortedIssueIndex],
	);

	const onCancel = useCallback(() => onCancelCreateIssue(issueId), [issueId, onCancelCreateIssue]);

	if (issueTypeIds === undefined) {
		return null;
	}

	return (
		<RowSticky containerStickyClassName="idea-input-wrapper">
			{fg('jpd_issues_relationships') ? (
				<InlineIssueCreate
					enforcedIssueTypeId={getIssueTypeIdFromGrouping(getGrouping(createdProperty))}
					onCancel={onCancel}
					onCreate={onCreate}
				/>
			) : (
				<AddIdeaInput issueTypeIds={issueTypeIds} onCancel={onCancel} onCreate={onCreate} />
			)}
		</RowSticky>
	);
};

const CreateIdeaRowLoading = () => (
	<RowSticky containerStickyClassName="idea-input-wrapper">
		<SavingSpinner />
	</RowSticky>
);

type DropHintProps = {
	id: string;
};

const DropHintInner = ({ id }: DropHintProps) => {
	const { dropEdge } = useDraggingInfo();
	const hiddenBetween = useHiddenByFilter(id, dropEdge === 'top' ? 'before' : 'after');

	return dropEdge ? <DropHint dropEdge={dropEdge} hiddenNumber={hiddenBetween} /> : null;
};

const DropHintWrapper = ({ id }: DropHintProps) => {
	const isDropTarget = useIsDropTargetItem(id);

	return isDropTarget ? <DropHintInner id={id} /> : null;
};

export const Row = ({ DefaultComponent, ...rest }: RowRendererComponentProps) => {
	const { id } = rest.rowData;

	const createdIssues = useCreatedIssues();
	const createdIssue = createdIssues[id];

	const createdIssueStatus = createdIssue?.status;
	const issueAnalitycsAttributes = useIssueAnalitycsAttributes(id);

	// Row in in creation state
	if (createdIssueStatus === IssueCreateStatusInCreation) {
		return <CreateIdeaRow issueId={id} createdProperty={createdIssue} />;
	}

	// Row in in saving state
	if (createdIssueStatus === IssueCreateStatusInTransition) {
		return <CreateIdeaRowLoading />;
	}

	const content = <DefaultComponent {...rest} />;

	return (
		<ContextualAnalyticsData attributes={issueAnalitycsAttributes}>
			<DropHintWrapper id={id} />
			{fg('jpd_issues_relationships') ? (
				<GroupAwareWrapper groupId={rest.rowData.groupId}>{content}</GroupAwareWrapper>
			) : (
				content
			)}
		</ContextualAnalyticsData>
	);
};
