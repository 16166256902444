import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { PolarisTooltip } from '@atlassian/jira-polaris-lib-tooltip/src/ui/index.tsx';

export const CompactValuesList = ({ children = [] }: { children: JSX.Element[] | undefined }) => {
	if (children.length === 0) {
		return null;
	}

	const [firstOption, ...hiddenOptions] = children;
	const shouldShowTooltip = hiddenOptions.length > 0;

	if (!firstOption) {
		return null;
	}

	return (
		<PolarisTooltip
			hideTooltipOnMouseDown
			content={shouldShowTooltip && <PreviewContainer>{hiddenOptions}</PreviewContainer>}
		>
			<ValueWrapper>
				{firstOption}
				{shouldShowTooltip && <MoreTagWrapper>+ {hiddenOptions.length}</MoreTagWrapper>}
			</ValueWrapper>
		</PolarisTooltip>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ValueWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MoreTagWrapper = styled.div({
	whiteSpace: 'nowrap',
	font: token('font.body.small'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '22px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N200),
	marginLeft: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PreviewContainer = styled.span({
	display: 'flex',
	flexWrap: 'wrap',
	maxWidth: '370px',
	gap: token('space.100'),
});
