import React, { useState } from 'react';
import { styled } from '@compiled/react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import InlineEdit from '@atlaskit/inline-edit';
import { ReactRenderer } from '@atlaskit/renderer';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { smartLinksDefault } from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import { FixedFieldDescription } from '@atlassian/jira-polaris-component-fixed-field-description/src/ui/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldDescription } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { Editor, EditorBoundary } from '@atlassian/jira-polaris-lib-editor/src/async.tsx';
import { prepareForSave } from '@atlassian/jira-polaris-lib-editor/src/common/utils/adf.tsx';
import {
	IssuelessAdfController,
	WaitForAdfConsumerProps,
} from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import type { AkRendererProps } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/types.tsx';
import type {
	EditorActions,
	EditorProps as AkEditorProps,
} from '@atlassian/jira-polaris-lib-editor/src/ui/editor/types.tsx';
import {
	isFocusOutsideJFE,
	OutsideClickAlerter,
} from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';
import { isOnlyWhitespaceAdf } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import { useFieldType } from '../../../controllers/selectors/field-hooks.tsx';
import messages from './messages.tsx';

export type DescriptionEditorProps = {
	fieldDescription: FieldDescription;
	fieldKey: string;
	isEditable: boolean;
	isEditorAiEnabled: boolean;
	onUpdateDescription: (value: string) => void;
	projectId: string;
	projectKey: string;
	testId?: string;
	isPreview?: boolean;
};

export const DescriptionEditor = ({
	fieldKey,
	isEditable,
	onUpdateDescription,
	fieldDescription,
	isEditorAiEnabled,
	projectId,
	projectKey,
	testId,
	isPreview = false,
}: DescriptionEditorProps) => {
	const { formatMessage } = useIntl();
	const [isExpanded, setIsExpanded] = useState(false);
	const [editorActions, setEditorActions] = useState<EditorActions | undefined>();
	const expandEditor = () => setIsExpanded(true);
	const collapseEditor = () => setIsExpanded(false);

	const fieldType = useFieldType();
	const isDeliveryProgressField = fieldType === FIELD_TYPES.DELIVERY_PROGRESS;
	const isDeliveryStatusField = fieldType === FIELD_TYPES.DELIVERY_STATUS;

	if (fieldDescription.fixed) {
		return (
			<DescriptionReadOnly isPreview={isPreview}>
				<DescriptionReadContainer data-testid={testId} reducedSpacing={isPreview || !isEditable}>
					<FixedFieldDescription fieldKey={fieldKey} />
				</DescriptionReadContainer>
			</DescriptionReadOnly>
		);
	}

	const handleSave = async (value: ADF) => {
		const newDescription = isOnlyWhitespaceAdf(value) ? '' : JSON.stringify(value);

		onUpdateDescription(newDescription);
		collapseEditor();
	};

	const readView = (rendererProps: AkRendererProps) => {
		// render UI hardcoded description for delivery fields
		if (isDeliveryProgressField) {
			return (
				<DeliveryFieldDescriptionReadContainer
					data-testid={testId}
					reducedSpacing={isPreview || !isEditable}
				>
					{formatMessage(
						fg('polaris-issue-terminology-refresh')
							? messages.deliveryProgressDescriptionIssueTermRefresh
							: messages.deliveryProgressDescription,
					)}
				</DeliveryFieldDescriptionReadContainer>
			);
		}

		if (isDeliveryStatusField) {
			return (
				<DeliveryFieldDescriptionReadContainer
					data-testid={testId}
					reducedSpacing={isPreview || !isEditable}
				>
					{formatMessage(
						fg('polaris-issue-terminology-refresh')
							? messages.deliveryStatusDescriptionIssueTermRefresh
							: messages.deliveryStatusDescription,
					)}
				</DeliveryFieldDescriptionReadContainer>
			);
		}

		// Avoid rendering "Add a description" disabled button when the field is read-only
		if (!isEditable && !fieldDescription.value) {
			return null;
		}

		return (
			<DescriptionReadContainer data-testid={testId} reducedSpacing={isPreview || !isEditable}>
				{fieldDescription.value ? (
					<ReactRenderer
						{...rendererProps}
						document={fieldDescription.adf}
						smartLinks={smartLinksDefault}
						UNSTABLE_allowTableResizing={expVal(
							'platform_editor_support_table_in_comment_jira',
							'isEnabled',
							false,
						)}
						UNSTABLE_allowTableAlignment={expVal(
							'platform_editor_support_table_in_comment_jira',
							'isEnabled',
							false,
						)}
					/>
				) : (
					formatMessage(messages.addDescription)
				)}
			</DescriptionReadContainer>
		);
	};

	const handleOutsideClick = async (event: Event) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		if (isFocusOutsideJFE(event?.target as HTMLElement) || !editorActions) {
			return;
		}

		const adf = await editorActions?.getValue();
		const trimmedAdf = prepareForSave(adf);

		handleSave(trimmedAdf);
	};

	const editView = (editorProps: AkEditorProps, rendererProps: AkRendererProps) => (
		<OutsideClickAlerter onClickOutside={handleOutsideClick}>
			{(outsideClickAlerterProps) => (
				<>
					<EditorContainer {...outsideClickAlerterProps}>
						<EditorBoundary fallback={readView(rendererProps)}>
							<Editor
								{...editorProps}
								testId="polaris-component-field-configuration.ui.field-main-properties-editor.description-editor.text-area"
								maxContentSize={4000}
								shouldFocus
								defaultValue={fieldDescription.adf}
								onSave={handleSave}
								onCancel={collapseEditor}
								// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
								popupsMountPoint={document.body || undefined}
								onEditorReady={setEditorActions}
								isAiEnabled={isEditorAiEnabled}
								mentionProvider={
									// We don't have projectId and projectKey in
									// the context of Global Fields page so for
									// now we disable mentions there.
									// Potentially we can fix it by using
									// AbstractMentionProvider.
									projectId && projectKey ? editorProps.mentionProvider : undefined
								}
								maxHeight={300}
							/>
						</EditorBoundary>
					</EditorContainer>
				</>
			)}
		</OutsideClickAlerter>
	);

	return (
		<IssuelessAdfController projectId={projectId} projectKey={projectKey}>
			<WaitForAdfConsumerProps>
				{({ akEditorProps, akRendererProps }) => (
					<>
						{isEditable ? (
							<InlineEdit
								isEditing={isExpanded}
								onEdit={expandEditor}
								defaultValue={fieldDescription}
								readView={() => readView(akRendererProps)}
								editView={() => editView(akEditorProps, akRendererProps)}
								readViewFitContainerWidth
								hideActionButtons
								// eslint-disable-next-line @typescript-eslint/no-empty-function
								onConfirm={() => {}}
							/>
						) : (
							fieldDescription.value && (
								<DescriptionReadOnly isPreview={isPreview}>
									{readView(akRendererProps)}
								</DescriptionReadOnly>
							)
						)}
					</>
				)}
			</WaitForAdfConsumerProps>
		</IssuelessAdfController>
	);
};

const minRows = 2;
const textAreaLineHeightFactor = 2.5;
const space100InPx = 8;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionReadContainer = styled.div<{ reducedSpacing?: boolean }>({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '20px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minHeight: ({ reducedSpacing }) =>
		reducedSpacing ? undefined : `${space100InPx * textAreaLineHeightFactor * minRows}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ reducedSpacing }) =>
		reducedSpacing ? undefined : `${token('space.075')} ${token('space.075')}`,
	wordBreak: 'break-word',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryFieldDescriptionReadContainer = styled.div<{ reducedSpacing?: boolean }>({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '20px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minHeight: ({ reducedSpacing }) => (reducedSpacing ? undefined : `${20 * minRows}px`),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ reducedSpacing }) =>
		reducedSpacing ? undefined : `${token('space.075')} ${token('space.075')}`,
	wordBreak: 'break-word',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N900),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditorContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N900),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionReadOnly = styled.div<{ isPreview?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginTop: ({ isPreview }) => (isPreview ? '0' : token('space.150')),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBottom: ({ isPreview }) => (isPreview ? token('space.075') : '0'),
});
