import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { AccountId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { AtlBrowserStorageLocal } from '@atlassian/browser-storage-controls';
import type { Props, State } from '../../types.tsx';

const storage = createLocalStorageProvider('polaris');

const LEGACY_STORE_PREFIX = '__storejs_polaris_';

const createStorageKey = (issueKey: IssueKey, currentUserAccountId: null | AccountId) =>
	`polaris-description-draft-${issueKey}:${currentUserAccountId}`;

const createStorageKeyLegacy = (issueKey: IssueKey, currentUserAccountId: null | AccountId) =>
	`description.draft.${issueKey}:${currentUserAccountId}`;

const convertDraftToJSON = (storedString: string | null) => {
	if (!storedString) {
		return undefined;
	}
	try {
		const storedDraft = JSON.parse(storedString);
		if (storedDraft?.content) {
			return storedDraft;
		}
	} catch (e) {
		return undefined;
	}
};

export const saveDescriptionDraft =
	(draft: ADF, currentUserAccountId: AccountId | null) =>
	(_actionApi: StoreActionApi<State>, { issueKey }: Props) => {
		if (!issueKey) {
			return;
		}
		if (fg('jira-web-storage-controls')) {
			if (draft.content.length > 0) {
				AtlBrowserStorageLocal.setStrictlyNecessaryItem(
					createStorageKey(issueKey, currentUserAccountId),
					JSON.stringify(draft),
				);
			} else {
				AtlBrowserStorageLocal.removeItem(createStorageKey(issueKey, currentUserAccountId));
			}
			return;
		}
		if (draft.content.length > 0) {
			storage.set(createStorageKeyLegacy(issueKey, currentUserAccountId), draft);
		} else {
			storage.remove(createStorageKeyLegacy(issueKey, currentUserAccountId));
		}
	};

/**
 * `storage.get` that we used to use returns undefined if the FG `jira-web-storage-controls` is enabled
 *
 * This is a temporary workaround to support transition between the two ways of loading drafts.
 * e.g. User saves draft before the rollout, then tries to edit right after the rollout.
 * We'll remove it alongisde `jira-web-storage-controls` clean-up
 */
const loadLegacyDraft = (issueKey: string, currentUserAccountId: string | null) => {
	const storedLegacyDraftString = AtlBrowserStorageLocal.getItem(
		`${LEGACY_STORE_PREFIX}${createStorageKeyLegacy(issueKey, currentUserAccountId)}`,
	);
	const storedLegacyDraft = convertDraftToJSON(storedLegacyDraftString);
	return storedLegacyDraft;
};

export const loadDescriptionDraft =
	(currentUserAccountId: AccountId | null) =>
	(_actionApi: StoreActionApi<State>, { issueKey }: Props) => {
		if (!issueKey) {
			return;
		}
		if (fg('jira-web-storage-controls')) {
			const storedDraftString = AtlBrowserStorageLocal.getItem(
				createStorageKey(issueKey, currentUserAccountId),
			);
			const storedDraft = convertDraftToJSON(storedDraftString);

			if (storedDraft) {
				return storedDraft;
			}
			return loadLegacyDraft(issueKey, currentUserAccountId);
		}

		return storage.get(createStorageKeyLegacy(issueKey, currentUserAccountId));
	};

export const clearDescriptionDraft =
	(currentUserAccountId: AccountId | null) =>
	(_actionApi: StoreActionApi<State>, { issueKey }: Props) => {
		if (!issueKey) {
			return;
		}
		if (fg('jira-web-storage-controls')) {
			AtlBrowserStorageLocal.removeItem(createStorageKey(issueKey, currentUserAccountId));
			// To be removed alongside `jira-web-storage-controls` clean-up
			AtlBrowserStorageLocal.removeItem(
				`${LEGACY_STORE_PREFIX}${createStorageKeyLegacy(issueKey, currentUserAccountId)}`,
			);
			return;
		}
		storage.remove(createStorageKeyLegacy(issueKey, currentUserAccountId));
	};
