/** @jsx jsx */
import React, {
	type SyntheticEvent,
	type ReactNode,
	type ErrorInfo,
	memo,
	useMemo,
	Component,
	useCallback,
} from 'react';
import { css, styled, jsx } from '@compiled/react';
import keyBy from 'lodash/keyBy';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import {
	type CardAppearance,
	type ElementItem,
	type ActionItem,
	type CardProps,
	Card,
	CardAction,
	TitleBlock,
	ActionName,
	SmartLinkTheme,
	SmartLinkSize,
	ElementName,
} from '@atlaskit/smart-card';
import { CardSSR } from '@atlaskit/smart-card/ssr';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import logs from '@atlassian/jira-common-util-logging/src/log.tsx';
import { useIsExportingViewImage } from '@atlassian/jira-polaris-component-view-export/src/controllers/selectors.tsx';
import {
	EXTERNAL_REFERENCE_PROVIDERS,
	type ExternalReferenceProvider,
} from '@atlassian/jira-polaris-domain-field/src/field/external-reference/types.tsx';
import { useSmartCardGrantedResponse } from '@atlassian/jira-polaris-lib-smart-card/src/controllers/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useAtlasGoals, useAtlasProjects } from '../../../../controllers/atlas/index.tsx';
import type { ExternalReferenceEntity } from '../../../../services/atlas/types.tsx';
import TestIdWrapper from '../common/test-wrapper/index.tsx';
import { Unavailable } from '../common/unavailable/index.tsx';
import linkIcon from './assets/link.svg';
import { customCss } from './styled.tsx';

type ErrorBoundaryProps = {
	children: ReactNode;
	useFallback: () => ReactNode;
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, { hasError: boolean }> {
	static getDerivedStateFromError() {
		return { hasError: true };
	}

	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
		logs.safeErrorWithoutCustomerData(
			'jpd-external-reference-smart-card-renderer',
			'Falied to render smart card without lazy loading',
			error,
		);
	}

	render() {
		if (this.state.hasError) {
			return this.props.useFallback();
		}

		return this.props.children;
	}
}

const cardUi = {
	clickableContainer: true,
	hideBackground: false,
	hideElevation: false,
	hidePadding: true,
	theme: SmartLinkTheme.Black,
	size: SmartLinkSize.Medium,
};

type SmartCardProps = {
	url: string;
	maxLines?: number;
	showHoverCard?: boolean;
	appareance?: CardAppearance;
	frameStyle?: CardProps['frameStyle'];
	isContainerClickable: boolean;
	metadata: ElementItem[];
	onClick?: (evt: SyntheticEvent) => void;
};

const useActions = (url: string): ActionItem[] =>
	useMemo(
		() => [
			{
				content: null,
				name: ActionName.CustomAction,
				size: SmartLinkSize.Small,
				icon: <img src={linkIcon} height={16} alt="" />,
				hideContent: true,

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				onClick: () => window.open(url, '_blank'),
			},
		],
		[url],
	);

const titleBlockCustomCss = css({
	paddingTop: token('space.025'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.025'),
	paddingLeft: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors,@atlaskit/ui-styling-standard/no-unsafe-selectors
	'& > div:nth-of-type(2)': {
		flex: '0 1 auto',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		a: {
			wordBreak: 'break-all',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors,@atlaskit/ui-styling-standard/no-unsafe-selectors
	'& > div:nth-of-type(2) ~ div': {
		flex: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors,@atlaskit/ui-styling-standard/no-unsafe-selectors
	'& > div:last-of-type': {
		minWidth: 0,
	}, // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > div': {
		alignSelf: 'center',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& a, a:active, a:visited, a:hover, a:focus': {
		color: token('color.text.subtle', '#344563'),
		textDecoration: 'none',
	},
	// Style from Hyperlink field
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors,@atlaskit/ui-styling-standard/no-unsafe-selectors
	'& > span + div:nth-of-type(2) span': {
		textAlign: 'left',
	},
});

// Note: This component is also used for the hyperlink field. src/packages/polaris/apps/common/src/ui/fields/string/index.tsx
export const SmardCardWithoutLazyLoading = ({
	url,
	metadata,
	onClick,
	maxLines,
	appareance,
	frameStyle,
	showHoverCard,
	isContainerClickable,
}: SmartCardProps) => {
	const actions = useActions(url);

	return (
		<CardSSR
			key={url}
			url={url}
			ui={cardUi}
			appearance={appareance ?? 'inline'}
			frameStyle={frameStyle ?? 'showOnHover'}
			showHoverPreview={showHoverCard}
			actionOptions={{
				hide: false,
				exclude: [CardAction.ChangeStatusAction],
			}}
			onClick={onClick}
		>
			{fg('bandicoots-compiled-migration-smartcard') ? (
				<TitleBlock
					hideTitleTooltip
					showActionOnHover={false}
					maxLines={maxLines ?? 1}
					metadata={metadata}
					// to display lozenge on left
					// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
					css={titleBlockCustomCss}
					actions={isContainerClickable ? [] : actions}
				/>
			) : (
				<TitleBlock
					hideTitleTooltip
					showActionOnHover={false}
					maxLines={maxLines ?? 1}
					metadata={metadata}
					// to display lozenge on left
					// @ts-expect-error emotion types are not compatible with compiled
					overrideCss={customCss}
					actions={isContainerClickable ? [] : actions}
				/>
			)}
		</CardSSR>
	);
};

// Note: This component is also used for the hyperlink field. src/packages/polaris/apps/common/src/ui/fields/string/index.tsx
export const SmartCard = ({
	url,
	metadata,
	onClick,
	maxLines,
	appareance,
	frameStyle,
	showHoverCard,
	isContainerClickable,
}: SmartCardProps) => {
	const actions = useActions(url);

	return (
		<Card
			key={url}
			url={url}
			ui={cardUi}
			appearance={appareance ?? 'inline'}
			frameStyle={frameStyle ?? 'showOnHover'}
			showHoverPreview={showHoverCard}
			actionOptions={{
				hide: false,
				exclude: [CardAction.ChangeStatusAction],
			}}
			onClick={onClick}
		>
			{fg('bandicoots-compiled-migration-smartcard') ? (
				<TitleBlock
					hideTitleTooltip
					showActionOnHover={false}
					maxLines={maxLines ?? 1}
					metadata={metadata}
					// to display lozenge on left
					// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
					css={titleBlockCustomCss}
					actions={isContainerClickable ? [] : actions}
				/>
			) : (
				<TitleBlock
					hideTitleTooltip
					showActionOnHover={false}
					maxLines={maxLines ?? 1}
					metadata={metadata}
					// to display lozenge on left
					// @ts-expect-error emotion types are not compatible with compiled
					overrideCss={customCss}
					actions={isContainerClickable ? [] : actions}
				/>
			)}
		</Card>
	);
};

const RenderCard = memo(
	({ url, metadata, onClick, maxLines, showHoverCard, isContainerClickable }: SmartCardProps) => {
		const data = useSmartCardGrantedResponse(url);
		const isExportingViewImage = useIsExportingViewImage();

		if (isExportingViewImage) {
			if (data) {
				// Render title from smartcard data
				return (
					<Box
						testId="polaris-common.ui.fields.external-reference.renderer.render-card-exported"
						xcss={exportContainerStyles}
					>
						{data?.name ? data?.name : url}
					</Box>
				);
			}
			// Render hyperlink as a fallback
			return (
				<Inline testId="polaris-common.ui.fields.external-reference.renderer.render-card-exported">
					{url}
				</Inline>
			);
		}

		if (data) {
			return (
				<Container>
					<ErrorBoundary
						useFallback={() => (
							<SmartCard
								url={url}
								metadata={metadata}
								onClick={onClick}
								maxLines={maxLines}
								showHoverCard={showHoverCard}
								isContainerClickable={isContainerClickable}
							/>
						)}
					>
						<SmardCardWithoutLazyLoading
							url={url}
							metadata={metadata}
							onClick={onClick}
							maxLines={maxLines}
							showHoverCard={showHoverCard}
							isContainerClickable={isContainerClickable}
						/>
					</ErrorBoundary>
				</Container>
			);
		}

		return (
			<Container>
				<SmartCard
					url={url}
					metadata={metadata}
					onClick={onClick}
					maxLines={maxLines}
					showHoverCard={showHoverCard}
					isContainerClickable={isContainerClickable}
				/>
			</Container>
		);
	},
);

type RenderCardListProps = {
	isLoading: boolean;
	list?: (ExternalReferenceEntity | undefined)[];
	aris: string[];
	metadata: ElementItem[];
	maxLines?: number;
	showHoverCard?: boolean;
	isContainerClickable: boolean;
};

const RenderCardList = ({
	isLoading,
	list,
	aris,
	metadata,
	maxLines,
	showHoverCard,
	isContainerClickable,
}: RenderCardListProps) => {
	const onClick = useCallback(
		(evt: SyntheticEvent) => {
			if (isContainerClickable) {
				evt.stopPropagation();
				return;
			}
			evt.preventDefault();
		},
		[isContainerClickable],
	);

	const items = useMemo(() => keyBy(list, 'id'), [list]);

	if (!list?.length && isLoading) {
		return (
			<div>
				<Spinner />
			</div>
		);
	}

	return (
		<>
			{aris?.map((ari) => {
				const item = items[ari];
				return item && item.url ? (
					<RenderCard
						showHoverCard={showHoverCard}
						isContainerClickable={isContainerClickable}
						maxLines={maxLines}
						key={item.url}
						url={item.url}
						metadata={metadata}
						onClick={onClick}
					/>
				) : (
					<Unavailable key={ari} ari={ari} />
				);
			}) || null}
		</>
	);
};

type AtlasGoalsProps = {
	goalAris: string[];
	maxLines?: number;
	showHoverCard?: boolean;
	isContainerClickable: boolean;
	testId?: string;
};

const AtlasGoals = ({
	goalAris,
	maxLines,
	showHoverCard,
	isContainerClickable,
	testId,
}: AtlasGoalsProps) => {
	const [{ data, isLoading }] = useAtlasGoals({
		goalAris,
	});

	return (
		// Using test id wrapper since the actual component doesn't accept testId
		<TestIdWrapper testId={testId}>
			<RenderCardList
				showHoverCard={showHoverCard}
				isContainerClickable={isContainerClickable}
				list={data}
				maxLines={maxLines}
				isLoading={isLoading}
				aris={goalAris}
				metadata={useMemo(() => [], [])}
			/>
		</TestIdWrapper>
	);
};

type AtlasProjectsProps = {
	projectAris: string[];
	maxLines?: number;
	showHoverCard?: boolean;
	isContainerClickable: boolean;
	testId?: string;
};

const AtlasProjects = ({
	projectAris,
	maxLines,
	showHoverCard,
	isContainerClickable,
	testId,
}: AtlasProjectsProps) => {
	const [{ data, isLoading }] = useAtlasProjects({
		projectAris,
	});

	return (
		// using testId wrapper because the render card component or smart card component
		// is not accepting the testId
		<TestIdWrapper testId={testId}>
			<RenderCardList
				showHoverCard={showHoverCard}
				isContainerClickable={isContainerClickable}
				list={data}
				maxLines={maxLines}
				isLoading={isLoading}
				aris={projectAris}
				metadata={useMemo(
					() => [
						{
							name: ElementName.State,
						},
					],
					[],
				)}
			/>
		</TestIdWrapper>
	);
};

type ExternalReferenceRendererProps = {
	provider?: ExternalReferenceProvider;
	aris: string[];
	maxLines?: number;
	isContainerClickable?: boolean;
	showHoverCard?: boolean;
	testId?: string;
};

export const ExternalReferenceRenderer = ({
	provider,
	aris,
	maxLines,
	isContainerClickable,
	showHoverCard = false,
	testId,
}: ExternalReferenceRendererProps) => {
	if (aris.length === 0) {
		return null;
	}

	// showHoverCard should be true for links which are not in a picker or tooltip / inline dialog
	if (provider === EXTERNAL_REFERENCE_PROVIDERS.ATLAS_PROJECT) {
		return (
			<AtlasProjects
				testId={testId}
				projectAris={aris}
				maxLines={maxLines}
				showHoverCard={showHoverCard}
				isContainerClickable={isContainerClickable || false}
			/>
		);
	}

	// showHoverCard should be true for links which are not in a picker or tooltip / inline dialog
	if (provider === EXTERNAL_REFERENCE_PROVIDERS.ATLAS_GOAL) {
		return (
			<AtlasGoals
				testId={testId}
				goalAris={aris}
				maxLines={maxLines}
				showHoverCard={showHoverCard}
				isContainerClickable={isContainerClickable || false}
			/>
		);
	}

	return <>Unknown external reference provider</>;
};

const exportContainerStyles = xcss({
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: 'color.border',
	borderRadius: 'border.radius.100',
	paddingInline: 'space.050',
	paddingBlock: 'space.025',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-link-container="true"]': {
		borderRadius: token('border.radius.200', '4px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& .actions-button-group': {
		width: 0,
		minWidth: 0,
		marginLeft: token('space.negative.100'),
		height: '20px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		div: {
			height: '20px',
		},
	},
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'.actions-button-group': {
			width: 'initial',
			minWidth: 'initial',
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
			marginLeft: '-0.3rem',
		},
	},
});
