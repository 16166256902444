import React from 'react';
import { LinkIconButton } from '@atlaskit/button/new';
import UploadIcon from '@atlaskit/icon/core/migration/upload--export';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';

const IMPORT_ISSUES_URL =
	'/secure/BulkCreateSetupPage!default.jspa?externalSystem=com.atlassian.jira.plugins.jim-plugin%3AbulkCreateCsv';

type ImportButtonProps = {
	isMenuItem?: boolean;
};

export const ImportButton = ({ isMenuItem }: ImportButtonProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (isMenuItem) {
		return (
			<DropdownItem
				elemBefore={<UploadIcon label="" />}
				href={IMPORT_ISSUES_URL}
				onClick={() => {
					fireUIAnalytics(
						createAnalyticsEvent({ action: 'clicked', actionSubject: 'viewImportMenuItem' }),
					);
				}}
			>
				{formatMessage(messages.captionMenuItem)}
			</DropdownItem>
		);
	}

	return (
		<LinkIconButton
			label={formatMessage(messages.caption)}
			appearance={isVisualRefreshEnabled() ? 'default' : 'subtle'}
			icon={UploadIcon}
			isTooltipDisabled={false}
			href={IMPORT_ISSUES_URL}
			onClick={() => {
				fireUIAnalytics(
					createAnalyticsEvent({ action: 'clicked', actionSubject: 'viewImportButton' }),
				);
			}}
		/>
	);
};
