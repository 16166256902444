import isArray from 'lodash/isArray';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createGetTransitionForStatus } from '../../../workflow/selectors/transitions.tsx';
import type { IssueTypeTransitionConfig, Status } from '../../../workflow/types.tsx';
import {
	createGetFieldMapping,
	createGetFieldsOfType,
	getAllFieldsByKey,
} from '../../selectors/fields.tsx';
import { createGetIssueType, createGetStatus } from '../../selectors/properties/index.tsx';
import type { PropertyMaps, Props, State } from '../../types.tsx';
import type { FieldMapping } from '../../utils/field-mapping/types.tsx';

export const getNewPropertiesMapping = <TFieldValue,>(
	fieldMapping: FieldMapping<unknown>,
	oldPropertyMap: PropertyMaps,
	localIssueIds: LocalIssueId | LocalIssueId[],
	newValue: TFieldValue | undefined,
	removeValue: TFieldValue | undefined,
	appendMultiValues: boolean,
) => {
	// In case of connection field, we don't want to update the properties here
	// It is done inside updateConnectionFieldValue since it requires more logic to update the properties
	if (fieldMapping.field?.type === FIELD_TYPES.CONNECTION) {
		return oldPropertyMap;
	}

	// In case of platform goals field, we don't want to update the properties here
	// It is done inside updateGoalsFieldValue since it requires more logic to update the properties
	if (fieldMapping.field?.type === FIELD_TYPES.PLATFORM_GOALS) {
		return oldPropertyMap;
	}

	if (isArray(localIssueIds)) {
		let newMapping = oldPropertyMap;
		localIssueIds.forEach((localIssueId) => {
			newMapping = getNewPropertiesMapping(
				fieldMapping,
				newMapping,
				localIssueId,
				newValue,
				removeValue,
				appendMultiValues,
			);
		});
		return newMapping;
	}
	return fieldMapping.isMultiValueField && appendMultiValues
		? fieldMapping.modifyImmutableIfMultiValueField(
				oldPropertyMap,
				localIssueIds,
				newValue,
				removeValue,
			)
		: fieldMapping.setImmutable(oldPropertyMap, localIssueIds, newValue);
};

export const getTransitionsForTargetStatusByLocalIssueIds = (
	localIssueIds: LocalIssueId[],
	toStatus: Status,
	state: State,
	props: Props,
): Record<LocalIssueId, IssueTypeTransitionConfig | undefined> =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	localIssueIds.reduce<Record<string, any>>((acc, issueId) => {
		const issueType = createGetIssueType(issueId)(state, props);
		const status = createGetStatus(issueId)(state, props);

		if (issueType === undefined || status === undefined) {
			acc[issueId] = undefined;
			return acc;
		}

		const getTransitionForStatus = createGetTransitionForStatus(issueType.id, status, toStatus);

		const transition = getTransitionForStatus({
			transitions: props.workflowTransitions,
		});

		acc[issueId] = transition;
		return acc;
	}, {});

export const isBulkOperationSupported = (fieldKeys: FieldKey[], state: State, props: Props) =>
	fieldKeys.every((fieldKey) => {
		const fieldMapping = createGetFieldMapping(fieldKey)(state, props);
		return fieldMapping?.getFieldValueForJiraBulkUpdate;
	});

export const hasIssueTypeField = (fieldKeys: FieldKey[], state: State, props: Props) => {
	const fields = getAllFieldsByKey(state, props);

	return fieldKeys.some((fieldKey) => fields[fieldKey]?.type === FIELD_TYPES.ISSUE_TYPE);
};

export const getUpdatedStateForDualWrite = ({
	field,
	propertyMaps,
	localIssueIds,
	newValue,
	removeValue,
	appendMultiValues,
	state,
	props,
}: {
	field: Field;
	propertyMaps: PropertyMaps;
	localIssueIds: string[];
	newValue: unknown;
	removeValue: unknown;
	appendMultiValues: boolean;
	state: Readonly<State>;
	props: Props;
}): {
	newProperties: PropertyMaps;
	fieldToDualWrite: Field | undefined;
	fieldToDualWriteFieldMapping: ReturnType<ReturnType<typeof createGetFieldMapping>>;
} => {
	if (field.type === FIELD_TYPES.ATLAS_GOAL && !fg('jpd_platform_goals_field_support')) {
		return {
			newProperties: propertyMaps,
			fieldToDualWrite: undefined,
			fieldToDualWriteFieldMapping: undefined,
		};
	}

	const fieldsOfType =
		field.type === FIELD_TYPES.ATLAS_GOAL
			? createGetFieldsOfType(FIELD_TYPES.PLATFORM_GOALS)(state, props)
			: createGetFieldsOfType(field.type)(state, props);
	const fieldToDualWrite = fieldsOfType.find((f) => f.key !== field.key);
	const fieldToDualWriteFieldMapping = createGetFieldMapping(fieldToDualWrite?.key)(state, props);
	let includeDualWriteField = false;

	const getDualWrittenMapping = () => {
		let dualWriteValue = newValue;

		if (!fieldToDualWrite || !fieldToDualWriteFieldMapping) {
			return propertyMaps;
		}

		if (field.type === FIELD_TYPES.ARCHIVED && newValue) {
			const archivedJiraOptionId = fieldToDualWrite.archivedOption?.jiraOptionId;

			if (!archivedJiraOptionId) {
				return propertyMaps;
			}

			dualWriteValue = { id: archivedJiraOptionId };
		}

		includeDualWriteField = true;

		return getNewPropertiesMapping(
			fieldToDualWriteFieldMapping,
			propertyMaps,
			localIssueIds,
			dualWriteValue,
			removeValue,
			appendMultiValues,
		);
	};

	const newProperties = getDualWrittenMapping();

	return {
		newProperties,
		fieldToDualWrite: includeDualWriteField ? fieldToDualWrite : undefined,
		fieldToDualWriteFieldMapping: includeDualWriteField ? fieldToDualWriteFieldMapping : undefined,
	};
};
