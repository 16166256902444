import difference from 'lodash/difference';
import find from 'lodash/find';
import intersection from 'lodash/intersection';
import mapValues from 'lodash/mapValues';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import reject from 'lodash/reject';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	LocalIssueId,
	ExternalIssueId,
} from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getLocalIssueIdToJiraId } from '../../selectors/issue-ids.tsx';
import {
	createGetIdSelector,
	createGetIssueAnalyticsAttributes,
} from '../../selectors/properties/index.tsx';
import type { State, Props } from '../../types.tsx';
import { updateConnectionsProperties } from '../common/connection/index.tsx';

export const deleteExternalIssues =
	(issueIdsToDelete: ExternalIssueId[]) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const state = getState();

		setState({
			properties: {
				...state.properties,
				linkedDeliveryIssues: mapValues(state.properties.linkedDeliveryIssues, (vals) =>
					reject(vals, (val) => issueIdsToDelete.includes(val)),
				),
			},
			externalIssueData: omit(state.externalIssueData, issueIdsToDelete),
		});
	};

export const deleteIssues =
	(issueIds: LocalIssueId[], performSideEffects = true, onFinish: () => void = noop) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		if (performSideEffects) {
			props.issuesRemote.deleteIssues({
				issueKeys: issueIds.map((id) => state.properties.string.key[id]).filter((key) => key),
				onFinish: (result) => {
					const error = find(result, (caughtError) => !!caughtError);
					if (error) {
						props.onIssueUpdateFailed(error);
					}
					onFinish();
				},
			});
		}

		if (intersection(state.ids, issueIds).length > 0) {
			if (fg('jpd_issues_relationships')) {
				const localIssueIdToJiraId = getLocalIssueIdToJiraId(state, props);
				const issuesToDisconnect = issueIds.map((issueId) => ({
					id: localIssueIdToJiraId[issueId],
				}));
				const newState = { ...state };

				state.ids.forEach((localIssueId) => {
					newState.properties = updateConnectionsProperties({
						issuesToDisconnect,
						localIssueId,
						state: newState,
						props,
					});
				});

				setState({
					ids: difference(state.ids, issueIds),
					properties: newState.properties,
				});
			} else {
				setState({
					ids: difference(state.ids, issueIds),
				});
			}
		}

		fireTrackAnalytics(
			props.createAnalyticsEvent({}),
			'issue deleted',
			issueIds.length === 1
				? {
						...createGetIssueAnalyticsAttributes(issueIds[0])(state),
					}
				: {
						issueCount: issueIds.length,
						issueIds: issueIds.map((id) => createGetIdSelector(id)(state)?.toString()),
						actionType: 'bulk',
					},
		);
	};
