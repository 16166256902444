import React, { memo, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { Draggable } from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';
import { useHasReachedViewFieldsLimit } from '../../../controllers/views/selectors/view-hooks.tsx';
import { type TogglePermissionType, LISTS } from '../constants.tsx';
import { FieldListItem } from '../item/index.tsx';
import { Header, ExpandableHeader } from './header/index.tsx';
import messages from './messages.tsx';

type CallbacksProps = {
	onFieldClick: (fk: FieldKey) => void;
	onFieldToggle: (fk: FieldKey, isSelected: boolean) => void;
	onFieldHide?: (fk: FieldKey) => void;
};

type FieldsListProps = {
	fields: Field[];
	hiddenFieldKeys: FieldKey[];
	selectedFieldKeys: FieldKey[];
	isDragDisabled: boolean;
	isDropDisabled: boolean;
	hasReachedViewFieldsLimit: string | null;
	togglePermissionType: TogglePermissionType;
} & CallbacksProps;

const InnerFieldsList = memo<FieldsListProps>(
	({
		fields,
		hiddenFieldKeys,
		selectedFieldKeys,
		isDragDisabled,
		isDropDisabled,
		hasReachedViewFieldsLimit,
		togglePermissionType,
		onFieldClick,
		onFieldHide,
		onFieldToggle,
	}: FieldsListProps) => (
		<>
			{fields.map(({ key }) => (
				<Draggable
					id={key}
					key={key}
					isDragDisabled={isDragDisabled}
					isDropDisabled={isDropDisabled}
				>
					<FieldListItem
						fieldKey={key}
						isHidden={hiddenFieldKeys.includes(key)}
						isSelected={selectedFieldKeys.includes(key)}
						isDragEnabled={!isDragDisabled}
						hasReachedViewFieldsLimit={hasReachedViewFieldsLimit}
						togglePermissionType={togglePermissionType}
						onEdit={onFieldClick}
						onHide={onFieldHide}
						onToggle={onFieldToggle}
					/>
				</Draggable>
			))}
		</>
	),
);

type InnerListProps = {
	listId: string;
	fields: Field[];
	unselectedFieldsAvailable?: boolean;
	hiddenFieldKeys: FieldKey[];
	selectedFieldKeys: FieldKey[];
	isCollapsed: boolean;
	isDragDisabled: boolean;
	isDropDisabled: boolean;
	togglePermissionType: TogglePermissionType;
} & CallbacksProps;

const InnerList = memo<InnerListProps>(
	({
		listId,
		fields,
		unselectedFieldsAvailable,
		hiddenFieldKeys,
		selectedFieldKeys,
		isCollapsed,
		isDragDisabled,
		isDropDisabled,
		togglePermissionType,
		onFieldClick,
		onFieldHide,
		onFieldToggle,
	}: InnerListProps) => {
		const { formatMessage } = useIntl();
		const hasReachedViewFieldsLimit = useHasReachedViewFieldsLimit();

		if (isCollapsed) {
			return <Box xcss={dropeZoneStyles} />;
		}

		return (
			<>
				{fields.length !== 0 ? (
					<InnerFieldsList
						fields={fields}
						hiddenFieldKeys={hiddenFieldKeys}
						selectedFieldKeys={selectedFieldKeys}
						isDragDisabled={isDragDisabled}
						isDropDisabled={isDropDisabled}
						hasReachedViewFieldsLimit={hasReachedViewFieldsLimit}
						togglePermissionType={togglePermissionType}
						onFieldClick={onFieldClick}
						onFieldHide={onFieldHide}
						onFieldToggle={onFieldToggle}
					/>
				) : (
					<EmptyFieldsState>
						{listId === LISTS.AVAILABLE && !unselectedFieldsAvailable
							? formatMessage(messages.allFieldsInUse)
							: formatMessage(messages.noMatches)}
					</EmptyFieldsState>
				)}
			</>
		);
	},
);

type ListProps = {
	listId: string;
	fields: Field[];
	unselectedFieldsAvailable?: boolean;
	hiddenFieldKeys?: FieldKey[];
	selectedFieldKeys?: FieldKey[];
	title?: string;
	helpText?: string;
	isExpandable?: boolean;
	isDragDisabled?: boolean;
	togglePermissionType: TogglePermissionType;
} & CallbacksProps;

export const List = memo<ListProps>(
	({
		listId,
		fields,
		unselectedFieldsAvailable,
		hiddenFieldKeys = [],
		selectedFieldKeys = [],
		title,
		helpText,
		isExpandable = false,
		isDragDisabled = false,
		togglePermissionType,
		onFieldClick,
		onFieldHide,
		onFieldToggle,
	}: ListProps) => {
		const [isExpanded, setIsExpanded] = useState(true);
		const isDropDisabled = useMemo(() => listId !== LISTS.SELECTED, [listId]);
		const fieldsCount = useMemo(() => fields.length ?? 0, [fields]);

		return (
			<>
				{isExpandable ? (
					<ExpandableHeader
						title={title}
						helpText={helpText}
						fieldsCount={fieldsCount}
						isExpanded={isExpanded}
						onChange={setIsExpanded}
					/>
				) : (
					<Header title={title} helpText={helpText} fieldsCount={fieldsCount} />
				)}
				<Wrapper>
					<InnerList
						listId={listId}
						fields={fields}
						unselectedFieldsAvailable={unselectedFieldsAvailable}
						hiddenFieldKeys={hiddenFieldKeys}
						selectedFieldKeys={selectedFieldKeys}
						isCollapsed={!isExpanded}
						isDragDisabled={isDragDisabled}
						isDropDisabled={isDropDisabled}
						togglePermissionType={togglePermissionType}
						onFieldClick={onFieldClick}
						onFieldHide={onFieldHide}
						onFieldToggle={onFieldToggle}
					/>
				</Wrapper>
			</>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	outlineOffset: '-1px',
	borderRadius: '4px',
	transition: 'background-color 0.2s ease',
	userSelect: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-of-type': {
		flexGrow: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyFieldsState = styled.div({
	paddingTop: token('space.150'),
	paddingRight: token('space.200'),
	paddingBottom: token('space.150'),
	paddingLeft: token('space.200'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N100),
});

const dropeZoneStyles = xcss({
	height: '100%',
});
