/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import noop from 'lodash/noop';
import AvatarGroup, { type AvatarProps } from '@atlaskit/avatar-group';
import Button from '@atlaskit/button';
import type { TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { useAvatars } from '../utils.tsx';
import { messages } from './messages.tsx';

type Props = {
	viewUUID: ViewUUID;
	onClick?: () => void;
	triggerProps?: TriggerProps;
	testId?: string;
};

const useAvatarProps = (viewUUID: ViewUUID) => {
	const avatars = useAvatars(viewUUID);

	return avatars.map(
		(avatar): AvatarProps => ({
			name: avatar.name || 'Unknown',
			src: avatar.src,
			presence: avatar.presence,
		}),
	);
};

export const VisitorsButton = ({ viewUUID, onClick, triggerProps, testId }: Props) => {
	const avatars = useAvatarProps(viewUUID);
	const { formatMessage } = useIntl();

	return (
		<Box>
			<Button
				css={buttonCss}
				onClick={onClick}
				{...triggerProps}
				testId={testId && `${testId}.button`}
				appearance="subtle"
				label={formatMessage(messages.buttonLabel)}
				iconAfter={
					<Box xcss={avatarGroupWrapperCss}>
						<AvatarGroup
							size="small"
							maxCount={5}
							appearance="stack"
							data={avatars}
							isTooltipDisabled
							onMoreClick={noop}
							testId="polaris-component-view-visitors.ui.visitors-button.avatar-group"
						/>
					</Box>
				}
			/>
		</Box>
	);
};

const buttonCss = css({ backgroundColor: token('elevation.surface') });

const avatarGroupWrapperCss = xcss({
	':hover::after': {
		backgroundColor: 'color.background.neutral.subtle',
	},
});
