import React, { memo, useCallback, useMemo, type PropsWithChildren, useState } from 'react';
import { styled } from '@compiled/react';
import type { EmojiDescription } from '@atlaskit/emoji';
import { JiraProductDiscoveryLogo } from '@atlaskit/logo';
import { Text, Flex, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Button, { IconButton } from '@atlaskit/button/new';
import OverviewIcon from '@atlaskit/icon/core/migration/align-left--overview';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import CopyIcon from '@atlaskit/icon/core/migration/copy';
import BoardIcon from '@atlaskit/icon/core/migration/board';
import DeleteIcon from '@atlaskit/icon/core/migration/delete--trash';
import { useIntl } from '@atlassian/jira-intl';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { PAGE_MARGIN_X } from '@atlassian/jira-polaris-common/src/common/constants.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	useIsCollectionView,
	useIsSharedView,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useIsSharedViewUnavailable } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/meta-hooks.tsx';
import {
	useIsInitialized,
	useIsIssuesLoading,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import { useIsRightSidebarOpen } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import {
	useIsIssueOpenInSidebar,
	useIsIssueOpenInFullscreen,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useCurrentViewSharingSettings } from '@atlassian/jira-polaris-common/src/controllers/sharing/selectors/hooks.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useIsViewsLoading } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/meta-hooks.tsx';
import {
	useCurrentViewIsEditingTitle,
	useCurrentViewTitle,
	useCurrentViewContainsArchived,
	useCurrentViewKind,
	useCurrentViewEmoji,
	useCanManageCurrentView,
	useCurrentViewId,
	useCurrentViewUUID,
	useAutoOpenSidebarCondition,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import FullscreenButton from '@atlassian/jira-polaris-component-fullscreen-button/src/index.tsx';
import {
	useHasNoProjectPermissions,
	useCanImportIssues,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { Visitors } from '@atlassian/jira-polaris-component-view-visitors/src/ui/index.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { ViewEmojiSpotlightProvider } from '@atlassian/jira-polaris-lib-control-sharing/src/controllers/view-emoji-spotlight-provider/index.tsx';
import { EditableViewTitle } from '@atlassian/jira-polaris-lib-editable-view-title/src/ui/index.tsx';
import { whyDidYouRenderPlaceholder } from '@atlassian/jira-polaris-why-did-you-render-placeholder/src/index.tsx';
import {
	useAnalyticsEvents,
	ContextualAnalyticsData,
	SCREEN,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { ViewHeader as ViewHeaderPackage } from '@atlassian/jira-polaris-component-view-header/src/ui/index.tsx';
import { useOpenRightSidebarOnViewInfo } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import {
	useEnvironmentContainer,
	PolarisEnvironmentContainerTypes,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useFeedbackModal } from '@atlassian/jira-polaris-common/src/controllers/feedback-modal/index.tsx';
import { PROJECTS_PATH } from '@atlassian/jira-polaris-common/src/controllers/route/constants/index.tsx';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	VIEW_KIND_BOARD,
	VIEW_KIND_TABLE,
	VIEW_KIND_TIMELINE,
	VIEW_KIND_MATRIX,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	PolarisIcon,
	PolarisIconType,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { useProjectName } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks.tsx';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { DeleteViewModal } from './delete-view-modal/index.tsx';
import { AccessButton } from './access/index.tsx';
import { ImportButton } from './import-button/index.tsx';
import { AvatarControl } from './avatar/index.tsx';
import { CommentsButton } from './comments-button/index.tsx';
import { ExportDialog } from './export-dialog/index.tsx';
import { FullscreenToggle } from './fullscreen/index.tsx';
import { PublishButton } from './publish/index.tsx';
import { ShareDialog } from './share-dialog/index.tsx';
import { Subtitle } from './view-subtitle/index.tsx';
import { ViewIdeaCount } from './view-subtitle/view-idea-count/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	isFullscreen: boolean;
	onToggleFullscreen: () => void;
};

const PUBLIC_UTM_LINK =
	'https://www.atlassian.com/software/jira/product-discovery?utm_source=product-logo&utm_medium=in-product&utm_campaign=P%3Ajira-product-discovery%7CO%3Apmm-devops-agile%7CF%3Aawareness%7CC%3Aweb%7CW%3Asingle%7CE%3Acloud%7CD%3Adesktop%7CL%3Aen%7CI%3Ajpd-public-view';
const STAKEHOLDERS_UTM_LINK =
	'https://www.atlassian.com/software/jira/product-discovery?utm_source=product-logo&utm_medium=in-product&utm_campaign=P%3Ajira-product-discovery%7CO%3Apmm-devops-agile%7CF%3Aawareness%7CC%3Aweb%7CW%3Asingle%7CE%3Acloud%7CD%3Adesktop%7CL%3Aen%7CI%3Ajpd-view-publishing';

const getJpdProjectsListLink = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const siteUrl = `${window.location.protocol}//${window.location.host}`;
	return `${siteUrl}/jira/projects?types=${PRODUCT_DISCOVERY_PROJECT}`;
};

export const JpdLogoLink = ({ children }: PropsWithChildren) => {
	const sharingSettings = useCurrentViewSharingSettings();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isPublicView = sharingSettings?.publicSharingEnabled;
	let sharedViewLogoLink: string;
	if (hasNoProjectPermissions) {
		if (isPublicView) {
			sharedViewLogoLink = PUBLIC_UTM_LINK;
		} else {
			sharedViewLogoLink = STAKEHOLDERS_UTM_LINK;
		}
	} else {
		sharedViewLogoLink = getJpdProjectsListLink();
	}

	return (
		<a
			data-testid="polaris-ideas.ui.view-header.shared-view-logo"
			href={sharedViewLogoLink}
			id="pendo.shared-view.logo-link"
			onClick={() => {
				fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'jpdLogo');
			}}
		>
			{children}
		</a>
	);
};

const ViewHeaderOld = memo(({ isFullscreen, onToggleFullscreen }: Props) => {
	const title = useCurrentViewTitle();
	const isEditingTitle = useCurrentViewIsEditingTitle();
	const canManageCurrentView = useCanManageCurrentView();
	const { renameCurrentView, setEditCurrentViewTitle, addCurrentViewEmoji } = useViewActions();
	const isArchiveView = useCurrentViewContainsArchived();
	const [isRightSidebarOpen] = useIsRightSidebarOpen();
	const isIssueOpenInSidebar = useIsIssueOpenInSidebar();
	const isIssueOpenInFullscreen = useIsIssueOpenInFullscreen();
	const isCollectionView = useIsCollectionView();
	const isSharedView = useIsSharedView();
	const isSharedViewUnavailable = useIsSharedViewUnavailable();
	const isViewsLoading = useIsViewsLoading();
	const viewKind = useCurrentViewKind();
	const emojiId = useCurrentViewEmoji();
	const isIssuesLoading = useIsIssuesLoading();
	const isInitialized = useIsInitialized();
	const currentViewId = useCurrentViewId();
	const viewUUID = useCurrentViewUUID();

	const onCancelEditTitle = useCallback(() => {
		setEditCurrentViewTitle(false);
		experience.listView.changeViewName.abort();
	}, [setEditCurrentViewTitle]);

	const onEditTitle = useCallback(() => {
		setEditCurrentViewTitle(true);
		experience.listView.changeViewName.start();
	}, [setEditCurrentViewTitle]);

	const onRenameView = useCallback(
		(newTitle: string) => {
			renameCurrentView(newTitle, (view): void => {
				if (isClientFetchError(view?.saveError)) {
					experience.listView.changeViewName.abort(view?.saveError);
				} else if (view && view.saveError) {
					experience.listView.changeViewName.failure(view.saveError);
				} else if (view && !view.saveError) {
					experience.listView.changeViewName.success();
				}
			});
		},
		[renameCurrentView],
	);

	const sharedViewLogo = (
		<LogoContainer>
			<JiraProductDiscoveryLogo size="small" appearance="brand" />
		</LogoContainer>
	);

	const renderHeaderContent = (props?: {
		setLoadedEmoji: (emoji: EmojiDescription | null) => void;
	}) => (
		<>
			<EditableViewTitle
				title={title}
				emojiId={emojiId}
				viewKind={viewKind}
				isEditingTitle={isEditingTitle}
				onEmojiChange={addCurrentViewEmoji}
				onEmojiLoaded={props?.setLoadedEmoji}
				onTitleEdit={onEditTitle}
				onTitleCancel={onCancelEditTitle}
				onTitleRename={onRenameView}
				isReadOnly={!canManageCurrentView || isArchiveView || isRightSidebarOpen}
				isLoading={isIssuesLoading || !isInitialized}
			/>
			{isRightSidebarOpen ||
			isIssueOpenInSidebar ||
			isIssueOpenInFullscreen ||
			isSharedView ? null : (
				<MenuContainer>
					{!isCollectionView && (
						<Visitors testId="polaris-ideas.ui.view-header.visitors" viewUUID={viewUUID} />
					)}
					{!isArchiveView && (
						<>
							<CommentsButton />
							<PublishButton />
							<AccessButton />
							<ShareDialog />
							{expVal('jpd_import_improvement_v1', 'isEnabled', false) && <ImportButton />}
							<ExportDialog />
						</>
					)}
					{getWillShowNav4() ? (
						<FullscreenButton
							testId="polaris-ideas.ui.view-header.fullscreen-button"
							onToggle={onToggleFullscreen}
						/>
					) : (
						<FullscreenToggle isFullscreen={isFullscreen} onToggle={onToggleFullscreen} />
					)}
				</MenuContainer>
			)}
		</>
	);

	return (
		<ContextualAnalyticsData sourceName="viewHeader" sourceType={SCREEN}>
			{whyDidYouRenderPlaceholder('ViewHeader')}
			<Wrapper isSharedView={isSharedView}>
				<Flex direction="column" gap="space.075" xcss={headerContentContainerStyles}>
					<Flex justifyContent="space-between" alignItems="center" gap="space.150">
						<ViewEmojiSpotlightProvider key={currentViewId}>
							{renderHeaderContent}
						</ViewEmojiSpotlightProvider>
					</Flex>
					<Subtitle
						showViewDescriptionButton={
							!(isArchiveView || (isSharedView && (isViewsLoading || isSharedViewUnavailable)))
						}
					/>
				</Flex>
				{isSharedView && (
					<MenuContainer>
						{!isArchiveView && (
							<>
								<CommentsButton />
								<PublishButton />
							</>
						)}

						<SharedViewLogoContainer
							data-component-selector="shared-view-logo-container-Aw12"
							data-testid="polaris-ideas.ui.view-header.shared-view-logo-container"
						>
							<JpdLogoLink>{sharedViewLogo}</JpdLogoLink>
							<AvatarControl />
						</SharedViewLogoContainer>
					</MenuContainer>
				)}
			</Wrapper>
		</ContextualAnalyticsData>
	);
});

ViewHeaderOld.displayName = 'ViewHeader';

const ViewHeaderNew = ({ isFullscreen, onToggleFullscreen }: Props) => {
	const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
		useState<boolean>(false);
	const title = useCurrentViewTitle();
	const isEditingTitle = useCurrentViewIsEditingTitle();
	const canManageCurrentView = useCanManageCurrentView();
	const { renameCurrentView, setEditCurrentViewTitle, addCurrentViewEmoji, cloneView, deleteView } =
		useViewActions();
	const isArchiveView = useCurrentViewContainsArchived();
	const [isRightSidebarOpen] = useIsRightSidebarOpen();
	const isIssueOpenInSidebar = useIsIssueOpenInSidebar();
	const isIssueOpenInFullscreen = useIsIssueOpenInFullscreen();
	const isCollectionView = useIsCollectionView();
	const isSharedView = useIsSharedView();
	const viewKind = useCurrentViewKind();
	const emojiId = useCurrentViewEmoji();
	const isIssuesLoading = useIsIssuesLoading();
	const isInitialized = useIsInitialized();
	const currentViewId = useCurrentViewId();
	const viewUUID = useCurrentViewUUID();
	const sharingSettings = useCurrentViewSharingSettings();
	const openRightSidebarOnViewInfo = useOpenRightSidebarOnViewInfo();
	const autoOpenSidebarCondition = useAutoOpenSidebarCondition();
	const { formatMessage } = useIntl();
	const [, { setIsFeedbackModalOpen }] = useFeedbackModal();
	const setFeedbackModalOpen = useCallback(
		() => setIsFeedbackModalOpen(true),
		[setIsFeedbackModalOpen],
	);
	const container = useEnvironmentContainer();
	const { clearCreatedProperty } = useIssueActions();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectId : undefined;
	const { error } = useNotifications();
	const canImportIssues = useCanImportIssues();
	const [projectNameOld] = useProjectName();
	const projectMeta = useProjectMetadata({ projectId });
	const projectName = fg('polaris-refactor-skipprojectinfo') ? projectMeta?.name : projectNameOld;

	const onCloneView = useCallback(
		(viewType: ViewKind | undefined) => {
			if (viewType && currentViewId) {
				experience.navBar.createView.start();
				cloneView(
					currentViewId,
					viewType,
					() => {
						experience.navBar.createView.success({
							metadata: {
								kind: viewType,
								source: 'view-header',
							},
						});
					},
					(err?: Error) => {
						error({
							title: formatMessage(messages.cloneViewErrorTitle),
							description: formatMessage(messages.cloneViewErrorDescription),
						});
						if (isClientFetchError(err)) {
							experience.navBar.createView.abort(err);
							return;
						}
						experience.navBar.createView.failure({
							metadata: {
								error: err?.message,
								kind: viewType,
								source: 'view-header',
							},
						});
					},
				);
				clearCreatedProperty();
			}
		},
		[clearCreatedProperty, cloneView, currentViewId, error, formatMessage],
	);

	const cloneViewMenuElement = useCallback(
		(viewType: ViewKind | undefined) => {
			switch (viewType) {
				case VIEW_KIND_TABLE:
					return (
						<DropdownItem
							elemBefore={<CopyIcon label="" />}
							onClick={() => onCloneView(VIEW_KIND_TABLE)}
						>
							{formatMessage(messages.saveAsNewList)}
						</DropdownItem>
					);
				case VIEW_KIND_BOARD:
					return (
						<DropdownItem
							elemBefore={<BoardIcon label="" />}
							onClick={() => onCloneView(VIEW_KIND_BOARD)}
						>
							{formatMessage(messages.saveAsNewBoard)}
						</DropdownItem>
					);
				case VIEW_KIND_TIMELINE:
					return (
						<DropdownItem
							elemBefore={<PolarisIcon name={PolarisIconType.Timeline} size="medium" label="" />}
							onClick={() => onCloneView(VIEW_KIND_TIMELINE)}
						>
							{formatMessage(messages.saveAsNewTimeline)}
						</DropdownItem>
					);
				case VIEW_KIND_MATRIX:
					return (
						<DropdownItem
							elemBefore={<PolarisIcon name={PolarisIconType.Matrix} size="medium" label="" />}
							onClick={() => onCloneView(VIEW_KIND_MATRIX)}
						>
							{formatMessage(messages.saveAsNewMatrix)}
						</DropdownItem>
					);
				default:
					return (
						<DropdownItem
							elemBefore={<CopyIcon label="" />}
							onClick={() => onCloneView(VIEW_KIND_TABLE)}
						>
							{formatMessage(messages.saveAsNewList)}
						</DropdownItem>
					);
			}
		},
		[formatMessage, onCloneView],
	);

	const fullscreenButton = useMemo(
		() =>
			getWillShowNav4() ? (
				<FullscreenButton
					testId="polaris-ideas.ui.view-header.fullscreen-button"
					onToggle={onToggleFullscreen}
				/>
			) : (
				<FullscreenToggle isFullscreen={isFullscreen} onToggle={onToggleFullscreen} />
			),
		[isFullscreen, onToggleFullscreen],
	);

	const viewButtons = useMemo(() => {
		if (!isArchiveView) {
			if (isSharedView) {
				return (
					<>
						<Button
							iconBefore={OverviewIcon}
							onClick={openRightSidebarOnViewInfo}
							interactionName="jpd.view-header.view-info"
						>
							{formatMessage(messages.aboutView)}
						</Button>
						<CommentsButton />
						<PublishButton />
					</>
				);
			}
			return (
				<>
					<Button
						iconBefore={OverviewIcon}
						onClick={openRightSidebarOnViewInfo}
						interactionName="jpd.view-header.view-info"
					>
						{formatMessage(messages.aboutView)}
					</Button>
					<CommentsButton />
					<PublishButton />
					<AccessButton />
					<ShareDialog />
					<DropdownMenu
						trigger={({ triggerRef, ...props }) => (
							<IconButton
								{...props}
								icon={ShowMoreHorizontalIcon}
								label={formatMessage(messages.openDropdownMenu)}
								ref={triggerRef}
								appearance={isVisualRefreshEnabled() ? 'default' : 'subtle'}
							/>
						)}
						placement="bottom-end"
					>
						<DropdownItemGroup>
							<ExportDialog />
							{canImportIssues && expVal('jpd_import_improvement_v1', 'isEnabled', false) && (
								<ImportButton isMenuItem />
							)}
						</DropdownItemGroup>
						{canManageCurrentView && (
							<>
								<DropdownItemGroup hasSeparator>
									{cloneViewMenuElement(VIEW_KIND_TABLE)}
									{cloneViewMenuElement(viewKind === VIEW_KIND_TABLE ? VIEW_KIND_BOARD : viewKind)}
								</DropdownItemGroup>
								<DropdownItemGroup hasSeparator>
									<DropdownItem
										elemBefore={<DeleteIcon color={token('color.icon.danger')} label="" />}
										onClick={() => setIsDeleteConfirmationModalOpen(true)}
									>
										<Text color="color.text.danger">{formatMessage(messages.deleteView)}</Text>
									</DropdownItem>
								</DropdownItemGroup>
							</>
						)}
					</DropdownMenu>
					{fullscreenButton}
					{title && currentViewId && (
						<DeleteViewModal
							isOpen={isDeleteConfirmationModalOpen}
							title={title}
							onConfirm={() => {
								setIsDeleteConfirmationModalOpen(false);
								deleteView(currentViewId);
							}}
							onCancel={() => setIsDeleteConfirmationModalOpen(false)}
						/>
					)}
				</>
			);
		}
	}, [
		isArchiveView,
		isSharedView,
		openRightSidebarOnViewInfo,
		formatMessage,
		canImportIssues,
		canManageCurrentView,
		cloneViewMenuElement,
		viewKind,
		fullscreenButton,
		title,
		currentViewId,
		isDeleteConfirmationModalOpen,
		deleteView,
	]);

	const viewIdeaCount = useMemo(() => <ViewIdeaCount />, []);
	const avatarControl = useMemo(() => <AvatarControl />, []);

	return (
		<ViewHeaderPackage
			pageMarginX={PAGE_MARGIN_X}
			isCollectionView={isCollectionView}
			isSharedView={isSharedView}
			isInitialized={isInitialized}
			isIssuesLoading={isIssuesLoading}
			isRightSidebarOpen={isRightSidebarOpen}
			isIssueOpenInSidebar={isIssueOpenInSidebar}
			isIssueOpenInFullscreen={isIssueOpenInFullscreen}
			isEditingTitle={isEditingTitle}
			title={title}
			isArchiveView={isArchiveView}
			viewKind={viewKind}
			emojiId={emojiId}
			canManageCurrentView={canManageCurrentView}
			currentViewId={currentViewId}
			viewUUID={viewUUID}
			sharingSettings={sharingSettings}
			onRenameCurrentView={renameCurrentView}
			setEditCurrentViewTitle={setEditCurrentViewTitle}
			onAddCurrentViewEmoji={addCurrentViewEmoji}
			viewButtons={viewButtons}
			fullscreenButton={fullscreenButton}
			avatarControl={avatarControl}
			viewIdeaCount={viewIdeaCount}
			onOpenRightSidebarOnViewInfo={openRightSidebarOnViewInfo}
			autoOpenSidebarCondition={autoOpenSidebarCondition}
			setFeedbackModalOpen={setFeedbackModalOpen}
			projectsPath={PROJECTS_PATH}
			projectName={projectName}
		/>
	);
};

export const ViewHeader = componentWithFG(
	'polaris_pol-12839_view_header_redesign',
	ViewHeaderNew,
	ViewHeaderOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.150'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'* + [data-component-selector="shared-view-logo-container-Aw12"]::before': {
		marginRight: token('space.200'),
		content: '',
		position: 'absolute',
		left: 0,
		top: '3px',
		bottom: '3px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderLeft: `1px solid ${token('color.border', colors.N40)}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ isSharedView: boolean }>(
	{
		position: 'relative',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		padding: `0 ${PAGE_MARGIN_X}px`,
		boxSizing: 'border-box',
		marginTop: token('space.250'),
		marginBottom: token('space.050'),
		gap: token('space.075'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isSharedView }) =>
		isSharedView && {
			marginBottom: token('space.150'),
			marginTop: token('space.negative.050'),
			paddingTop: token('space.100'),
			paddingBottom: token('space.100'),
			boxShadow: token(
				'elevation.shadow.overflow',
				'0px 0px 8px rgba(9, 30, 66, 0.16),0px 0px 1px rgba(9, 30, 66, 0.12)',
			),
			minHeight: '70px',
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LogoContainer = styled.div({
	height: '24px',
	marginTop: 0,
	marginRight: token('space.200'),
	marginBottom: 0,
	marginLeft: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SharedViewLogoContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	position: 'relative',
	marginLeft: token('space.050'),
});

const headerContentContainerStyles = xcss({ flexGrow: 1, width: '100%' });
