// styles extracted to separate constants in order to reuse them in card height calculations
export const CONNECTION_ITEM_LINE_HEIGHT = 20;

export const CONNECTION_ITEM_STYLES = {
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: `${CONNECTION_ITEM_LINE_HEIGHT}px`,
	fontsize: '13px',
	display: '-webkit-box',
	overflow: 'hidden',
	paddingLeft: '16px',
	webkitLineClamp: '2',
	webkitBoxOrient: 'vertical',
} as const;

export const CONNECTION_ITEM_ELEM_AFTER_STYLES = {
	marginLeft: 6,
	width: 65,
} as const;

export const CONNECTION_ITEM_RATING_FIELD_WIDTH = 80;
