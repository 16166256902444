import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noIssuesFound: {
		id: 'polaris-ideas.common.link-issue.no-issues-found',
		defaultMessage: 'No issues found',
		description:
			'Placeholder text in issue picker dropdown, when no linkable issues could be found.',
	},
	noIssuesFoundIssueTermRefresh: {
		id: 'polaris-ideas.common.link-issue.no-issues-found-issue-term-refresh',
		defaultMessage: 'No work items found',
		description:
			'Placeholder text in issue picker dropdown, when no linkable issues could be found.',
	},
	typeToSearch: {
		id: 'polaris-ideas.common.link-issue.type-to-search',
		defaultMessage: 'Type to search for a delivery ticket',
		description:
			'Initial placeholder text in issue picker dropdown before a search request was made.',
	},
	issuePickerPlaceholder: {
		id: 'polaris-ideas.common.link-issue.issue-picker-placeholder',
		defaultMessage: 'Search for an issue',
		description: 'Placeholder text in issue picker select component.',
	},
	issuePickerPlaceholderIssueTermRefresh: {
		id: 'polaris-ideas.common.link-issue.issue-picker-placeholder-issue-term-refresh',
		defaultMessage: 'Search for a work item',
		description: 'Placeholder text in issue picker select component.',
	},
	optionGroupOpenIssues: {
		id: 'polaris-ideas.common.link-issue.option-group-open-issues',
		defaultMessage: 'Open issues',
		description: 'Option group caption in issue picker dropdown.',
	},
	optionGroupOpenIssuesIssueTermRefresh: {
		id: 'polaris-ideas.common.link-issue.option-group-open-issues-issue-term-refresh',
		defaultMessage: 'Open work items',
		description: 'Option group caption in issue picker dropdown.',
	},
	heading: {
		id: 'polaris-ideas.common.link-issue.heading',
		defaultMessage: 'Link existing ticket',
		description:
			'Panel heading for creating an issue link from this idea to an existing Jira ticket in any other project.',
	},
	link: {
		id: 'polaris-ideas.common.link-issue.link',
		defaultMessage: 'Add',
		description:
			'Button label for create an issue link between this idea and a selected delivery work item.',
	},
	cancel: {
		id: 'polaris-ideas.common.link-issue.cancel',
		defaultMessage: 'Cancel',
		description: 'Button label for cancellation.',
	},
	projectLabel: {
		id: 'polaris-ideas.common.link-issue.project-label',
		defaultMessage: 'Search for a project',
		description: 'Label for the project select.',
	},
	issueTypeLabel: {
		id: 'polaris-ideas.common.link-issue.issue-type-label',
		defaultMessage: 'Issue type',
		description: 'Label for the issue type select',
	},
	issueTypeLabelIssueTermRefresh: {
		id: 'polaris-ideas.common.link-issue.issue-type-label-issue-term-refresh',
		defaultMessage: 'Work types',
		description: 'Label for the issue type select',
	},
	errorTitle: {
		id: 'polaris-ideas.common.link-issue.error-title',
		defaultMessage: 'Something went wrong.',
		description: 'Flag title to communicate an error.',
	},
	errorDescription: {
		id: 'polaris-ideas.common.link-issue.error-description',
		defaultMessage:
			'An error occurred while sending your ideas to the backlog. Our team has been notified. Please try again.',
		description: 'Flag description to communicate a general "sent to backlog" error.',
	},
});
