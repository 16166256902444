import React, { useCallback, memo, useMemo } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import EditorEditIcon from '@atlaskit/icon/core/migration/edit--editor-edit';
import InlineEdit, { type InlineEditProps } from '@atlaskit/inline-edit';
import { Box, xcss } from '@atlaskit/primitives';
import { type ElementItem, ElementName } from '@atlaskit/smart-card';
import TextField from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useIsExportingViewImage } from '@atlassian/jira-polaris-component-view-export/src/controllers/selectors.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { ErrorContainer } from '@atlassian/jira-polaris-lib-inputs-error/src/ui/styled.tsx';
import { CustomTooltip } from '@atlassian/jira-polaris-lib-inputs-error/src/ui/tooltip/index.tsx';
import { useSmartCardGrantedResponse } from '@atlassian/jira-polaris-lib-smart-card/src/controllers/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { MAX_FIELD_LENGTH } from '../../../common/constants.tsx';
import { isSafeUrl } from '../../../common/utils/url/index.tsx';
import {
	ErrorBoundary,
	SmardCardWithoutLazyLoading,
	SmartCard,
} from '../external-reference/renderer/index.tsx';
import { messages } from './messages.tsx';

type HyperlinkFieldProps = {
	isEditable: boolean;
	value: string | undefined;
	testId?: string;
	metadata?: ElementItem[];
};

type HyperlinkCardProps = {
	value: string | undefined;
	metadata?: ElementItem[];
};

export const HyperlinkField = ({ value, isEditable, testId }: HyperlinkFieldProps) => {
	const [canEditIssues] = useCanEditIssues();
	const shouldRenderEditButton = canEditIssues && isEditable;
	const isExportingViewImage = useIsExportingViewImage();

	if (!value) return null;

	return isExportingViewImage ? (
		<HyperlinkImageExportContainer value={value} testId={testId} />
	) : (
		<HyperlinkInner value={value} testId={testId} isEditable={shouldRenderEditButton} />
	);
};

const HyperlinkImageExportContainer = ({
	value,
	testId,
}: {
	value: string | undefined;
	testId?: string;
}) =>
	value ? (
		<ExportedAnchorContainer>
			<ExportedAnchor data-testid={testId ? `${testId}--exported` : ''} href={value}>
				{value}
			</ExportedAnchor>
		</ExportedAnchorContainer>
	) : null;

const HyperlinkInner = ({ value, testId, isEditable }: HyperlinkFieldProps) => (
	<LinkContainer data-component-selector="hyperlink-container-sg47" data-testid={testId}>
		<HyperlinkCardWithoutLazyLoading
			value={value}
			metadata={useMemo(
				() => [
					{
						name: ElementName.State,
					},
				],
				[],
			)}
		/>
		{isEditable ? <HyperlinkEditButtonContainer /> : null}
	</LinkContainer>
);

const HyperlinkCard = ({ value, metadata }: HyperlinkCardProps) => (
	<SmartCard
		url={value ?? ''}
		metadata={metadata ?? []}
		isContainerClickable
		showHoverCard={false}
		frameStyle="hide"
	/>
);

const HyperlinkCardWithoutLazyLoading = memo(({ value, metadata }: HyperlinkCardProps) => {
	const data = useSmartCardGrantedResponse(value || '');
	if (data) {
		return (
			<>
				<ErrorBoundary useFallback={() => <HyperlinkCard value={value} metadata={metadata} />}>
					<SmardCardWithoutLazyLoading
						url={value ?? ''}
						metadata={metadata ?? []}
						showHoverCard={false}
						frameStyle="hide"
						isContainerClickable
					/>
				</ErrorBoundary>
			</>
		);
	}
	return <HyperlinkCard value={value} metadata={metadata} />;
});

const HyperlinkEditButtonContainer = () => {
	const { formatMessage } = useIntl();
	return (
		<EditButtonContainer data-component-selector="edit-button-aAZ">
			<Tooltip content={formatMessage(messages.editLink)}>
				<Button
					iconAfter={
						<EditorEditIcon
							label={formatMessage(messages.editLink)}
							LEGACY_size="medium"
							spacing="spacious"
						/>
					}
					appearance="subtle"
					aria-label={formatMessage(messages.editLink)}
				/>
			</Tooltip>
		</EditButtonContainer>
	);
};

type StringFieldProps = {
	isIdeaView?: boolean;
	isEditable: boolean;
	value: string | undefined;
	placeholder?: string | undefined;
	testId?: string;
	fieldType: FieldType | undefined;
	onUpdate: (inputValue: string | undefined) => void;
};

export const StringField = ({
	isIdeaView,
	onUpdate,
	fieldType,
	isEditable,
	value,
	placeholder,
	testId,
}: StringFieldProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const validateStringInput = useCallback(
		(val?: string) => {
			if (val && fieldType === FIELD_TYPES.HYPERLINK && !isSafeUrl(val)) {
				return formatMessage(messages.invalidLink);
			}
			if (val !== undefined && val?.length > MAX_FIELD_LENGTH) {
				if (fieldType === FIELD_TYPES.HYPERLINK) {
					fireCompoundAnalyticsEvent.HyperlinkFieldMaxLengthError(createAnalyticsEvent({}));
				}
				return formatMessage(messages.shortTextFieldTypeError);
			}
			return undefined;
		},
		[createAnalyticsEvent, formatMessage, fieldType],
	);

	const handleConfirm = useCallback(
		(newValue: string) => {
			const valueForUpdate = newValue === '' ? undefined : newValue;

			if (valueForUpdate !== value) {
				onUpdate(valueForUpdate);
			}
		},
		[onUpdate, value],
	);

	const onTextFieldFocus = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'focused', actionSubject: 'textField' }),
			'issueField',
		);
	}, [createAnalyticsEvent]);

	const readView = () => {
		if (value !== undefined && fieldType === FIELD_TYPES.HYPERLINK) {
			return <HyperlinkField value={value} isEditable={isEditable} />;
		}
		if (value === undefined && placeholder !== undefined) {
			return <Placeholder>{placeholder}</Placeholder>;
		}
		return (
			<ReadViewContainer data-component-selector="read-view-container-A9z3">
				{value}
			</ReadViewContainer>
		);
	};

	const editView: InlineEditProps<string>['editView'] = ({ errorMessage, ...fieldProps }) => {
		const error = errorMessage !== undefined ? errorMessage : null;
		const isInvalid = !!error;
		// We need to wrap this with the same border as below. Otherwise the cell shrinks when editing.
		return (
			<EditViewContainer>
				{isIdeaView && fieldType === FIELD_TYPES.HYPERLINK ? (
					<>
						<TextField
							{...fieldProps}
							autoFocus
							isCompact
							isInvalid={isInvalid}
							onFocus={onTextFieldFocus}
							placeholder={fieldType === FIELD_TYPES.HYPERLINK ? 'https://' : undefined}
						/>
						{isInvalid && (
							<ErrorContainer data-testid={testId && `${testId}--error`}>
								{errorMessage}
							</ErrorContainer>
						)}
					</>
				) : (
					<TextField
						{...fieldProps}
						autoFocus
						isCompact
						isInvalid={isInvalid}
						onFocus={onTextFieldFocus}
						placeholder={fieldType === FIELD_TYPES.HYPERLINK ? 'https://' : undefined}
						elemAfterInput={
							isInvalid && (
								<Tooltip component={CustomTooltip} content={errorMessage} position="bottom-end">
									<ErrorIconContainer>
										<ErrorIcon
											color={token('color.icon.danger')}
											label={formatMessage(messages.errorMessage)}
											LEGACY_primaryColor={colors.R400}
										/>
									</ErrorIconContainer>
								</Tooltip>
							)
						}
					/>
				)}
			</EditViewContainer>
		);
	};

	return isEditable ? (
		<Box xcss={containerStyles}>
			<InlineEdit
				defaultValue={value}
				// POL-10012: in some scenarios InlineEdit uses stale value, this
				// will force it to render with the correct state
				key={value}
				readViewFitContainerWidth
				onConfirm={handleConfirm}
				readView={readView}
				editView={editView}
				validate={validateStringInput}
			/>
		</Box>
	) : (
		<div
			// Inline Edit component wraps a border around the read view component, we do the same here
			// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={{ border: '2px solid transparent' }}
			data-testid={testId && `${testId}--read-view`}
		>
			{readView()}
		</div>
	);
};

const containerStyles = xcss({
	marginTop: 'space.negative.100',
	paddingLeft: 'space.025',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	paddingTop: token('space.100'),
	paddingRight: token('space.025'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.025'),
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-link-container="true"]': {
		borderRadius: token('border.radius.200', '4px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="edit-button-aAZ"]': {
		display: 'none',
	},
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-component-selector="edit-button-aAZ"]': {
			display: 'block',
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Placeholder = styled.div({
	minHeight: '16px',
	paddingTop: token('space.075'),
	paddingRight: token('space.025'),
	paddingBottom: token('space.075'),
	paddingLeft: token('space.025'),
	overflow: 'hidden',
	wordBreak: 'break-word',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewContainer = styled.div({
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'transparent',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorIconContainer = styled.div({
	cursor: 'pointer',
	marginBottom: token('space.negative.025'),
	marginRight: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditButtonContainer = styled.div({
	paddingLeft: token('space.075'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExportedAnchor = styled.a({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	width: '100%',
	display: 'inline-block',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExportedAnchorContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
});
