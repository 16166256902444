import React, { useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import RemoveIcon from '@atlaskit/icon/core/migration/delete--editor-remove';
import ShortcutIcon from '@atlaskit/icon/core/migration/link-external--shortcut';
import { Flex } from '@atlaskit/primitives';
import { useIntl, type IntlShape } from '@atlassian/jira-intl';
import {
	useCanEditFields,
	useCanManageGlobalFields,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { ButtonWithTooltip } from '@atlassian/jira-polaris-lib-button-with-tooltip/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsCollectionView } from '../../../controllers/environment/index.tsx';
import {
	useIsDeletable,
	useIsGlobalCustomField,
	useIsGlobalNonSystemField,
} from '../../../controllers/field/selectors/field-hooks.tsx';
import { DeleteFieldModal } from '../../config/fields/delete-field/index.tsx';
import { PermissionsIcon } from '../edit-global-field/permissions-icon/index.tsx';
import { messages } from './messages.tsx';

const getDeleteButtonTooltipMessage = (
	canEditFields: boolean,
	isDeletable: boolean,
	formatMessage: IntlShape['formatMessage'],
) => {
	if (!canEditFields) {
		return formatMessage(messages.noPermissions);
	}
	if (!isDeletable) {
		return formatMessage(messages.fieldNotDeletable);
	}
	return undefined;
};

type Props = {
	fieldKey: FieldKey;
	onDeleteConfirmed: () => void;
};

export const ManageFieldButtons = ({ fieldKey, onDeleteConfirmed }: Props) => {
	const { formatMessage } = useIntl();
	const canEditFields = useCanEditFields();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isDeletable = useIsDeletable(fieldKey);
	const isDisabled = !canEditFields || !isDeletable;
	const [fieldKeyForDelete, setFieldKeyForDelete] = useState<FieldKey | null>(null);
	const isGlobalField = useIsGlobalCustomField(fieldKey);
	const isGlobalNonSystemField = useIsGlobalNonSystemField(fieldKey);
	const canManageGlobalFields = useCanManageGlobalFields();
	const isCollectionView = useIsCollectionView();

	const onCloseModal = useCallback(() => {
		setFieldKeyForDelete(null);
	}, [setFieldKeyForDelete]);

	const onOpenModal = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'deleteFieldButton clicked', 'config-fields');
		experience.fieldsSidebar.fieldDelete.start();
		setFieldKeyForDelete(fieldKey);
	}, [createAnalyticsEvent, fieldKey]);

	const showGlobalFieldControls = fg('polaris_display-gsf-as-a-regular-project-field')
		? isGlobalNonSystemField
		: isGlobalField;

	if (isCollectionView && !showGlobalFieldControls) return;

	return (
		<>
			{fieldKeyForDelete && (
				<DeleteFieldModal
					fieldKey={fieldKeyForDelete}
					onClose={onCloseModal}
					onDeleteConfirmed={onDeleteConfirmed}
				/>
			)}
			<FieldMainButtonContainer id="polaris-sidebar.field.delete">
				<Flex alignItems="center" gap="space.100">
					{!isCollectionView && (
						<ButtonWithTooltip
							testId="polaris-common.ui.common.manage-field-buttons.delete-button"
							appearance="default"
							isDisabled={isDisabled}
							onClick={onOpenModal}
							iconBefore={
								<RemoveIcon
									spacing="spacious"
									label={formatMessage(
										showGlobalFieldControls ? messages.removeField : messages.deleteField,
									)}
								/>
							}
							tooltipProps={{
								content: getDeleteButtonTooltipMessage(canEditFields, isDeletable, formatMessage),
							}}
						>
							{formatMessage(showGlobalFieldControls ? messages.removeField : messages.deleteField)}
						</ButtonWithTooltip>
					)}

					{showGlobalFieldControls && (
						<>
							<Button
								isDisabled={!canManageGlobalFields}
								appearance="default"
								testId="polaris-common.ui.common.manage-field-buttons.edit-button"
								onClick={(_event, analyticEvent) => {
									fireUIAnalytics(analyticEvent, 'globalFieldEdit');
								}}
								iconAfter={<ShortcutIcon label={formatMessage(messages.editGlobalField)} />}
								href={`/jira/settings/products/jira-product-discovery/global-fields?openFieldConfigurationPanel=${fieldKey}`}
								target="_blank"
							>
								{formatMessage(messages.editGlobalField)}
							</Button>
							{!canManageGlobalFields && <PermissionsIcon />}
						</>
					)}
				</Flex>
			</FieldMainButtonContainer>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldMainButtonContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	borderTop: '1px solid #ebecf0',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	padding: '15px',
});
