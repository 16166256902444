import React, { useMemo, useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import EditorSearchIcon from '@atlaskit/icon/core/migration/search--editor-search';
import SelectClearIcon from '@atlaskit/icon/core/migration/cross-circle--select-clear';
import Textfield from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	isFocused: boolean;
	value?: string | undefined;
	onChange: (value: string) => void;
	onFocus?: () => void;
	isCompact?: boolean;
};

export const SearchBoxComponent = ({
	value,
	isFocused,
	onChange,
	onFocus = noop,
	isCompact = true,
}: Props) => {
	const { formatMessage } = useIntl();

	const [localValue, setValue] = useState(value);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const debouncedChange = useMemo(() => debounce(onChange, 200), [onChange]);

	useEffect(() => {
		if (isFocused) {
			inputRef.current?.focus();
		}
	}, [isFocused]);

	useEffect(() => {
		// Clear local value on filter clear.
		if (value === '') {
			setValue(value);
		}
	}, [value]);

	return (
		<Textfield
			ref={inputRef}
			isCompact={isCompact}
			value={localValue}
			placeholder={formatMessage(messages.searchHint)}
			onChange={(e) => {
				const { value: val } = e.currentTarget;
				setValue(val);
				debouncedChange(val);
			}}
			onMouseDown={(e) => {
				e.stopPropagation();
				// @ts-expect-error - TS2339 - Property 'focus' does not exist on type 'EventTarget'.
				e.target.focus();
			}}
			onTouchEnd={(e) => {
				e.stopPropagation();
				// @ts-expect-error - TS2339 - Property 'focus' does not exist on type 'EventTarget'.
				e.target.focus();
			}}
			onFocus={(e) => {
				e.stopPropagation();
				onFocus();
			}}
			elemBeforeInput={
				<EditorSearchIcon spacing="spacious" color={token('color.icon', colors.N100)} label="" />
			}
			elemAfterInput={
				localValue !== '' && (
					<Button
						testId="polaris-lib-search-box.ui.clear-search-button"
						iconBefore={<SelectClearIcon label="" LEGACY_size="small" />}
						appearance="subtle-link"
						spacing="compact"
						onClick={() => {
							setValue('');
							onChange('');
						}}
					/>
				)
			}
		/>
	);
};
