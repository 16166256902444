import mapValues from 'lodash/mapValues';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const syncFieldsIssueTypes =
	(): Action<State, Props> =>
	({ setState, getState }, { issueTypeIds = [] }) => {
		setState({
			fields: mapValues(getState().fields, (field) => ({
				...field,
				issueTypes: issueTypeIds,
			})),
		});
	};
