import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import type { ViewSortConfiguration } from '@atlassian/jira-polaris-domain-view/src/sort/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals/src/index.tsx';
import type { RankingRemote } from '@atlassian/jira-polaris-remote-ranking/src/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import { isCurrentViewAutosaveEnabled } from '../../selectors/view/autosave/index.tsx';
import type { State, Props } from '../../types.tsx';
import {
	withSortFieldsSet,
	withViewRankMode,
	withProjectRankMode,
	withMovedIssue,
	withInsertedIssue,
	withMovedIssuesToTop,
} from '../../utils/index.tsx';
import { saveViewWithAutoSave } from '../save/index.tsx';
import { fireViewUpdatedEvent } from '../utils/analytics.tsx';
import { updateViewState } from '../utils/state/index.tsx';
import { currentViewFilter } from '../utils/views/index.tsx';

const sync = <T extends string>(
	rankingRemote: RankingRemote,
	listId: string,
	viewIssueRanking: T[] | undefined,
	sortedIds: T[],
): Promise<void> => {
	if (isShallowEqual(viewIssueRanking, sortedIds)) {
		return Promise.resolve();
	}
	return rankingRemote.moveBatchFirst({
		listId,
		items:
			viewIssueRanking !== undefined && sortedIds.length < viewIssueRanking.length
				? viewIssueRanking
				: sortedIds,
	});
};

const updateSortConfigurationOfCurrentView =
	(
		permanentSortConfigurationTransition?: (
			arg1: ViewSortConfiguration | View,
		) => ViewSortConfiguration,
		sideEffects?: (arg1: RankingRemote, arg2: Ari, viewIssueRanking?: IssueId[]) => Promise<void>,
		onSuccess?: () => void,
		onError?: (error?: Error) => void,
	) =>
	async ({ getState, setState, dispatch }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const { currentViewSlug, rankingRemote, onViewUpdateFailed, createAnalyticsEvent } = props;
		const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);

		const { changedView, viewSets, currentView } = updateViewState(
			state.viewSets,
			currentViewFilter(currentViewSlug),
			(view: View): View => {
				if (!permanentSortConfigurationTransition) {
					return view;
				}

				const permanentSortConfig = permanentSortConfigurationTransition
					? permanentSortConfigurationTransition(view)
					: view;
				const draftSortConfig = permanentSortConfigurationTransition
					? permanentSortConfigurationTransition(view.draft)
					: view.draft;

				return {
					...view,
					...(isAutosaveEnabled && {
						sortBy: permanentSortConfig.sortBy,
						sortMode: permanentSortConfig.sortMode,
					}),
					issueRanking: permanentSortConfig.issueRanking,
					modified: isAutosaveEnabled,
					draft: {
						...view.draft,
						sortBy: draftSortConfig.sortBy,
						sortMode: draftSortConfig.sortMode,
					},
				};
			},
		);
		if (currentView && currentView.viewId && sideEffects) {
			sideEffects(rankingRemote, currentView.viewId, currentView.issueRanking).catch(
				onViewUpdateFailed,
			);
		}
		if (changedView) {
			setState({ viewSets });

			if (permanentSortConfigurationTransition !== undefined) {
				dispatch(
					saveViewWithAutoSave(changedView.id, (view: View | undefined) => {
						if (view && view.saveError) {
							onError?.(view.saveError);
						} else if (view && !view.saveError) {
							onSuccess?.();
						}
					}),
				);

				const updatedItems = [];
				if (currentView?.sortMode !== changedView.sortMode) {
					updatedItems.push({
						name: 'sortMode',
						oldVal: currentView?.sortMode,
						newVal: changedView.sortMode,
					});
				}
				if (currentView?.sortBy !== changedView.sortBy) {
					updatedItems.push({
						name: 'sortBy',
						oldVal: currentView?.sortBy,
						newVal: changedView.sortBy,
					});
				}

				fireViewUpdatedEvent(createAnalyticsEvent, changedView, { updatedItems });
			}
		}
	};

export const moveIssuesToTopOfCurrentView = (
	ids: IssueId[],
	sortedIssueIdsOnCreation: IssueId[],
): Action<State, Props> =>
	updateSortConfigurationOfCurrentView(
		(view) => withMovedIssuesToTop(view, ids, sortedIssueIdsOnCreation),
		(rankingRemote, viewId) =>
			rankingRemote.moveBatchFirst({
				listId: viewId,
				items: ids,
			}),
	);

export const setPermanentSortFieldsOfCurrentView = (
	sortFields?: SortField[],
	onSuccess?: () => void,
	onError?: () => void,
): Action<State, Props> =>
	updateSortConfigurationOfCurrentView(
		(view) => withSortFieldsSet(view, sortFields),
		undefined,
		onSuccess,
		onError,
	);

export const setSortModeOfCurrentViewToProjectRank = (
	clearIssueRanking = false,
): Action<State, Props> =>
	updateSortConfigurationOfCurrentView((view) => withProjectRankMode(view, clearIssueRanking));

export const setSortModeOfCurrentViewToViewRank = (
	rankedIssueIds: IssueId[],
	overrideCurrentIssueRanking = false,
): Action<State, Props> =>
	updateSortConfigurationOfCurrentView(
		(view) => withViewRankMode(view, rankedIssueIds, overrideCurrentIssueRanking),
		(rankingRemote, viewId) => {
			if (overrideCurrentIssueRanking) {
				return rankingRemote.create({
					listId: viewId,
					items: rankedIssueIds,
				});
			}
			return rankingRemote.get({ listId: viewId }).then((result) => {
				if (result === undefined) {
					return rankingRemote.create({
						listId: viewId,
						items: rankedIssueIds,
					});
				}
				return Promise.resolve();
			});
		},
	);

export const moveIssueInCurrentView = (
	id: IssueId,
	destinationBeforeId: IssueId | undefined,
	destinationAfterId: IssueId | undefined,
	sortedIssueIdsOnCreation: IssueId[],
): Action<State, Props> =>
	updateSortConfigurationOfCurrentView(
		(view) =>
			withMovedIssue(view, id, destinationBeforeId, destinationAfterId, sortedIssueIdsOnCreation),
		(rankingRemote, viewId, viewIssueRanking) =>
			sync(rankingRemote, viewId, viewIssueRanking, sortedIssueIdsOnCreation).then(() => {
				if (destinationBeforeId !== undefined) {
					return rankingRemote.moveBefore({
						listId: viewId,
						items: [id],
						refId: destinationBeforeId,
					});
				}
				if (destinationAfterId !== undefined) {
					return rankingRemote.moveAfter({
						listId: viewId,
						items: [id],
						refId: destinationAfterId,
					});
				}
				return Promise.reject(new Error('invalid input'));
			}),
	);

export const insertIssueAtIndexInCurrentView = (
	index: number,
	id: IssueId,
	sortedIds: IssueId[],
): Action<State, Props> =>
	updateSortConfigurationOfCurrentView(
		(view) => withInsertedIssue(view, id, index, sortedIds),
		(rankingRemote, viewId, viewIssueRanking) =>
			sync(rankingRemote, viewId, viewIssueRanking, sortedIds).then(() => {
				if (index === 0) {
					return rankingRemote.moveFirst({
						listId: viewId,
						itemId: id,
					});
				}
				const baseRanking =
					viewIssueRanking !== undefined && sortedIds.length < viewIssueRanking.length
						? viewIssueRanking
						: sortedIds;
				if (baseRanking[index - 1] !== undefined) {
					return rankingRemote.moveAfter({
						listId: viewId,
						items: [id],
						refId: baseRanking[index - 1],
					});
				}
				return Promise.reject(new Error('invalid input'));
			}),
	);
