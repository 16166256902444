import React, { type SyntheticEvent, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import LinkIcon from '@atlaskit/icon/core/link';
import { Box, Pressable, xcss } from '@atlaskit/primitives';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useSafeIssueKey,
	useIssueJiraId,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useIssueLinkingEnabled } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useVisibleSelectedIssueIds } from '../../../../../../utils.tsx';
import { SendToJiraDialog } from '../../../../../../view-controls/send-to-backlog-modal/index.tsx';
import { LinkIssue } from '../../../../../../../common/ui/link-issue/index.tsx';
import { messages } from './messages.tsx';

const ICON = iconForPolarisFieldType(FIELD_TYPES.DELIVERY_PROGRESS);

type InteractiveProps = {
	issueId: LocalIssueId;
	appearance: 'list' | 'board';
};

export const EmptyDeliveryPreview = () => <IconOnlyContainer>{ICON}</IconOnlyContainer>;

export const EmptyDeliveryInteractive = ({ appearance, issueId }: InteractiveProps) => {
	const { formatMessage } = useIntl();
	const spacing = appearance === 'board' ? 'compact' : 'default';
	const { openIssueView } = usePolarisRouter();
	const isIssueLinkingEnabled = useIssueLinkingEnabled();

	const visibleSelectedIssueIds = useVisibleSelectedIssueIds();
	const issueKey = useSafeIssueKey(issueId);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openIssueDeliverySection = useCallback(() => {
		if (!issueKey) return;
		openIssueView(issueKey, { layout: 'sidebar', section: 'deliver' });
	}, [issueKey, openIssueView]);

	const onButtonClick = (_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'fieldValue', {
			issueField: 'deliveryProgress',
		});

		if (visibleSelectedIssueIds.length > 1) {
			setIsModalOpen(true);
		} else {
			openIssueDeliverySection();
		}
	};

	return isIssueLinkingEnabled ? (
		<>
			<StyledButton
				spacing={spacing}
				onClick={onButtonClick}
				appearance="subtle"
				// @ts-expect-error - TS2322 - Type 'ReactNode' is not assignable to type 'ReactChild | undefined'.
				iconBefore={ICON}
			>
				<PlaceholderLabel data-testid="polaris-ideas.ui.view-content.idea-list.cell.cells.linked-issues-field.empty.cta">
					{formatMessage(
						fg('polaris-issue-terminology-refresh')
							? messages.placeholderIssueTermRefresh
							: messages.placeholder,
					)}
				</PlaceholderLabel>
			</StyledButton>
			<SendToJiraDialog isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
		</>
	) : null;
};

type EmptyDeliveryNextProps = {
	issueId: LocalIssueId;
};

export const EmptyDeliveryNext = ({ issueId }: EmptyDeliveryNextProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const { formatMessage } = useIntl();
	const issueKey = useSafeIssueKey(issueId);
	const isIssueLinkingEnabled = useIssueLinkingEnabled();
	const { refreshDeliveryProgressForSpecificIssue } = useIssueActions();
	const issueJiraId = useIssueJiraId(issueId);

	return (
		<Popup
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			placement="bottom"
			content={() => (
				<Box
					testId="polaris-ideas.ui.view-content.idea-list.cell.cells.linked-issues-field.empty.popup-content"
					xcss={linkIssueWrapper}
				>
					<LinkIssue
						isPolarisIssueLink
						excludedProjectTypes={[PRODUCT_DISCOVERY_PROJECT]}
						onCancel={() => setIsOpen(false)}
						onIssueLinked={() => {
							setIsOpen(false);
							refreshDeliveryProgressForSpecificIssue(String(issueJiraId));
						}}
						issueKey={issueKey}
						localIssueId={issueId}
						captionMessage={formatMessage(messages.linkIssueCaption)}
						showHelperMessage={false}
						cancelMessage={formatMessage(messages.linkIssueCancel)}
						submitMessage={formatMessage(messages.linkIssueSubmit)}
					/>
				</Box>
			)}
			trigger={(triggerProps) => (
				<Pressable
					{...triggerProps}
					isDisabled={issueKey === undefined || !isIssueLinkingEnabled}
					onClick={() => setIsOpen(!isOpen)}
					testId="polaris-ideas.ui.view-content.idea-list.cell.cells.linked-issues-field.empty.button"
					title={formatMessage(messages.buttonTitle)}
					xcss={pressableStyles}
				>
					<LinkIcon label={formatMessage(messages.iconLabel)} />
				</Pressable>
			)}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderLabel = styled.div({
	color: token('color.text.subtle', N200),
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.semibold'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: token('space.400'),
	textAlign: 'left',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		paddingTop: 0,
		paddingRight: token('space.075'),
		paddingBottom: 0,
		paddingLeft: token('space.050'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconOnlyContainer = styled.div({
	opacity: 0.3,
	display: 'flex',
	alignItems: 'center',
	height: '100%',
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	paddingTop: 0,
	paddingRight: token('space.075'),
	paddingBottom: 0,
	paddingLeft: token('space.050'),
});

const pressableStyles = xcss({
	alignItems: 'center',
	backgroundColor: 'color.background.neutral.subtle',
	display: 'flex',
	height: '100%',
	opacity: 0.3,
	padding: '0',

	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
		opacity: 1,
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
		opacity: 1,
	},
});

const linkIssueWrapper = xcss({
	margin: 'space.100',
});
