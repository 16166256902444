import React, { useCallback, useMemo, useState } from 'react';
import intersection from 'lodash/intersection';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import Skeleton from '@atlaskit/skeleton';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { useIntl } from '@atlassian/jira-intl';
import { useSelectedIssueConfiguredConnectionFields } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/connection-hooks.tsx';
import { useSelectedIssueIssueType } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import {
	useIssueTypesConnectionsActions,
	useConnectionsConfigurationForIssueType,
} from '@atlassian/jira-polaris-component-issue-types-connections-configuration/src/controllers/index.tsx';
import { ConnectionsConfigEmpty } from '@atlassian/jira-polaris-component-issue-types-connections-configuration/src/ui/connections-config-empty.tsx';
import { ConnectionsConfig } from '@atlassian/jira-polaris-component-issue-types-connections-configuration/src/ui/connections-config.tsx';
import { ConnectionsConfigError } from '@atlassian/jira-polaris-component-issue-types-connections-configuration/src/ui/connections-config-error.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/index.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ConnectionSection } from './connection-section.tsx';
import { messages } from './messages.tsx';
import { useCollapsedSections, useLoadIssuesAndInitialize } from './utils.tsx';

type ConnectionsListInnerProps = { issueTypeId: IssueTypeId };

const ConnectionsListInner = ({ issueTypeId }: ConnectionsListInnerProps) => {
	const { formatMessage } = useIntl();
	const availableConnectionFields = useSelectedIssueConfiguredConnectionFields();
	const isIssuesInitialized = useLoadIssuesAndInitialize();
	const [isConfigurationOpen, setIsConfigurationOpen] = useState(false);
	const defaultConfiguration = useMemo(
		() => availableConnectionFields.map(({ key }) => key),
		[availableConnectionFields],
	);
	const { configuration, isLoading, error } = useConnectionsConfigurationForIssueType(
		issueTypeId,
		defaultConfiguration,
	);
	const { saveConfiguration } = useIssueTypesConnectionsActions();
	const { error: showErrorFlag } = useNotifications();

	const handleConfigure = useCallback(() => {
		setIsConfigurationOpen(true);
	}, []);

	const handleSave = useCallback(
		async (newConfiguration: FieldKey[]) => {
			try {
				await saveConfiguration(issueTypeId, intersection(newConfiguration, defaultConfiguration));
				setIsConfigurationOpen(false);
			} catch (_) {
				showErrorFlag({
					title: formatMessage(messages.errorTitle),
					description: formatMessage(messages.errorDescription),
				});
			}
		},
		[defaultConfiguration, formatMessage, issueTypeId, saveConfiguration, showErrorFlag],
	);

	const [isCollapsedStateLoading, collapsedSections, toggleSection] =
		useCollapsedSections(issueTypeId);

	if (!isIssuesInitialized || isLoading || isCollapsedStateLoading) {
		return fg('jpd_idea_view_tabs_ufo_fixes') ? (
			<UFOLoadHold name="connections-list">
				<Stack space="space.250" xcss={containerStyles}>
					<Stack space="space.100">
						<Skeleton height={32} width="100%" borderRadius={4} />
						<Skeleton height={212} width="100%" borderRadius={4} />
					</Stack>
					<Stack space="space.100">
						<Skeleton height={32} width="100%" borderRadius={4} />
						<Skeleton height={212} width="100%" borderRadius={4} />
					</Stack>
				</Stack>
			</UFOLoadHold>
		) : (
			<Stack space="space.250" xcss={containerStyles}>
				<Stack space="space.100">
					<Skeleton height={32} width="100%" borderRadius={4} />
					<Skeleton height={212} width="100%" borderRadius={4} />
				</Stack>
				<Stack space="space.100">
					<Skeleton height={32} width="100%" borderRadius={4} />
					<Skeleton height={212} width="100%" borderRadius={4} />
				</Stack>
			</Stack>
		);
	}

	if (error) {
		return <ConnectionsConfigError />;
	}

	if (isConfigurationOpen) {
		return (
			<Box xcss={containerStyles}>
				<ConnectionsConfig
					issueTypeId={issueTypeId}
					allConnections={availableConnectionFields}
					selectedConnectionsKeys={configuration}
					onCancel={() => setIsConfigurationOpen(false)}
					onSave={handleSave}
				/>
			</Box>
		);
	}

	if (configuration.length === 0) {
		return (
			<Box xcss={containerStyles}>
				<ConnectionsConfigEmpty
					availableConnectionsCount={availableConnectionFields.length}
					onConfigure={handleConfigure}
				/>
			</Box>
		);
	}

	return (
		<Stack space="space.250" xcss={containerStyles}>
			{configuration.map((fieldKey) => (
				<ConnectionSection
					key={fieldKey}
					fieldKey={fieldKey}
					issueTypeId={issueTypeId}
					isCollapsed={collapsedSections.includes(fieldKey)}
					onConfigure={handleConfigure}
					onToggle={toggleSection}
				/>
			))}
		</Stack>
	);
};

export const ConnectionsList = () => {
	const issueTypeId = useSelectedIssueIssueType()?.id;

	if (!issueTypeId) {
		return null;
	}

	return <ConnectionsListInner issueTypeId={issueTypeId} />;
};

const containerStyles = xcss({
	paddingTop: 'space.100',
});
