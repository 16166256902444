import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import type { Attributes } from '../../services/pendo/types.tsx';
import { extractAttributesFromContext } from './extract-attributes-from-context.tsx';

export const getViewViewedPendoEventAttributes = (event: UIAnalyticsEvent) => {
	const pendoEventAttributes: Attributes = {};

	if (fg('jpd_issues_relationships')) {
		const attributes = extractAttributesFromContext(event.context);
		pendoEventAttributes.viewHasConnectionFields = !!attributes.viewHasConnectionFields;
	}

	return pendoEventAttributes;
};
