import React from 'react';
import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

export const EditWorkflowButton = () => {
	const { formatMessage } = useIntl();
	return (
		<Box xcss={wrapperStyles} data-component-selector="edit-workflow-ew1">
			<Tooltip content={formatMessage(messages.editWorkflowButtonTooltip)}>
				{(tooltipProps) => (
					<Button {...tooltipProps} isDisabled>
						{formatMessage(messages.editWorkflowButtonLabel)}
					</Button>
				)}
			</Tooltip>
		</Box>
	);
};

const wrapperStyles = xcss({
	visibility: 'hidden',
});
