/** @jsx jsx */

import React, { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import { css, jsx } from '@compiled/react';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/utility/migration/chevron-up';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Pressable, Inline, xcss, Box } from '@atlaskit/primitives';
import { useStickyHeaderRegistration } from '@atlassian/jira-issue-header-tracker-service/src/services/sticky-header/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { issueViewLayers as layers } from '@atlassian/jira-issue-view-layers/src/index.tsx';
import type { HeaderComponentProps, Props, RequiredComponents } from '../../types.tsx';
import BaseGroup from '../base-group/index.tsx';
import { defaultComponents, IconWrapper } from '../styled.tsx';

const HEADER_TITLE_DATA_TEST_ID =
	'issue-view-layout-group.common.ui.collapsible-group-factory.title';

const HeaderComponentOld = ({
	id,
	isOpen,
	subTitle,
	title,
	onToggle,
	renderHeaderActions,
	stickyHeaderPosition,
	componentsMerged,
	innerRef,
	enableVisualRefreshForTitle,
}: HeaderComponentProps) => {
	const { Title, SubTitle, Header, HeaderRightAligned } = componentsMerged;
	const testId =
		id === null || id === undefined
			? undefined
			: {
					'data-testid': `issue-view-layout-group.common.ui.collapsible-group-factory.${id}`,
				};

	return (
		<Header
			{...testId}
			stickyHeaderPosition={stickyHeaderPosition}
			ref={innerRef}
			role="button"
			tabIndex={0}
			onClick={onToggle}
			aria-label={title}
		>
			<Title
				data-testid={HEADER_TITLE_DATA_TEST_ID}
				enableVisualRefreshForTitle={enableVisualRefreshForTitle}
			>
				{title}
			</Title>
			{subTitle !== undefined && !isOpen && <SubTitle aria-hidden="true">{subTitle}</SubTitle>}
			<HeaderRightAligned isOpen={isOpen}>
				{typeof renderHeaderActions === 'function' && renderHeaderActions()}
				<IconWrapper>
					{isOpen ? (
						<ChevronUpIcon
							spacing="spacious"
							label=""
							color={token('color.icon.subtle', colors.N500)}
						/>
					) : (
						<ChevronDownIcon
							spacing="spacious"
							label=""
							color={token('color.icon.subtle', colors.N500)}
						/>
					)}
				</IconWrapper>
			</HeaderRightAligned>
		</Header>
	);
};

/**
 * The default Composable Component group, that acts
 * as a skeleton, and can be re-used for different purpose or
 * design.
 */
export const CollapsibleGroupFactoryOld = forwardRef<HTMLDivElement, Props>((props, ref) => {
	const {
		children,
		components,
		id,
		isOpen,
		onHeaderClick,
		renderHeaderActions,
		stickyHeaderPosition,
		subTitle,
		title,
		enableVisualRefreshForTitle = false,
	} = props;
	const componentsMerged: RequiredComponents = { ...defaultComponents, ...components };
	const { Group, Body } = componentsMerged;
	const didOpenAtLeastOnceRef = useRef(false);

	const onToggle = useCallback(
		(event: Event) => {
			// Using `useRef` here instead of `useState` prevents an extra re-render
			// because `useState`'s updater functionc causes a re-render when being
			// updated.
			// See: https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/pull-requests/60341/overview?commentId=3695533
			didOpenAtLeastOnceRef.current = true;
			event.preventDefault();
			onHeaderClick?.(event);
		},
		// `onHeaderClick` should be a dependency as this is passed from a prop and
		// would otherwise trigger un-necessary re-renders (especially as this is not
		// memoized in the parent component `CollapsibleGroup`).
		[onHeaderClick],
	);

	const headerRef = useRef(null);

	const { registerSticky, deregisterSticky } = useStickyHeaderRegistration();

	useEffect(() => {
		const STICKY_NAME = Symbol('group-header');
		registerSticky(STICKY_NAME, headerRef);
		return () => deregisterSticky(STICKY_NAME);
	}, [registerSticky, deregisterSticky, stickyHeaderPosition]);

	return (
		<BaseGroup
			headerNode={
				<HeaderComponentOld
					isOpen={isOpen}
					componentsMerged={componentsMerged}
					id={id}
					onToggle={onToggle}
					renderHeaderActions={renderHeaderActions}
					stickyHeaderPosition={stickyHeaderPosition}
					subTitle={subTitle}
					title={title}
					innerRef={headerRef}
					enableVisualRefreshForTitle={enableVisualRefreshForTitle}
				/>
			}
			Group={Group}
			isOpen={isOpen}
			ref={ref}
			enableVisualRefreshForTitle={enableVisualRefreshForTitle}
		>
			{(!didOpenAtLeastOnceRef.current && !isOpen) || children === undefined ? null : (
				<Body isOpen={isOpen}>{children}</Body>
			)}
		</BaseGroup>
	);
});

const HeaderComponent = ({
	id,
	isOpen,
	subTitle,
	title,
	onToggle,
	renderHeaderActions,
	stickyHeaderPosition,
	componentsMerged,
	innerRef,
	enableVisualRefreshForTitle,
}: HeaderComponentProps) => {
	const { Title, SubTitle } = componentsMerged;
	const testId =
		id === null || id === undefined
			? undefined
			: `issue-view-layout-group.common.ui.collapsible-group-factory.${id}`;

	const headerActions = useMemo(() => renderHeaderActions?.(), [renderHeaderActions]);

	return (
		<summary
			data-testid={testId}
			css={[summaryHeaderStyles]}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{
				top: stickyHeaderPosition ? `${stickyHeaderPosition}px` : 0,
			}}
			ref={innerRef}
			tabIndex={-1}
		>
			<Pressable
				role="button"
				tabIndex={0}
				// @ts-expect-error - Type '(event: Event) => void' is not assignable to type '(e: MouseEvent<HTMLButtonElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => void'.
				// to fix this just need to update parent components, and will do when refactor is complete
				onClick={onToggle}
				aria-label={title}
				xcss={[
					headerClickableStyles,
					!isOpen && headerClickableClosedStyles,
					!headerActions && !isOpen && headerClickableWithoutActionsClosedStyles,
					!headerActions && isOpen && headerClickableWithoutActionsOpenStyles,
				]}
			>
				<Inline alignBlock="center" spread="space-between" grow="fill">
					<Inline alignBlock="center" grow="fill" xcss={titleWrapperStyles}>
						<Title
							data-testid={HEADER_TITLE_DATA_TEST_ID}
							enableVisualRefreshForTitle={enableVisualRefreshForTitle}
						>
							{title}
						</Title>
						{subTitle !== undefined && !isOpen && (
							<SubTitle aria-hidden="true">{subTitle}</SubTitle>
						)}
					</Inline>
					<Inline xcss={iconRightStyles}>
						{isOpen ? (
							<ChevronUpIcon
								spacing="spacious"
								label=""
								color={token('color.icon.subtle', colors.N500)}
							/>
						) : (
							<ChevronDownIcon
								spacing="spacious"
								label=""
								color={token('color.icon.subtle', colors.N500)}
							/>
						)}
					</Inline>
				</Inline>
			</Pressable>
			{!!headerActions && <Box xcss={headerActionsStyles}>{headerActions}</Box>}
		</summary>
	);
};

const summaryHeaderStyles = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	backgroundColor: token('elevation.surface.overlay'),
	borderStyle: 'solid',
	borderWidth: '1px',
	borderColor: token('color.border'),
	borderRadius: '4px',
	position: 'sticky',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	zIndex: layers.groupStickyHeader,
	height: '48px',

	'&::-webkit-details-marker': {
		display: 'none',
		content: '',
		width: 0,
		height: 0,
	},
});

const headerClickableStyles = xcss({
	height: '100%',
	width: '100%',
	background: 'transparent',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	borderTopLeftRadius: '2px',
	backgroundColor: 'elevation.surface.overlay',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
	':hover': {
		backgroundColor: 'elevation.surface.overlay.hovered',
	},
	':focus': {
		outline: `2px solid ${token('color.border.accent.blue')}`,
		// @ts-expect-error Atlaskit appears broken for this style, need to do manually
		outlineOffset: '-1px',
		backgroundColor: 'elevation.surface.overlay.hovered',
	},
	':focus-within': {
		outline: `2px solid ${token('color.border.accent.blue')}`,
		// @ts-expect-error Atlaskit appears broken for this style, need to do manually
		outlineOffset: '-1px',
		backgroundColor: 'elevation.surface.overlay.hovered',
	},
	':active': {
		outline: `2px solid ${token('color.border.accent.blue')}`,
		// @ts-expect-error Atlaskit appears broken for this style, need to do manually
		outlineOffset: '-1px',
		backgroundColor: 'elevation.surface.overlay.hovered',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
	':focus:not(:focus-visible)': {
		outline: `2px solid ${token('color.border.accent.blue')}`,
	},
});

const headerClickableClosedStyles = xcss({
	borderBottomLeftRadius: '2px',
});

const headerClickableWithoutActionsClosedStyles = xcss({
	borderRadius: '2px',
});

const headerClickableWithoutActionsOpenStyles = xcss({
	borderTopRightRadius: '2px',
});

const headerActionsStyles = xcss({
	borderLeftStyle: 'solid',
	borderLeftWidth: '1px',
	borderLeftColor: 'color.border',
	padding: 'space.100',
});

const iconRightStyles = xcss({
	position: 'relative',
	right: '0px',
});

const titleWrapperStyles = xcss({
	maxWidth: '90%',
});

/**
 * The default Composable Component group, that acts
 * as a skeleton, and can be re-used for different purpose or
 * design.
 */
export const CollapsibleGroupFactory = forwardRef<HTMLDivElement, Props>((props, ref) => {
	const {
		children,
		components,
		id,
		isOpen,
		onHeaderClick,
		renderHeaderActions,
		stickyHeaderPosition,
		subTitle,
		title,
		enableVisualRefreshForTitle = false,
	} = props;
	const componentsMerged: RequiredComponents = { ...defaultComponents, ...components };
	const { Group, Body } = componentsMerged;
	const didOpenAtLeastOnceRef = useRef(false);

	const onToggle = useCallback(
		(event: Event) => {
			// Using `useRef` here instead of `useState` prevents an extra re-render
			// because `useState`'s updater functionc causes a re-render when being
			// updated.
			// See: https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/pull-requests/60341/overview?commentId=3695533
			didOpenAtLeastOnceRef.current = true;
			event.preventDefault();
			onHeaderClick?.(event);
		},
		// `onHeaderClick` should be a dependency as this is passed from a prop and
		// would otherwise trigger un-necessary re-renders (especially as this is not
		// memoized in the parent component `CollapsibleGroup`).
		[onHeaderClick],
	);

	const headerRef = useRef(null);

	const { registerSticky, deregisterSticky } = useStickyHeaderRegistration();

	useEffect(() => {
		const STICKY_NAME = Symbol('group-header');
		registerSticky(STICKY_NAME, headerRef);
		return () => deregisterSticky(STICKY_NAME);
	}, [registerSticky, deregisterSticky, stickyHeaderPosition]);

	return (
		<BaseGroup
			headerNode={
				<HeaderComponent
					isOpen={isOpen}
					componentsMerged={componentsMerged}
					id={id}
					onToggle={onToggle}
					renderHeaderActions={renderHeaderActions}
					stickyHeaderPosition={stickyHeaderPosition}
					subTitle={subTitle}
					title={title}
					innerRef={headerRef}
					enableVisualRefreshForTitle={enableVisualRefreshForTitle}
				/>
			}
			Group={Group}
			isOpen={isOpen}
			ref={ref}
			enableVisualRefreshForTitle={enableVisualRefreshForTitle}
		>
			{(!didOpenAtLeastOnceRef.current && !isOpen) || children === undefined ? null : (
				<Body isOpen={isOpen}>{children}</Body>
			)}
		</BaseGroup>
	);
});

const CollapsibleGroupFactoryDefault = componentWithCondition(
	() =>
		expValEquals<'not-enrolled' | 'control' | 'experiment'>(
			'inline_field_config_settings_button',
			'cohort',
			'experiment',
		),
	CollapsibleGroupFactory,
	CollapsibleGroupFactoryOld,
);

export default CollapsibleGroupFactoryDefault;
