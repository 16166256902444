import type { StylesConfig } from '@atlaskit/select';
import { N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import type { OptionProperty } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

export const stylesConfig: StylesConfig<OptionProperty> = {
	menu: (styles) => ({
		...styles,
		zIndex: 100,
		...(fg('jpd_issues_relationships') ? { right: 0, minWidth: '240px' } : { width: '240px' }),
	}),
	menuList: (styles) => ({
		...styles,
		padding: 0,
	}),
	groupHeading: (styles) => ({
		...styles,
		':empty': {
			display: 'none',
		},
	}),
	group: (styles) => ({
		...styles,
		padding: `${token('space.075')} 0 ${token('space.075')} 0`,
		':not(:first-of-type)': {
			borderTop: `1px solid ${token('color.border', N40)}`,
		},
	}),
	option: (styles) => ({
		...styles,
		padding: 0,
		paddingTop: 0,
		paddingBottom: 0,
	}),
	valueContainer: (styles) => ({
		...styles,
		display: 'flex',
	}),
};
