import React, { useState, useCallback, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Blanket from '@atlaskit/blanket';
import { IconButton } from '@atlaskit/button/new';
import DownloadButton from '@atlaskit/icon/core/migration/download';
import type { TriggerProps } from '@atlaskit/popup';
import { useIntl } from '@atlassian/jira-intl';
import {
	OutsideClickAlerter,
	isFocusOutsideJFE,
} from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import {
	ContextualAnalyticsData,
	fireUIAnalytics,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ExportDialogContent,
	type ExportDialogContentProps,
} from './export-dialog-content/index.tsx';
import messages from './messages.tsx';

export type ExportDialogProps = Omit<ExportDialogContentProps, 'isLoading' | 'onExporting'>;

export const ExportViewDialog = (props: ExportDialogProps) => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { formatMessage } = useIntl();

	const handleTogglePopup = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			if (!dialogOpen) {
				fireUIAnalytics(analyticsEvent, 'exportModal');
			}
			setDialogOpen(!dialogOpen);
		},
		[dialogOpen],
	);

	const handleClickOutside = useCallback(
		(event: Event) => {
			if (isFocusOutsideJFE(event?.target)) {
				return;
			}
			!isLoading && setDialogOpen(false);
		},
		[isLoading, setDialogOpen],
	);

	if (fg('polaris_pol-12839_view_header_redesign')) {
		return (
			<ContextualAnalyticsData sourceName="export" sourceType={MODAL}>
				<ExportDialogContent isLoading={isLoading} onExporting={setIsLoading} {...props} />
			</ContextualAnalyticsData>
		);
	}

	return (
		<>
			{isLoading && <Blanket isTinted />}
			<OutsideClickAlerter onClickOutside={handleClickOutside}>
				{(outsideClickAlerterProps) => (
					<div {...outsideClickAlerterProps}>
						<Popup
							isOpen={dialogOpen}
							placement="bottom-end"
							messageId="polaris-component-view-export.ui.export-view-dialog.popup"
							messageType="transactional"
							content={() => (
								<ContextualAnalyticsData sourceName="export" sourceType={MODAL}>
									<ExportDialogContent
										isLoading={isLoading}
										onExporting={setIsLoading}
										{...props}
									/>
								</ContextualAnalyticsData>
							)}
							zIndex={501} // zIndex of exporting blanked is 500
							trigger={(triggerProps: TriggerProps) => (
								<IconButton
									id="pendo.export-modal.open-button"
									testId="polaris-component-view-export.ui.export-view-dialog.button"
									{...triggerProps}
									label={formatMessage(messages.exportButtonLabel)}
									appearance={isVisualRefreshEnabled() ? 'default' : 'subtle'}
									isSelected={dialogOpen}
									onClick={handleTogglePopup}
									icon={DownloadButton}
									isTooltipDisabled={false}
								/>
							)}
						/>
					</div>
				)}
			</OutsideClickAlerter>
		</>
	);
};
