import React, { useState, useCallback, type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import Avatar from '@atlaskit/avatar';
import Heading from '@atlaskit/heading';
import { Box } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useEditorAiEnabled } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import { onHandleADFUploadError } from '@atlassian/jira-polaris-component-error-handlers/src/handle-afd-upload-error/index.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { Editor, EditorBoundary } from '@atlassian/jira-polaris-lib-editor/src/async.tsx';
import { WaitForAdfConsumerProps } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	useCurrentUserAvatar,
	useCurrentUserDisplayName,
} from '../../../controllers/user/index.tsx';
import { useScrollableContainer } from '../../common/scrollable-container/main.tsx';
import { PolarisNavigationBlocker } from '../../navigation-blocker/index.tsx';
import { MAX_CONTENT_SIZE } from '../constants.tsx';
import messages from './messages.tsx';

type Props = {
	issueKey?: IssueKey;
	onDirty?: (_: boolean) => void;
	onSave: (arg1: ADF) => Promise<boolean>;
	onEnterEditMode?: () => void;
	onCloseEditMode?: () => void;
	disabled?: boolean;
};

type ReadViewProps = {
	onActivate?: () => void;
	disabled?: boolean;
};

type EditViewProps = {
	issueKey?: IssueKey;
	onSave: (arg1: ADF) => Promise<boolean>;
	onDiscard: () => void;
	onDirty?: (_: boolean) => void;
};

const EditViewHeaderAuthorDetails = (
	props: ComponentPropsWithoutRef<typeof EditViewHeaderAuthorDetailsComponent>,
) => (
	<Heading size="xsmall">
		<EditViewHeaderAuthorDetailsComponent {...props} />
	</Heading>
);

const AddCommentReadView = ({ onActivate, disabled }: ReadViewProps) => {
	const { formatMessage } = useIntl();
	const currentUserAvatar = useCurrentUserAvatar();

	return (
		<ReadViewContainer>
			<Avatar src={currentUserAvatar} size="medium" />
			<ReadViewTextFieldMock
				data-testid="polaris-common.ui.comments.add-comment.read-view-text-field-mock"
				onClick={() => {
					if (disabled) {
						return;
					}
					onActivate?.();
				}}
				isDisabled={disabled}
			>
				{formatMessage(messages.createCommentPlaceholder)}
			</ReadViewTextFieldMock>
		</ReadViewContainer>
	);
};

const AddCommentEditView = ({ onSave, onDiscard, onDirty }: EditViewProps) => {
	const currentUserAvatar = useCurrentUserAvatar();
	const currentUser = useCurrentUserDisplayName();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const scrollableContainer = useScrollableContainer();

	const { formatMessage } = useIntl();
	const isEditorAiEnabled = useEditorAiEnabled();

	const [isEditing, setIsEditing] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const handleSave = useCallback(
		(value: ADF) => {
			experience.ideaView.commentCreate.start();
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'button',
				}),
				'saveComment',
			);
			setIsSaving(true);
			onSave(value)
				.then(() => {
					setIsSaving(false);
					setIsEditing(false);
					experience.ideaView.commentCreate.success();
				})
				.catch((error) => {
					onHandleADFUploadError(experience.ideaView.commentCreate, error);
				});
		},
		[onSave, createAnalyticsEvent],
	);

	const handleChange = useCallback(() => {
		setIsEditing(true);
		onDirty?.(true);
	}, [onDirty]);

	const handleCancel = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'discardComment',
		);
		onDiscard();
		setIsEditing(false);
	}, [onDiscard, createAnalyticsEvent]);

	const onNavigationAllowed = () => {
		onDirty?.(false);
	};

	return (
		<WaitForAdfConsumerProps>
			{({ akEditorProps }) => (
				<EditorBoundary fallback={<AddCommentReadView />}>
					<EditViewContainer>
						<PolarisNavigationBlocker isDirty={isEditing} onDiscard={onNavigationAllowed} />
						<EditViewHeader>
							<Box>
								<Avatar src={currentUserAvatar} size="medium" />
							</Box>
							<EditViewHeaderAuthorDetails>{currentUser}</EditViewHeaderAuthorDetails>
						</EditViewHeader>
						<Editor
							{...akEditorProps}
							testId="polaris-common.ui.comments.add-comment.editor"
							maxContentSize={MAX_CONTENT_SIZE}
							shouldFocus
							placeholder={formatMessage(messages.createCommentPlaceholder)}
							onChange={handleChange}
							onSave={handleSave}
							onCancel={handleCancel}
							disabled={isSaving}
							saveButtonLabel={formatMessage(messages.create)}
							popupsBoundariesElement={scrollableContainer || undefined}
							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							popupsMountPoint={scrollableContainer ? undefined : document.body}
							withDebouncedOnChange={false}
							allowFullEditorCapabilities
							isAiEnabled={isEditorAiEnabled}
							useStickyToolbar
						/>
					</EditViewContainer>
				</EditorBoundary>
			)}
		</WaitForAdfConsumerProps>
	);
};

export const AddCommentComponent = ({
	onSave,
	onEnterEditMode,
	onCloseEditMode,
	onDirty,
	issueKey,
	disabled,
}: Props) => {
	const [inEditMode, setEditMode] = useState(false);

	const enterEditMode = useCallback(() => {
		setEditMode(true);
		if (onEnterEditMode !== undefined) {
			onEnterEditMode();
		}
	}, [onEnterEditMode]);

	const handleSave = useCallback(
		(content: ADF) => {
			if (content.content.length === 0) {
				return Promise.resolve(true);
			}

			return onSave(content).then((success) => {
				setEditMode(false);
				onDirty?.(false);
				if (onCloseEditMode !== undefined) {
					onCloseEditMode();
				}
				return success;
			});
		},

		[onSave, onDirty, onCloseEditMode],
	);

	const handleDiscard = useCallback(() => {
		setEditMode(false);
		if (onCloseEditMode !== undefined) {
			onCloseEditMode();
		}
		onDirty?.(false);
	}, [onCloseEditMode, onDirty]);

	return (
		<AddCommentContainer data-test-id="polaris-common.ui.comments.add-comment.add-comment-container">
			{inEditMode && (
				<AddCommentEditView
					onSave={handleSave}
					onDiscard={handleDiscard}
					onDirty={onDirty}
					issueKey={issueKey}
				/>
			)}
			{!inEditMode && <AddCommentReadView onActivate={enterEditMode} disabled={disabled} />}
		</AddCommentContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddCommentContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	paddingTop: token('space.150'),
	paddingRight: 0,
	paddingBottom: token('space.150'),
	paddingLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewHeader = styled.div({
	display: 'flex',
	flexDirection: 'row',
	width: '100%',
	alignItems: 'center',
	marginBottom: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewTextFieldMock = styled.div<{ isDisabled?: boolean }>({
	borderWidth: '1px',
	borderStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderColor: `${token('color.border.input', colors.N40A)}`,
	boxSizing: 'border-box',
	borderRadius: '3px',
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N100),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isDisabled }) => isDisabled && token('color.background.disabled'),
	paddingTop: token('space.050'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.050'),
	marginLeft: token('space.050'),
	width: '100%',
	cursor: 'text',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewHeaderAuthorDetailsComponent = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N900),
	font: token('font.body'),
	marginTop: '0px',
	marginLeft: token('space.075'),
});
