import keyBy from 'lodash/keyBy';
import type { Action } from '@atlassian/react-sweet-state';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { getProjectConfigInsights } from '@atlassian/jira-polaris-remote-insight/src/services/polaris-api/get-insights/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	jpdProjectPageLoad,
	PAGE_LOAD_MARK_LOAD_INSIGHTS_START,
	PAGE_LOAD_MARK_LOAD_INSIGHTS_END,
} from '@atlassian/jira-polaris-component-metrics/src/project.tsx';
import type {
	ArchivedMode,
	InsightsFetcherCommonArgs,
	LoadInsightsCommonArgs,
	State,
} from './types.tsx';

type FetchProjectConfigInsightsRequest = InsightsFetcherCommonArgs & { archivedMode: ArchivedMode };

type EntityWithCreatedDate = {
	created: string;
};
const sortByCreatedDate = (a: EntityWithCreatedDate, b: EntityWithCreatedDate) =>
	new Date(a.created).getTime() - new Date(b.created).getTime();

const fetchProjectConfigInsights =
	({
		createAnalyticsEvent,
		archivedMode,
		fireAnalyticsEvent,
		apolloClient,
		cloudId,
	}: FetchProjectConfigInsightsRequest) =>
	(projectId: ProjectId) =>
		getProjectConfigInsights(
			apolloClient,
			cloudId,
			projectId,
			archivedMode,
			createAnalyticsEvent,
			fireAnalyticsEvent,
		)
			.then((insights) => [
				...insights.map((insight) => ({
					description: null,
					...insight,
					snippets: [
						...insight.snippets.map((snippet) => ({
							data: null,
							oauthClientId: '',
							url: null,
							...snippet,
						})),
					],
				})),
			])
			.then((insights) =>
				fg('jpd-aurora-fix-insights-notifications') ? insights.sort(sortByCreatedDate) : insights,
			);

type LoadInsightsArgs = InsightsFetcherCommonArgs &
	LoadInsightsCommonArgs & {
		archivedMode: ArchivedMode;
	};

export const loadInsights =
	({
		apolloClient,
		createAnalyticsEvent,
		cloudId,
		fireAnalyticsEvent,
		archivedMode,
		onError,
		projectIds,
	}: LoadInsightsArgs): Action<State> =>
	async ({ getState, setState }) => {
		setState({
			...getState(),
			loading: archivedMode !== 'ACTIVE_ONLY',
			error: undefined,
		});

		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_INSIGHTS_START);

		if (!projectIds.length) return;

		try {
			const requests = projectIds.map(
				fetchProjectConfigInsights({
					createAnalyticsEvent,
					archivedMode,
					fireAnalyticsEvent,
					apolloClient,
					cloudId,
				}),
			);

			const insights = await Promise.all(requests).then((projectsInsights) =>
				projectsInsights.flat(),
			);

			jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_INSIGHTS_END);

			const existingInsights = getState().insights || [];
			const insightsById = keyBy(insights, 'id');

			existingInsights.map((insight) => {
				if (insightsById[insight.id]) {
					delete insightsById[insight.id];
					return insightsById[insight.id];
				}
				return insight;
			});

			setState({
				...getState(),
				loading: false,
				error: undefined,
				insights: [...existingInsights, ...Object.values(insightsById)],
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			// jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_INSIGHTS_END);

			setState({
				...getState(),
				loading: false,
				error,
			});
			onError(error);
		}
	};
