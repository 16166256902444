import { fetchJson } from '@atlassian/jira-fetch/src/index.tsx';
import {
	performPostRequest,
	performDeleteRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type {
	ConnectionStatusResponse,
	ConnectionsBulkRequestInput,
	ConnectionsBulkResponse,
	ConnectionsRequestInput,
	ConnectionsResponse,
} from './types.tsx';

const CONNECTIONS_API_URL = '/rest/polaris/v2/issue-connections';
const CONNECTIONS_BULK_API_URL = '/rest/polaris/v2/issue-connections/bulk';

export const createConnectionsBulk = (connections: ConnectionsBulkRequestInput) =>
	performPostRequest<ConnectionsBulkResponse>(CONNECTIONS_BULK_API_URL, {
		body: JSON.stringify({
			connections,
		}),
	});

export const deleteConnectionsBulk = (connections: ConnectionsBulkRequestInput) =>
	performDeleteRequest<ConnectionsBulkResponse>(CONNECTIONS_BULK_API_URL, {
		body: JSON.stringify({
			connections,
		}),
	});

export const getConnectionBulkStatus = (taskId: string) =>
	fetchJson<ConnectionStatusResponse>(`/rest/polaris/v2/issue-connections/${taskId}/status`);

export const createConnections = (
	connection: ConnectionsRequestInput,
): Promise<ConnectionsResponse> =>
	performPostRequest<ConnectionsResponse | null>(CONNECTIONS_API_URL, {
		body: JSON.stringify(connection),
	}).then((response) => ({ failures: response?.failures ?? [] }));

export const deleteConnections = (
	connection: ConnectionsRequestInput,
): Promise<ConnectionsResponse> =>
	performDeleteRequest<ConnectionsResponse>(CONNECTIONS_API_URL, {
		body: JSON.stringify(connection),
	}).then((response) => ({ failures: response?.failures ?? [] }));
