import React, { useCallback, useEffect, useState, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import InlineEdit, { type InlineEditProps } from '@atlaskit/inline-edit';
import { Box, xcss } from '@atlaskit/primitives';
import TextArea from '@atlaskit/textarea';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { ErrorContainer } from '@atlassian/jira-polaris-lib-inputs-error/src/ui/styled.tsx';
import { PolarisTooltip } from '@atlassian/jira-polaris-lib-tooltip/src/ui/index.tsx';
import { MAX_FIELD_LENGTH } from '../../../common/constants.tsx';
import { messages } from './messages.tsx';

type TextareaFieldProps = {
	isEditable: boolean;
	value: string | undefined;
	placeholder?: string | undefined;
	testId?: string;
	onUpdate: (inputValue: string | undefined) => void;
};

export const TextareaField = ({
	onUpdate,
	isEditable,
	value,
	placeholder,
	testId,
}: TextareaFieldProps) => {
	const { formatMessage } = useIntl();
	const [localValue, setLocalValue] = useState(value);

	useEffect(() => {
		setLocalValue(value);
	}, [value]);

	const validateInput = useCallback(
		(val?: string) => {
			if (val !== undefined && val?.length > MAX_FIELD_LENGTH) {
				return formatMessage(messages.shortTextFieldTypeError);
			}
			return undefined;
		},
		[formatMessage],
	);

	const handleConfirm = useCallback(() => {
		const valueForUpdate = localValue === '' ? undefined : localValue;
		onUpdate(valueForUpdate);
	}, [localValue, onUpdate]);

	const readView = () => (
		<PolarisTooltip hideTooltipOnMouseDown content={value} maxWidth="418px">
			<ReadViewContainer>
				{value === undefined && placeholder !== undefined ? (
					<Placeholder>{placeholder}</Placeholder>
				) : (
					<ReadViewValue>{value}</ReadViewValue>
				)}
			</ReadViewContainer>
		</PolarisTooltip>
	);

	const editView: InlineEditProps<string>['editView'] = ({
		errorMessage,
		onChange,
		...fieldProps
	}) => {
		const onChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
			const { value: newValue } = event.target;
			onChange(newValue);
			setLocalValue(newValue);
		};
		return (
			<EditViewContainer>
				<TextArea
					{...fieldProps}
					value={localValue}
					autoFocus
					minimumRows={3}
					maxHeight="76px"
					isInvalid={!!errorMessage}
					onChange={onChangeHandler}
				/>
				{!!errorMessage && (
					<ErrorContainer data-testid={testId && `${testId}--error`}>{errorMessage}</ErrorContainer>
				)}
			</EditViewContainer>
		);
	};

	return isEditable ? (
		<Box xcss={containerStyles}>
			<InlineEdit
				defaultValue={value}
				readViewFitContainerWidth
				onConfirm={handleConfirm}
				readView={readView}
				editView={editView}
				validate={validateInput}
			/>
		</Box>
	) : (
		<ReadOnlyContainer data-testid={testId && `${testId}--read-view`}>
			{readView()}
		</ReadOnlyContainer>
	);
};

const containerStyles = xcss({
	marginTop: 'space.negative.100',
	paddingLeft: 'space.025',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadOnlyContainer = styled.div({
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'transparent',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div({
	display: 'flex',
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	boxSizing: 'border-box',
	paddingTop: token('space.075'),
	paddingRight: token('space.075'),
	paddingBottom: token('space.075'),
	paddingLeft: token('space.075'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '20px',
	wordBreak: 'break-word',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewValue = styled.div({
	display: '-webkit-box',
	WebkitLineClamp: '3',
	WebkitBoxOrient: 'vertical',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Placeholder = styled(ReadViewValue)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewContainer = styled.div({
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'transparent',
});
