import React, { forwardRef } from 'react';

import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { type TooltipPrimitiveProps, TooltipPrimitive } from '@atlaskit/tooltip';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Tooltip = styled(TooltipPrimitive)({
	display: 'block',
	boxSizing: 'border-box',
	margin: '0 -3px',
	paddingTop: token('space.050'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.100'),
	borderRadius: token('border.radius.100', '3px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.icon.danger', colors.R400),

	color: token('color.text.inverse'),
	boxShadow: token(
		'elevation.shadow.overlay',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`0 4px 8px -2px ${colors.N50A}, 0 0 1px ${colors.N60A}`,
	),
});

export const CustomTooltip = forwardRef<HTMLDivElement, TooltipPrimitiveProps>((props, ref) => (
	<Tooltip {...props} ref={ref} />
));
