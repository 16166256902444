import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	atlasGoalSectionTitle: {
		id: 'polaris-common.config.fields.field-item.atlas.atlas-goal-section-title',
		defaultMessage: 'Map ideas to goals in Atlas',
		description: 'Title for Atlas goals section message',
	},
	atlasProjectSectionTitle: {
		id: 'polaris-common.config.fields.field-item.atlas.atlas-project-section-title',
		defaultMessage: 'Map ideas to projects in Atlas',
		description: 'Title for Atlas project section message',
	},
	atlasGoalSectionDescription: {
		id: 'polaris-common.config.fields.field-item.atlas.atlas-goal-section-description',
		defaultMessage:
			'Connect a specific Atlas site to map ideas in Jira Product Discovery to goals in Atlas.',
		description: 'Description for Atlas goals section message',
	},
	atlasProjectSectionDescription: {
		id: 'polaris-common.config.fields.field-item.atlas.atlas-project-section-description',
		defaultMessage:
			'Connect a specific Atlas site to map ideas in Jira Product Discovery to projects in Atlas.',
		description: 'Description for Atlas project section message',
	},
	atlasSectionConfigurationMessage: {
		id: 'polaris-common.config.fields.field-item.atlas.atlas-section-configuration-message',
		defaultMessage: 'Configuration for Atlas fields can now be done in project settings.',
		description: 'Description for Atlas section message',
	},
	configureButton: {
		id: 'polaris-common.config.fields.field-item.atlas.configure-button',
		defaultMessage: 'Configure',
		description: 'Label for button that will redirect to project settings',
	},
});
