import React, { type ChangeEvent, useCallback, useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import Button from '@atlaskit/button';
import { ErrorMessage } from '@atlaskit/form';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import LinkFilledIcon from '@atlaskit/icon/core/migration/link--link-filled';
import Textfield from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	useUnfurlActions,
	useUnfurlProps,
	useUnfurlState,
} from '@atlassian/jira-polaris-common/src/controllers/unfurl/index.tsx';
import type { ObjectResolved } from '@atlassian/jira-polaris-common/src/controllers/unfurl/types.tsx';
import { useAuthentication } from '@atlassian/jira-polaris-common/src/controllers/unfurl/utils/authentication/index.tsx';
import {
	UnfurlStatus,
	useHasActionableItems,
} from '@atlassian/jira-polaris-common/src/controllers/unfurl/utils/index.tsx';
import { useInsights } from '../../../../../controllers/insights/main.tsx';
import { AddAsLinkButton } from './add-as-link/index.tsx';
import { messages } from './messages.tsx';

type UnfurlFormProps = {
	isDisabled: boolean;
	isCompact?: boolean;
	url?: string;
	onObjectResolved?: (arg1: ObjectResolved) => Promise<void> | void;
	onChange: (arg1: string | undefined) => void;
	onReset: () => void;
};

type UnfurlFormInternalProps = {
	isDisabled: boolean;
	isCompact?: boolean;
	onChange: (arg1: string | undefined) => void;
	onReset: () => void;
};

const UnfurlFormInternal = ({
	isDisabled,
	onChange,
	onReset,
	isCompact,
}: UnfurlFormInternalProps) => {
	const { error, isResolving } = useUnfurlState();
	const { url } = useUnfurlProps();
	const hasActionableItems = useHasActionableItems();
	const { formatMessage } = useIntl();
	const { requiresAuthentication } = useAuthentication();
	const { addAsLink } = useUnfurlActions();

	const [localUrl, setLocalUrl] = useState(url);

	const handleReset = useCallback(() => {
		setLocalUrl('');
		onReset();
	}, [onReset]);

	const handleChange = useCallback(
		(evt: ChangeEvent<HTMLInputElement>) => {
			if (!isResolving) {
				onChange(evt.target.value);
				setLocalUrl(evt.target.value);
			}
		},
		[onChange, isResolving],
	);

	useEffect(() => {
		if (url !== undefined && localUrl === undefined) {
			setLocalUrl(url);
		}
	}, [handleChange, localUrl, url]);

	return (
		<>
			<InputContainer grayedOut={isResolving}>
				<Textfield
					id="polaris.insights.src.ui.add-insight.unfurl-form.textfield"
					value={localUrl}
					title={localUrl}
					isDisabled={isDisabled || hasActionableItems}
					placeholder={
						isCompact === true
							? formatMessage(messages.compactPlaceholder)
							: formatMessage(messages.placeholder)
					}
					onChange={handleChange}
					appearance="none"
					elemBeforeInput={
						<LinkIconContainer>
							<LinkFilledIcon spacing="spacious" label="" />
						</LinkIconContainer>
					}
					elemAfterInput={
						<ActionsContainer>
							<UnfurlStatus />
							{requiresAuthentication ? (
								<AddAsLinkContainer>
									<AddAsLinkButton onClick={addAsLink} />
								</AddAsLinkContainer>
							) : null}
							{localUrl !== null && localUrl !== undefined && localUrl !== '' && !isResolving && (
								<Button
									testId="polaris-ideas.ui.insights.insights.add-insight.unfurl-form.button"
									key="clean-button"
									appearance="subtle-link"
									iconBefore={<CrossIcon label="" LEGACY_size="small" />}
									onClick={handleReset}
								/>
							)}
						</ActionsContainer>
					}
				/>
			</InputContainer>
			{error && (
				<ErrorContainer>
					<ErrorMessage testId="polaris-ideas.ui.insights.insights.add-insight.unfurl-form.error-message">
						{error.message}
					</ErrorMessage>
				</ErrorContainer>
			)}
		</>
	);
};

export const UnfurlForm = ({ onChange, isDisabled, isCompact, onReset }: UnfurlFormProps) => {
	const [{ meta }] = useInsights();

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleUrlChange = useCallback(
		debounce((newUrl) => {
			onChange(newUrl);
		}, 300),
		[],
	);

	if (meta?.issueId === undefined || meta.projectId === undefined) {
		return null;
	}

	return (
		<UnfurlFormInternal
			isDisabled={isDisabled}
			isCompact={isCompact}
			onChange={handleUrlChange}
			onReset={onReset}
		/>
	);
};

UnfurlForm.defaultProps = {
	isDisabled: false,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorContainer = styled.div({
	marginLeft: token('space.100'),
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		margin: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InputContainer = styled.div<{ grayedOut: boolean }>({
	borderWidth: '2px',
	borderStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderColor: `${token('color.border.input', colors.N40)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.input', colors.N10),
	borderRadius: '3px',
	flex: '1 0',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'input:-webkit-autofill, input:-webkit-autofill:focus': {
		transition: 'background-color 600000s 0s, color 600000s 0s',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& [data-ds--text-field--container]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: ({ grayedOut }) => (grayedOut ? token('color.text.disabled', colors.N70) : 'unset'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		cursor: ({ grayedOut }) => (grayedOut ? 'not-allowed' : 'unset'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionsContainer = styled.div({
	paddingRight: token('space.100'),
	paddingLeft: token('space.100'),
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddAsLinkContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '5px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkIconContainer = styled.div({
	margin: '0 5px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});
