import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { N600 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { SearchInput } from '@atlassian/jira-polaris-lib-search-input/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { messages } from './messages.tsx';
import { HeaderTitle } from './styled.tsx';

type HeaderProps = {
	searchValue: string;
	onSearchChange: (value: string) => void;
	onClose: () => void;
};

export const Header = ({ searchValue, onClose, onSearchChange }: HeaderProps) => {
	const { formatMessage } = useIntl();

	return (
		<HeaderContainer>
			<HeaderTopContainer>
				<HeaderTitle>
					{fg('jpd_issues_relationships')
						? formatMessage(messages.title)
						: formatMessage(messages.titleLegacy)}
				</HeaderTitle>
				<Button
					appearance="subtle-link"
					iconBefore={<CrossIcon label="close" LEGACY_size="medium" spacing="spacious" />}
					onClick={onClose}
				/>
			</HeaderTopContainer>
			<SearchInput
				isCompact={false}
				value={searchValue}
				placeholder={formatMessage(messages.searchPlaceholder)}
				onChange={onSearchChange}
			/>
			<HeaderDescription>
				{fg('jpd_issues_relationships')
					? formatMessage(messages.description)
					: formatMessage(messages.descriptionLegacy)}
			</HeaderDescription>
		</HeaderContainer>
	);
};

// eslint-disable-next-line @atlaskit/design-system/use-primitives, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContainer = styled.div({
	paddingTop: token('space.300'),
	paddingRight: token('space.200'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderTopContainer = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginBottom: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderDescription = styled.div({
	marginTop: token('space.100'),
	font: token('font.body'),
	textAlign: 'center',
	color: token('color.text.subtle', N600),
});
