/** @jsx jsx */

import type { ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import Button from '@atlaskit/button/new';

import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import { token } from '@atlaskit/tokens';

type Props = {
	children: ReactNode;
	isSelected: boolean;
	isDisabled: boolean;
	onClick: () => void;
	isUsingCustomColor: boolean;
};

export const SelectableButton = ({
	isSelected,
	onClick,
	children,
	isDisabled,
	isUsingCustomColor,
}: Props) => {
	return (
		<div css={[isUsingCustomColor && buttonWrapperCustomColorStyles]}>
			<Button
				data-component-selector="polaris-ideas.ui.view-controls.config-filters.filter-component.select.button-l75G"
				isSelected={isSelected}
				onClick={onClick}
				iconAfter={(iconProps) => <ChevronDownIcon {...iconProps} spacing="none" />}
				isDisabled={isDisabled}
			>
				{children}
			</Button>
		</div>
	);
};

const buttonWrapperCustomColorStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="polaris-ideas.ui.view-controls.config-filters.filter-component.select.button-l75G"]':
		{
			backgroundColor: token('color.background.warning'),
			color: token('color.text.accent.yellow.bolder'),
		},
});
