import React, { type SyntheticEvent, useCallback, useEffect } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import * as colors from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import logs from '@atlassian/jira-common-util-logging/src/log.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { getAriConfig } from '@atlassian/jira-platform-ari/src/index.tsx';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { useTeamCentralBaseUrl } from '@atlassian/jira-tenant-context-controller/src/components/team-central-url/index.tsx';
import messages from './messages.tsx';

export const Unavailable = ({ ari }: { ari: string }) => {
	useEffect(() => {
		logs.safeErrorWithoutCustomerData(
			'jpd-external-reference-smart-card-renderer',
			'Falied to render external reference',
			new Error(ari),
		);
	}, [ari]);

	const { formatMessage } = useIntl();
	const icon = iconForPolarisFieldType(FIELD_TYPES.ATLAS_PROJECT);
	const teamCentralBaseUrl = useTeamCentralBaseUrl();
	let url = '';
	try {
		const parsed = getAriConfig(ari);
		url = `${teamCentralBaseUrl}?cloudId=${parsed.cloudId}`;
	} catch {
		// do nothing
	}
	const onOpen = useCallback(
		(evt: SyntheticEvent) => {
			evt.preventDefault();
			evt.stopPropagation();
			if (!url) {
				return;
			}

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window?.open(url, '_blank')?.focus();
		},
		[url],
	);
	const _onRefresh = useCallback((evt: SyntheticEvent) => {
		evt.stopPropagation();

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window?.location.reload();
	}, []);
	return (
		<Container>
			{icon}
			<Button href={url} onClick={onOpen} appearance="subtle-link" spacing="none">
				{formatMessage(messages.unavailable)}
			</Button>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N100),
	height: '28px',
	padding: '0 3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > *:first-child': {
		marginRight: token('space.075'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > *:last-child': {
		minWidth: 0,
	},
});
