import { notImplementedError } from '../../common/utils.tsx';
import {
	createConnectionsBulk,
	deleteConnectionsBulk,
	getConnectionBulkStatus,
	createConnections,
	deleteConnections,
} from '../../services/jira/connection/index.tsx';
import type { IssueConnectionRemote } from './types.tsx';

export const createNotImplementedIssueConnectionRemote = (): IssueConnectionRemote => ({
	createConnections: notImplementedError('createConnections'),
	createConnectionsBulk: notImplementedError('createConnectionsBulk'),
	deleteConnections: notImplementedError('deleteConnections'),
	deleteConnectionsBulk: notImplementedError('deleteConnectionsBulk'),
	getConnectionBulkStatus: notImplementedError('getConnectionBulkStatus'),
});

export const createSharingIssueConnectionRemote = (): IssueConnectionRemote => ({
	...createNotImplementedIssueConnectionRemote(),
});

export const createIssueConnectionRemote = (): IssueConnectionRemote => ({
	...createNotImplementedIssueConnectionRemote(),
	deleteConnectionsBulk,
	createConnectionsBulk,
	getConnectionBulkStatus,
	createConnections,
	deleteConnections,
});
