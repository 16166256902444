import { useMemo } from 'react';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { useFieldOfType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useAllProperties } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import type { PropertyMaps } from '@atlassian/jira-polaris-common/src/controllers/issue/types.tsx';
import { useCurrentUserTimezone } from '@atlassian/jira-polaris-common/src/controllers/user/index.tsx';
import {
	useCurrentViewVisibleFields,
	useCurrentViewLayoutType,
	useCurrentViewVisibleIssueActionFields,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { CARD_HORIZONTAL_PADDING } from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/constants.tsx';
import { getCardHeights } from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/utils.tsx';
import { timestampAggDateToInterval } from '@atlassian/jira-polaris-domain-delivery/src/dates/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { INTERVAL_FIELD_SOURCES } from '@atlassian/jira-polaris-domain-field/src/field/interval/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { EXTERNAL_REFERENCE_PROPERTY_TEMPLATE } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/external-reference-property/types.tsx';
import type { TimeZone } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useColumnAndGroupAwareConnections } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/connection-hooks.tsx';
import {
	BOARD_CARD_HORIZONTAL_MARGIN,
	BOARD_COLUMN_BORDER_WIDTH,
	BOARD_COLUMN_PADDING,
	BOARD_COLUMN_WIDTH,
} from '@atlassian/jira-polaris-lib-board/src/constants.tsx';

type ColumnId = string;
type GroupId = string | undefined;
type BoardIssueId = `${ColumnId}-${GroupId}-${LocalIssueId}`;

export type BoardIssue = {
	id: BoardIssueId;
	localIssueId: string;
	column: {
		fieldKey: string;
		fieldValue: unknown;
	};
	group?: {
		fieldKey: string;
		fieldValue: unknown;
	};
};

export const createBoardIssueId = ({
	columnId,
	groupId,
	localIssueId,
}: {
	groupId?: string;
	columnId: string;
	localIssueId: LocalIssueId;
}): BoardIssueId => `${columnId}-${groupId}-${localIssueId}`;

type IssueMap = Map<
	BoardIssueId,
	Array<{
		key: string;
		field: Field;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		value: any;
	}>
>;

const populateIssueMapWithFormulaFields = (
	issueMap: IssueMap,
	currentFields: Field[],
	filteredIssues: BoardIssue[],
) => {
	const formulaFields = currentFields.filter(
		(field) =>
			field.type === FIELD_TYPES.FORMULA ||
			field.type === FIELD_TYPES.INSIGHTS ||
			field.type === FIELD_TYPES.LINKED_ISSUES,
	);

	if (formulaFields.length === 0) {
		return;
	}

	for (const { id } of filteredIssues) {
		const fields = [];

		for (const field of formulaFields) {
			fields.push({
				key: field.key,
				field,
				value: 1, // we're faking a value because evaluating all formulas would be too expensive
			});
		}

		if (issueMap.get(id)) {
			issueMap.get(id)?.push(...fields);
		}
	}
};

const populateIssueMapWithDeliveryFields = (
	issueMap: IssueMap,
	currentFields: Field[],
	filteredIssues: BoardIssue[],
	properties: PropertyMaps,
) => {
	const deliveryFields = currentFields.filter(
		(field) =>
			field.type === FIELD_TYPES.DELIVERY_PROGRESS || field.type === FIELD_TYPES.DELIVERY_STATUS,
	);

	if (deliveryFields.length === 0) {
		return;
	}

	for (const { id, localIssueId } of filteredIssues) {
		const fields = [];

		for (const field of deliveryFields) {
			if (properties.aggregatedDeliveryProgress[localIssueId] !== undefined) {
				fields.push({
					key: field.key,
					field,
					value: properties.aggregatedDeliveryProgress[localIssueId],
				});
			}
		}

		if (issueMap.get(id)) {
			issueMap.get(id)?.push(...fields);
		}
	}
};

const populateIssueMapWithAtlasProjectStatusFields = (
	issueMap: IssueMap,
	currentFields: Field[],
	filteredIssues: BoardIssue[],
	properties: PropertyMaps,
) => {
	const atlasProjectStatusFields = currentFields.filter(
		(field) => field.type === FIELD_TYPES.ATLAS_PROJECT_STATUS,
	);

	if (atlasProjectStatusFields.length === 0) {
		return;
	}

	for (const { localIssueId, id } of filteredIssues) {
		const fields = [];

		for (const field of atlasProjectStatusFields) {
			if (field.formula?.template !== EXTERNAL_REFERENCE_PROPERTY_TEMPLATE) {
				// eslint-disable-next-line no-continue
				continue;
			}

			const externalReferenceId = field.formula.parameters.externalReferenceFieldKey;
			let entityId = properties.externalReference[externalReferenceId][localIssueId];
			if (Array.isArray(entityId)) {
				[entityId] = entityId;
			}
			const value = entityId ? properties.externalReferenceEntities[entityId] : undefined;

			if (value) {
				fields.push({
					key: field.key,
					field,
					value,
				});
			}
		}

		if (issueMap.get(id)) {
			issueMap.get(id)?.push(...fields);
		}
	}
};

const populateIssueMapWithDateFieldsOverridenByAtlas = (
	issueMap: IssueMap,
	currentFields: Field[],
	atlasProjectField: Field | undefined,
	filteredIssues: BoardIssue[],
	properties: PropertyMaps,
) => {
	if (!atlasProjectField) {
		return;
	}

	const dateFieldsOverridenByAtlas = currentFields.filter(
		(field) =>
			field.type === FIELD_TYPES.INTERVAL &&
			field.configuration?.source === INTERVAL_FIELD_SOURCES.ATLAS_PROJECT_TARGET_DATE,
	);

	if (dateFieldsOverridenByAtlas.length === 0) {
		return;
	}

	for (const { localIssueId, id } of filteredIssues) {
		const fields = [];

		for (const field of dateFieldsOverridenByAtlas) {
			const allExternalReferenceEntities = properties.externalReferenceEntities;
			const externalReferenceValue =
				properties.externalReference[atlasProjectField.key] !== undefined
					? properties.externalReference[atlasProjectField.key][localIssueId]
					: undefined;
			const value =
				typeof externalReferenceValue === 'string'
					? allExternalReferenceEntities[externalReferenceValue]?.dueDate?.dateRange
					: undefined;

			if (value) {
				fields.push({
					key: field.key,
					field,
					value,
				});
			}
		}

		if (issueMap.get(id)) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const existingFields = issueMap.get(id)!;
			for (const field of fields) {
				const existingFieldIndex = existingFields.findIndex(
					(existingField) => existingField.key === field.key,
				);
				if (existingFieldIndex !== -1) {
					// Override the date value by the atlas date value
					existingFields[existingFieldIndex].value = field.value;
				} else {
					issueMap.get(id)?.push(field);
				}
			}
		}
	}
};

const populateIssueMapWithDateFieldsOverridenByDeliveryDates = (
	issueMap: IssueMap,
	currentFields: Field[],
	filteredIssues: BoardIssue[],
	properties: PropertyMaps,
	locale: Locale,
	currentUserTimeZone: TimeZone,
) => {
	const dateFieldsOverridenByDeliveryDates = currentFields.filter(
		(field) =>
			field.type === FIELD_TYPES.INTERVAL &&
			field.configuration?.source === INTERVAL_FIELD_SOURCES.DELIVERY_DATE,
	);

	if (dateFieldsOverridenByDeliveryDates.length === 0) {
		return;
	}

	for (const { id, localIssueId } of filteredIssues) {
		const fields = [];

		for (const field of dateFieldsOverridenByDeliveryDates) {
			const customFieldKey = field?.configuration?.customfieldKey;
			const aggregationType = field?.configuration?.aggregationType;

			const value = properties.aggregatedDeliveryDates[localIssueId]?.find(
				(v) =>
					v.fieldKey === customFieldKey &&
					v.aggregationType.toLowerCase() === aggregationType?.toLowerCase(),
			);
			const intervalValue = timestampAggDateToInterval(value, locale, currentUserTimeZone);

			if (intervalValue) {
				fields.push({
					key: field.key,
					field,
					value: JSON.stringify(intervalValue),
				});
			}
		}

		if (issueMap.get(id)) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const existingFields = issueMap.get(id)!;
			for (const field of fields) {
				const existingFieldIndex = existingFields.findIndex(
					(existingField) => existingField.key === field.key,
				);
				if (existingFieldIndex !== -1) {
					// Override the date value by the delivery date value
					existingFields[existingFieldIndex].value = field.value;
				} else {
					issueMap.get(id)?.push(field);
				}
			}
		}
	}
};

const populateIssueMapWithConnections = (
	issueMap: IssueMap,
	filteredIssues: BoardIssue[],
	filteredConnections: ReturnType<typeof useColumnAndGroupAwareConnections>,
) => {
	for (const { id } of filteredIssues) {
		const connectionFields = filteredConnections.get(id) ?? [];
		const existingFields = issueMap.get(id) ?? [];

		for (const field of connectionFields) {
			const existingFieldIndex = existingFields.findIndex(
				(existingField) => existingField.key === field.key,
			);
			if (existingFieldIndex !== -1) {
				existingFields[existingFieldIndex].value = field.value;
			} else {
				issueMap.get(id)?.push(field);
			}
		}
	}
};

const useFilteredConnectionsForIssues = ({ filteredIssues }: { filteredIssues: BoardIssue[] }) => {
	const currentFields = useCurrentViewVisibleFields();
	const connectionFieldKeys = useMemo(
		() => currentFields.filter((f) => f.type === FIELD_TYPES.CONNECTION).map((f) => f.key),
		[currentFields],
	);
	const filteredConnections = useColumnAndGroupAwareConnections(
		filteredIssues,
		connectionFieldKeys,
	);

	return filteredConnections;
};

// TODO: refactor this hook to use fieldMapping value accessors because at the moment it can break
// pretty easily

/**
 * This hook retrieves the values of all (visible) fields for each card, and then
 * calculates the heights of the cards.
 */
export const useBoardViewCardHeights = ({ filteredIssues }: { filteredIssues: BoardIssue[] }) => {
	const properties = useAllProperties();
	const currentFields = useCurrentViewVisibleFields();
	const filteredConnections = useFilteredConnectionsForIssues({ filteredIssues });
	const actionFields = useCurrentViewVisibleIssueActionFields();
	const layoutType = useCurrentViewLayoutType();
	const isCompact = layoutType === ViewLayoutType.COMPACT;
	const atlasProjectField = useFieldOfType(FIELD_TYPES.ATLAS_PROJECT);
	const { locale } = useTenantContext();
	const currentUserTimeZone = useCurrentUserTimezone();

	const cardHeightsMap = useMemo(() => {
		const actionFieldKeys = actionFields.map((field) => field.key);
		const currentFieldKeys = currentFields.map((field) => field.key);
		const issueMap: IssueMap = new Map();

		for (const { id } of filteredIssues) {
			issueMap.set(id, []);
		}

		// some fields need a specific treatment because their values need to be
		// retrieved differently
		populateIssueMapWithFormulaFields(issueMap, currentFields, filteredIssues);
		populateIssueMapWithDeliveryFields(issueMap, currentFields, filteredIssues, properties);
		populateIssueMapWithAtlasProjectStatusFields(
			issueMap,
			currentFields,
			filteredIssues,
			properties,
		);

		for (const [, fieldTypeMap] of Object.entries(properties)) {
			if (!fieldTypeMap) {
				// eslint-disable-next-line no-continue
				continue;
			}

			for (const [fieldKey, fieldKeyMap] of Object.entries(fieldTypeMap)) {
				if (!currentFieldKeys.includes(fieldKey) || !fieldKeyMap) {
					// eslint-disable-next-line no-continue
					continue;
				}

				for (const [localIssueId, value] of Object.entries(fieldKeyMap)) {
					const filteredfilteredIssues = filteredIssues.filter(
						(i) => i.localIssueId === localIssueId,
					);

					const field = currentFields[currentFieldKeys.indexOf(fieldKey)];

					// connections are set separately in populateIssueMapWithConnections
					if (field?.type === FIELD_TYPES.CONNECTION) {
						// eslint-disable-next-line no-continue
						continue;
					}

					filteredfilteredIssues.forEach(({ id }) => {
						issueMap
							// the field property will be set in the next nested loop
							.get(id)
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							?.push({ key: fieldKey, field: {} as Field, value });
					});
				}
			}
		}

		populateIssueMapWithDateFieldsOverridenByAtlas(
			issueMap,
			currentFields,
			atlasProjectField,
			filteredIssues,
			properties,
		);

		populateIssueMapWithDateFieldsOverridenByDeliveryDates(
			issueMap,
			currentFields,
			filteredIssues,
			properties,
			locale,
			currentUserTimeZone,
		);

		populateIssueMapWithConnections(issueMap, filteredIssues, filteredConnections);

		for (const { id } of filteredIssues) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const fields = issueMap.get(id)!;
			const visibleFields: Array<{
				key: string;
				field: Field;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				value: any;
			}> = [];

			for (const { key, value } of fields) {
				if (
					value !== undefined &&
					value !== null &&
					!(Array.isArray(value) && value.length === 0)
				) {
					const field = currentFields[currentFieldKeys.indexOf(key)];
					visibleFields.push({
						key,
						field,
						value,
					});
				}
			}

			issueMap.set(id, visibleFields);
		}

		const calculatedHeightMap = getCardHeights({
			width:
				BOARD_COLUMN_WIDTH -
				BOARD_COLUMN_PADDING * 2 -
				BOARD_COLUMN_BORDER_WIDTH * 2 -
				BOARD_CARD_HORIZONTAL_MARGIN * 2 -
				CARD_HORIZONTAL_PADDING * 2,
			isCompact,
			allFields: currentFields,
			issueMap,
			actionFieldKeys,
		});

		return calculatedHeightMap;
	}, [
		actionFields,
		currentFields,
		filteredIssues,
		properties,
		atlasProjectField,
		locale,
		currentUserTimeZone,
		isCompact,
		filteredConnections,
	]);

	return cardHeightsMap;
};
