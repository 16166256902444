import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import {
	type ExternalReferenceProvider,
	EXTERNAL_REFERENCE_PROVIDERS,
} from '@atlassian/jira-polaris-domain-field/src/field/external-reference/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { Link } from '@atlassian/react-resource-router';
import { useField } from '../../../../../controllers/issue/selectors/fields-hooks.tsx';
import { useCloseRightSidebar } from '../../../../../controllers/right-sidebar/actions/hooks.tsx';
import messages from './messages.tsx';

export const MovedConfigToProjectSettingsHint = ({
	provider,
}: {
	provider?: ExternalReferenceProvider;
}) => {
	const { formatMessage } = useIntl();
	const closeRightSidebar = useCloseRightSidebar();
	const projectKey = useProjectKeyUnsafe();
	const atlasIntegrationSettingsUrl = `/jira/polaris/projects/${projectKey}/ideas/settings/atlas-integration`;
	const sectionTitle =
		provider === EXTERNAL_REFERENCE_PROVIDERS.ATLAS_GOAL
			? formatMessage(messages.atlasGoalSectionTitle)
			: formatMessage(messages.atlasProjectSectionTitle);
	const sectionDescription =
		provider === EXTERNAL_REFERENCE_PROVIDERS.ATLAS_GOAL
			? formatMessage(messages.atlasGoalSectionDescription)
			: formatMessage(messages.atlasProjectSectionDescription);

	return (
		<SectionWrapper>
			<SectionMessage
				appearance="information"
				title={sectionTitle}
				actions={[
					<SectionMessageAction key="connect-atlas-site">
						<Button
							appearance="primary"
							component={Link}
							href={atlasIntegrationSettingsUrl}
							onClick={closeRightSidebar}
						>
							{formatMessage(messages.configureButton)}
						</Button>
					</SectionMessageAction>,
				]}
			>
				{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
				<p>{sectionDescription}</p>
				<Text>{formatMessage(messages.atlasSectionConfigurationMessage)}</Text>
			</SectionMessage>
		</SectionWrapper>
	);
};

export const AtlasConfiguration = ({ fieldKey }: { fieldKey: FieldKey }) => {
	const field = useField(fieldKey);

	return <MovedConfigToProjectSettingsHint provider={field?.configuration?.provider} />;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Text = styled.div({
	color: token('color.text.subtlest', N200),
	font: token('font.body.small'),
	marginTop: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > section > div > div:first-child': {
		display: 'none',
	},
});
