import React, { useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { POLARIS_GLOBAL_SUBMIT_IDEA_SWITCH_PROJECTS_TRIGGER } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants.tsx';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import { useProjectId } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useCanImportIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import ProjectPicker from '@atlassian/jira-project-picker/src/index.tsx';
import {
	useProjectAvatarUrls,
	useProjectKey,
	useProjectName,
} from '../../../../controllers/project/selectors/project-hooks.tsx';
import messages from './messages.tsx';

export const IMPORT_ISSUES_URL =
	'/secure/BulkCreateSetupPage!default.jspa?externalSystem=com.atlassian.jira.plugins.jim-plugin%3AbulkCreateCsv';

type Props = {
	onProjectSwitch: () => void;
};

export const ProjectInfo = ({ onProjectSwitch }: Props) => {
	const projectId = useProjectId();
	const projectMeta = useProjectMetadata({ projectId });
	const [projectKeyOld] = useProjectKey();
	const [projectNameOld] = useProjectName();
	const [projectAvatarUrlsOld] = useProjectAvatarUrls();
	const { formatMessage } = useIntl();

	const projectKey = fg('polaris-refactor-skipprojectinfo') ? projectMeta?.key : projectKeyOld;
	const projectName = fg('polaris-refactor-skipprojectinfo') ? projectMeta?.name : projectNameOld;
	const projectAvatarUrls = fg('polaris-refactor-skipprojectinfo')
		? projectMeta?.avatarUrls
		: projectAvatarUrlsOld;

	const canImportIssues = useCanImportIssues();

	const [, { openIssueCreateModal }] = useTriggerIssueCreateModal();

	const selectedProjectPicker = useMemo(() => {
		if (
			projectMeta?.id &&
			projectKey &&
			expVal('jpd_create_idea_template_experiment', 'ideaCreateFormExperimentEnabled', false)
		) {
			return {
				value: projectKey,
				name: projectName,
				label: projectName,
				avatarUrl: projectAvatarUrls?.['16x16'],
				details: {
					id: projectMeta.id,
					key: projectKey,
					issueTypes: [],
					lead: undefined,
					projectTypeKey: PRODUCT_DISCOVERY_PROJECT,
					simplified: true,
				},
			};
		}
		return undefined;
	}, [projectMeta, projectAvatarUrls, projectKey, projectName]);

	const onGlobalIssueCreate = useCallback(() => {
		onProjectSwitch();

		openIssueCreateModal({
			triggerPointKey: POLARIS_GLOBAL_SUBMIT_IDEA_SWITCH_PROJECTS_TRIGGER,
		});
	}, [onProjectSwitch, openIssueCreateModal]);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<ProjectPickerWithActionWrapper>
			<div>
				<ProjectLabel>{formatMessage(messages.projectLabel)}</ProjectLabel>
				{expVal('jpd_create_idea_template_experiment', 'ideaCreateFormExperimentEnabled', false) ? (
					<ProjectPicker
						// eslint-disable-next-line jira/deprecations/no-base-url
						baseUrl=""
						isCompact
						isClearable={false}
						width="300px"
						selectedProject={selectedProjectPicker}
						requireBrowsePermission
						placeholder=""
						onChange={onGlobalIssueCreate}
						renderAvatar={(avatarUrl) => (
							<Avatar appearance="square" size="xsmall" src={avatarUrl} />
						)}
					/>
				) : (
					<ProjectDetailsWrapper>
						<Avatar appearance="square" size="xsmall" src={projectAvatarUrls?.['16x16']} />
						<div>{`${projectName} (${projectKey})`}</div>
						<Button
							testId="polaris-common.ui.create-idea-dialog.form-modal-dialog.project-info.button"
							appearance="link"
							onClick={onGlobalIssueCreate}
						>
							{formatMessage(messages.switchProjectBtn)}
						</Button>
					</ProjectDetailsWrapper>
				)}
			</div>
			{canImportIssues &&
				!expVal(
					'jpd_create_idea_template_experiment',
					'ideaCreateFormExperimentEnabled',
					false,
				) && (
					<Button
						href={IMPORT_ISSUES_URL}
						testId="polaris-common.ui.create-idea-dialog.form-modal-dialog.project-info.import-issues-button"
						onClick={() => {
							fireUIAnalytics(
								createAnalyticsEvent({ action: 'clicked', actionSubject: 'importButton' }),
							);
						}}
					>
						{formatMessage(
							fg('polaris-issue-terminology-refresh')
								? messages.importIssuesIssueTermRefresh
								: messages.importIssues,
						)}
					</Button>
				)}
		</ProjectPickerWithActionWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectLabel = styled.div({
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.semibold'),
	marginBottom: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectDetailsWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectPickerWithActionWrapper = styled.div({
	display: 'flex',
	alignItems: 'flex-start',
	justifyContent: 'space-between',
});
