import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import EditorAddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import type { TriggerProps } from '@atlaskit/popup';
import { ButtonItem } from '@atlaskit/side-navigation';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { VIEW_CREATE_POPUP_TYPES } from '../../../types.tsx';

type SidebarSectionTriggerProps = {
	triggerProps: TriggerProps;
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
	label: string;
	type:
		| typeof VIEW_CREATE_POPUP_TYPES.SIDEBAR_SECTION_ITEM
		| typeof VIEW_CREATE_POPUP_TYPES.SIDEBAR_SUB_SECTION_ITEM;
};

export const SidebarSectionTrigger = ({
	triggerProps,
	isOpen,
	setIsOpen,
	label,
	type,
}: SidebarSectionTriggerProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'toggled',
				actionSubject: 'dropdown',
			}),
			'createView',
			{
				dropdownLocation:
					type === VIEW_CREATE_POPUP_TYPES.SIDEBAR_SECTION_ITEM ? 'navItem' : 'viewSectionNavItem',
			},
		);
		setIsOpen(!isOpen);
	}, [createAnalyticsEvent, isOpen, setIsOpen, type]);

	return (
		<ButtonItemWrapper>
			<ButtonItem
				{...triggerProps}
				testId="polaris-lib-create-view-popup.ui.triggers.sidebar-section-trigger"
				onClick={handleClick}
				iconBefore={<EditorAddIcon LEGACY_size="medium" spacing="spacious" label="" />}
				isSelected={isOpen}
			>
				{label}
			</ButtonItem>
		</ButtonItemWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonItemWrapper = styled.div<{ isActive?: boolean }>(
	{
		display: 'flex',
		alignItems: 'center',
		flex: 1,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& [data-item-elem-before]': {
			height: 'unset',
			width: 'unset',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		button: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			color: ({ isActive }) =>
				isActive
					? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						token('color.background.selected', colors.B400)
					: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						token('color.text.subtle', colors.N500),
			paddingTop: token('space.050'),
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
			paddingRight: '10px',
			paddingBottom: token('space.050'),
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
			paddingLeft: '18px',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			':active': {
				backgroundColor: token('color.background.neutral.subtle.hovered'),
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			':hover': {
				color: token('color.text'),
			},
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isActive }) =>
		isActive
			? {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					background: token('color.background.selected', colors.N20A),
					borderRadius: '3px',
				}
			: {},
);
