/** @jsx jsx */
import React, { useCallback, useMemo, useState, type SyntheticEvent } from 'react';
import { css, jsx } from '@compiled/react';
import { format } from 'date-fns';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/new';
import FileIcon from '@atlaskit/icon/core/migration/file';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { DATETIME_FORMAT } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { ExportCommonProps } from '../types.tsx';
import messages from './messages.tsx';
import type { StatusCategoryMap, onExportStringValuesHandler } from './types.tsx';
import { createCSVDownload, getFieldsLabels, joinMatrixAsCsv } from './utils.tsx';

export type ExportViewAsCsvProps = ExportCommonProps & {
	statusCategories: StatusCategoryMap | undefined;
	issueIds: string[];
	visibleFields: Field[];
	onExportStringValues: onExportStringValuesHandler;
};

export const ExportViewAsCsv = ({
	viewTitle,
	containerName,
	statusCategories,
	issueIds,
	visibleFields,
	isLoading,
	onExporting,
	onExportStringValues,
}: ExportViewAsCsvProps) => {
	const { formatMessage } = useIntl();
	const locale = useLocale();
	const [isExportingCsv, setIsExportingCsv] = useState(false);

	const fieldNeededValues = useMemo(
		() => ({
			locale,
			statusCategories,
			messages,
			formatMessage,
		}),
		[locale, statusCategories, formatMessage],
	);

	const stringMatrix = useMemo(() => {
		const valueMatrix = onExportStringValues(visibleFields, issueIds, fieldNeededValues);
		return [getFieldsLabels(visibleFields), ...valueMatrix];
	}, [onExportStringValues, visibleFields, issueIds, fieldNeededValues]);

	const exportAsCsv = useCallback(
		(valueMatrix: string[][]) => {
			if (viewTitle === undefined || containerName === undefined) {
				return;
			}

			const csvExport = joinMatrixAsCsv(valueMatrix);
			const filename = `${viewTitle} - ${containerName} ${format(new Date(), DATETIME_FORMAT)}.csv`;

			createCSVDownload(csvExport, filename);

			setIsExportingCsv(false);
			onExporting(false);
		},
		[viewTitle, containerName, setIsExportingCsv, onExporting],
	);

	const handleClick = useCallback(
		(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'downloadCSVButton');
			setIsExportingCsv(true);
			onExporting(true);
			exportAsCsv(stringMatrix);
		},
		[exportAsCsv, onExporting, stringMatrix],
	);

	return (
		<div
			css={[
				buttonWrapperStyles,
				!isVisualRefreshEnabled() &&
					fg('polaris_pol-12839_view_header_redesign') &&
					buttonWrapperWithVrOffStyles,
			]}
		>
			<Button
				id="pendo.export-modal.export-as-csv-button"
				testId="polaris-component-view-export.ui.export-view-dialog.export-dialog-content.export-as-csv.button"
				iconBefore={(iconProps) => (
					<FileIcon {...iconProps} label={formatMessage(messages.label)} LEGACY_size="medium" />
				)}
				appearance="subtle"
				onClick={handleClick}
				isDisabled={isLoading && !isExportingCsv}
				isLoading={isLoading && isExportingCsv}
				data-component-selector="export-as-csv-button-ls0D"
			>
				<Box
					xcss={[
						fg('polaris_pol-12839_view_header_redesign')
							? messageContainerStylesNew
							: messageContainerStylesOld,
						!isVisualRefreshEnabled() &&
							fg('polaris_pol-12839_view_header_redesign') &&
							messagesContainerWithVROffStyles,
					]}
				>
					{fg('polaris_pol-12839_view_header_redesign')
						? formatMessage(messages.exportAsCsv)
						: formatMessage(messages.button)}
				</Box>
			</Button>
		</div>
	);
};

const messageContainerStylesOld = xcss({
	textAlign: 'start',
});

const messageContainerStylesNew = xcss({
	textAlign: 'start',
	fontWeight: 'font.weight.regular',
	color: 'color.text',
});

const messagesContainerWithVROffStyles = xcss({
	paddingInlineStart: 'space.100',
});

const buttonWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="export-as-csv-button-ls0D"]': {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		height: '40px',
	},
});

const buttonWrapperWithVrOffStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="export-as-csv-button-ls0D"]': {
		paddingInlineStart: token('space.200'),
	},
});
