import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import Button from '@atlaskit/button';
import EditorSearchIcon from '@atlaskit/icon/core/migration/search--editor-search';
import SelectClearIcon from '@atlaskit/icon/core/migration/cross-circle--select-clear';
import Textfield, { type TextFieldProps } from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	placeholder?: string;
	testId?: string;
	value?: string;
	isCompact?: boolean;
	onChange: (value: string) => void;
} & Omit<TextFieldProps, 'onChange'>;

export const SearchInput = forwardRef<HTMLElement, Props>(
	({ placeholder = '', testId, value, isCompact = true, onChange, ...textFieldProps }, ref) => {
		const { formatMessage } = useIntl();
		const [localValue, setValue] = useState(value);
		const isUserTypingRef = useRef(false);

		const debouncedChange = useMemo(() => debounce(onChange, 200), [onChange]);

		useEffect(() => {
			// we need to sync local state because each view has own search value, check ref to not overwrite the input if user is typing
			if (isUserTypingRef.current) {
				isUserTypingRef.current = false;
			} else {
				setValue(value);
			}
		}, [value]);

		return (
			<Textfield
				ref={ref}
				{...textFieldProps}
				testId={testId}
				isCompact={isCompact}
				value={localValue}
				placeholder={placeholder}
				onChange={(event) => {
					isUserTypingRef.current = true;
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					const target = event.target as HTMLInputElement;
					setValue(target.value);
					debouncedChange(target.value);
				}}
				elemBeforeInput={
					<EditorSearchIcon spacing="spacious" color={token('color.icon', colors.N100)} label="" />
				}
				elemAfterInput={
					localValue && (
						<Button
							aria-label={formatMessage(messages.clear)}
							iconBefore={<SelectClearIcon label="" LEGACY_size="small" />}
							appearance="subtle-link"
							spacing="compact"
							onClick={() => {
								setValue('');
								onChange('');
							}}
						/>
					)
				}
			/>
		);
	},
);
