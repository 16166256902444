import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

type Props = {
	value: string | undefined;
	compact?: boolean;
};

export const Tag = memo<Props>(({ value, compact }: Props) => (
	<Content compact={compact}>
		<ValueWrapper>{value}</ValueWrapper>
	</Content>
));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div<{ compact?: boolean }>({
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.accent.gray.subtlest', colors.N20),
	borderRadius: '3px',
	marginLeft: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: ({ compact }) => `${compact ? 0 : token('space.100')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginTop: ({ compact }) => `${compact ? 0 : token('space.050')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBottom: ({ compact }) => `${compact ? 0 : token('space.050')}`,
	maxWidth: '100%',
	transition: '0.3s',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ValueWrapper = styled.div({
	paddingLeft: token('space.050'),
	paddingRight: token('space.050'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '250px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '20px',

	color: token('color.text'),
});
