import React, { useCallback } from 'react';
import LikeIcon from '@atlaskit/icon/core/migration/thumbs-up--like';
import { useIntl } from '@atlassian/jira-intl';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { useCanAddPlayContributions } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useIsExportingViewImage } from '@atlassian/jira-polaris-component-view-export/src/controllers/selectors.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIsEmbedded } from '../../../controllers/environment/index.tsx';
import {
	useGetIdeaPlayTotalBudget,
	useGetIdeaPlayUserContribution,
} from '../../../controllers/issue/selectors/properties/plays/hooks.tsx';
import { useOpenRightSidebarOnPlay } from '../../../controllers/right-sidebar/actions/hooks.tsx';
import { useCurrentUserAccountId } from '../../../controllers/user/index.tsx';
import { ValueButtonField } from '../value-button-field/index.tsx';
import messages from './messages.tsx';

type Props = {
	appearance: 'list' | 'board';
	localIssueId: LocalIssueId;
	playId: Ari;
	showPlaceholderLabel?: boolean;
};

// Deprecated, use PlayField instead
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { CommonPlayField } from './legacy';

export const PlayField = ({
	localIssueId,
	playId,
	appearance,
	showPlaceholderLabel = true,
}: Props) => {
	const { formatMessage } = useIntl();
	const openRightSidebarOnPlay = useOpenRightSidebarOnPlay();
	const embedded = useIsEmbedded();
	const totalBudget = useGetIdeaPlayTotalBudget(playId, localIssueId);
	const canAddPlayContributions = useCanAddPlayContributions();

	const currentUserAccountId = useCurrentUserAccountId();
	const currentUserContribution = useGetIdeaPlayUserContribution(
		playId,
		localIssueId,
		currentUserAccountId,
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isExportingViewImage = useIsExportingViewImage();

	const handleShow = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
			'fieldValue',
			{
				issueField: 'votes',
			},
		);
		openRightSidebarOnPlay(localIssueId, playId);
	}, [openRightSidebarOnPlay, localIssueId, playId, createAnalyticsEvent]);

	return (
		<ValueButtonField
			appearance={appearance === 'board' ? 'compact' : 'normal'}
			addGlyph={() => <LikeIcon LEGACY_size="small" label="vote" />}
			glyph={() => <LikeIcon LEGACY_size="small" label="vote" />}
			onClick={handleShow}
			hasUnread={false}
			placeholder={formatMessage(messages.placeholder)}
			placeholderAppearance={showPlaceholderLabel ? 'verbose' : undefined}
			isReadOnly={embedded}
			itemCount={totalBudget}
			highlight={!!currentUserContribution}
			label="vote"
			isExporting={isExportingViewImage}
			canAdd={canAddPlayContributions}
		/>
	);
};
