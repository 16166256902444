import React, { memo, useCallback, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import qs from 'query-string';
import { SpotlightTarget } from '@atlaskit/onboarding';
import AppStyle from '@atlassian/jira-common-components-app-style/src/index.tsx';
import { ErrorBoundaryFlag as ErrorBoundary } from '@atlassian/jira-error-boundary-flag-renderer/src/ErrorBoundaryFlag.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import IssueMeatballMenu from '@atlassian/jira-issue-meatball-menu/src/ui/index.tsx';
import type { MeatballMenuData } from '@atlassian/jira-issue-meatball-menu/src/ui/types.tsx';
import useConnectOperations from '@atlassian/jira-issue-non-critical-gira-service/src/controllers/use-connect-operations/index.tsx';
import { BOARD_SIDEBAR } from '@atlassian/jira-issue-view-common-constants/src/view-constants.tsx';
import { useIsEmbedMode } from '@atlassian/jira-issue-view-embed-mode/src/index.tsx';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import UnarchiveIssueModal from '@atlassian/jira-unarchive-modal/src/async.tsx';
import { useIsConcealActionsInMeatballExpEnabled } from '@atlassian/jira-is-conceal-actions-experiment-enabled/src/useIsConcealActionsInMeatballExpEnabled.tsx';
import {
	useAddToIssueActions,
	addToIssueDropdownGroup,
} from './add-to-issue-dropdown-group/index.tsx';
import { useAppsActions, appsDropdownGroup } from './apps-dropdown-group/index.tsx';
import { useSecurityActions, securityDropdownGroup } from './security-dropdown-group/index.tsx';
import {
	adminActionsDropdownGroup,
	useIssueAdminActions,
} from './admin-actions-dropdown-group/index.tsx';
import { CloneIssueModal } from './clone-issue/dialog/index.tsx';
import { CommandPaletteIssueActions } from './command-palette-issue-actions/index.tsx';
import { compalDropdownGroup } from './compal-button-dropdown-group/index.tsx';
import { feedbackComponentsProps } from './constants.tsx';
import DeleteIssueView from './delete-issue/index.tsx';
import { ForgeActionRenderer } from './ecosystem-action/forge/forge-action-renderer-view.tsx';
import ExportConfluenceModal from './export-confluence-modal/index.tsx';
import ExportPdfModal from './export-pdf-modal/index.tsx';
import FindFieldModal from './find-field/index.tsx';
import useMeatballMenuFocusReturn from './focus-return-meatball-menu/index.tsx';
import issueActionsDropdownGroup from './issue-actions-dropdown-group/index.tsx';
import issueConfigurationDropdownGroup from './issue-configuration-dropdown-group/index.tsx';
import issueExportBlockedFooterGroup from './issue-export-blocked-footer-group/index.tsx';
import issueExportDropdownGroup from './issue-export-dropdown-group/index.tsx';
import {
	useIssueManipulationActions,
	issueManipulationDropdownGroup,
} from './issue-manipulation-dropdown-group/index.tsx';
import type { IssueActionsMeatballMenuProps } from './types.tsx';
import { logWorkDropdownGroup, useLogworkActions } from './log-work-dropdown-group/index.tsx';
import issueOpenActionDropdownGroup from './open-action-dropdown-group/index.tsx';
import {
	useAddToIssueDropdownActions,
	addToIssueDropdownActionGroup,
} from './add-to-issue-action-group/index.tsx';
import {
	useAdminActions,
	issueAdminActionsDropdownGroup,
} from './issue-admin-actions-group/index.tsx';

const IssueActionsMeatballMenuInternal = ({
	isLoading,
	isForgeLoading,
	shouldShowPulse,
	issueDeleteCallbacks,
	onClose,
	shouldShowActions,
	shouldShowConfiguration,
	isSoftwareProjectType,
	viewModeOptions,
	analyticsSource,
	shouldShowStartTour,
	shouldShowFindOutMore,
	forgeIssueActions,
	hasParent,
	shouldShowExportActions,
	applicationKey,
	issueViewRelayFragment,
	shouldShowFeedback,
}: IssueActionsMeatballMenuProps) => {
	const triggerButtonRef = useRef<HTMLButtonElement>(null);
	const isEmbedMode = useIsEmbedMode();
	const addToIssueActions = useAddToIssueActions(forgeIssueActions ?? []);
	const addToIssueDropdownActions = useAddToIssueDropdownActions();
	const appsActions = useAppsActions(forgeIssueActions ?? []);
	const securityActions = useSecurityActions();

	const logWorkActions = useLogworkActions();
	const isConcealActionsExpEnabled = useIsConcealActionsInMeatballExpEnabled();

	const issueManipulationActions = useIssueManipulationActions(
		hasParent,
		issueDeleteCallbacks.onIssueDeleteSuccess,
		onClose,
	);

	const shouldShowFeedbackButton = isConcealActionsExpEnabled ? shouldShowFeedback : false;
	const feedbackButtonProps = isConcealActionsExpEnabled
		? feedbackComponentsProps[applicationKey]
		: undefined;

	const issueAdminActions = useIssueAdminActions();
	const adminActions = useAdminActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const setReturnFocusTo = useMeatballMenuFocusReturn(triggerButtonRef);
	const onClick = useCallback(
		(itemKey: string, event?: Event, actionAttributes: Attributes = {}) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'dropdownItem',
			});
			const itemId: string = itemKey.split(' ').join('');
			setReturnFocusTo(itemId);
			fireUIAnalytics(analyticsEvent, itemId, {
				...actionAttributes,
				...(isConcealActionsExpEnabled ? { source: 'meatball-menu' } : {}),
			});
		},
		[createAnalyticsEvent, isConcealActionsExpEnabled, setReturnFocusTo],
	);

	const issueKey = useIssueKey();
	const [{ isReady: areConnectOperationsReady }] = useConnectOperations(issueKey);

	if (!shouldShowActions) {
		return null;
	}
	if (isLoading || !areConnectOperationsReady) {
		return <IssueMeatballMenu isDisabled getMeatballMenuData={() => []} />;
	}
	const viewModeOptionsDefault = {
		viewModeSwitchEnabled: false,
	};

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const { jql, filter } = qs.parse(window.location.search);
	const isGinOrPin = jql != null || filter != null;

	const getMeatballMenuData = () =>
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		[
			// @ts-expect-error - TS2345 - Argument of type '(itemKey: string) => void' is not assignable to parameter of type '(itemKey: string) => Promise<undefined> | undefined'.
			addToIssueDropdownGroup(addToIssueActions, onClick),
			// @ts-expect-error - TS2345 - Argument of type '(itemKey: string) => void' is not assignable to parameter of type '(itemKey: string) => Promise<undefined> | undefined'.
			issueManipulationDropdownGroup(issueManipulationActions, onClick),
			// @ts-expect-error - TS2345 - Argument of type '(itemKey: string) => void' is not assignable to parameter of type '(itemKey: string) => Promise<undefined> | undefined'.
			adminActionsDropdownGroup(issueAdminActions, onClick),
			compalDropdownGroup(!isGinOrPin && !isEmbedMode, onClick),
			issueExportDropdownGroup(shouldShowExportActions),
			issueActionsDropdownGroup(
				shouldShowStartTour,
				shouldShowFindOutMore,
				shouldShowFeedbackButton,
				feedbackButtonProps,
			),
			issueConfigurationDropdownGroup(
				viewModeOptions || viewModeOptionsDefault,
				shouldShowConfiguration,
				isSoftwareProjectType,
				analyticsSource,
				issueViewRelayFragment,
			),
			...(shouldShowExportActions ? [] : [issueExportBlockedFooterGroup]),
		] as MeatballMenuData;

	const getNewMeatballMenuData = () =>
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		[
			// @ts-expect-error - TS2345 - Argument of type '(itemKey: string) => void' is not assignable to parameter of type '(itemKey: string) => Promise<undefined> | undefined'.
			logWorkDropdownGroup(logWorkActions, onClick),
			issueOpenActionDropdownGroup(
				viewModeOptions || viewModeOptionsDefault,
				isSoftwareProjectType,
				!isGinOrPin && !isEmbedMode,
				onClick,
				analyticsSource,
			),
			// @ts-expect-error - TS2345 - Argument of type '(itemKey: string) => void' is not assignable to parameter of type '(itemKey: string) => Promise<undefined> | undefined'.
			addToIssueDropdownActionGroup(addToIssueDropdownActions, onClick),
			// @ts-expect-error - TS2345 - Argument of type '(itemKey: string) => void' is not assignable to parameter of type '(itemKey: string) => Promise<undefined> | undefined'.
			securityDropdownGroup(securityActions, onClick),
			// @ts-expect-error - TS2345 - Argument of type '(itemKey: string) => void' is not assignable to parameter of type '(itemKey: string) => Promise<undefined> | undefined'.
			issueManipulationDropdownGroup(issueManipulationActions, onClick),
			// @ts-expect-error - TS2345 - Argument of type '(itemKey: string) => void' is not assignable to parameter of type '(itemKey: string) => Promise<undefined> | undefined'.
			appsDropdownGroup(appsActions, onClick),
			issueExportDropdownGroup(shouldShowExportActions),
			issueActionsDropdownGroup(
				shouldShowStartTour,
				shouldShowFindOutMore,
				shouldShowFeedbackButton,
				feedbackButtonProps,
			),
			issueAdminActionsDropdownGroup(
				shouldShowConfiguration,
				adminActions,
				// @ts-expect-error - TS2345 - Argument of type '(itemKey: string) => void' is not assignable to parameter of type '(itemKey: string) => Promise<undefined> | undefined'.
				onClick,
				issueViewRelayFragment,
			),
		] as MeatballMenuData;

	return (
		<ErrorBoundary>
			<AppStyle>
				<SpotlightTarget name={BOARD_SIDEBAR}>
					<IssueMeatballMenu
						hasPulse={shouldShowPulse}
						isLoading={isForgeLoading}
						getMeatballMenuData={
							isConcealActionsExpEnabled ? getNewMeatballMenuData : getMeatballMenuData
						}
						ref={triggerButtonRef}
					/>
					{/* @ts-expect-error TS2322: Missing deleteCancelAnalytics, deleteConfirmAnalytic */}
					<DeleteIssueView
						onIssueDeleteSuccess={issueDeleteCallbacks.onIssueDeleteSuccess}
						onIssueDeleteFailure={issueDeleteCallbacks.onIssueDeleteFailure}
						onClose={onClose}
					/>
					<UnarchiveIssueModal />
					<ForgeActionRenderer />
					<CloneIssueModal />
					<FindFieldModal />
					<ExportConfluenceModal />
					<ExportPdfModal />
					<CommandPaletteIssueActions />
				</SpotlightTarget>
			</AppStyle>
		</ErrorBoundary>
	);
};

export default memo<IssueActionsMeatballMenuProps>(IssueActionsMeatballMenuInternal, isEqual);
