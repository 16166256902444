import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Generic16Icon from '@atlaskit/icon-file-type/glyph/generic/16';
import { Card } from '@atlaskit/media-card';
import type { MediaClientConfig } from '@atlaskit/media-core';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { File } from '../../types/media/types.tsx';

type AttachmentAction = {
	icon: ReactNode;
	label: string;
	handler: () => void;
};

type AttachmentProps = {
	attachment?: File;
	mediaClientConfig: MediaClientConfig;
	actions?: AttachmentAction[];
	preview: boolean;
	onClick?: (cardEvent: { mediaItemDetails?: unknown }) => void;
	testId?: string;
};

const previewCardDimensions = {
	width: 188,
	height: 150,
} as const;

export const JiraAttachment = ({
	attachment,
	mediaClientConfig,
	actions,
	preview,
	onClick,
	testId,
}: AttachmentProps) => {
	let identifier;

	if (!attachment) {
		identifier = {
			// @ts-expect-error - Object literal may only specify known properties, and 'mediaApiFileId' does not exist in type 'FileIdentifier'
			mediaApiFileId: 'none',
			mediaItemType: 'file' as const,
		};
	} else {
		identifier = {
			id: attachment.attachmentMediaApiId,
			mediaItemType: 'file' as const,
		};
	}

	return (
		<AttachmentWrapper>
			<Card
				onClick={onClick}
				disableOverlay={preview}
				testId={testId}
				dimensions={preview ? previewCardDimensions : undefined}
				identifier={identifier}
				shouldOpenMediaViewer={!preview}
				useInlinePlayer={false}
				mediaClientConfig={mediaClientConfig}
				actions={actions}
			/>
		</AttachmentWrapper>
	);
};

JiraAttachment.defaultProps = {
	preview: false,
};

type RemoteAttachmentProps = {
	icon?: string;
	title: string;
	url: string;
};

export const RemoteAttachment = ({ icon, title, url }: RemoteAttachmentProps) => (
	<RemoteAttachmentWrapper href={url} target="_blank">
		{icon !== undefined ? (
			<RemoteAttachmentIcon src={icon} />
		) : (
			<RemoteAttachmentEmptyIcon>
				<Generic16Icon label="" />
			</RemoteAttachmentEmptyIcon>
		)}
		<RemoteAttachmentTitle>{title}</RemoteAttachmentTitle>
	</RemoteAttachmentWrapper>
);

type ImageProps = {
	src: string;
	preview: boolean;
	onClick?: (cardEvent: { mediaItemDetails?: unknown }) => void;
	alt?: string;
};

export const Image = ({ src, preview, onClick }: ImageProps) => {
	const identifier = {
		mediaItemType: 'external-image' as const,
		dataURI: src,
	};
	return (
		<AttachmentWrapper>
			{/* @ts-expect-error - Property 'mediaClientConfig' is missing */}
			<Card
				onClick={onClick}
				disableOverlay
				dimensions={preview ? previewCardDimensions : undefined}
				identifier={identifier}
				useInlinePlayer={false}
				shouldOpenMediaViewer={false}
			/>
		</AttachmentWrapper>
	);
};

Image.defaultProps = {
	preview: false,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AttachmentWrapper = styled.div({
	display: 'inline-flex',
	marginTop: token('space.150'),
	marginRight: token('space.150'),
	marginBottom: '0px',
	marginLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RemoteAttachmentWrapper = styled.a({
	display: 'flex',
	alignItems: 'center',
	paddingTop: '0px',
	paddingRight: '0px',
	paddingBottom: token('space.100'),
	paddingLeft: '0px',
	'&:hover, &:active, &:visited, &:link': {
		outline: 'none',
		textDecoration: 'none',
		boxShadow: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RemoteAttachmentIcon = styled.img({
	width: '16px',
	height: '16px',
	marginRight: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RemoteAttachmentEmptyIcon = styled.div({
	width: '16px',
	height: '16px',
	marginRight: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RemoteAttachmentTitle = styled.div({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
});
