import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { type Flag, toFlagId } from '@atlassian/jira-flags/src/services/types';
import type { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

const ISSUE_TRANSITION_SUCCESS_FLAG_ID = 'issueTransitionSuccessful';
const ISSUE_TRANSITION_FAILURE_FLAG_ID = 'issueTransitionFailure';

export const getTransitionSuccessFlag = (
	issueKey: string,
	status: string,
	formatMessage: ReturnType<typeof useIntl>['formatMessage'],
): Flag => {
	const showLinkToIssue = Boolean(issueKey && status);
	if (showLinkToIssue) {
		return {
			id: toFlagId(ISSUE_TRANSITION_SUCCESS_FLAG_ID),
			type: 'success',
			title: formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.issueTransitionSuccessfulWithIssueKeyAndStatusIssueTermRefresh
					: messages.issueTransitionSuccessfulWithIssueKeyAndStatus,
				{
					issueKey,
					status,
				},
			),
			description: messages.issueTransitionSuccessfulFlagDescription,
			messageId: `issue-transition-use-show-flag.ui.use-show-flag.${ISSUE_TRANSITION_SUCCESS_FLAG_ID}.success.issueTransitionSuccessfulWithIssueKeyAndStatus`,
			messageType: 'transactional',
		};
	}
	return {
		id: toFlagId(ISSUE_TRANSITION_SUCCESS_FLAG_ID),
		type: 'success',
		title: formatMessage(
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.issueTransitionSuccessfulIssueTermRefresh
				: messages.issueTransitionSuccessful,
		),
		messageId: `issue-transition-use-show-flag.ui.use-show-flag.${ISSUE_TRANSITION_SUCCESS_FLAG_ID}.success.issueTransitionSuccessful`,
		messageType: 'transactional',
	};
};

export const getTransitionFailureFlag = (
	issueKey: string,
	formatMessage: ReturnType<typeof useIntl>['formatMessage'],
): Flag => ({
	id: toFlagId(ISSUE_TRANSITION_FAILURE_FLAG_ID),
	type: 'error',
	title: issueKey
		? formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.issueTransitionFailureWithKeyIssueTermRefresh
					: messages.issueTransitionFailureWithKey,
				{ issueKey },
			)
		: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.issueTransitionFailureIssueTermRefresh
					: messages.issueTransitionFailure,
			),
	messageId: `issue-transition-use-show-flag.ui.use-show-flag.${ISSUE_TRANSITION_FAILURE_FLAG_ID}.error`,
	messageType: 'transactional',
});
