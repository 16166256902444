import React, { useState, type PropsWithChildren } from 'react';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';

type Props = PropsWithChildren<{
	label: string;
	description: string;
	initiallyOpen: boolean | undefined;
	controlsVisible?: boolean;
}>;

export const TogglableSection = ({
	label,
	description,
	initiallyOpen = false,
	children,
	controlsVisible = false,
}: Props) => {
	const [isOpen, setIsOpen] = useState(initiallyOpen);

	const shouldBeOpen = isOpen || controlsVisible === false;

	return (
		<>
			<Inline alignBlock="center" spread="space-between">
				<Text weight="bold">{label}</Text>
				{controlsVisible && (
					<Toggle isChecked={isOpen} onChange={({ target }) => setIsOpen(target.checked)} />
				)}
			</Inline>
			<Box xcss={descriptionStyle}>
				<Text size="UNSAFE_small">{description}</Text>
			</Box>
			{shouldBeOpen && <Box>{children}</Box>}
		</>
	);
};

const descriptionStyle = xcss({
	paddingBottom: 'space.050',
	marginTop: 'space.negative.050',
});
