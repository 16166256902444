import React, { type MouseEvent, type ReactNode, useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import messages from './messages.tsx';

interface MoreTagProps {
	children: ReactNode;
	onDialogOpen?: () => void;
	onDialogClose?: () => void;
}

export const MoreTag = ({ children, onDialogClose, onDialogOpen }: MoreTagProps) => {
	const [isReactionsDialogOpen, setReactionsDialogOpen] = useState(false);
	const { formatMessage } = useIntl();

	const onMoreBtnClick = useCallback(
		(evt: MouseEvent<HTMLDivElement>) => {
			evt.stopPropagation();
			setReactionsDialogOpen(true);
			onDialogOpen?.();
		},
		[onDialogOpen],
	);

	const handleDialogClose = () => {
		setReactionsDialogOpen(false);
		onDialogClose?.();
	};

	return (
		<PolarisInlineDialog
			noPadding
			placement="bottom"
			onClose={handleDialogClose}
			content={children}
			isOpen={isReactionsDialogOpen}
		>
			<MoreButtonWrapper onClick={onMoreBtnClick}>
				<Tooltip content={formatMessage(messages.moreTooltip)}>
					<MoreTagContainer>{formatMessage(messages.more)}</MoreTagContainer>
				</Tooltip>
			</MoreButtonWrapper>
		</PolarisInlineDialog>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MoreTagContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
	width: '36px',
	borderRadius: '20px',
	paddingTop: token('space.050'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.050'),
	font: token('font.heading.xxsmall'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: `${token('color.background.accent.gray.subtler', colors.N40)}`,

	boxShadow: `-2px 0px 8px 4px ${token('elevation.surface')}`,

	color: `${token('color.text')}`,
	clipPath: 'inset(0px 0px 0px -12px)',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MoreButtonWrapper = styled.div({
	position: 'absolute',
	right: '0',
	bottom: '0',

	background: `linear-gradient(270deg, ${token('elevation.surface')}, transparent)`,
});
