import { fg } from '@atlassian/jira-feature-gating';
import HttpError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { getDefaultOptions } from '@atlassian/jira-fetch/src/utils/fetch-default-options.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { getTraceId } from '@atlassian/jira-fetch/src/utils/trace-id.tsx';
import type {
	FieldConfiguration,
	FieldConfigurationUpdateType,
} from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { createJsonPatch } from '@atlassian/jira-polaris-lib-json-patch/src/index.tsx';

type ErrorResponseMessage = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errors?: any;
	errorMessages?: String[];
	httpStatusCode?: {
		empty: boolean;
		present: boolean;
	};
};

export const updatePolarisFieldConfiguration = async (
	fieldKey: string,
	oldConfiguration: FieldConfiguration,
	newConfiguration: FieldConfiguration,
	projectIdOrKey: number | string,
	issueTypeId: string,
	type?: FieldConfigurationUpdateType,
	isGlobalSystemField = false,
): Promise<void> => {
	try {
		experience.fieldsSidebar.fieldConfigurationUpdate.start();

		const patch = createJsonPatch(oldConfiguration, newConfiguration);
		if (patch.length === 0) {
			return Promise.resolve();
		}

		const url =
			isGlobalSystemField && fg('polaris_new_custom_types_global_system_fields')
				? `/rest/polaris/v2/fields/config/${fieldKey}/patch`
				: `/rest/polaris/v2/projects/${projectIdOrKey}/fields/${fieldKey}/configuration/patch`;
		const options = {
			headers: {
				'Content-Type': 'application/json-patch+json',
			},
			body: JSON.stringify({
				...(fg('jpd_use_field_association_helper') ? {} : { issueTypeId }),
				patch,
				...(isGlobalSystemField && fg('polaris_new_custom_types_global_system_fields')
					? {
							projectId: projectIdOrKey,
						}
					: {}),
			}),
		};

		const defaultOptions = getDefaultOptions(url);
		const mergedOptions = {
			...defaultOptions,
			...options,
			method: 'POST',
			headers: {
				...defaultOptions.headers,
				...options.headers,
			},
		};

		const response = await fetch(url, mergedOptions);

		if (!response.ok) {
			const message: ErrorResponseMessage = await response.json();
			const errorServerResponse =
				message.errorMessages?.join(' - ') ?? `Error server response: ${response.status}`;

			const traceId = getTraceId(response);
			throw new HttpError(response.status, errorServerResponse, traceId);
		}

		experience.fieldsSidebar.fieldConfigurationUpdate.success({
			metadata: {
				type,
			},
		});
	} catch (error) {
		if (isClientFetchError(error)) {
			experience.fieldsSidebar.fieldConfigurationUpdate.abort({
				metadata: {
					error:
						error instanceof Error
							? `FieldConfigurationUpdate: ${error.message}`
							: 'FieldConfigurationUpdate: Client fetch error',
					type,
				},
			});
		} else {
			experience.fieldsSidebar.fieldConfigurationUpdate.failure({
				metadata: {
					error:
						error instanceof Error
							? `FieldConfigurationUpdate: ${error.message}`
							: 'FieldConfigurationUpdate: Generic error',
					type,
				},
			});
		}

		throw error;
	}
};
