import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import messages from './messages.tsx';

type ValueProps = {
	value: ReactNode;
	intl: IntlShape;
};

type FieldValueProps = {
	value: ReactNode;
};

const Value = ({ value, intl }: ValueProps) => (
	<ValueContainer>{value || <Subtle>{intl.formatMessage(messages.none)}</Subtle>}</ValueContainer>
);

const InlineValue = ({ value, intl }: ValueProps) => (
	<InlineValueContainer>
		{value || <Subtle>{intl.formatMessage(messages.none)}</Subtle>}
	</InlineValueContainer>
);

const FieldValue = ({ value }: FieldValueProps) => (
	<FieldValueContainer>{value}</FieldValueContainer>
);

type Props = {
	isRichTextField?: Boolean;
	field?: ReactNode;
	from?: ReactNode;
	to?: ReactNode;
};

const GroupedChange = ({ field, from, to, isRichTextField = false }: Props) => {
	const intl = useIntl();

	return (
		<>
			{isRichTextField && (
				<InlineContainer>
					{field !== undefined && <FieldValue value={field} />}
					<InlineValue value={to} intl={intl} />
				</InlineContainer>
			)}
			{!isRichTextField && (
				<Container>
					{field !== undefined && <FieldValue value={field} />}
					{from !== undefined && <Value value={from} intl={intl} />}
					{from !== undefined && <Arrow />}
					{to !== undefined && <Value value={to} intl={intl} />}
				</Container>
			)}
		</>
	);
};

export default GroupedChange;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
	marginTop: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
	overflowWrap: 'anywhere',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InlineContainer = styled.div({
	marginTop: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
});

Container.displayName = 'Container';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
export const Subtle = styled.span({
	color: token('color.text.subtlest'),
});

Subtle.displayName = 'Subtle';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Arrow = styled.div({
	marginTop: 0,
	marginRight: token('space.100'),
	marginBottom: 0,
	marginLeft: token('space.100'),
	'&::before': {
		display: 'block',
		content: "'→'",
	},
});

Arrow.displayName = 'Arrow';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
export const ValueContainer = styled.div({
	color: token('color.text'),
	maxWidth: '50%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
export const InlineValueContainer = styled.div({
	color: token('color.text'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
export const FieldValueContainer = styled.div({
	color: token('color.text.subtle'),
	font: token('font.body.small'),
	fontWeight: token('font.weight.semibold'),
	marginRight: token('space.100'),
});
