import type { FieldsByKey } from '../collections/types.tsx';
import { isSystemFieldType, FIELD_TYPES } from '../field-types/index.tsx';
import type { FieldType } from '../field-types/types.tsx';
import { isGlobalSystemFieldStableId } from '../stable-ids/index.tsx';
import type { Field, FieldKey } from './types.tsx';

export const toFieldKey = (key: string): FieldKey => key;

export const getFieldId = (fieldKey: string): string => {
	const customFieldPrefix = 'customfield_';

	if (!fieldKey || !fieldKey.startsWith(customFieldPrefix)) {
		return '';
	}

	return fieldKey.replace(customFieldPrefix, '');
};

const FIELDS_CANNOT_BE_HIDDEN: Set<FieldType> = new Set([
	FIELD_TYPES.SUMMARY,
	FIELD_TYPES.ISSUE_KEY,
	FIELD_TYPES.CREATED,
]);

/** We only allow hiding jpd system fields, and even then there are a few exceptions. */
export const fieldCanBeHidden = (fieldType?: FieldType) => {
	if (!fieldType || !isSystemFieldType(fieldType)) {
		return false;
	}

	return !FIELDS_CANNOT_BE_HIDDEN.has(fieldType);
};

const FIELDS_CANNOT_BE_MAPPED_TO_GLOBAL: Set<FieldType> = new Set([
	FIELD_TYPES.FORMULA,
	FIELD_TYPES.REACTIONS,
	FIELD_TYPES.VOTES,
]);

export const canHaveValuesCopiedToGlobalField = (fieldType?: FieldType) =>
	fieldType && !isSystemFieldType(fieldType) && !FIELDS_CANNOT_BE_MAPPED_TO_GLOBAL.has(fieldType);

/** Predicate for filtering global fields to determine which are eligible to copy values to */
export const eligibleCopyTargetFilterPredicate = ({
	from: sourceFieldType,
	to: targetFieldType,
}: {
	from: FieldType | undefined;
	to: FieldType;
}) => {
	if (targetFieldType === sourceFieldType) {
		return true;
	}

	return (
		(sourceFieldType === FIELD_TYPES.MULTI_SELECT &&
			targetFieldType === FIELD_TYPES.JSW_MULTI_SELECT) ||
		(sourceFieldType === FIELD_TYPES.PEOPLE && targetFieldType === FIELD_TYPES.JSW_PEOPLE)
	);
};

const SYSTEM_FIELDS: Set<FieldType> = new Set([
	FIELD_TYPES.ISSUE_COMMENTS,
	FIELD_TYPES.INSIGHTS,
	FIELD_TYPES.LINKED_ISSUES,
	FIELD_TYPES.DELIVERY_PROGRESS,
	FIELD_TYPES.DELIVERY_STATUS,
	FIELD_TYPES.ARCHIVED,
	FIELD_TYPES.ARCHIVED_ON,
	FIELD_TYPES.ARCHIVED_BY,
	FIELD_TYPES.ATLAS_GOAL,
	FIELD_TYPES.ATLAS_PROJECT,
	FIELD_TYPES.ATLAS_PROJECT_STATUS,

	FIELD_TYPES.PLATFORM_GOALS,
	FIELD_TYPES.INSIGHTS_GLOBAL,
	FIELD_TYPES.LINKED_ISSUES_GLOBAL,
	FIELD_TYPES.DELIVERY_PROGRESS_GLOBAL,
	FIELD_TYPES.DELIVERY_STATUS_GLOBAL,
	FIELD_TYPES.ISSUE_COMMENTS_GLOBAL,
	FIELD_TYPES.PLATFORM_PROJECT,
	FIELD_TYPES.PLATFORM_PROJECT_STATUS,
]);

const SYSTEM_FIELDS_WITH_PROJECT_CONFIGURATION: Set<FieldType> = new Set([
	FIELD_TYPES.LINKED_ISSUES,
	FIELD_TYPES.DELIVERY_PROGRESS,
	FIELD_TYPES.DELIVERY_STATUS,

	FIELD_TYPES.LINKED_ISSUES_GLOBAL,
	FIELD_TYPES.DELIVERY_PROGRESS_GLOBAL,
	FIELD_TYPES.DELIVERY_STATUS_GLOBAL,
]);

export const hasSystemFields = (
	fields: FieldsByKey,
): { hasGlobalSystemFields: boolean; hasProjectSystemFields: boolean } => {
	let hasGlobalSystemFields = false;
	let hasProjectSystemFields = false;

	Object.values(fields).forEach((field) => {
		if (isGlobalSystemField(field)) {
			hasGlobalSystemFields = true;
			return;
		}

		if (isProjectSystemField(field)) {
			hasProjectSystemFields = true;
		}
	});

	return { hasGlobalSystemFields, hasProjectSystemFields };
};

export const isProjectSystemField = (field: Field): boolean => {
	return SYSTEM_FIELDS.has(field.type) && !field.global;
};

export const isGlobalSystemField = ({ global, custom, type, stableId }: Field): boolean => {
	if (global && custom && (SYSTEM_FIELDS.has(type) || isGlobalSystemFieldStableId(stableId))) {
		return true;
	}

	return false;
};

export const isGlobalNonSystemField = ({ global, custom, type, stableId }: Field): boolean => {
	if (global && custom && !(SYSTEM_FIELDS.has(type) || isGlobalSystemFieldStableId(stableId))) {
		return true;
	}

	return false;
};

export const isGlobalSystemFieldWithProjectLevelConfiguration = (field: Field): boolean => {
	return isGlobalSystemField(field) && SYSTEM_FIELDS_WITH_PROJECT_CONFIGURATION.has(field.type);
};
