import React, { useCallback, useEffect, useRef } from 'react';
import { styled } from '@compiled/react';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import TextField from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { MAX_SUMMARY_LENGTH, BLUR, ENTER } from '../common/constants.tsx';
import type { CreateIssueInputProps } from '../types.tsx';
import { InputForm } from './form/index.tsx';
import { messages } from './messages.tsx';
import { ValidationIcon } from './validation/index.tsx';

/**
 * @deprecated This component is deprecated and will be removed during "jpd_issues_relationships" FG cleanup
 * Please use the component from @atlassian/jira-polaris-component-issue-create-input instead
 */
export const CreateIssueInput = ({
	defaultValue = '',
	isCompact = false,
	placeholder,
	onCancel,
	onCreate,
	validationMessageType = 'popup',
	preventAutoFocusScroll = false,
	shouldPreventCreationOnBlur = () => false,
	testId = 'polaris-lib-create-issue-input.ui.text-field',
}: CreateIssueInputProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const textFieldRef = useRef<HTMLInputElement>(null);

	const validate = useCallback(
		(value?: string) => {
			if (value && value.length > MAX_SUMMARY_LENGTH) {
				return formatMessage(messages.maxLengthError);
			}

			return undefined;
		},
		[formatMessage],
	);

	const handleSubmit = useCallback(
		(summary: string, isSubmittedByBlur = false) => {
			const summaryTrimmed = summary.trim();

			// Discard if no value entered
			if (summaryTrimmed.length === 0) {
				onCancel();
				return;
			}

			fireUIAnalytics(
				createAnalyticsEvent({ action: 'submitted', actionSubject: 'form' }),
				'createIdea',
			);
			sendPendoTrackEvent({
				actionSubjectAndAction: 'form submitted',
				actionSubjectId: 'createIdea',
			});

			const createdBy = isSubmittedByBlur ? BLUR : ENTER;
			onCreate({ summary: summaryTrimmed }, { createdBy });
		},
		[onCancel, onCreate, createAnalyticsEvent],
	);

	useEffect(() => {
		if (preventAutoFocusScroll) {
			textFieldRef.current?.focus({ preventScroll: true });
		}
	}, [preventAutoFocusScroll]);

	return (
		<InputForm
			defaultValue={defaultValue}
			editView={({ errorMessage, isInvalid, ...props }) =>
				validationMessageType === 'icon' ? (
					<TextField
						{...props}
						ref={textFieldRef}
						autoFocus={!preventAutoFocusScroll}
						autoComplete="off"
						isCompact={isCompact}
						testId={testId}
						placeholder={placeholder}
						isInvalid={isInvalid}
						elemAfterInput={isInvalid && <ValidationIcon errorMessage={errorMessage} />}
					/>
				) : (
					<Popup
						autoFocus={false}
						isOpen={isInvalid}
						content={() => <WarningPopupContainer>{errorMessage}</WarningPopupContainer>}
						placement="bottom-end"
						trigger={(triggerProps) => (
							<TextField
								{...triggerProps}
								{...props}
								ref={textFieldRef}
								autoFocus={!preventAutoFocusScroll}
								autoComplete="off"
								isCompact={isCompact}
								testId={testId}
								placeholder={placeholder}
								isInvalid={isInvalid}
							/>
						)}
						testId={testId && `${testId}-popup`}
					/>
				)
			}
			onConfirm={handleSubmit}
			onCancel={onCancel}
			validate={validate}
			shouldPreventOnBlur={shouldPreventCreationOnBlur}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WarningPopupContainer = styled.div({
	display: 'block',
	boxSizing: 'border-box',
	paddingTop: token('space.050'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.100'),
	borderRadius: token('border.radius.100', '3px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.icon.danger', colors.R400),

	color: token('color.text.inverse'),
	boxShadow: token(
		'elevation.shadow.overlay',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`0 4px 8px -2px ${colors.N50A}, 0 0 1px ${colors.N60A}`,
	),
});
