import React, { memo, useEffect, useCallback, useRef, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import { N30 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { StatusValue } from '@atlassian/jira-issue-field-status/src/common/types.tsx';
import IssueStatusField from '@atlassian/jira-issue-field-status/src/ui/main.tsx';
import StatusViewLozenge from '@atlassian/jira-issue-field-status/src/ui/status-view-lozenge/index.tsx';
import type { StatusFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useIsEmbedded } from '../../../controllers/environment/index.tsx';

export type StatusFieldProps = {
	issueKey: IssueKey | undefined;
	issueId?: string;
	isEditable: boolean;
	value: StatusFieldValue | undefined;
	onSubmit: (oldStatus: StatusValue, newStatus: StatusValue) => void;
	onSuccess?: (newStatus: StatusValue) => void;
	onFailure?: (error?: Error) => void;
	testId?: string;
};

export const StatusField = memo<StatusFieldProps>(
	({ value, issueKey, isEditable, issueId, onSubmit, onSuccess, onFailure }: StatusFieldProps) => {
		const embedded = useIsEmbedded();

		const wrapper = useRef<HTMLDivElement | null>(null);
		const time = useRef<number | null>(null);

		const { createAnalyticsEvent } = useAnalyticsEvents();

		useEffect(() => {
			const statusButton = wrapper.current?.querySelector('button');
			const observer = new MutationObserver((mutations) => {
				mutations.forEach((mutation) => {
					if (mutation.type === 'attributes' && mutation.attributeName === 'aria-expanded') {
						if (statusButton && statusButton.getAttribute('aria-expanded') === 'false') {
							time.current = Date.now();
						}
					}
				});
			});

			if (statusButton) {
				observer.observe(statusButton, {
					attributes: true,
				});
			}

			return () => {
				observer.disconnect();
			};
		}, []);

		const onClick = useCallback((evt: MouseEvent<HTMLDivElement>) => {
			const statusButton = wrapper.current?.querySelector<HTMLElement>('button') ?? null;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if (!(evt.target as HTMLDivElement).contains(statusButton)) {
				return;
			}
			if ((time.current && Date.now() - time.current < 20) || !statusButton) {
				return;
			}
			statusButton.click();
		}, []);

		const onEditStart = useCallback(() => {
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'toggled', actionSubject: 'dropdown' }),
				'issueField',
			);
		}, [createAnalyticsEvent]);

		if (value === undefined || issueKey === undefined) {
			return null;
		}

		return isEditable ? (
			<Container showHoverStyle={!embedded} onClick={onClick}>
				<IssueStatusFieldWrapper ref={wrapper}>
					<IssueStatusField
						isEditable
						appearance="lozenge"
						initialValue={value}
						issueId={issueId}
						issueKey={issueKey}
						popperProps={{ strategy: 'absolute' }}
						onSubmit={onSubmit}
						onSuccess={onSuccess}
						onFailure={onFailure}
						onEditStart={onEditStart}
					/>
				</IssueStatusFieldWrapper>
			</Container>
		) : (
			<Container showHoverStyle={false}>
				<StatusViewLozenge
					isEditable={false}
					appearance="lozenge"
					initialValue={value}
					issueKey={issueKey}
					issueId={issueId}
					popperProps={{ strategy: 'absolute' }}
					projectType={null}
				/>
			</Container>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ showHoverStyle: boolean }>({
	paddingTop: token('space.075'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingRight: '5px',
	paddingBottom: token('space.075'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingLeft: '5px',
	borderRadius: '4px',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ showHoverStyle }) =>
			showHoverStyle && token('color.background.neutral.subtle.hovered', N30),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueStatusFieldWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& span[role='presentation']": {
		display: 'none',
	},
});
