import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import WatchIcon from '@atlaskit/icon/core/migration/eye-open--watch';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import loadingWrapper from '@atlassian/jira-issue-view-common-utils/src/loading-component-wrapper/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';

export const IssueWatchersLoading = () =>
	loadingWrapper(
		<div data-testid="issue-view-watchers.loading">
			<WatchIconWrapper>
				<WatchIcon
					label=""
					LEGACY_size="medium"
					spacing="spacious"
					color={token('color.icon.disabled', colors.N30)}
				/>
			</WatchIconWrapper>
		</div>,
	);

export type IssueWatchersBoundaryProps = {
	children: ReactNode;
	fallback: ReactNode;
	packageName: string;
};

export const IssueWatchersBoundary = ({
	children,
	fallback,
	packageName,
}: IssueWatchersBoundaryProps) => (
	<ErrorBoundary id="issue-watchers" packageName={packageName} render={() => fallback}>
		<Placeholder name="issue-watchers" fallback={fallback}>
			{children}
		</Placeholder>
	</ErrorBoundary>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WatchIconWrapper = styled.div({
	display: 'flex',
	marginTop: token('space.050'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginRight: '25px',
	marginBottom: token('space.050'),
	marginLeft: token('space.100'),
});
