/** @jsx jsx */
import React, { useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import EditorSuccessIcon from '@atlaskit/icon/core/migration/success--editor-success';
import EditorWarningIcon from '@atlaskit/icon/core/migration/warning';
import { Box, Grid, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useOpenIdeaViewFieldSidebar } from '@atlassian/jira-polaris-common/src/controllers/idea/utils/idea-view.tsx';
import {
	useGetFilterTypeForField,
	useIsInFilterResult,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/filters-hooks.tsx';
import {
	useActivePermanentFiltersFields,
	useIsQuickSearchFilterDefined,
	useFilter,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import { FilterComponent } from '@atlassian/jira-polaris-common/src/ui/filters/filter-component/index.tsx';
import { ISSUEID_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { Y300, G300 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { FieldComponent } from './field/index.tsx';
import messages from './messages.tsx';
import { SummaryFilterComponent } from './summary-filter/index.tsx';
import { ActiveFilters as ActiveFiltersLegacy } from './legacy.tsx';

type FilterStatusProps = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
};

const FilterStatus = ({ fieldKey, localIssueId }: FilterStatusProps) => {
	const { formatMessage } = useIntl();
	const filterType = useGetFilterTypeForField(fieldKey);
	const filter = useFilter({ fieldKey, filterType });
	const isInFilterResult = useIsInFilterResult(fieldKey, filter, localIssueId);

	if (isInFilterResult) {
		return (
			<Box>
				<EditorSuccessIcon
					color={token('color.icon.success')}
					label={formatMessage(messages.editorSuccessIcon)}
					LEGACY_primaryColor={G300}
					LEGACY_size="medium"
				/>
			</Box>
		);
	}

	return (
		<Box>
			<EditorWarningIcon
				color={token('color.icon.warning')}
				label={formatMessage(messages.editorWarningIcon)}
				LEGACY_primaryColor={Y300}
				LEGACY_size="medium"
			/>
		</Box>
	);
};

type Props = {
	localIssueId: LocalIssueId;
	portalElement?: HTMLElement;
	onClose: () => void;
};

export const ActiveFilters = componentWithFG(
	'polaris_fix-styles-for-filtered-issue-modal',
	({ localIssueId, portalElement, onClose }: Props) => {
		const { formatMessage } = useIntl();

		const activeFiltersFields = useActivePermanentFiltersFields();
		const isQuickSearchFilterDefined = useIsQuickSearchFilterDefined();

		const openIdeaViewFieldSidebar = useOpenIdeaViewFieldSidebar(localIssueId);
		const onManageField = useCallback(
			(fieldKey: string, optionId?: string) => {
				onClose();
				openIdeaViewFieldSidebar(fieldKey, optionId);
			},
			[openIdeaViewFieldSidebar, onClose],
		);

		const rowOffset = isQuickSearchFilterDefined ? 3 : 2;

		return (
			<Grid gap="space.050" templateColumns="3fr 48px 2fr">
				<Box xcss={filterHeaderStyles}>{formatMessage(messages.filterHeading)}</Box>
				<Box xcss={valueHeaderStyles}>{formatMessage(messages.valueHeading)}</Box>
				{isQuickSearchFilterDefined && (
					<>
						<div css={[filterWrapperStyles, gridRow2Styles]}>
							<SummaryFilterComponent />
						</div>
						<div css={[statusWrapperStyles, gridRow2Styles]}>
							<FilterStatus fieldKey="summary" localIssueId={localIssueId} />
						</div>
						<div css={[valueWrapperStyles, gridRow2Styles]}>
							<FieldComponent
								fieldKey="summary"
								localIssueId={localIssueId}
								onManageField={onManageField}
								portalElement={portalElement}
							/>
						</div>
					</>
				)}
				{activeFiltersFields.map((field, idx, { length }) => {
					// do not show issue-id filter
					if (field.key === ISSUEID_FIELDKEY) {
						return null;
					}

					if (field.type === FIELD_TYPES.ISSUE_TYPE && !fg('jpd_issues_relationships')) {
						return null;
					}

					return (
						<React.Fragment key={field.key}>
							{/* eslint-disable-next-line jira/react/no-style-attribute */}
							<div css={filterWrapperStyles} style={{ gridRow: idx + rowOffset }}>
								<FilterComponent field={field} isLastFilter={length - 1 === idx} />
							</div>
							{/* eslint-disable-next-line jira/react/no-style-attribute */}
							<div css={statusWrapperStyles} style={{ gridRow: idx + rowOffset }}>
								<FilterStatus fieldKey={field.key} localIssueId={localIssueId} />
							</div>
							{/* eslint-disable-next-line jira/react/no-style-attribute */}
							<div css={valueWrapperStyles} style={{ gridRow: idx + rowOffset }}>
								<FieldComponent
									fieldKey={field.key}
									localIssueId={localIssueId}
									onManageField={onManageField}
									portalElement={portalElement}
								/>
							</div>
						</React.Fragment>
					);
				})}
			</Grid>
		);
	},
	ActiveFiltersLegacy,
);

const gridRow2Styles = css({
	gridRow: 2,
});

const filterWrapperStyles = css({
	gridColumn: 1,
	alignSelf: 'center',
});

const filterHeaderStyles = xcss({
	font: 'font.body.UNSAFE_small',
	color: 'color.text',
	gridColumn: 1,
	gridRow: 1,
});

const valueHeaderStyles = xcss({
	font: 'font.body.UNSAFE_small',
	color: 'color.text',
	gridColumn: 3,
	gridRow: 1,
});

const valueWrapperStyles = css({
	gridColumn: 3,
	alignSelf: 'center',
	maxWidth: '350px',
	marginLeft: token('space.100'),
});

const statusWrapperStyles = css({
	gridColumn: 2,
	alignSelf: 'center',
	justifySelf: 'end',
});
