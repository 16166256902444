import React, {
	type ComponentType,
	type PropsWithChildren,
	type ReactNode,
	forwardRef,
} from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { PolarisTooltip } from '@atlassian/jira-polaris-lib-tooltip/src/ui/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useObservableValuesListContext } from '../context/index.tsx';
import messages from './messages.tsx';

interface MoreTagContentProps {
	count: number;
	ref?: React.Ref<HTMLDivElement>;
}

const MoreTagContent = forwardRef<HTMLDivElement, MoreTagContentProps>(({ count }, ref) => {
	const { formatMessage } = useIntl();
	const { HiddenCountComponent } = useObservableValuesListContext();

	return (
		<MoreTagWrapper ref={ref} data-component-selector="more-tag-wrapper-93Qc">
			{HiddenCountComponent ? (
				<HiddenCountComponent count={count} />
			) : (
				<MoreTagContainer data-component-selector="more-tag-container-327a">
					{formatMessage(messages.optionsCount, { count })}
				</MoreTagContainer>
			)}
		</MoreTagWrapper>
	);
});

type MoreTagProps = {
	children: ReactNode;
	showTooltip?: boolean;
	TooltipComponent?: ComponentType<{ children: ReactNode }>;
	count: number;
};

export const MoreTag = forwardRef<HTMLDivElement, MoreTagProps>(
	({ children, count, showTooltip = false, TooltipComponent }, ref) => {
		if (TooltipComponent) {
			return (
				<TooltipComponent>
					<MoreTagContent count={count} ref={ref} />
				</TooltipComponent>
			);
		}

		return showTooltip ? (
			<PolarisTooltip
				hideTooltipOnMouseDown
				content={<PreviewContainer>{children}</PreviewContainer>}
			>
				<MoreTagContent count={count} ref={ref} />
			</PolarisTooltip>
		) : (
			<MoreTagContent count={count} ref={ref} />
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MoreTagWrapperOld = styled.div({
	position: 'absolute',
	right: 0,
	bottom: token('space.050'),

	background: `linear-gradient(270deg, ${token('elevation.surface')}, transparent)`,
});

const moreTagWrapperStyles = xcss({
	position: 'absolute',
	right: 'space.0',
	bottom: '50%',
	transform: 'translateY(50%)',
	background: `linear-gradient(270deg, ${token('elevation.surface')}, transparent)`,
	zIndex: '1',
});

const MoreTagWrapper = componentWithFG(
	'jpd_issues_relationships',
	({ children }: PropsWithChildren) => <Box xcss={moreTagWrapperStyles}>{children}</Box>,
	MoreTagWrapperOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MoreTagContainer = styled.div({
	borderRadius: '3px',
	paddingTop: token('space.050'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.050'),
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.semibold'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.accent.gray.subtler', colors.N40),

	boxShadow: `-2px 0px 8px 4px ${token('elevation.surface')}`,

	color: token('color.text'),
	clipPath: 'inset(0px 0px 0px -12px)',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PreviewContainer = styled.span({
	display: 'flex',
	flexWrap: 'wrap',
	maxWidth: '370px',
});
