import React, { useState } from 'react';

import { Checkbox } from '@atlaskit/checkbox';
import { Box } from '@atlaskit/primitives';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	CONNECTION_FIELD_FILTER,
	CONNECTION_FIELD_FILTER_BOARD_COLUMN,
	CONNECTION_FIELD_FILTER_VIEW_GROUP,
	type ConnectionFieldFilter,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useConnectionFieldFilter } from '../../../../../controllers/views/selectors/filters-hooks.tsx';
import { useViewActions } from '../../../../../controllers/views/main.tsx';

import { TogglableSection } from './togglable-section.tsx';
import messages from './messages.tsx';

type FilterScope = {
	columns: boolean;
	groups: boolean;
};

type Props = {
	field: Field;
	columnsEnabled?: boolean;
	groupsEnabled?: boolean;
	togglable?: boolean;
};
export const InFieldConnectionFilter = ({
	field,
	groupsEnabled = false,
	columnsEnabled = false,
	togglable = false,
}: Props) => {
	const { formatMessage } = useIntl();

	const { updateConnectionFieldFilter } = useViewActions();

	const connectionFilter = useConnectionFieldFilter(field.key);

	const [connectionFieldScope, setConnectionFieldScope] = useState<FilterScope>({
		columns:
			connectionFilter?.values.find((v) => v.enumValue === CONNECTION_FIELD_FILTER_BOARD_COLUMN) !==
			undefined,
		groups:
			connectionFilter?.values.find((v) => v.enumValue === CONNECTION_FIELD_FILTER_VIEW_GROUP) !==
			undefined,
	});

	const onScopeChange = (scope: FilterScope) => {
		setConnectionFieldScope(scope);

		const values: ConnectionFieldFilter['values'] = [];

		if (scope.columns === true) {
			values.push({ enumValue: CONNECTION_FIELD_FILTER_BOARD_COLUMN });
		}

		if (scope.groups === true) {
			values.push({ enumValue: CONNECTION_FIELD_FILTER_VIEW_GROUP });
		}

		updateConnectionFieldFilter({
			field: field.key,
			type: CONNECTION_FIELD_FILTER,
			values,
		});
	};

	return (
		<TogglableSection
			initiallyOpen={connectionFieldScope.columns || connectionFieldScope.groups}
			label={formatMessage(messages.filterInField)}
			description={formatMessage(messages.filterInFieldDescription)}
			controlsVisible={togglable}
		>
			{columnsEnabled && (
				<Box>
					<Checkbox
						isChecked={connectionFieldScope.columns}
						label={formatMessage(messages.columns)}
						onChange={(event) => {
							onScopeChange({
								...connectionFieldScope,
								columns: event.target.checked,
							});
						}}
						name="checkbox-for-columns"
						testId="polaris-common.ui.filters.filter-component.filters.connection-filter.cb-columns"
					/>
				</Box>
			)}
			{groupsEnabled && (
				<Box>
					<Checkbox
						isChecked={connectionFieldScope.groups}
						label={formatMessage(messages.groups)}
						onChange={(event) => {
							onScopeChange({
								...connectionFieldScope,
								groups: event.target.checked,
							});
						}}
						name="checkbox-for-groups"
						testId="polaris-common.ui.filters.filter-component.filters.connection-filter.cb-groups"
					/>
				</Box>
			)}
		</TogglableSection>
	);
};
