import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	delete: {
		id: 'polaris-ideas.delete-modal.delete',
		defaultMessage: 'Delete',
		description: 'Button label for deleting a view section',
	},
	cancel: {
		id: 'polaris-ideas.delete-modal.cancel',
		defaultMessage: 'Cancel',
		description: 'Button label to cancel the deletion of view or view section',
	},
	deleteHeader: {
		id: 'polaris-ideas.delete-modal.delete-header',
		defaultMessage: 'Delete "{title}" ?',
		description: 'Header caption in delete view section confirmation dialog',
	},
	description: {
		id: 'polaris-ideas.delete-modal.description',
		defaultMessage: 'This deletes {title}. This action cannot be undone.',
		description: 'Description in view section confirmation dialog',
	},
});
