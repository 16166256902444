import React, { useCallback, useMemo, useState } from 'react';
import noop from 'lodash/noop';
import { graphql, useMutation, useFragment, useRefetchableFragment } from 'react-relay';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import iframeRedirect from '@atlassian/jira-common-navigation/src/iframe-redirect/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { useFlagsService } from '@atlassian/jira-flags/src/services';
import { ChangeIssueType } from '@atlassian/jira-issue-type-changer/src/ui/main.tsx';
import { ISSUE_HIERARCHY_LEVEL_SUBTASK } from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import type { IssueType } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import type { IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useSuspenselessRefetch } from '@atlassian/jira-issue-hooks/src/services/use-suspenseless-refetch/index.tsx';
import type {
	ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch_fragmentKey$key,
	ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch_fragmentKey$data,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch_fragmentKey.graphql';
import type {
	ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithoutRefetch_fragmentKey$key,
	ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithoutRefetch_fragmentKey$data,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithoutRefetch_fragmentKey.graphql';
import type { ui_issueTypeSwitcherWithoutRefetch_Mutation } from '@atlassian/jira-relay/src/__generated__/ui_issueTypeSwitcherWithoutRefetch_Mutation.graphql';
import type { ui_issueTypeSwitcherWithRefetch_Mutation } from '@atlassian/jira-relay/src/__generated__/ui_issueTypeSwitcherWithRefetch_Mutation.graphql';
import issueViewLayoutIssueTypeSwitcherRefetchQuery, {
	type issueViewLayoutIssueTypeSwitcherRefetchQuery as IssueViewLayoutIssueTypeSwitcherRefetchQuery,
} from '@atlassian/jira-relay/src/__generated__/issueViewLayoutIssueTypeSwitcherRefetchQuery.graphql';
import messages from './messages.tsx';

type AggValueShapeOld = Pick<
	ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithoutRefetch_fragmentKey$data,
	'issueType' | 'issueTypes'
>;

type AggValueShape = Pick<
	ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch_fragmentKey$data,
	'issueType' | 'issueTypes'
>;

export type AggItemValue = {
	readonly avatar:
		| {
				readonly xsmall: string | null | undefined;
		  }
		| null
		| undefined;
	readonly hierarchy:
		| {
				readonly level: number | null | undefined;
		  }
		| null
		| undefined;
	readonly id: string;
	readonly issueTypeId: string | null | undefined;
	readonly name: string;
};

const toComponentValueShape = (fieldData: AggItemValue): IssueType => ({
	id: Number(fieldData.issueTypeId ?? ''),
	iconUrl: fieldData.avatar?.xsmall ?? '',
	name: fieldData.name ?? '',
	subtask: fieldData.hierarchy?.level === ISSUE_HIERARCHY_LEVEL_SUBTASK,
});

function transformComponentValueToAggShapeOld(
	issueTypeValueLegacy: IssueType,
	issueTypes: ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithoutRefetch_fragmentKey$data['issueTypes'],
): AggValueShapeOld {
	return {
		issueType: issueTypes?.edges?.find(
			(fieldOption) => fieldOption?.node?.issueTypeId === issueTypeValueLegacy.id.toString(),
		)?.node,
		issueTypes,
	};
}

function transformComponentValueToAggShape(
	issueTypeValueLegacy: IssueType,
	issueTypes: ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch_fragmentKey$data['issueTypes'],
): AggValueShape {
	return {
		issueType: issueTypes?.edges?.find(
			(fieldOption) => fieldOption?.node?.issueTypeId === issueTypeValueLegacy.id.toString(),
		)?.node,
		issueTypes,
	};
}

export type IssueTypeSwitcherWithoutRefetchProps = {
	fragmentKey: ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithoutRefetch_fragmentKey$key;
};

export type IssueTypeSwitcherWithRefetchProps = {
	fragmentKey: ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch_fragmentKey$key;
};

export const useIsOpen = () => useState(false);

export const IssueTypeSwitcherWithRefetch = (props: IssueTypeSwitcherWithRefetchProps) => {
	const { showFlag } = useFlagsService();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();
	const [data, refetch] = useRefetchableFragment<
		IssueViewLayoutIssueTypeSwitcherRefetchQuery,
		ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch_fragmentKey$key
	>(
		graphql`
			fragment ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch_fragmentKey on JiraIssueTypeField
			@refetchable(queryName: "issueViewLayoutIssueTypeSwitcherRefetchQuery") {
				id
				fieldId
				type
				__typename
				issue {
					issueId
					projectField {
						project {
							projectStyle
						}
					}
				}
				issueType {
					id
					name
					issueTypeId
					avatar {
						xsmall
					}
					hierarchy {
						level
					}
				}
				issueTypes {
					edges {
						node {
							id
							issueTypeId
							name
							avatar {
								xsmall
							}
							hierarchy {
								level
							}
						}
					}
				}
			}
		`,
		props.fragmentKey,
	);

	const [isOpen, setIsOpen] = useIsOpen();

	const { id: uniqueFieldId, issueTypes, issue, issueType, fieldId, type } = data;

	const options = useMemo(
		() =>
			mapNodes(issueTypes)
				.filter((issueTypeNode) =>
					issue?.projectField?.project?.projectStyle === 'TEAM_MANAGED_PROJECT'
						? issueTypeNode.hierarchy?.level === issueType?.hierarchy?.level
						: issueTypeNode,
				)
				.map((option) => toComponentValueShape(option)),
		[issue?.projectField?.project?.projectStyle, issueType?.hierarchy?.level, issueTypes],
	);

	const onSubmit = useCallback(
		(value: AggItemValue | null | undefined) => {
			issue?.issueId &&
				fieldChangeRequested(issue?.issueId, fieldId, value, undefined, {
					type,
					__typename: data.__typename,
				});
		},
		[data.__typename, fieldId, type, fieldChangeRequested, issue?.issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggItemValue | null | undefined) => {
			if (issue?.issueId) {
				fieldChanged(issue?.issueId, fieldId, value, {
					type,
					__typename: data.__typename,
				});
			}

			showFlag({
				type: 'success',
				title: [messages.changeTypeSuccessTitle, { destType: value?.name }],
				description: messages.changeTypeSuccessDescription,
			});
		},
		[issue?.issueId, showFlag, fieldChanged, fieldId, type, data.__typename],
	);

	const onSubmitFailed = useCallback(() => {
		if (issue?.issueId) {
			fieldChangeFailed(issue?.issueId, fieldId);
		}
	}, [issue?.issueId, fieldChangeFailed, fieldId]);

	const [commit] = useMutation<ui_issueTypeSwitcherWithRefetch_Mutation>(graphql`
		mutation ui_issueTypeSwitcherWithRefetch_Mutation($input: JiraUpdateIssueTypeFieldInput!)
		@raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateIssueTypeField(input: $input) {
					success
					errors {
						message
					}
					field {
						issueType {
							id
							name
							issueTypeId
							avatar {
								xsmall
							}
							hierarchy {
								level
							}
						}
						issueTypes {
							edges {
								node {
									id
									issueTypeId
									name
									avatar {
										xsmall
									}
									hierarchy {
										level
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const handleSubmit = useCallback(
		(_issueKey: IssueKey, issueId: IssueId, currentType: IssueType, newType: IssueType) => {
			if (!currentType.subtask && newType.subtask) {
				return iframeRedirect(`/secure/ConvertIssue.jspa?id=${issueId}`);
			}
			if (currentType.subtask && newType.subtask) {
				return iframeRedirect(`/secure/MoveSubTaskChooseOperation!default.jspa?id=${issueId}`);
			}

			const newAggValue = transformComponentValueToAggShape(newType, issueTypes);
			onSubmit(newAggValue?.issueType);

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							id: newAggValue.issueType?.id ?? '',
						},
					},
				},
				onCompleted(mutationData) {
					if (mutationData.jira?.updateIssueTypeField?.success) {
						onSubmitSucceeded(newAggValue?.issueType);
					} else {
						onSubmitFailed();
						return iframeRedirect(`/secure/MoveIssue!default.jspa?id=${issueId}`);
					}
				},
				onError() {
					onSubmitFailed();
					showFlag({
						type: 'error',
						title: messages.changeTypeFailureTitle,
						description: messages.changeTypeFailureDescription,
					});
				},
				optimisticResponse: {
					jira: {
						updateIssueTypeField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								...newAggValue,
							},
						},
					},
				},
			});
		},
		[commit, issueTypes, onSubmit, onSubmitFailed, onSubmitSucceeded, showFlag, uniqueFieldId],
	);

	const [suspenselessRefetch] = useSuspenselessRefetch<
		IssueViewLayoutIssueTypeSwitcherRefetchQuery,
		ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch_fragmentKey$key
	>(issueViewLayoutIssueTypeSwitcherRefetchQuery, refetch, { fetchPolicy: 'network-only' });

	return (
		<ErrorBoundary id="change-issue-type" packageName="issue-view">
			<ChangeIssueType
				issueId={issue?.issueId ?? ''}
				issueTypes={options}
				isOpen={isOpen}
				value={issueType ? toComponentValueShape(issueType) : undefined}
				showIssueTypeList={setIsOpen}
				changeIssueType={handleSubmit}
				loadIssueTypes={noop}
				icon={<AsyncIcon url={issueType?.avatar?.xsmall} alt={issueType?.name} />}
				refetchIssueTypes={() =>
					suspenselessRefetch({
						id: uniqueFieldId,
					})
				}
			/>
		</ErrorBoundary>
	);
};

export const IssueTypeSwitcherWithoutRefetch = (props: IssueTypeSwitcherWithoutRefetchProps) => {
	const { showFlag } = useFlagsService();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();
	const data =
		useFragment<ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithoutRefetch_fragmentKey$key>(
			graphql`
				fragment ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithoutRefetch_fragmentKey on JiraIssueTypeField {
					id
					fieldId
					type
					__typename
					issue {
						issueId
						projectField {
							project {
								projectStyle
							}
						}
					}
					issueType {
						id
						name
						issueTypeId
						avatar {
							xsmall
						}
						hierarchy {
							level
						}
					}
					issueTypes {
						edges {
							node {
								id
								issueTypeId
								name
								avatar {
									xsmall
								}
								hierarchy {
									level
								}
							}
						}
					}
				}
			`,
			props.fragmentKey,
		);

	const [isOpen, setIsOpen] = useIsOpen();

	const { id: uniqueFieldId, issueTypes, issue, issueType, fieldId, type } = data;

	const options = useMemo(
		() =>
			mapNodes(issueTypes)
				.filter((issueTypeNode) =>
					issue?.projectField?.project?.projectStyle === 'TEAM_MANAGED_PROJECT'
						? issueTypeNode.hierarchy?.level === issueType?.hierarchy?.level
						: issueTypeNode,
				)
				.map((option) => toComponentValueShape(option)),
		[issue?.projectField?.project?.projectStyle, issueType?.hierarchy?.level, issueTypes],
	);

	const onSubmit = useCallback(
		(value: AggItemValue | null | undefined) => {
			issue?.issueId &&
				fieldChangeRequested(issue?.issueId, fieldId, value, undefined, {
					type,
					__typename: data.__typename,
				});
		},
		[data.__typename, fieldId, type, fieldChangeRequested, issue?.issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggItemValue | null | undefined) => {
			if (issue?.issueId) {
				fieldChanged(issue?.issueId, fieldId, value, {
					type,
					__typename: data.__typename,
				});
			}

			showFlag({
				type: 'success',
				title: [messages.changeTypeSuccessTitle, { destType: value?.name }],
				description: messages.changeTypeSuccessDescription,
			});
		},
		[issue?.issueId, showFlag, fieldChanged, fieldId, type, data.__typename],
	);

	const onSubmitFailed = useCallback(() => {
		if (issue?.issueId) {
			fieldChangeFailed(issue?.issueId, fieldId);
		}
	}, [issue?.issueId, fieldChangeFailed, fieldId]);

	const [commit] = useMutation<ui_issueTypeSwitcherWithoutRefetch_Mutation>(graphql`
		mutation ui_issueTypeSwitcherWithoutRefetch_Mutation($input: JiraUpdateIssueTypeFieldInput!)
		@raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateIssueTypeField(input: $input) {
					success
					errors {
						message
					}
					field {
						issueType {
							id
							name
							issueTypeId
							avatar {
								xsmall
							}
							hierarchy {
								level
							}
						}
						issueTypes {
							edges {
								node {
									id
									issueTypeId
									name
									avatar {
										xsmall
									}
									hierarchy {
										level
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const handleSubmit = useCallback(
		(_issueKey: IssueKey, issueId: IssueId, currentType: IssueType, newType: IssueType) => {
			if (!currentType.subtask && newType.subtask) {
				return iframeRedirect(`/secure/ConvertIssue.jspa?id=${issueId}`);
			}
			if (currentType.subtask && newType.subtask) {
				return iframeRedirect(`/secure/MoveSubTaskChooseOperation!default.jspa?id=${issueId}`);
			}

			const newAggValue = transformComponentValueToAggShapeOld(newType, issueTypes);
			onSubmit(newAggValue?.issueType);

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							id: newAggValue.issueType?.id ?? '',
						},
					},
				},
				onCompleted(mutationData) {
					if (mutationData.jira?.updateIssueTypeField?.success) {
						onSubmitSucceeded(newAggValue?.issueType);
					} else {
						onSubmitFailed();
						return iframeRedirect(`/secure/MoveIssue!default.jspa?id=${issueId}`);
					}
				},
				onError() {
					onSubmitFailed();
					showFlag({
						type: 'error',
						title: messages.changeTypeFailureTitle,
						description: messages.changeTypeFailureDescription,
					});
				},
				optimisticResponse: {
					jira: {
						updateIssueTypeField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								...newAggValue,
							},
						},
					},
				},
			});
		},
		[commit, issueTypes, onSubmit, onSubmitFailed, onSubmitSucceeded, showFlag, uniqueFieldId],
	);

	return (
		<ErrorBoundary id="change-issue-type" packageName="issue-view">
			<ChangeIssueType
				issueId={issue?.issueId ?? ''}
				issueTypes={options}
				isOpen={isOpen}
				value={issueType ? toComponentValueShape(issueType) : undefined}
				showIssueTypeList={setIsOpen}
				changeIssueType={handleSubmit}
				loadIssueTypes={noop}
				icon={<AsyncIcon url={issueType?.avatar?.xsmall} alt={issueType?.name} />}
			/>
		</ErrorBoundary>
	);
};

// TODO: move to internal package
type Nullable<T> = T | null | undefined;

type Connection<TNode> = {
	readonly edges?: Nullable<
		ReadonlyArray<
			Nullable<{
				readonly node?: Nullable<TNode>;
			}>
		>
	>;
} | null;

function mapNodes<TNode>(conn: Nullable<Connection<TNode>>): TNode[] {
	const nodes: TNode[] = [];

	return (
		conn?.edges?.reduce((acc, edge) => {
			if (edge?.node) {
				acc.push(edge.node);
			}

			return acc;
		}, nodes) ?? []
	);
}
