import React, { type MutableRefObject, useMemo, useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import Button from '@atlaskit/button';
import EditorSearchIcon from '@atlaskit/icon/core/migration/search--editor-search';
import SelectClearIcon from '@atlaskit/icon/core/migration/cross-circle--select-clear';
import Textfield from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

type Props = {
	isFocused: boolean;
	value?: string | undefined;
	onChange: (value: string) => void;
	placeholder: string;
	innerRef: MutableRefObject<unknown> | undefined;
	onFocus: () => void;
};

export const SearchBoxComponent = ({
	innerRef,
	value,
	placeholder,
	isFocused,
	onChange,
	onFocus,
}: Props) => {
	const [localValue, setValue] = useState(value);
	const ref = useRef<unknown>();

	const debouncedChange = useMemo(() => debounce(onChange, 200), [onChange]);

	useEffect(() => {
		if (isFocused) {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			ref.current?.focus();
		}
	}, [isFocused]);

	useEffect(() => {
		// Clear local value on filter clear.
		if (value === '') {
			setValue(value);
		}
	}, [value]);

	return (
		<Textfield
			ref={(node) => {
				ref.current = node;
				if (innerRef) {
					Object.assign(innerRef, { current: node });
				}
			}}
			isCompact
			value={localValue}
			placeholder={placeholder}
			onChange={(e) => {
				const { value: val } = e.currentTarget;
				setValue(val);
				debouncedChange(val);
			}}
			onMouseDown={(e) => {
				e.stopPropagation();
				// @ts-expect-error - TS2339 - Property 'focus' does not exist on type 'EventTarget'.
				e.target.focus();
			}}
			onTouchEnd={(e) => {
				e.stopPropagation();
				// @ts-expect-error - TS2339 - Property 'focus' does not exist on type 'EventTarget'.
				e.target.focus();
			}}
			onFocus={(e) => {
				e.stopPropagation();
				onFocus();
			}}
			elemBeforeInput={
				<EditorSearchIcon spacing="spacious" color={token('color.icon', colors.N100)} label="" />
			}
			elemAfterInput={
				localValue !== '' && (
					<Button
						iconBefore={<SelectClearIcon label="" LEGACY_size="small" />}
						appearance="subtle-link"
						spacing="compact"
						onClick={() => {
							setValue('');
							onChange('');
						}}
					/>
				)
			}
		/>
	);
};
