import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type {
	ConnectionFailure,
	ConnectionStatusResponse,
	ConnectionsBulkResponse,
} from '@atlassian/jira-polaris-remote-issue/src/services/jira/connection/types.tsx';
import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import type { GetUpdateIssueFieldsBulkProgress } from '@atlassian/jira-polaris-component-bulk-issue-update-progress/src/controllers/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { ISSUETYPE_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { Props, State } from '../../types.tsx';
import {
	getLocalIssueIdToJiraId,
	getLocalIssueIdsByJiraIssueId,
} from '../../selectors/issue-ids.tsx';
import { getIssueTypeProperties } from '../../selectors/properties/index.tsx';

type ConnectionOperation = 'create' | 'delete';

export const logUpdateIssueConnectionsError = (
	error?: Error,
	operation?: ConnectionOperation,
	isBulk = false,
) => {
	const operationString = operation ? `.${operation}` : '';

	fireErrorAnalytics({
		meta: {
			id: isBulk
				? `polaris.issue.action.updateIssueConnectionsBulk${operationString}`
				: `polaris.issue.action.updateIssueConnections${operationString}`,
			teamName: 'JPD - Sirius',
		},
		error,
		sendToPrivacyUnsafeSplunk: true,
	});
};

export const getFailedConnections = (
	failures: ConnectionFailure[],
	issues: ConnectionFieldValue[],
	operation: ConnectionOperation,
): ConnectionFieldValue[] => {
	return failures
		.map(({ issueTo, error }) => {
			logUpdateIssueConnectionsError(new Error(error), operation);

			return issues.find(({ id }) => id === issueTo.toString());
		})
		.filter(Boolean);
};

const runningStatusResponse: ConnectionStatusResponse = {
	progress: 0,
	status: 'RUNNING',
	failures: [],
};
const completeStatusResponse: ConnectionStatusResponse = {
	progress: 100,
	status: 'COMPLETE',
	failures: [],
};

export const createGetUpdateIssueFieldsBulkProgress = (
	createConnectionsResponse: ConnectionsBulkResponse | undefined,
	deleteConnectionsResponse: ConnectionsBulkResponse | undefined,
	props: Props,
): GetUpdateIssueFieldsBulkProgress => {
	const { issuesRemote } = props;

	let createConnectionsStatus: ConnectionStatusResponse = createConnectionsResponse
		? runningStatusResponse
		: completeStatusResponse;
	let deleteConnectionsStatus: ConnectionStatusResponse = deleteConnectionsResponse
		? runningStatusResponse
		: completeStatusResponse;

	return async () => {
		[createConnectionsStatus, deleteConnectionsStatus] = await Promise.all([
			createConnectionsStatus.progress !== 100 && createConnectionsResponse
				? issuesRemote.getConnectionBulkStatus(createConnectionsResponse.taskId)
				: createConnectionsStatus,
			deleteConnectionsStatus.progress !== 100 && deleteConnectionsResponse
				? issuesRemote.getConnectionBulkStatus(deleteConnectionsResponse.taskId)
				: deleteConnectionsStatus,
		]);

		const commonProgress =
			(createConnectionsStatus.progress + deleteConnectionsStatus.progress) / 2;
		const commonStatus =
			createConnectionsStatus.status === 'COMPLETE' && deleteConnectionsStatus.status === 'COMPLETE'
				? 'COMPLETE'
				: 'RUNNING';

		if (commonStatus === 'COMPLETE') {
			const failures: (ConnectionFailure & { operation: ConnectionOperation })[] = [
				...createConnectionsStatus.failures
					.map(({ issueToFailures }) => issueToFailures)
					.flat()
					.map((failure) => ({ ...failure, operation: 'create' as const })),
				...deleteConnectionsStatus.failures
					.map(({ issueToFailures }) => issueToFailures)
					.flat()
					.map((failure) => ({ ...failure, operation: 'delete' as const })),
			];

			failures.forEach(({ error, operation }) => {
				logUpdateIssueConnectionsError(new Error(error), operation, true);
			});
		}

		return {
			progress: commonProgress,
			status: commonStatus,
		};
	};
};

export const getFilteredIssuesForConnections = (
	localIssueId: LocalIssueId,
	issues: ConnectionFieldValue[],
	state: State,
	props: Props,
) => {
	const localIssueIdToJiraId = getLocalIssueIdToJiraId(state, props);
	const jiraIdToLocalIssueId = getLocalIssueIdsByJiraIssueId(state, props);
	const issueTypeProperties = getIssueTypeProperties(state)[ISSUETYPE_FIELDKEY];

	return issues.filter(
		({ id }) =>
			// Don't connect issue to itself
			id !== localIssueIdToJiraId[localIssueId] &&
			// Don't connect issue to the same issue type
			issueTypeProperties?.[localIssueId]?.id !==
				issueTypeProperties?.[jiraIdToLocalIssueId[Number(id)]]?.id,
	);
};
