/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useRef, type RefCallback, type RefObject } from 'react';
import { jsx, css } from '@compiled/react';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	FIELD_TYPES,
	type FieldType,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { useIssueKey } from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { ConnectionItem, type ConnectionItemProps } from '../connection-item/index.tsx';
import {
	useConnectionFieldHighlightedFieldKey,
	useField,
	useFieldLabel,
	useFieldTypeIcon,
} from '../../../controllers/field/selectors/field-hooks.tsx';
import { useConnectedIssueDataByIssueId } from '../../../controllers/issue/selectors/connection-hooks.tsx';
import { useElementSize } from '../../../common/utils/react/elements.tsx';
import {
	CONNECTION_ITEM_ELEM_AFTER_STYLES,
	CONNECTION_ITEM_LINE_HEIGHT,
} from '../connection-item/constants.tsx';
import { SummaryCardField } from '../../idea-card-v2/summary-card/summary-card-field-next/index.tsx';
import { useLocalIssueIdForJiraIssueId } from '../../../controllers/issue/selectors/issue-ids-hooks.tsx';
import messages from './messages.tsx';
import {
	CONNECTIONS_LIST_STYLES,
	CONNECTION_FIELD_ITEMS_LIMIT,
	CONNECTION_FIELD_LABEL_HEIGHT,
	CONNECTION_FIELD_TOP_PADDING,
} from './constants.tsx';

const EXCLUDED_HIGHLIGHTED_FIELDS: FieldType[] = [FIELD_TYPES.SHORT_TEXT];

const ConnectedIssue = ({
	issueId,
	containerRef,
	lastChildRef,
	fieldKey,
	...props
}: {
	issueId: string;
	fieldKey: FieldKey;
	containerRef: RefObject<HTMLDivElement>;
	lastChildRef?: RefCallback<HTMLDivElement>;
} & Pick<ConnectionItemProps, 'isLastChild'>) => {
	const localIssueId = useLocalIssueIdForJiraIssueId(issueId);
	const highlightedFieldKey = useConnectionFieldHighlightedFieldKey(fieldKey) ?? '';
	const [highlightedField] = useField(highlightedFieldKey);
	const containerWidth = containerRef.current?.offsetWidth ?? 0;
	const connectedIssue = useConnectedIssueDataByIssueId(issueId);

	if (connectedIssue === undefined) {
		return null;
	}

	return (
		<ConnectionItem
			summary={connectedIssue.summary}
			summaryRef={lastChildRef}
			issueKey={connectedIssue.issueKey}
			fieldType={highlightedField?.type}
			elementAfter={
				highlightedField &&
				!EXCLUDED_HIGHLIGHTED_FIELDS.includes(highlightedField.type) &&
				localIssueId && (
					<SummaryCardField
						field={highlightedField}
						issueId={localIssueId}
						maxWidth={CONNECTION_ITEM_ELEM_AFTER_STYLES.width}
						reservedSpace={CONNECTION_ITEM_ELEM_AFTER_STYLES.width}
						containerWidth={containerWidth}
						showCompactOption={false}
					/>
				)
			}
			{...props}
		/>
	);
};

type ConnectionsListProps = {
	connectionItems: ConnectionFieldValue[];
	fieldKey: string;
	localIssueId: string;
};

export const ConnectionsList = ({
	fieldKey,
	connectionItems,
	localIssueId,
}: ConnectionsListProps) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const { formatMessage } = useIntl();
	const [setLastConnectionRef, lastConnectionSize] = useElementSize();
	const label = useFieldLabel(fieldKey);
	const icon = useFieldTypeIcon(fieldKey, undefined);
	const issueKey = useIssueKey(localIssueId);

	if (connectionItems.length === 0) {
		return null;
	}

	const itemsOverflowing = connectionItems.length - CONNECTION_FIELD_ITEMS_LIMIT;
	const hasOverflowedItems = itemsOverflowing > 1;

	const connections = connectionItems
		// show 11th connection if needed instead of "+1 more"
		.slice(0, hasOverflowedItems ? CONNECTION_FIELD_ITEMS_LIMIT : CONNECTION_FIELD_ITEMS_LIMIT + 1)
		.map(({ id }, index, items) => {
			const isLast = !hasOverflowedItems && index === items.length - 1;
			return (
				<ConnectedIssue
					key={id}
					fieldKey={fieldKey}
					containerRef={containerRef}
					issueId={id}
					isLastChild={isLast}
					lastChildRef={isLast ? setLastConnectionRef : undefined}
				/>
			);
		});

	return (
		<div css={containerStyles}>
			<div css={labelStyles}>
				{icon}

				<Text color="color.text.subtlest" size="small">
					{label}
				</Text>
			</div>

			<div
				ref={containerRef}
				css={[
					listStyles,
					!hasOverflowedItems &&
						lastConnectionSize.height > CONNECTION_ITEM_LINE_HEIGHT &&
						wrappedListStyle,
				]}
			>
				{connections}

				{hasOverflowedItems && (
					<ConnectionItem
						summary={formatMessage(messages.more, { amount: itemsOverflowing })}
						issueKey={issueKey}
						issueViewSection="connections"
						isLastChild
					/>
				)}
			</div>
		</div>
	);
};

const containerStyles = css({
	gridColumn: '1 / -1',
	paddingBlockStart: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	paddingTop: CONNECTION_FIELD_TOP_PADDING,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	paddingLeft: `${CONNECTIONS_LIST_STYLES.paddingLeft}px`,
});

const labelStyles = css({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.075'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	height: CONNECTION_FIELD_LABEL_HEIGHT,
	marginLeft: token('space.negative.075'),
});

const wrappedListStyle = css({
	'&::before': {
		height: 'calc(100% - 27px)',
	},
});

const listStyles = css({
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
	gap: `${CONNECTIONS_LIST_STYLES.gap}px`,
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
	marginTop: `${CONNECTIONS_LIST_STYLES.marginTop}px`,
	position: 'relative',
	'&::before': {
		content: '""',
		position: 'absolute',
		borderLeft: `2px solid ${token('color.border')}`,
		borderBottom: `2px solid ${token('color.border')}`,
		borderBottomLeftRadius: '4px',
		transform: 'translateX(1px) translateY(-3px)',
		width: '8px',
		height: 'calc(100% - 7px)',
	},
});
