import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	sendToBacklogButton: {
		id: 'polaris-ideas.view-controls.manage-bar.send-to-backlog.send-to-backlog-button',
		defaultMessage: 'Create delivery tickets',
		description: 'Button label to send an idea to the backlog of another project.',
	},
	sendToBacklogButtonIssueTermRefresh: {
		id: 'polaris-ideas.view-controls.manage-bar.send-to-backlog.send-to-backlog-button-issue-term-refresh',
		defaultMessage: 'Create work items',
		description: 'Button label to send an idea to the backlog of another project.',
	},
});
