import React, { useMemo } from 'react';
import difference from 'lodash/difference';
import head from 'lodash/head';
import some from 'lodash/some';
import { IconButton } from '@atlaskit/button/new';
import TrashIcon from '@atlaskit/icon/core/migration/delete--trash';
import { Box, Flex, Stack, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useIsRightSidebarOpen } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import { useIsIssueOpenInSidebar } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useActivePermanentFiltersFields,
	useAvailableFiltersFields,
	useQuickSearchFilter,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import { useCanManageCurrentView } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { AddFilterComponent } from '@atlassian/jira-polaris-common/src/ui/filters/add/index.tsx';
import { ClearFilterComponent } from '@atlassian/jira-polaris-common/src/ui/filters/clear/index.tsx';
import { FilterComponent } from '@atlassian/jira-polaris-common/src/ui/filters/filter-component/index.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useTmpFiltersKeysState } from './hooks.tsx';
import { messages } from './messages.tsx';
import { QuickSearchFilterComponent } from './quick-search/index.tsx';

export const PolarisFilters = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { clearAllFilters } = useViewActions();
	const { clearFilter } = useViewActions();

	const canManageCurrentView = useCanManageCurrentView();

	// Fields used in filters (permanent)
	const activePermanentFiltersFields = useActivePermanentFiltersFields();
	// Available fields to use in filters
	const availableFiltersFields = useAvailableFiltersFields();

	// Local state filter keys, including not fully configured ones that are not on BE yet
	const { tmpFiltersKeys, setTmpFiltersKeys, tmpFieldKey } = useTmpFiltersKeysState({
		currentActiveFields: activePermanentFiltersFields,
	});

	// Alter labels and sort them alpabetically
	const sortedAvailableFiltersFields = useMemo(() => {
		// Alter filters names mapping
		const filterLabelsMap = {
			issueid: formatMessage(messages.excludeIdeas),
		};

		return availableFiltersFields
			.map((field: Field) => {
				// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ issueid: string; }'.
				if (filterLabelsMap[field.label]) {
					const newField = { ...field };
					// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ issueid: string; }'.
					newField.label = filterLabelsMap[field.label];
					return newField;
				}
				return field;
			})
			.sort(({ label: labelA }, { label: labelB }) => labelA.localeCompare(labelB));
	}, [availableFiltersFields, formatMessage]);

	const allFieldsMap = useMemo(
		() =>
			[...activePermanentFiltersFields, ...sortedAvailableFiltersFields].reduce<
				Record<string, Field>
			>((acc, field) => {
				acc[field.key] = field;
				return acc;
			}, {}),
		[activePermanentFiltersFields, sortedAvailableFiltersFields],
	);

	// Combine active and temp filters fields
	const allActivePermanentFiltersFields = useMemo(() => {
		const tmpFiltersSet = new Set(tmpFiltersKeys);

		return [
			...activePermanentFiltersFields.filter((field) => !tmpFiltersSet.has(field.key)),
			...tmpFiltersKeys.map((fieldKey) => allFieldsMap[fieldKey]),
		];
	}, [tmpFiltersKeys, activePermanentFiltersFields, allFieldsMap]);

	const helpMessage = canManageCurrentView ? messages.helpText : messages.sharedViewHelpText;

	// New filter listener
	const onAddFilter = (fieldKey?: FieldKey) => {
		const newFilter: Field | undefined = sortedAvailableFiltersFields.find(
			({ key }) => key === fieldKey,
		);

		if (newFilter) {
			setTmpFiltersKeys((oldFiltersKeys) => [...oldFiltersKeys, newFilter.key]);
		}
	};

	// Clear filter by FieldKey
	const onClearFilter = (key: FieldKey) => {
		const attributes = {
			filterFieldKey: key,
		};
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'clearFilter', attributes);
		sendPendoTrackEvent({
			actionSubjectAndAction: 'button clicked',
			actionSubjectId: 'clearFilter',
			attributes,
		});

		if (some(activePermanentFiltersFields, { key })) {
			experience.headerView.viewFilter.start();
			clearFilter(
				key,
				() => {
					experience.headerView.viewFilter.success();
				},
				(error?: Error) => {
					experience.headerView.viewFilter.failure(error);
				},
			);
		}

		setTmpFiltersKeys((prevTmpFiltersKeys) =>
			prevTmpFiltersKeys.filter((filterKey) => filterKey !== key),
		);
	};

	const onClearFilters = () => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'clearAllFilters');
		sendPendoTrackEvent({
			actionSubjectAndAction: 'button clicked',
			actionSubjectId: 'clearAllFilters',
		});

		experience.headerView.viewFilter.start();
		clearAllFilters(
			() => {
				experience.headerView.viewFilter.success();
			},
			(error?: Error) => {
				experience.headerView.viewFilter.failure(error);
			},
		);
		setTmpFiltersKeys([]);
	};

	return (
		<Stack xcss={filterContainerStyles} space="space.200">
			<Stack space="space.150">
				<Box>{formatMessage(helpMessage)}</Box>
				<Box>
					{allActivePermanentFiltersFields.map((field, idx, { length }) => (
						<Flex
							justifyContent="space-between"
							alignItems="center"
							key={field.key}
							gap="space.050"
						>
							<FilterComponent
								field={field}
								isLastFilter={length - 1 === idx}
								defaultOpen={tmpFieldKey === field.key}
							/>
							<IconButton
								interactionName="jpd.view-controls.clear-filter"
								id="pendo.config-filters.clear-filter-button"
								appearance="subtle"
								onClick={() => onClearFilter(field.key)}
								label={formatMessage(messages.closeButton)}
								icon={(iconProps) => <TrashIcon LEGACY_size="small" {...iconProps} />}
							/>
						</Flex>
					))}
					<Flex gap="space.050" xcss={actionsStyles}>
						<AddFilterComponent
							onAddFilter={onAddFilter}
							options={difference(sortedAvailableFiltersFields, allActivePermanentFiltersFields)}
						/>
						{allActivePermanentFiltersFields.length > 0 && (
							<ClearFilterComponent onClear={onClearFilters} />
						)}
					</Flex>
				</Box>
			</Stack>
		</Stack>
	);
};

export const PolarisQuickSearch = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const qsFilter = useQuickSearchFilter();
	const { updateQuickSearchFilter } = useViewActions();

	const [isRightSidebarOpen] = useIsRightSidebarOpen();
	const isOpenInSidebar = useIsIssueOpenInSidebar();

	const onUpdateFilterValue = (stringValue: string) => {
		fireUIAnalytics(createAnalyticsEvent({}), 'quickSearchFilter updated');

		updateQuickSearchFilter([{ stringValue }]);
	};

	const qsFilterVal = head(qsFilter.values.map(({ stringValue }) => stringValue)) ?? '';

	return isRightSidebarOpen || isOpenInSidebar ? null : (
		<Box xcss={quickSearchFilterComponentWrapperStyles}>
			<QuickSearchFilterComponent value={qsFilterVal} onChange={onUpdateFilterValue} />
		</Box>
	);
};

const actionsStyles = xcss({
	paddingBlock: 'space.050',
});

const filterContainerStyles = xcss({
	padding: 'space.200',
});

const quickSearchFilterComponentWrapperStyles = xcss({
	width: '240px',
	marginInlineStart: 'auto',
});
