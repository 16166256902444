import React, { useMemo } from 'react';
import {
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	useProjectKey,
	useIsClassifyEditEnabled,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectType,
	useProjectContext,
	useEdition,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { STANDARD_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import type { ShortcutOption } from '@atlassian/jira-shortcuts-dialog/src/common/types.tsx';
import { ClassifyIssueButtonItem } from '../add-to-issue-dropdown-group/classify-issue/button-item.tsx';
import {
	classifyIssueLabel,
	classifyRequestLabel,
} from '../add-to-issue-dropdown-group/classify-issue/index.tsx';
import { ClassifyIssue } from '../add-to-issue-dropdown-group/classify-issue/main.tsx';
import { RestrictIssueButtonItem } from '../add-to-issue-dropdown-group/restrict-issue/button-item.tsx';
import { restrictIssueLabel } from '../add-to-issue-dropdown-group/restrict-issue/index.tsx';
import { RestrictIssue } from '../add-to-issue-dropdown-group/restrict-issue/main.tsx';

export const securityDropdownGroup = (
	actions: ShortcutOption[],
	onClick: (
		itemKey: string,
		event?: Event,
		actionAttributes?: Attributes,
	) => Promise<undefined> | undefined,
) => ({
	name: '',
	key: 'SecurityDropdownGroup',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items: actions.reduce<Array<any>>((itemsArray, { Component, label, key }) => {
		if (Component) {
			itemsArray.push(<Component label={label} key={key} itemKey={key} onClick={onClick} />);
		}
		return itemsArray;
	}, []),
});

export const useSecurityActions = (): ShortcutOption[] => {
	const { formatMessage } = useIntl();
	const projectKey = useProjectKey();
	const projectContext = useProjectContext(projectKey);
	const projectType = useProjectType(projectKey);

	const edition = useEdition(projectKey, true);

	const canClassifyIssue = useIsClassifyEditEnabled();

	const canRestrictIssue =
		projectType === SOFTWARE_PROJECT &&
		edition === STANDARD_EDITION &&
		projectContext?.isSimplified;

	const items = useMemo(
		() => [
			...(canClassifyIssue
				? [
						{
							Component: ClassifyIssue,
							DialogComponent: ClassifyIssueButtonItem,
							label:
								projectType === SERVICE_DESK_PROJECT
									? formatMessage(classifyRequestLabel)
									: formatMessage(classifyIssueLabel),
							key: 'classifyIssue',
						},
					]
				: []),
			...(canRestrictIssue
				? [
						{
							Component: RestrictIssue,
							DialogComponent: RestrictIssueButtonItem,
							label: formatMessage(restrictIssueLabel),
							key: 'restrictAccess',
						},
					]
				: []),
		],
		[canClassifyIssue, canRestrictIssue, formatMessage, projectType],
	);

	return items;
};
