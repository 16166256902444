import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { AtlBrowserStorageLocal } from '@atlassian/browser-storage-controls';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';

const localStorage = createLocalStorageProvider('polaris');

const SIDEBAR_WIDTH_STORAGE_KEY = 'polaris-sidebar-width';
const SIDEBAR_WIDTH_STORAGE_KEY_OLD = 'sidebar-width';

export const setSidebarWidthToStorage = (width: number) => {
	try {
		if (fg('jira-web-storage-controls')) {
			AtlBrowserStorageLocal.setItem(SIDEBAR_WIDTH_STORAGE_KEY, width.toString());
			return;
		}
		localStorage.set(SIDEBAR_WIDTH_STORAGE_KEY_OLD, width);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(
			'polaris.sidebar-resizer-error',
			'Failed to save sidebar size to localStorage',
			error,
		);
	}
};

export const getSidebarWidthFromStorage = (): number | null => {
	if (fg('jira-web-storage-controls')) {
		const width = AtlBrowserStorageLocal.getItem(SIDEBAR_WIDTH_STORAGE_KEY);
		return width ? parseFloat(width) : null;
	}
	return parseFloat(localStorage.get(SIDEBAR_WIDTH_STORAGE_KEY_OLD)) || null;
};
