import { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { useSetFilteredLocalIssueId } from '../../../../controllers/create-with-filters/index.tsx';
import { messages } from './messages.tsx';
import { useTryApplyFiltersToCreatedIssue } from './utils.tsx';

export const useOnCreatedOrMovedIssueFiltered = () => {
	const { clearAllFilters } = useViewActions();
	const { success } = useNotifications();
	const setFilteredLocalIssue = useSetFilteredLocalIssueId();
	const { formatMessage } = useIntl();
	const tryApplyFiltersToCreatedIssue = useTryApplyFiltersToCreatedIssue();

	return useCallback(
		(issueId: string, isMoved = false, label?: string) => {
			const hasSuccessfullyAppliedFilters = tryApplyFiltersToCreatedIssue(issueId);
			if (hasSuccessfullyAppliedFilters) return;

			const movedTitle =
				label !== undefined
					? formatMessage(messages.moveSuccessSpecific, {
							columnLabel: label,
						})
					: formatMessage(messages.moveSuccessGeneric);

			const dismissFunction = success({
				title: isMoved ? movedTitle : formatMessage(messages.createSuccess),
				description: isMoved
					? formatMessage(messages.moveSuccessInvisibleDescription)
					: formatMessage(messages.createSuccessInvisibleDescription),
				actions: [
					{
						content: formatMessage(messages.clearFilters),
						onClick: () => {
							clearAllFilters();
							dismissFunction();
						},
					},
					{
						content: formatMessage(messages.editFiltersAndValues),
						onClick: () => {
							setFilteredLocalIssue(issueId);
							dismissFunction();
						},
					},
				],
			});
		},
		[clearAllFilters, formatMessage, setFilteredLocalIssue, success, tryApplyFiltersToCreatedIssue],
	);
};
