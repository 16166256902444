/** @jsx jsx */
import React, { useMemo, memo } from 'react';
import { styled, css, jsx } from '@compiled/react';
import UFOSegment from '@atlaskit/react-ufo/segment';
import { fg } from '@atlassian/jira-feature-gating';
import type { RowRendererProps } from '../../common/types/react-base-table/index.tsx';
import { useRowComponent } from '../../controllers/selectors/components-hooks.tsx';
import {
	useFixedColumnIndices,
	useHighlightedRow,
	useInvisibleColumnPlaceholderWidthLeft,
	useIsFramedDisplayMode,
	useVisibleColumnIndices,
} from '../../controllers/selectors/ui-hooks.tsx';
import { ROW_BORDER_WIDTH, activeBorderColor, borderColor } from '../constants.tsx';

const DefaultRowComponent = memo<RowRendererProps>(({ cells, rowData }: RowRendererProps) => {
	const fixedColumnIndices = useFixedColumnIndices();
	const visibleColumnIndices = useVisibleColumnIndices();
	const placeholderWidthLeft = useInvisibleColumnPlaceholderWidthLeft();
	const isFramedDisplayMode = useIsFramedDisplayMode();
	const highlightedRow = useHighlightedRow();
	const isHighlighted = highlightedRow === rowData.id;

	const fixedCells = useMemo(
		() => cells.filter((_, index) => fixedColumnIndices.includes(index)),
		[cells, fixedColumnIndices],
	);

	const visibleCells = useMemo(
		() =>
			cells.filter(
				(_, index) => visibleColumnIndices.includes(index) && !fixedColumnIndices.includes(index),
			),
		[cells, fixedColumnIndices, visibleColumnIndices],
	);

	return (
		<RowContainer
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={rowData.updated ? 'updated' : ''}
			data-testid="polaris-lib-list.ui.row.row"
		>
			{fixedCells}
			{placeholderWidthLeft > 0 && <PlaceholderLeft width={placeholderWidthLeft} />}
			{visibleCells}
			{!isFramedDisplayMode && <PlaceholderRight isHighlighted={isHighlighted} />}
		</RowContainer>
	);
});

export const RowComponent = memo<RowRendererProps>(({ ...props }: RowRendererProps) => {
	const ConsumerRowComponent = useRowComponent();

	if (ConsumerRowComponent !== undefined) {
		if (props.withUFOSegment) {
			return (
				<UFOSegment name="jpd.list.table-row">
					<ConsumerRowComponent {...props} DefaultComponent={DefaultRowComponent} />
				</UFOSegment>
			);
		}
		return <ConsumerRowComponent {...props} DefaultComponent={DefaultRowComponent} />;
	}

	if (props.withUFOSegment) {
		return (
			<UFOSegment name="jpd.list.table-row">
				<DefaultRowComponent {...props} />
			</UFOSegment>
		);
	}
	return <DefaultRowComponent {...props} />;
});

const PlaceholderLeftNext = memo(({ width }: { width: number }) => {
	// eslint-disable-next-line jira/react/no-style-attribute
	return <div css={placeholderLeftStyles} style={{ width: `${width}px` }} />;
});

const PlaceholderLeft = memo(({ width }: { width: number }) => {
	if (fg('polaris_idea_list_performance_improvements')) {
		return <PlaceholderLeftNext width={width} />;
	}

	return <PlaceholderLeftLegacy $width={width} />;
});

const PlaceholderRightNext = memo(({ isHighlighted }: { isHighlighted: boolean }) => {
	return <div css={[placeholderRightStyles, isHighlighted && placeholderRightActiveStyles]} />;
});

const PlaceholderRight = memo(({ isHighlighted }: { isHighlighted: boolean }) => {
	if (fg('polaris_idea_list_performance_improvements')) {
		return <PlaceholderRightNext isHighlighted={isHighlighted} />;
	}

	return <PlaceholderRightLegacy isHighlighted={isHighlighted} />;
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RowContainer = styled.div({
	display: 'flex',
	width: '100%',
	height: '100%',
	backgroundColor: 'inherit',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderLeftLegacy = styled.div<{ $width: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ $width }) => `${$width}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderBottom: `${ROW_BORDER_WIDTH}px solid ${borderColor}`,
});

const placeholderLeftStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	borderBottom: `${ROW_BORDER_WIDTH}px solid ${borderColor}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderRightLegacy = styled.div<{ isHighlighted: boolean }>({
	flex: 1,
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	borderTop: ({ isHighlighted }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		isHighlighted ? `${ROW_BORDER_WIDTH}px solid ${activeBorderColor}` : undefined,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	borderBottom: ({ isHighlighted }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		`${ROW_BORDER_WIDTH}px solid ${isHighlighted ? activeBorderColor : borderColor}`,
});

const placeholderRightStyles = css({
	flex: 1,
	boxSizing: 'border-box',
	borderBottomStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	borderBottomWidth: `${ROW_BORDER_WIDTH}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	borderBottomColor: borderColor,
});

const placeholderRightActiveStyles = css({
	borderTopStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	borderTopWidth: `${ROW_BORDER_WIDTH}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	borderTopColor: activeBorderColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	borderBottomColor: activeBorderColor,
});
