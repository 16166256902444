import mapKeys from 'lodash/mapKeys';
import mergeWith from 'lodash/mergeWith';
import { fetchCommentsAndInsightsMetadata } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/main.tsx';
import type { MetadataPerIssueAri } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { createIssueAri, createProjectAri } from '../../../../common/utils/ari/index.tsx';
import { getLocalIssueIdsByJiraIssueId } from '../../selectors/issue-ids.tsx';
import type { State, Props } from '../../types.tsx';

const setMetadata = (state: State, metadata: MetadataPerIssueAri, props: Props): State => {
	const ariToLocalIssueId = mapKeys(getLocalIssueIdsByJiraIssueId(state, props), (value, issueId) =>
		createIssueAri(props.cloudId, issueId),
	);
	return {
		...state,
		properties: {
			...state.properties,
			commentsMetadata: mapKeys(metadata.comments, (v, issueAri) => ariToLocalIssueId[issueAri]),
			insightsMetadata: mapKeys(metadata.insights, (v, issueAri) => ariToLocalIssueId[issueAri]),
		},
	};
};

export const loadTrackingMetadata =
	(): Action<State, Props> =>
	({ getState, setState }, props) => {
		const { cloudId, projectId, projectIds, apolloClient, isSharedView } = props;
		if (fg('jpd-aurora-roadmap-insights-field')) {
			if (!projectIds?.length || isSharedView) {
				return;
			}
			Promise.all(
				projectIds.map((pId) =>
					fetchCommentsAndInsightsMetadata(apolloClient, createProjectAri(cloudId, pId)),
				),
			)
				.then((metadatas) => ({
					comments: mergeWith({}, ...metadatas.map((m) => m.comments)),
					insights: mergeWith({}, ...metadatas.map((m) => m.insights)),
				}))
				.then((metadata) => {
					setState(setMetadata(getState(), metadata, props));
				});
		} else {
			if (!projectId || isSharedView) {
				return;
			}
			fetchCommentsAndInsightsMetadata(apolloClient, createProjectAri(cloudId, projectId)).then(
				(metadata) => {
					setState(setMetadata(getState(), metadata, props));
				},
			);
		}
	};
